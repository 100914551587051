import { useTranslation } from 'react-i18next'

import FinishedTag from '@components/finished-tag/finished-tag'
import { type UserProgramCourse } from '@components/user-program-entry/user-program-entry-interfaces'
import UserProgramExercises from '@components/user-program-entry/user-program-exercises'

const UserProgramCourseEntry = ({ courseExercises, finishedAt, index, uid }: UserProgramCourse) => {
  const { t: translateDays } = useTranslation('calendar', { keyPrefix: 'days' })

  const getDayByIndex = (index) => {
    switch (index) {
      case 1 : return translateDays('monday')
      case 2 : return translateDays('tuesday')
      case 3 : return translateDays('wednesday')
      case 4 : return translateDays('thursday')
      case 5 : return translateDays('friday')
      case 6 : return translateDays('saturday')
      case 7 : return translateDays('sunday')
      default: return null
    }
  }

  return (
    <div className='rounded-lg p-1 bg-white relative w-[250px] flex-none' >
      <div className='px-3 py-1 flex items-center gap-2'>
        <div>
          <div className='font-medium text-gray-700'>{getDayByIndex(index)}</div>
        </div>

        {finishedAt && <FinishedTag date={finishedAt} />}
      </div>

      <UserProgramExercises courseExercises={courseExercises} courseUid={uid} />
    </div>
  )
}

export default UserProgramCourseEntry
