import { create } from 'zustand'

import { NotificationType } from '@components/notification/notification.interfaces'

interface NotificationStore {
  displayNotification: (notificationTitle: string, notificationMessage: string, notificationType: NotificationType) => void
  hideNotification: () => void
  notificationDisplayed: boolean
  notificationMessage: string
  notificationTitle: string
  notificationType: NotificationType
}

export const useNotificationStore = create<NotificationStore>((set) => ({
  displayNotification: (notificationTitle, notificationMessage, notificationType) => {
    set({ notificationDisplayed: true, notificationMessage, notificationTitle, notificationType })

    setTimeout(() => {
      set({ notificationDisplayed: false })
    }, 10000)
  },
  hideNotification: () => {
    set({ notificationDisplayed: false })
  },
  notificationDisplayed: false,
  notificationMessage: '',
  notificationTitle: '',
  notificationType: NotificationType.success
}))
