import React from 'react'
import { useParams } from 'react-router-dom'

import AnalyseDay from '@components/analyse-day/analyse-day'
import { type AnalysePeriodProps } from '@pages/analyse-period/analyse-period-interfaces'

const AnalysePeriod = ({ analysePeriod, analysePeriodDays }: AnalysePeriodProps) => {
  const { id = '' } = useParams()

  if (Number(id) !== analysePeriod?.user.id) {
    return null
  }

  return (
    <div className='mb-6 border-gray-100 border-2 p-1 rounded-lg'>
      <div className='flex flex-col gap-1'>
        {analysePeriodDays?.map((day, index) => {
          if (index < 3) {
            return (
              <AnalyseDay analyseDay={day} index={index + 1} key={index} />
            )
          }

          return null
        })}
      </div>
    </div>
  )
}

export default AnalysePeriod
