import { type OpenAPIV3 } from 'openapi-types'

import { type Field } from '@interfaces/api/definition/field'
import { type MethodDefinition } from '@interfaces/api/definition/method-definition'
import { fieldsBlacklist } from '@services/api/definition'
import { type ApiDefinition } from '@services/api/definition/api-definition'

export class Method {
  apiDefinition: ApiDefinition
  definition: MethodDefinition

  constructor (methodDefinition: MethodDefinition, apiDefinition: ApiDefinition) {
    this.apiDefinition = apiDefinition
    this.definition = methodDefinition
  }

  getFieldsToDisplay () {
    const fields: Field[] = []
    const ref = this.definition.responses['200'].content?.['application/json'].schema.items.$ref
    if (ref) {
      const schema = this.getSchema(ref)
      if (schema?.properties) {
        const properties = schema.properties as Record<string, OpenAPIV3.SchemaObject>
        for (const key in properties) {
          if (!fieldsBlacklist.includes(key)) {
            fields.push({
              name: key,
              properties: properties[key]
            })
          }
        }
      }
    }

    return fields
  }

  getRequestFields () {
    const fields: Field[] = []
    const content = this.definition.requestBody.content
    let ref: string | null = null
    let schema: OpenAPIV3.SchemaObject | null = null

    if (content) {
      if (content['multipart/form-data']) {
        schema = content['multipart/form-data'].schema
      } else if (content['application/json']) {
        ref = content['application/json'].schema.$ref
      } else if (content['application/merge-patch+json']) {
        ref = content['application/merge-patch+json'].schema.$ref
      } else {
        console.error('Unknown content format', content)
      }
    }

    if (ref) {
      schema = this.getSchema(ref)
    }

    if (schema?.properties) {
      const properties = schema.properties as Record<string, OpenAPIV3.SchemaObject>
      for (const key in properties) {
        let required = false
        if (schema.required) {
          required = schema.required.includes(key)
        }
        fields.push({
          name: key,
          properties: properties[key],
          required
        })
      }
    }

    return fields
  }

  getSchema (ref: string) {
    let schema: OpenAPIV3.SchemaObject | null = null
    const refId = ref.split('/').slice(-1).toString()
    if (refId in this.apiDefinition.schemas) {
      schema = this.apiDefinition.schemas[refId] as OpenAPIV3.SchemaObject
    } else {
      console.error(`API Resource: Can't find ref ${ref} in Open API Definition schemas`)
    }

    return schema
  }
}
