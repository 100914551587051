import { ArrowLeftIcon, CameraIcon, PlayIcon, TrashIcon } from '@heroicons/react/24/outline'
import { ErrorMessage, useField } from 'formik'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import OLDApiResourceAutocomplete from '@components/api-resource/OLD-api-resource-autocomplete'
import { type FormVideoFieldProps } from '@components/form-fields/form-video-field/index'
import ModalVideoPlayer from '@components/modals/modal-video-player'
import VideoUploader from '@components/video/video-uploader'
import { type Video } from '@interfaces/api/video'
import { captureException } from '@services/exceptions/capture-exception'

const FormVideoField = ({ isUploading, label, name, setIsUploading }: FormVideoFieldProps) => {
  const validate = () => {
    if (isUploading) {
      return translateError('videoIsUploading')
    }
  }

  const fieldHookConfig = {
    name,
    validate
  }
  const [input, , helpers] = useField(fieldHookConfig)
  const { setValue } = helpers
  const { value } = input
  const [video, setVideo] = useState<Video>()
  const [videoIsOpen, setVideoIsOpen] = useState(false)
  const { t } = useTranslation('apiResources')
  const { t: translateGeneral } = useTranslation('general')
  const { t: translateError } = useTranslation('errors')

  useEffect(() => {
    if (typeof value === 'object') {
      setVideo(value)
    }
  }, [])

  useEffect(() => {
    if (typeof value === 'object') {
      setVideo(value)
    }
  }, [value])

  const onVideoChange = (video: Video) => {
    setVideo(video)
    setValue(video['@id']).catch(captureException)
  }

  useEffect(() => {
    if (isUploading) {
      setMode('upload')
    }
  }, [isUploading])

  const [mode, setMode] = useState<'upload' | 'find' | null>(null)

  const resetFieldMode = () => {
    setValue('').catch(captureException)
    setVideo(undefined)
    setMode(null)
  }

  const handleVideoClick = () => {
    setVideoIsOpen(true)
  }

  return (
    <div className='flex flex-col order-2'>
      <label className='block mb-2 font-medium text-gray-700' htmlFor={name}>{label}</label>

      {!video && (
        <>
          {mode !== null && (
            <button
              className='mb-2 flex gap-1 items-center text-gray-500 text-xs hover:text-gray-900'
              onClick={resetFieldMode}
            >
              <ArrowLeftIcon className='w-3 h-3' />

              {translateGeneral('back')}
            </button>
          )}

          <div className={`grid gap-4 ${mode === 'find' ? 'grid-cols-1' : mode === 'upload' ? 'grid-cols-1' : 'grid-cols-2'}`}>
            {mode !== 'find' && (
              <VideoUploader name={name} onUpload={setValue} setIsUploading={setIsUploading} />
            )}

            {mode !== 'upload' && (
              <div>
                {mode === null && (
                  <button
                    className='border-2 w-44 border-gray-100 rounded-lg placeholder-gray-400 focus:outline-none flex justify-between px-4 py-6 group'
                    onClick={() => {
                      setMode('find')
                    }}
                  >
                    <div className='w-full flex flex-col items-center justify-center'>
                      <CameraIcon className='w-6 h-6 m-auto' />

                      <span className='font-medium mt-2'>{t('actions.findInVideoLibrary')}</span>

                      <span className='text-xs text-gray-500'>{t('actions.findVideoDescription')}</span>
                    </div>
                  </button>
                )}

                {mode === 'find' && (
                  <OLDApiResourceAutocomplete
                    field='video'
                    onChange={onVideoChange}
                  />
                )}
              </div>
            )}
          </div>
        </>
      )}

      <ErrorMessage className='mt-2 text-xs text-red-600 font-medium' component='div' name={name} />

      {video && (
        <>
          <div className='h-32 w-48 rounded-lg overflow-hidden relative' >
            <button className='absolute w-full h-full left-0 top-0' onClick={handleVideoClick} type='button' />

            <PlayIcon className='w-12 h-12 fill-white absolute m-auto top-0 left-0 bottom-0 right-0 pointer-events-none' />

            <img alt='' className='w-full h-full object-cover bg-gray-900' src={video?.image?.url} />

            <button className='p-1.5 bg-white rounded-full absolute top-3 right-3 hover:bg-red-500 hover:fill-white' onClick={resetFieldMode}>
              <TrashIcon className='w-3 h-3' />
            </button>
          </div>

          <ModalVideoPlayer
            open={videoIsOpen}
            setOpen={setVideoIsOpen}
            video={video}
          />
        </>
      )}
    </div>
  )
}

export default FormVideoField
