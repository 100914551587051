import { useTranslation } from 'react-i18next'

const ErrorPage = () => {
  const { t } = useTranslation('general', { keyPrefix: 'errorPage' })

  return (
    <div className='min-h-full bg-white py-16 px-6 sm:py-24 md:grid md:place-items-center lg:px-8'>
      <div className='mx-auto max-w-max'>
        <main className='sm:flex'>
          <p className='text-4xl font-bold tracking-tight text-secondary sm:text-5xl'>404</p>

          <div className='sm:ml-6'>
            <div className='sm:border-l sm:border-gray-200 sm:pl-6'>
              <h1 className='text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl max-w-md'>{t('notFound')}</h1>
            </div>

            <div className='mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6'>
              <a
                className='flex gap-2 items-center rounded-lg px-4 py-2 text-lg font-semibold tracking-wide bg-primary text-white fill-white hover:bg-gray-600 hover:text-white'
                href='/'
              >
                {t('goBack')}
              </a>
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}

export default ErrorPage
