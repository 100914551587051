import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import Table from '@components/table'
import UserPurchasesTableEntry from '@components/table/table-data/user-purchases-table-entry'
import { type UserProgramsProps } from '@components/user/user-programs/user-programs-interfaces'
import useUserPurchasesQuery from '@services/api/user/use-user-purchases-query'

const UserPurchases = ({ userId }: UserProgramsProps) => {
  const { t: translatePurchase } = useTranslation('user', { keyPrefix: 'purchases' })

  const [searchParams, setSearchParams] = useSearchParams({ page: '1', status: 'PUBLISHED' })
  const pageIndex = Number(searchParams.get('page') ?? '1')
  const setPageIndex = (index: number) => {
    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      page: `${index}`
    })
  }

  const {
    data: {
      data: userPurchases,
      pagination,
      totalItems
    } = {},
    isLoading
  } = useUserPurchasesQuery({ page: pageIndex, user: userId })

  return (
    <div className='mt-6'>
      <Table
        headers={[translatePurchase('orderId'), translatePurchase('createdAt')]}
        isLoading={isLoading}
        noScroll
        pageIndex={pageIndex}
        pagination={pagination}
        setPageIndex={setPageIndex}
        totalItems={totalItems}
      >
        {
          userPurchases?.map((userPurchase, index) => (
            <UserPurchasesTableEntry key={index} {...userPurchase} />
          ))
        }

        {!userPurchases?.length && (
          <tr className='text-center'>
            <td className='p-6' colSpan={4}>
              <div className='text-gray-900 text-sm'>{translatePurchase('noPurchases')}</div>
            </td>
          </tr>
        )}
      </Table>

    </div>
  )
}

export default UserPurchases
