import { BellIcon, FireIcon, ShoppingCartIcon, XCircleIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'

import Guard from '@components/guard/guard'
import { type SidebarProps } from '@components/sidebar/sidebar.interfaces'
import useUserMeQuery from '@services/api/user/use-user-me-query'
import { useLogout } from '@services/hooks/auth-token'
import {
  audioRoles, calendarRoles, configurationRoles,
  exercisesRoles,
  ingredientsRoles,
  mealplansRoles,
  programsRoles, purchaseListRoles, userListRoles,
  videosRoles
} from '@services/roles/roles'
import { Calendar, Camera, ChevronDown, Clipboard, Home, Logout, Programs, Settings, Users, Volume } from '@svg/icons'

const navigation = [
  { href: '/', icon: Home, name: 'home' },
  { acceptedRoles: programsRoles, href: '/programs', icon: Programs, name: 'programs', title: 'fitness' },
  { acceptedRoles: exercisesRoles, href: '/exercises', icon: Clipboard, name: 'exercises' },
  { acceptedRoles: videosRoles, href: '/videos', icon: Camera, name: 'videos', sublinks: [{ href: '', name: 'allVideos' }, { href: '/playlists', name: 'playlists' }] },
  { acceptedRoles: audioRoles, href: '/audio', icon: Volume, name: 'audio' },
  { acceptedRoles: mealplansRoles, href: '/mealplans', icon: Calendar, name: 'mealPlans', title: 'nutrition' },
  { acceptedRoles: ingredientsRoles, href: '/ingredients', icon: FireIcon, name: 'ingredients' },
  { acceptedRoles: ingredientsRoles, href: '/recipes', icon: FireIcon, name: 'recipes' },
  { acceptedRoles: userListRoles, href: '/users', icon: Users, name: 'users', title: 'management' },
  { acceptedRoles: purchaseListRoles, href: '/purchases', icon: ShoppingCartIcon, name: 'purchases' },
  { acceptedRoles: programsRoles, href: '/notifications/new', icon: BellIcon, name: 'notifications' },
  { acceptedRoles: calendarRoles, href: '/calendar', icon: Calendar, name: 'calendar' },
  { acceptedRoles: configurationRoles, href: '/configuration', icon: Settings, name: 'configuration' }
]

const Sidebar = ({ open, setOpen }: SidebarProps) => {
  const { pathname } = useLocation()
  const { logout } = useLogout()
  const { t } = useTranslation('general', { keyPrefix: 'sidebar' })
  const [currentPage, setCurrentPage] = useState<string>('')

  const isCurrent = (href: string) => {
    if (href === '/') {
      return currentPage === href
    }

    return currentPage.includes(href)
  }

  const closeSidebar = () => {
    setOpen(false)
  }

  useEffect(() => {
    setCurrentPage(pathname)
  }, [pathname])

  const { data } = useUserMeQuery()
  const fullName = data?.firstName && data?.lastName ? `${data.firstName} ${data.lastName}` : ''

  return (
    <div className={classNames(
      'bg-gray-900 px-2 py-6 pb-0 transition h-[100vh] tracking-wide text-lg font-medium',
      'fixed z-20 top-0 left-0 min-w-max sm:min-w-min w-3/5',
      'before:fixed before:-z-10 before:inset-0 before:bg-gray-900 before:opacity-50',
      (open ? 'opacity-100 translate-x-0' : 'opacity-0 -translate-x-1/3 pointer-events-none'),
      'md:sticky md:w-56 md:before:hidden md:opacity-100 md:pointer-events-auto md:translate-x-0'
    )}
    >
      <div className='md:sticky h-full flex flex-col overflow-y-auto scrollbar-hide top-6'>
        <div className='flex items-center flex-shrink-0 px-4'>
          <Link to={'/'}>
            <img
              alt='logo'
              className='h-auto w-20'
              src='/assets/theme/logo-white.svg'
            />
          </Link>

          <button className='p-2 ml-auto md:hidden' onClick={closeSidebar}>
            <XCircleIcon className='w-8 h-8 text-white' />
          </button>
        </div>

        <nav className='mt-2 md:mt-6 flex-1'>
          {navigation.map((item) => (
            <Guard acceptedRoles={item.acceptedRoles} errorPage={false} key={item.name}>
              <>
                {item.title && (
                  <div className='uppercase mt-4 md:mt-8 mb-2 md:mb-3 text-sm text-gray-200 px-2'>{t(item.title)}</div>
                )}

                <Link
                  className={classNames(
                    isCurrent(item.href) ? 'bg-gray-800 text-white' : 'text-gray-400 hover:bg-gray-800 hover:text-white',
                    'group flex items-center px-2 py-2 my-2 text-md rounded-md'
                  )}
                  to={item.href}
                >
                  <item.icon
                    aria-hidden='true'
                    className={classNames(
                      isCurrent(item.href) ? 'fill-white' : 'fill-gray-400 group-hover:fill-gray-300',
                      'mr-3 flex-shrink-0 h-6 w-6'
                    )}
                  />

                  {t(item.name)}
                </Link>

                {isCurrent(item.href) && item.sublinks?.map((link, index) => {
                  const sublinkPath = link.href

                  return (
                    <Link
                      className={classNames(
                        currentPage === sublinkPath ? 'bg-gray-800 text-white' : 'text-gray-200 hover:bg-gray-800 hover:text-white',
                        'group flex items-center px-4 py-1 my-1 text-md  rounded-md'
                      )}
                      key={index}
                      to={sublinkPath}
                    >
                      <ChevronDown
                        aria-hidden='true'
                        className={classNames(
                          currentPage === sublinkPath ? 'fill-white' : 'fill-gray-400 group-hover:fill-gray-300',
                          'mr-3 flex-shrink-0 h-6 w-6 -rotate-90'
                        )}
                      />

                      {t(link.name)}
                    </Link>
                  )
                })}
              </>
            </Guard>
          ))}
        </nav>

        <div className='flex flex-col py-4'>
          <div className='flex items-center mt-auto gap-x-4 text-md text-gray-100 px-2'>
            {data?.photo
              ? <img alt='User' className='h-10 w-10 rounded-full object-cover' src={data?.photo.url} />
              : <div className='text-white text-xl h-10 w-10 flex items-center justify-center bg-secondary rounded-full'>{fullName.charAt(0) ?? '-'}</div>
            }

            <div className='font-semibold'>{fullName}</div>
          </div>

          <button className='group flex items-center px-2 py-2 text-md rounded-md text-gray-200 mt-4 md:mt-8 hover:bg-gray-800 hover:text-white w-full' onClick={logout}>
            <Logout
              aria-hidden='true'
              className='fill-gray-400 group-hover:fill-gray-300 mr-3 flex-shrink-0 h-6 w-6'
            />

            {t('logout')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
