import { type UserProfileChatProps } from '@components/user/user-profile-chat/user-profile-chat-interfaces'
import ChatRoomEntry from '@root/pages/chat-room-entry/chat-room-entry'

const UserProfileChat = ({ userChatRooms, userId }: UserProfileChatProps) => {
  const currentChatRoom = userChatRooms?.find(chatRoom => !chatRoom.isDisabled)
  const disabledChatRoom = userChatRooms?.find(chatRoom => chatRoom.isDisabled)
  const activeChatRoom = currentChatRoom ?? disabledChatRoom

  return (
    <>
      {!!userChatRooms?.length && (
        <div className='relative flex-1 -mt-14 md:-mt-6 justify-between flex flex-col h-[70vh] max-w-5xl'>
          {activeChatRoom && <ChatRoomEntry chatRoom={activeChatRoom} />}
        </div>
      )}
    </>
  )
}

export default UserProfileChat
