import React from 'react'
import { useTranslation } from 'react-i18next'

import AnalyseDay from '@components/analyse-day/analyse-day'
import AnalyseDayAfter from '@components/analyse-day/analyse-day-after/analyse-day-after'
import { type UserAnalysePeriodProps } from '@components/user/user-analyse-period/user-analyse-period-interfaces'
import useAnalysePeriodDaysQuery from '@services/api/analyse-period-days/use-analyse-period-days-query'

const UserAnalysePeriod = ({ analysePeriodUid }: UserAnalysePeriodProps) => {
  const { t: translateAnalyse } = useTranslation('analysePeriods')

  const {
    data: {
      data: analysePeriodDays
    } = {}
  } = useAnalysePeriodDaysQuery(analysePeriodUid)

  const dayAfter = analysePeriodDays?.find(analysePeriod => analysePeriod.type === 'AFTER')

  return (
    <div className='flex flex-col gap-1'>
      {!analysePeriodDays?.length &&
        <div className='text-gray-900 text-sm text-center pt-2 pb-4'>{translateAnalyse('noData')}</div>
      }

      {analysePeriodDays?.map((day, index) => {
        if (index < 3) {
          return (
            <AnalyseDay analyseDay={day} index={index + 1} key={index} />
          )
        }

        return null
      })}

      {dayAfter &&
        <AnalyseDayAfter dayAfter={dayAfter} />
      }
    </div>
  )
}

export default UserAnalysePeriod
