import { type UserProgramCourseExercise } from '@components/user-program-entry/user-program-entry-interfaces'
import { type ResourceEntry } from '@root/interfaces/api/definition/resource'

const getIdList = (list: ResourceEntry[]) => {
  return list.map(entry => entry['@id'])
}

const getUserProgramExercisesIdList = (list: UserProgramCourseExercise[]) => {
  return list.map(entry => {
    return {
      position: entry.position,
      uid: entry.exercise.uid
    }
  })
}

export { getIdList, getUserProgramExercisesIdList }
