import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import ChatRoomEntry from '@pages/chat-room-entry/chat-room-entry'
import {
  useChatRoomQuery
} from '@services/api/chat'
import { userProfileRoles } from '@services/roles/roles'
import { ChevronDown } from '@svg/icons'

const ChatRoom = () => {
  const { t } = useTranslation('chat', { keyPrefix: 'room' })
  const { uid = '' } = useParams()
  const navigate = useNavigate()

  const { data: chatRoom } = useChatRoomQuery(uid)
  const fullName = chatRoom?.user ? `${chatRoom.user.firstName} ${chatRoom.user.lastName}` : '-'

  const onPreviousClick = () => {
    navigate('/')
  }

  const goToUser = () => {
    if (chatRoom?.user) {
      navigate(`/users/${chatRoom.user.id}`)
    }
  }

  return (
    <Layout description={t('description')} title={t('title')}>
      <Guard acceptedRoles={userProfileRoles}>
        <div className='relative flex-1 -mt-14 md:-mt-6 p-4 sm:p-6 md:px-8 justify-between flex flex-col h-nav max-w-5xl'>
          <div className='relative flex flex-col sm:items-center justify-between pb-3'>
            <button className='absolute hidden md:flex left-0 items-center gap-2 rounded-lg bg-white px-4 border-2 border-gray-100' onClick={onPreviousClick}>
              <div className='text-gray-900'>
                <ChevronDown className='h-10 md:h-12 rotate-90 w-6 flex-shrink-0' />
              </div>
            </button>

            <button onClick={goToUser}>
              <div className='text-2xl text-gray-700 text-center w-full'>{fullName}</div>

              <div className='text-md text-gray-400 text-center w-full'>{chatRoom?.user.email ?? '-'}</div>
            </button>
          </div>

          {chatRoom && <ChatRoomEntry chatRoom={chatRoom} />}
        </div>
      </Guard>
    </Layout>
  )
}

export default ChatRoom
