import { type UseMutationResult } from '@tanstack/react-query'
import { projectName } from '@themes/config'
import { ErrorMessage, Field, Form, Formik, type FormikConfig } from 'formik'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

import ButtonLoader from '@components/buttons/button-loader/button-loader'
import LayoutLogin from '@components/layout-login/layout-login'
import { type ApiReponseError } from '@interfaces/api'
import { useForgotPasswordMutation } from '@services/api/auth/use-forgot-password-mutation'
import { captureException } from '@services/exceptions/capture-exception'

const Page = () => {
  const { t: translateGeneral } = useTranslation('general')
  const { t: translateError } = useTranslation('errors')
  const { mutateAsync: forgotPassword } = useForgotPasswordMutation()
  const [errorMsg, setErrorMsg] = useState('')
  const [successMsg, setSuccessMsg] = useState('')

  const FormSchema = Yup.object({
    email: Yup.string().email('Format d\'email incorrect').required('Requis')
  })

  const onSubmit: FormikConfig<{ email: string }>['onSubmit'] = async (values, { setSubmitting }) => {
    setSubmitting(true)
    setErrorMsg('')
    setSuccessMsg('')

    try {
      await forgotPassword(values)
      setSubmitting(false)
      setSuccessMsg(translateGeneral('forgotPassword.success'))
    } catch (e) {
      captureException(e as Error)
      setSubmitting(false)

      const err = e as UseMutationResult<ApiReponseError>
      const errorCode = err.data?.errorCode ?? 'generic'

      if (errorCode === 'USER_NOT_FOUND') {
        setSuccessMsg(translateGeneral('forgotPassword.success'))
      } else {
        setErrorMsg(translateError(errorCode))
      }
    }
  }

  return (
    <>
      <Helmet>
        <title>{`${translateGeneral('loginTitle')} - ${projectName}`}</title>
      </Helmet>

      <LayoutLogin title='Mot de passe oublié'>
        <Formik
          initialValues={{
            email: ''
          }}
          onSubmit={onSubmit}
          validationSchema={FormSchema}
        >
          {({ isSubmitting }) => {
            return (
              <Form className='space-y-6 px-4 py-3 rounded-lg'>
                <div className='flex flex-col'>
                  <label className='block font-medium text-gray-700' htmlFor='email'>{translateGeneral('email')}</label>

                  <Field
                    autoComplete='email'
                    className='appearance-none block w-full mt-2 px-3 py-2.5 border-2 border-gray-100 rounded-lg placeholder-gray-400 focus:outline-none'
                    name='email'
                    type='email'
                  />

                  <ErrorMessage className='mt-2 text-xs text-red-600 font-medium' component='div' name='email' />
                </div>

                <div className='text-red-500 text-xs font-medium'>{errorMsg}</div>

                <div className='text-green-500 text-xs font-medium'>{successMsg}</div>

                <button
                  className='w-full flex items-center gap-2 justify-center py-2 px-4 border border-transparent rounded-lg text-lg font-semibold text-white bg-primary hover:bg-gray-800 focus:outline-none'
                  disabled={isSubmitting}
                  type='submit'
                >
                  {isSubmitting
                    ? <ButtonLoader />
                    : (
                      <>
                        Soumettre
                      </>
                    )}
                </button>

                <div className='text-sm leading-6 text-center w-full'>
                  <a className='font-medium text-slate-800 hover:text-slate-500' href='/login'>
                    Retour
                  </a>
                </div>

              </Form>
            )
          }}
        </Formik>
      </LayoutLogin>
    </>
  )
}

export default Page
