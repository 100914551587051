import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import ApiResource from '@components/api-resource'
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import Modal from '@components/modals/modal'
import SectionTitle from '@components/section-title/section-title'
import { type ApiPlatformEntity } from '@interfaces/api/api'
import { ProgramCreate } from '@pages/programs/index'
import { programsRoles } from '@services/roles/roles'
import { Programs } from '@svg/icons'

const ProgramsList = () => {
  const [open, setOpen] = useState(false)
  const { t: translateResource } = useTranslation('apiResources', { keyPrefix: 'create' })
  const { t: translateProgram } = useTranslation('program')
  const { t: bc } = useTranslation('breadcrumbs')

  const navigate = useNavigate()

  const onEntryClick = (entry: ApiPlatformEntity) => {
    navigate(`/programs/${entry.uid}`)
  }

  const breadcrumbs = [
    { href: '', name: bc('programs') }
  ]

  const onCreateClick = () => {
    setOpen(true)
  }

  return (
    <Layout description={translateProgram('description')} title={translateProgram('programs')}>
      <Guard acceptedRoles={programsRoles}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <SectionTitle description={translateProgram('description')} icon={Programs} title={translateProgram('programs')} />

        <ApiResource createdAt={'desc'} name={'programs'} onClick={onEntryClick} withCloneOption withCreateOption={onCreateClick} withDeleteOption />

        <Modal center open={open} setOpen={setOpen} title={translateResource('programs')} >
          <ProgramCreate />
        </Modal>
      </Guard>
    </Layout>
  )
}

export default ProgramsList
