import {
  type UserMealIngredient
} from '@components/user-mealplan-entry/user-mealplan-meal-entry/user-mealplan-meal-entry-interfaces'
import { type MealIngredient } from '@interfaces/api/mealplan'

export interface MealPayload {
  ingredient: string
  quantity: string
}

const convertMealsToPayload = (meals: MealIngredient[]) => {
  const payload: MealPayload[] = []
  meals.forEach((meal) => {
    payload.push({
      ingredient: meal.ingredient['@id'],
      quantity: meal.quantity
    })
  })

  return payload
}

const convertUserMealsToPayload = (meals: UserMealIngredient[]) => {
  const payload: MealPayload[] = []
  meals.forEach((meal) => {
    payload.push({
      ingredient: `/api/ingredients/${meal.ingredient.uid}`,
      quantity: meal.quantity
    })
  })

  return payload
}

export { convertMealsToPayload, convertUserMealsToPayload }
