import { useNavigate } from 'react-router-dom'

import ApiResourceCreate from '@components/api-resource/api-resource-create'
import useApiResource from '@services/api/definition'

const RecipeCreate = () => {
  const definition = useApiResource('recipes')
  const navigate = useNavigate()

  const onCreate = (iriReference: string) => {
    const splitRef = iriReference.split('/')
    const uid = splitRef[splitRef.length - 1]
    navigate(`/recipes/${uid}`)
  }

  return (
    <>
      {definition && <ApiResourceCreate definition={definition} fieldsToDisplay={['title', 'description', 'status', 'type', 'duration']} onEdit={onCreate} />}
    </>
  )
}

export default RecipeCreate
