import { type UseMutationResult } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import ApiResourceList from '@components/api-resource/api-resource-list'
import ContextualButton from '@components/buttons/contextual-button'
import Modal from '@components/modals/modal'
import { type AddUserProgramsProps } from '@components/user/user-programs/user-programs-interfaces'
import { type ApiReponseError } from '@interfaces/api'
import { type AnalysePeriod } from '@interfaces/api/analyse-periods'
import { type UserProgram } from '@interfaces/api/user-program'
import useCreateEntry from '@services/api/resources/create-entry-query'
import useApiResourceListFormatQuery from '@services/api/resources/list-format-query'
import { CircleAdd } from '@svg/icons'

const AddUserPrograms = ({ definition, isProgramInProgress, onSuccess, userId }: AddUserProgramsProps) => {
  const { t: translateUserProgram } = useTranslation('user', { keyPrefix: 'programs' })
  const { t: translateError } = useTranslation('errors')
  const { mutateAsync: createResourceEntry } = useCreateEntry({ path: 'user-programs' })

  const [openModal, setOpenModal] = useState(false)
  const [openErrorModal, setOpenErrorModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>()

  const handleClick = () => {
    if (isProgramInProgress) {
      setErrorMessage('Un programme est déjà en cours pour cet utilisateur. Vous devez le clôturer avant d\'en réaffecter un nouveau')
      setOpenErrorModal(true)
    } else {
      setOpenModal(true)
    }
  }

  // Get Analyse Period uid
  const path = {
    name: `analyse-periods?status=SUBMITTED&user=${userId}`,
    url: ''
  }

  const {
    data: {
      data: analysePeriods
    } = {}
  } = useApiResourceListFormatQuery<AnalysePeriod>({ definition: path })

  const analysePeriodsUid = analysePeriods?.length ? analysePeriods[0]['@id'] : null

  const onEntryClick = async (entry) => {
    const values = {
      analysePeriod: analysePeriodsUid ?? null,
      program: entry['@id'],
      user: `/api/users/${userId}`
    }

    try {
      await createResourceEntry(values) as UserProgram
      onSuccess()
      setOpenModal(false)
    } catch (e) {
      const err = e as UseMutationResult<ApiReponseError>
      const errorCode = err.data?.errorCode ?? 'generic'
      setErrorMessage(translateError(errorCode))
      setOpenErrorModal(true)
    }
  }

  return (
    <div className='flex justify-end mt-2'>
      <ContextualButton icon={CircleAdd} onClick={handleClick}>
        {translateUserProgram('addPrograms')}
      </ContextualButton>

      <Modal center open={openErrorModal} setOpen={setOpenErrorModal} size='normal' title='Programme déjà en cours'>
        <div className='py-4 px-2.5'>
          {errorMessage && <div className='text-red-700 flex font-medium px-2'>{errorMessage}</div>}
        </div>
      </Modal>

      <Modal center open={openModal} setOpen={setOpenModal} size='large' title={translateUserProgram('addPrograms')}>
        <div className='flex relative flex-col pt-8 pb-4 max-h-[80vh]'>
          <ApiResourceList definition={definition} onEntryClick={onEntryClick} />
        </div>
      </Modal>
    </div>
  )
}

export default AddUserPrograms
