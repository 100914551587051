import { ExerciseCreate } from '@pages'
import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import ApiResource from '@components/api-resource'
import ApiResourceEdit from '@components/api-resource/api-resource-edit'
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import Modal from '@components/modals/modal'
import SectionTitle from '@components/section-title/section-title'
import { type ApiPlatformEntity } from '@interfaces/api'
import { type Exercise } from '@interfaces/api/exercise'
import useApiResource from '@services/api/definition'
import { captureException } from '@services/exceptions/capture-exception'
import { exercisesRoles } from '@services/roles/roles'
import { Clipboard } from '@svg/icons'

const ExercisesList = () => {
  const { t: translateResource } = useTranslation('apiResources')
  const { t } = useTranslation('exercise', { keyPrefix: 'list' })
  const { t: bc } = useTranslation('breadcrumbs')

  const breadcrumbs = [
    { href: '', name: bc('exercices') }
  ]

  const [open, setOpen] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [exercise, setExercise] = useState<Exercise>()

  const queryClient = useQueryClient()
  const definition = useApiResource('exercises/{uid}')

  const onReload = () => {
    queryClient.refetchQueries({ queryKey: ['exercises'] }).catch((err) => {
      captureException(err as Error)
    })
  }

  const onEntryClick = (entry: ApiPlatformEntity) => {
    const exercise = entry as Exercise
    setExercise(exercise)
    setOpenEdit(true)
  }

  const onCreateClick = () => {
    setOpen(true)
  }

  const onCreate = () => {
    onReload()
    setOpen(false)
  }

  const onEdit = () => {
    onReload()
    setOpenEdit(false)
  }

  return (
    <Layout description={t('description')} title={t('title')}>
      <Guard acceptedRoles={exercisesRoles}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <SectionTitle description={t('description')} icon={Clipboard} title={t('title')} />

        <ApiResource name={'exercises'} onClick={onEntryClick} withCreateOption={onCreateClick} withDeleteOption withEditOption={onEntryClick} />

        <Modal center open={open} setOpen={setOpen} size='large' title={translateResource('create.exercises')}>
          <ExerciseCreate onCreate={onCreate} />
        </Modal>

        <>
          {exercise && (
            <Modal center open={openEdit} setOpen={setOpenEdit} size='large' title={translateResource('edit.exercises')}>
              {definition && <ApiResourceEdit definition={definition} id={exercise.uid} onEdit={onEdit} />}
            </Modal>
          )}
        </>
      </Guard>
    </Layout>
  )
}

export default ExercisesList
