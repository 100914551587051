import { useQuery, type UseQueryResult } from '@tanstack/react-query'

import { type ChatRoom } from '@interfaces/api/chat'
import useAxios from '@services/api/axios'

const useChatRoomQuery = (uid: string): UseQueryResult<ChatRoom> => {
  const { get } = useAxios()

  return useQuery({
    queryFn: async () => {
      return await get<undefined, ChatRoom>(`api/chat-rooms/${uid}`).then(result => result)
    },
    queryKey: ['chat-room', uid]
  })
}

export default useChatRoomQuery
