import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import Table from '@components/table'
import UserProgramTableEntry from '@components/table/table-data/user-programs-table-entry'
import AddUserPrograms from '@components/user/user-programs/add-user-programs'
import { type UserProgramsProps } from '@components/user/user-programs/user-programs-interfaces'
import { Status } from '@interfaces/api/status'
import useApiResource from '@services/api/definition'
import useUserProgramsQuery from '@services/api/user/use-user-programs-query'
import { captureException } from '@services/exceptions/capture-exception'

const UserPrograms = ({ userId }: UserProgramsProps) => {
  const { t: translateUserProgram } = useTranslation('user', { keyPrefix: 'programs' })
  const definition = useApiResource('programs')

  const [searchParams, setSearchParams] = useSearchParams({ page: '1', status: 'PUBLISHED,DRAFT,AWAITING_COACH' })
  const status = searchParams.get('status') ?? 'PUBLISHED,DRAFT,AWAITING_COACH'
  const pageIndex = Number(searchParams.get('page') ?? '1')
  const setPageIndex = (index: number) => {
    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      page: `${index}`
    })
  }

  const {
    data: {
      data: userPrograms,
      pagination,
      totalItems
    } = {},
    isFetched,
    isLoading,
    refetch
  } = useUserProgramsQuery({ page: pageIndex, status, user: userId })

  const onSuccess = () => {
    refetch().catch(captureException)
  }

  const isProgramInProgress = userPrograms?.some(uP => uP.status !== Status.STATUS_FINISHED)

  return (
    <div className='mt-6'>
      <Table
        headers={[translateUserProgram('title'), translateUserProgram('start'), translateUserProgram('end'), translateUserProgram('status')]}
        isLoading={isLoading}
        noScroll
        pageIndex={pageIndex}
        pagination={pagination}
        setPageIndex={setPageIndex}
        totalItems={totalItems}
      >
        {
          userPrograms?.map((userProgram, index) => (
            <UserProgramTableEntry key={index} {...userProgram} />
          ))
        }

        {!userPrograms?.length && (
          <tr className='text-center'>
            <td className='p-6' colSpan={4}>
              <div className='text-gray-900 text-sm'>{translateUserProgram('noPrograms')}</div>

              <div className='text-gray-500 text-xs'>{translateUserProgram('noProgramsDescription')}</div>
            </td>
          </tr>
        )}
      </Table>

      {!isLoading && isFetched && definition && userId && <AddUserPrograms definition={definition} isProgramInProgress={isProgramInProgress ?? false} onSuccess={onSuccess} userId={userId} />}
    </div>
  )
}

export default UserPrograms
