import Modal from '@components/modals/modal'
import { type ModalEditPlaylistProps } from '@components/modals/modal-edit-playlist/modal-edit-playlist-interfaces'
import PlaylistVideos from '@components/video/playlist-video/playlist-video'

const ModalEditPlaylist = ({ open, playlist, setOpen }: ModalEditPlaylistProps) => {
  return (
    <Modal center open={open} setOpen={setOpen} size='large' title={playlist.label}>
      <div className='py-6'>
        <PlaylistVideos playlistUid={playlist.uid} />
      </div>
    </Modal>
  )
}

export default ModalEditPlaylist
