import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { useEffect } from 'react'

import { type InformationPopupProps } from '@components/information-popup/information-popup-interfaces'

const InformationPopup = ({ active = false, children, setActive }: InformationPopupProps) => {
  // Reset style after 5sec
  useEffect(() => {
    if (active) {
      setTimeout(() => {
        setActive(false)
      }, 5000)
    }
  }, [active, setActive])

  return (
    <div className={`absolute top-0 flex justify-center right-8 z-10 border-none transition ${active ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-10'}`}>
      <div className='flex items-center gap-4 font-medium bg-green-300 border-t-4 border-green-400 rounded-b-xl px-4 py-3 shadow-md'>
        <InformationCircleIcon className='w-8 h-8' />

        <div className='text-lg'>
          { children }
        </div>
      </div>
    </div>
  )
}

export default InformationPopup
