import {
  type DefaultError,
  type UseMutateAsyncFunction
} from '@tanstack/react-query'

import { type ApiResourceEditProps } from '@components/api-resource/api-resource-edit/api-resource-edit.interfaces'
import ApiResourceForm from '@components/api-resource/api-resource-form'
import useApiResourceItemQuery from '@services/api/resources/item-query'
import usePatchEntry from '@services/api/resources/patch-entry-query'

const ApiResourceEdit = <T extends never>({
  definition,
  excludedFields,
  fieldsToDisplay,
  id,
  onEdit,
  ...props
}: ApiResourceEditProps) => {
  const {
    data,
    isFetching
  } = useApiResourceItemQuery<T>({
    id,
    path: definition.url,
    refetchOnMount: 'always',
    ...props
  })
  const { mutateAsync: editResourceEntry } = usePatchEntry({
    id,
    path: definition.url
  })

  const onSubmit: UseMutateAsyncFunction<unknown, DefaultError, unknown, unknown> = async (data) => {
    const result = await editResourceEntry(data)

    onEdit?.()

    return result
  }

  /** const reload = () => {
   refetch().catch(captureException)
   }**/

  return (
    <ApiResourceForm
      data={data}
      definition={definition}
      excludedFields={excludedFields}
      fieldsToDisplay={fieldsToDisplay}
      isFetching={isFetching}
      requestFields={definition.methods.patch.getRequestFields()}
      submitEntry={onSubmit}
    />
  )
}

export default ApiResourceEdit
