import moment from 'moment/moment'
moment.locale('fr')

const formatVideoName = (fileName: string) => {
  const date = moment().format('L').replaceAll('/', '')
  const hours = moment().format('LTS').replaceAll(':', '')
  const fullDate = date + hours

  const extIndex = fileName.lastIndexOf('.')
  const ext = fileName.substring(extIndex)
  const fileNameWithoutExt = fileName.substring(0, extIndex)
  const newFileName = `${fileNameWithoutExt}-${fullDate}${ext}`

  return newFileName
}

export default formatVideoName
