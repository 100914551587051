import { type ApiPlatformEntity, type ApiPlatformListResponse } from '@interfaces/api'
import { type Image } from '@interfaces/api/image'
import { type Status } from '@interfaces/api/status'
import { type Video } from '@interfaces/api/video'

export interface Recipe extends ApiPlatformEntity {
  description?: string
  duration: number
  image?: Image
  status: Status.STATUS_DRAFT | Status.STATUS_FINISHED | Status.STATUS_PUBLISHED
  title: string
  type: RecipeType
  // maxLength: 128
  video?: Video
}

export enum RecipeType {
  BREAKFAST = 'BREAKFAST',
  DINNER = 'DINNER',
  LUNCH = 'LUNCH',
  SNACK = 'SNACK'
}

export type RecipesResponse = ApiPlatformListResponse<Recipe>
