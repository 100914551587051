import { useQueryClient } from '@tanstack/react-query'
import { useFormik } from 'formik'
import moment from 'moment/moment'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import ButtonLoader from '@components/buttons/button-loader/button-loader'
import Modal from '@components/modals/modal'
import { type UserProfile } from '@interfaces/api/user-profile'
import usePatchEntry from '@services/api/resources/patch-entry-query'
import { useUploadImage } from '@services/api/upload-image/upload-image'
import { captureException } from '@services/exceptions/capture-exception'

const Profile = ({ email, firstName, id, lastName, photo, profile }: UserProfile) => {
  const { t: translateProfile } = useTranslation('user', { keyPrefix: 'profile' })
  const { t: translateGeneral } = useTranslation('general')
  const queryClient = useQueryClient()

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [imagePreview, setImagePreview] = useState('')

  const fullName = firstName && lastName ? `${firstName} ${lastName}` : ''
  const weight = profile?.weight ? `${profile.weight}${translateGeneral('weightUnit')}` : '-'
  const height = profile?.height ? `${profile.height}${translateGeneral('sizeUnit')}` : '-'
  const userId = id.toString()

  const onClick = () => {
    setIsOpen(true)
  }

  const reloadUser = () => {
    queryClient.refetchQueries({ queryKey: ['users'] }).catch((err) => {
      captureException(err as Error)
    })
  }

  const { mutateAsync: uploadImageAsync } = useUploadImage()
  const { mutateAsync: editUser } = usePatchEntry({ id: userId, path: 'users/{id}' })

  const { handleSubmit, isSubmitting, setFieldValue, setSubmitting, values } = useFormik({
    initialValues: { file: '' },
    onSubmit: async (values) => {
      setSubmitting(true)

      const formData = new FormData()
      formData.append('file', values.file)
      try {
        const imageId = await uploadImageAsync(formData)
        const body = { photo: imageId }

        try {
          await editUser(body)

          reloadUser()
          setSubmitting(false)
          setIsOpen(false)
        } catch (e) {
          captureException(e as Error)
        }
      } catch (e) {
        captureException(e as Error)
      }
    }
  })

  const handleChangeInput = async (event) => {
    const file = event?.currentTarget?.files[0]
    const fileUrl = URL.createObjectURL(file)
    await setFieldValue('file', file)
    setImagePreview(fileUrl)
  }

  return (
    <div className='flex gap-4 mb-12'>
      <button className='self-start' onClick={onClick}>
        {photo
          ? <img alt='preview' className='h-12 w-12 object-cover rounded-full object-center' src={photo.url} />
          : <div className='text-white text-2xl h-12 w-12 flex items-center justify-center bg-orange-400 rounded-full'>{fullName.charAt(0) ?? '-'}</div>
        }
      </button>

      <Modal center open={isOpen} setOpen={setIsOpen} title={translateProfile('addImg')} >
        <form className='flex flex-col justify-center items-center py-4 px-3 gap-4' onSubmit={handleSubmit}>
          <div className='flex justify-center'>
            <label className='cursor-pointer' htmlFor='file'>
              {imagePreview
                ? <img alt='preview' className='h-32 w-32 object-cover rounded-full object-center' src={imagePreview} />
                : (
                  <>
                    {photo
                      ? <img alt='preview' className='h-32 w-32 object-cover rounded-full object-center' src={photo.url} />
                      : <div className='text-white text-4xl h-32 w-32 flex items-center justify-center bg-orange-400 rounded-full'>{fullName.charAt(0) ?? '-'}</div>
                    }
                  </>
                )
              }
            </label>

            <input hidden id='file' name='file' onChange={handleChangeInput} type='file' />
          </div>

          <button className='flex gap-2 items-center rounded-lg px-4 py-2 text-lg font-semibold tracking-wide bg-primary text-white fill-white hover:bg-gray-600 hover:text-white' disabled={isSubmitting || values.file === ''} type='submit'>
            {isSubmitting ? <ButtonLoader /> : translateProfile('editImg')}
          </button>
        </form>
      </Modal>

      <div className='flex flex-col gap-2'>
        <span className='text-4xl text-gray-900 font-semibold'>{fullName}</span>

        <div className='flex flex-wrap max-w-sm gap-y-1 gap-x-4'>
          <div className='flex gap-2'>
            <div className='uppercase text-gray-500 font-light text-sm'>{translateProfile('gender')}</div>

            <div className='text-gray-900 font-normal text-sm'>{profile?.gender ?? '-'}</div>
          </div>

          <div className='flex gap-2'>
            <div className='uppercase text-gray-500 font-light text-sm'>{translateProfile('email')}</div>

            <div className='text-gray-900 font-normal text-sm'>{email ?? '-'}</div>
          </div>

          <div className='flex gap-2'>
            <div className='uppercase text-gray-500 font-light text-sm'>{translateProfile('birthdate')}</div>

            <div className='text-gray-900 font-normal text-sm'>{profile?.birthdate ? moment(profile?.birthdate).format('L') : '-'}</div>
          </div>

          <div className='flex gap-2'>
            <div className='uppercase text-gray-500 font-light text-sm'>{translateProfile('weight')}</div>

            <div className='text-gray-900 font-normal text-sm'>{weight}</div>
          </div>

          <div className='flex gap-2'>
            <div className='uppercase text-gray-500 font-light text-sm'>{translateProfile('height')}</div>

            <div className='text-gray-900 font-normal text-sm'>{height}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Profile
