import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import StatusTag from '@components/status-tag/status-tag'
import AnalysePeriod from '@pages/analyse-period/analyse-period'
import useAnalysePeriod from '@services/api/analyse-period/analyse-period'
import useAnalysePeriodDaysQuery from '@services/api/analyse-period-days/use-analyse-period-days-query'
import { userProfileRoles } from '@services/roles/roles'

const AnalysePeriods = () => {
  const { t: translateAnalysePeriods } = useTranslation('analysePeriods')
  const { t: bc } = useTranslation('breadcrumbs')
  const { uid = '' } = useParams()

  const {
    data: analysePeriod
  } = useAnalysePeriod(uid)

  const {
    data: {
      data: analysePeriodDays
    } = {}
  } = useAnalysePeriodDaysQuery(uid)

  const fullName = analysePeriod?.user.firstName && analysePeriod?.user.lastName ? `${analysePeriod.user.firstName} ${analysePeriod.user.lastName}` : '-'
  const breadcrumbs = [
    { href: '/users', name: bc('users') },
    { href: `/users/${analysePeriod?.user.id ?? ''}`, name: fullName },
    { href: '', name: bc('analysePeriod') }
  ]

  const daysFinished = analysePeriodDays ? analysePeriodDays?.filter(d => d?.closed)?.length : 0

  return (
    <Layout description={translateAnalysePeriods('description')} title={`${translateAnalysePeriods('title')} - ${fullName}`}>
      <Guard acceptedRoles={userProfileRoles}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <div className='flex flex-wrap gap-4 px-4 sm:px-6 md:px-8 justify-between mb-4'>
          <div className='flex gap-4 items-center'>
            <div className='text-white text-2xl h-12 w-12 flex items-center justify-center bg-orange-400 rounded-full'>{fullName.charAt(0) ?? '-'}</div>

            <span className='text-4xl text-gray-900 font-semibold'>{fullName}</span>
          </div>

          <div className='flex gap-4 items-center'>
            <div className='text-4xl font-medium'>{translateAnalysePeriods('title')}</div>

            <StatusTag status={analysePeriod?.status ?? '-'} />
          </div>
        </div>

        <div className='px-4 sm:px-6 md:px-8'>
          {analysePeriod && analysePeriodDays && (
            <>
              <div className='py-4 text-xl text-gray-900 font-semibold'>
                {`${translateAnalysePeriods('days.completed')}` }

                <span className='text-xl text-white font-semibold bg-primary rounded-full px-2'>{`${daysFinished}/${3}`}</span>
              </div>
              <AnalysePeriod analysePeriod={analysePeriod} analysePeriodDays={analysePeriodDays} />
            </>
          )}
        </div>
      </Guard>
    </Layout>
  )
}

export default AnalysePeriods
