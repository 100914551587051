import { Menu, Transition } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import { Fragment } from 'react'

import { type DropdownMenuProps } from '@components/dropdown-menu/dropdown-menu.interfaces'

const DropdownMenu = ({ items }: DropdownMenuProps) => {
  return (
    <Menu as='div' className='relative inline-block text-left'>
      <div>
        <Menu.Button className='flex items-center bg-white rounded-md border hover:fill-white hover:text-white p-1 border-gray-200 hover:border-gray-900 hover:bg-gray-800' onClick={(e) => {
          e.stopPropagation()
        }}
        >
          <span className='sr-only'>Open options</span>

          <EllipsisVerticalIcon aria-hidden='true' className='w-3.5 h-3.5' />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items
          className='absolute right-0 z-20 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
        >
          <div className='py-1'>
            {items.map((item, index) => (
              <Menu.Item
                disabled={!item.enabled}
              >
                {item.onClick
                  ? (
                    <>
                      <button
                        className={classNames({
                          'flex w-full px-4 py-2 text-sm hover:cursor-pointer': true,
                          'hover:bg-gray-100 hover:text-gray-900': item.enabled,
                          'text-gray-300': !item.enabled
                        })}
                        onClick={item.onClick}
                      >
                        {item.label}
                      </button>
                    </>
                  )
                  : (
                    <>
                      <a
                        className='flex w-full px-4 py-2 text-sm hover:cursor-pointer'
                        href={item.link}
                      >
                        {item.label}
                      </a>
                    </>
                  )
                }
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default DropdownMenu
