import { useQuery, type UseQueryResult } from '@tanstack/react-query'

import { type ApiError } from '@interfaces/api/api'
import { type Image } from '@interfaces/api/image'
import useAxios from '@services/api/axios'

const useImage = (imageUid: string): UseQueryResult<Image, ApiError> => {
  const { get } = useAxios()

  return useQuery<Image, ApiError>({
    enabled: !!imageUid,
    queryFn: async () => {
      return await get<undefined, Image>(`${imageUid}`)
    },
    queryKey: ['images', imageUid]
  })
}

export default useImage
