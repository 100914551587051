import { type InfiniteData } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { queryClient } from '@app'
import ContextualButton from '@components/buttons/contextual-button'
import { type VoiceRecorderProps } from '@components/chat-message/chat-message-form/chat-message-form-interfaces'
import { type FormattedApiListResponse } from '@interfaces/api'
import { type ChatMessage as ChatMessageType } from '@interfaces/api/chat'
import { useUploadAudio } from '@services/api/audio/audio/use-create-audio-mutation'
import { CHAT_MESSAGE_TYPE } from '@services/api/chat/message/use-create-chat-message-mutation'
import { captureException } from '@services/exceptions/capture-exception'
import useAudioRecorder from '@services/hooks/audio-recorder'
import { encodedAudio } from '@services/tools/mp3-encoder'
import { ArrowLeft, Microphone } from '@svg/icons'

const VoiceRecorder = ({ createMessage, onReturn, room, setOpen }: VoiceRecorderProps) => {
  const { t: translateChat } = useTranslation('chat', { keyPrefix: 'room' })
  const [audio, setAudio] = useState<string>('')

  const roomId = room.replace('/api/chat-rooms/', '')
  const { mutateAsync: uploadAudio } = useUploadAudio()

  const {
    isRecording,
    recordingBlob,
    recordingTime,
    startRecording,
    stopRecording
  } = useAudioRecorder()

  useEffect(() => {
    if (recordingBlob) {
      const url = URL.createObjectURL(recordingBlob)
      setAudio(url)
    }
  }, [recordingBlob])

  const formattedSecondes = (e) => {
    const m = Math.floor(e % 3600 / 60).toString().padStart(2, '0')
    const s = Math.floor(e % 60).toString().padStart(2, '0')

    return m + ':' + s
  }

  const handleSubmit = async () => {
    if (recordingBlob) {
      const blob = await encodedAudio(recordingBlob)

      const msgPayload = {
        attachment: null,
        audio: '',
        room,
        text: '',
        type: CHAT_MESSAGE_TYPE.TYPE_AUDIO
      }

      if (blob) {
        const formData = new FormData()
        formData.append('file', blob, 'audio.mp3')

        try {
          msgPayload.audio = await uploadAudio(formData)
        } catch (e) {
          captureException(e as Error)
        }
      }

      if (msgPayload.audio) {
        try {
          const result = await createMessage(msgPayload)

          const msgvalues: Partial<ChatMessageType> = {
            attachment: result.attachment,
            audio: result.audio,
            createdAt: result.createdAt,
            isDeleted: result.isDeleted,
            isEdited: result.isEdited,
            isFromAdmin: result.isFromAdmin,
            isRead: result.isRead,
            text: result.text,
            type: result.type,
            uid: result.uid
          }

          queryClient.setQueryData<InfiniteData<FormattedApiListResponse<ChatMessageType>>>(
            ['chat-messages', roomId], (data) => {
              const lastPage = data?.pages?.[0]
              if (lastPage?.data) {
                lastPage?.data?.unshift(msgvalues as ChatMessageType)
              }

              const nextPages = [...(data?.pages ?? [])]

              if (lastPage) {
                nextPages.splice(0, 1, lastPage)
              }

              return {
                pageParams: data?.pageParams ?? [],
                pages: nextPages
              }
            }
          )
        } catch (e) {
          captureException(e as Error)
        } finally {
          setAudio('')
          if (typeof setOpen === 'function') {
            setOpen(false)
          }
        }
      }
    }
  }

  const handleDeleteClick = (e) => {
    e.stopPropagation()
    setAudio('')
  }

  const handleStop = (e) => {
    e.stopPropagation()
    stopRecording()
  }

  const handleOnReturn = (e) => {
    e.stopPropagation()
    onReturn()
  }

  return (
    <>
      {!audio && (
        <div className='py-4 px-3.5 flex flex-col items-center gap-2'>
          <div className='text-sm text-gray-700 text-center'>
            {isRecording
              ? translateChat('registering')
              : translateChat('readyRegister')
            }
          </div>

          <button className={`px-4 py-3.5 text-white rounded-full ${isRecording ? 'bg-red-500' : 'bg-primary hover:bg-gray-600'}`} onClick={isRecording ? handleStop : startRecording}>
            <Microphone className='w-8 h-9 fill-white' />
          </button>

          <div className='text-gray-500 text-[10px]'>{formattedSecondes(recordingTime) ?? '00:00'}</div>
        </div>
      )}

      {audio && (
        <div className='flex flex-col items-center gap-4'>
          <audio controls src={audio} />

          <div className='flex gap-8'>
            <ContextualButton onClick={handleSubmit}>{translateChat('send')}</ContextualButton>

            <ContextualButton onClick={handleDeleteClick} style='warning' >{translateChat('delete')}</ContextualButton>
          </div>
        </div>
      )}

      <button className='border-2 self-start border-gray-100 rounded-full py-1 px-2 flex gap-1.5 items-center' onClick={handleOnReturn}>
        <ArrowLeft className='w-4 h-4 fill-gray-500' />

        <div className='text-xs text-gray-500'>{translateChat('back')}</div>
      </button>
    </>
  )
}

export default VoiceRecorder
