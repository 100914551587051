import { PlusIcon } from '@heroicons/react/24/outline'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'

import MealplanDayEntry from '@components/mealplan/mealplan-day-entry/mealplan-day-entry'
import { type MealplanDaysProps } from '@components/mealplan/mealplan-days/mealplan-days-interfaces'
import { type DroppableType } from '@interfaces/api/droppable'
import { type MealplanDay } from '@interfaces/api/mealplan'
import useAxios from '@services/api/axios'
import useCreateEntry from '@services/api/resources/create-entry-query'
import useItemChildrenQuery from '@services/api/resources/item-children'
import { captureException } from '@services/exceptions/capture-exception'
import { getIdList } from '@services/tools/api-resources/get-id-list'
import { getReorderList } from '@services/tools/drag-reorder'

const MealplanDays = ({ mealplanWeekId }: MealplanDaysProps) => {
  const { put } = useAxios()
  const { t: translateMealplan } = useTranslation('mealplan')

  const {
    data: {
      data: mealplanDays
    } = {},
    isFetching,
    refetch
  } = useItemChildrenQuery<MealplanDay>({
    itemId: mealplanWeekId,
    path: 'mealplan-weeks/{uid}/days'
  })

  const dayIds = mealplanDays ? getIdList(mealplanDays) : []

  const reload = () => {
    refetch().catch((err) => {
      captureException(err as Error)
    })
  }

  const { mutateAsync: createResourceEntry } = useCreateEntry({ path: 'mealplan-days' })

  const onDayAdd = () => {
    createResourceEntry({
      week: `/api/mealplan-weeks/${mealplanWeekId}`
    }).then(() => {
      reload()
    }).catch(captureException)
  }

  const onDayDelete = () => {
    reload()
  }

  const onDayClone = () => {
    reload()
  }

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return
    }

    const destination: DroppableType = result.destination
    const source: DroppableType = result.source

    // Prevent move on the same place
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return
    }

    // Reorder days
    const newDaysReorder = getReorderList(result.source.index, result.destination.index, mealplanDays ?? [])
    const newDaysList = getIdList(newDaysReorder)
    const url = `api/mealplan-weeks/${mealplanWeekId}/days/change-order`
    await put(url, { order: newDaysList }).catch(captureException)
  }

  return (
    <>
      {!!mealplanDays?.length && !isFetching
        ? (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={mealplanWeekId}>
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  className='flex flex-col'
                  ref={provided.innerRef}
                >
                  {mealplanDays.map((day, index) => (
                    <Draggable draggableId={day.uid} index={index} key={day.uid}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={`${snapshot.isDragging ? 'drop-shadow-md' : 'shadow-none'} pb-1.5 last:pb-0`}
                        >
                          <MealplanDayEntry
                            dayEntry={day}
                            idList={dayIds}
                            onClone={onDayClone}
                            onDelete={onDayDelete}
                            position={index + 1}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}

                  {provided.placeholder}

                  {(mealplanDays?.length ?? 7) < 7 && (
                    <div className='flex flex-col justify-start border border-gray-200 rounded-lg p-1'>
                      <div className='text-gray-900 text-sm px-5 py-12 text-center'>{translateMealplan('addDay')}</div>

                      <button className='w-full flex items-center justify-center bg-gray-200 rounded-lg p-1' onClick={onDayAdd}>
                        <PlusIcon className='w-4 h-4' />
                      </button>
                    </div>
                  )}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )
        : (
          <div className='flex flex-col justify-start border border-gray-200 rounded-lg p-1'>
            <div className='text-gray-900 text-sm px-5 py-12 text-center'>{translateMealplan('addDay')}</div>

            <button className='w-full flex items-center justify-center bg-gray-200 rounded-lg p-1' onClick={onDayAdd}>
              <PlusIcon className='w-4 h-4' />
            </button>
          </div>
        )}
    </>
  )
}

export default MealplanDays
