import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import ApiResource from '@components/api-resource'
import ApiResourceEdit from '@components/api-resource/api-resource-edit'
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import Modal from '@components/modals/modal'
import SectionTitle from '@components/section-title/section-title'
import { type ApiPlatformEntity } from '@interfaces/api'
import { type Recipe } from '@interfaces/api/recipe'
import RecipeCreate from '@pages/recipes/create'
import useApiResource from '@services/api/definition'
import { captureException } from '@services/exceptions/capture-exception'
import { ingredientsRoles } from '@services/roles/roles'
import { Layers } from '@svg/icons'

const RecipesList = () => {
  const { t: translateResource } = useTranslation('apiResources')
  const { t: translateRecipe } = useTranslation('recipe')
  const { t: bc } = useTranslation('breadcrumbs')
  const [openCreate, setOpenCreate] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [recipe, setRecipe] = useState<Recipe>()

  const queryClient = useQueryClient()
  const definition = useApiResource('recipes/{uid}')

  const breadcrumbs = [
    { href: '', name: bc('recipes') }
  ]

  const onReload = () => {
    queryClient.refetchQueries({ queryKey: ['recipes'] }).catch((err) => {
      captureException(err as Error)
    })
  }

  const onEditClick = (entry: ApiPlatformEntity) => {
    const recipe = entry as Recipe
    setRecipe(recipe)
    setOpenEdit(true)
  }

  const onCreateClick = () => {
    setOpenCreate(true)
  }

  const onEdit = () => {
    onReload()
    setOpenEdit(false)
  }

  return (
    <Layout description={translateRecipe('description')} title={translateRecipe('title')}>
      <Guard acceptedRoles={ingredientsRoles}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <SectionTitle description={translateRecipe('description')} icon={Layers} title={translateRecipe('title')} />

        <ApiResource customFieldsToDisplay={['title', 'status', 'type', 'createdAt']} name={'recipes'} onClick={onEditClick} withCreateOption={onCreateClick} withDeleteOption withEditOption={onEditClick} />

        <Modal center open={openCreate} setOpen={setOpenCreate} size={'large'} title={translateRecipe('create')}>
          <RecipeCreate />
        </Modal>

        <>
          {recipe && (
            <Modal center open={openEdit} setOpen={setOpenEdit} size={'large'} title={translateResource('actions.edit')}>
              {definition && <ApiResourceEdit definition={definition} fieldsToDisplay={['title', 'description', 'status', 'type', 'duration']} id={recipe.uid} onEdit={onEdit} />}
            </Modal>
          )}
        </>
      </Guard>
    </Layout>
  )
}

export default RecipesList
