import { type UseMutationResult, useQueryClient } from '@tanstack/react-query'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import ResourceHead from '@components/api-resource/resource-head/resource-head'
import ButtonLoader from '@components/buttons/button-loader/button-loader'
import ContextualButton from '@components/buttons/contextual-button'
import Modal from '@components/modals/modal'
import Tag from '@components/tag/tag'
import { type UserProgramHeadProps } from '@components/user-program-entry/user-program-head-interfaces'
import { type ApiReponseError } from '@interfaces/api'
import { Status } from '@interfaces/api/status'
import useCreateEntry from '@services/api/resources/create-entry-query'
import usePutEntry from '@services/api/resources/put-entry-query'
import { USER_PROGRAM_EVENT } from '@services/contants/program-event'
import { captureException } from '@services/exceptions/capture-exception'
import { ArrowLeft, Edit, Lock, Play } from '@svg/icons'

const UserProgramHead = ({ analysePeriod, currentModuleNumber, isStopped, modules, program, status, uid, userProgram }: UserProgramHeadProps) => {
  const { description, image, title } = program
  const { t: translateActions } = useTranslation('apiResources', { keyPrefix: 'actions' })
  const { t: translateLabel } = useTranslation('apiResources', { keyPrefix: 'labels' })
  const { t: translateError } = useTranslation('errors')
  const { t: translateAnalyse } = useTranslation('analysePeriods')

  const [publishModal, setPublishModal] = useState(false)
  const [finishModal, setFinishModal] = useState(false)
  const [unblockModal, setUnblockModal] = useState(false)
  const [restartModal, setRestartModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>()
  const [openErrorModal, setOpenErrorModal] = useState(false)

  const { mutateAsync: publishEntry } = usePutEntry(`/api/user-programs/${uid}/publish`)
  const { mutateAsync: finishEntry } = useCreateEntry({ path: 'user-program-events/force-finish' })
  const { mutateAsync: editUserProgram } = useCreateEntry({ path: 'user-program-events/edit' })
  const { mutateAsync: finishModule } = useCreateEntry({ path: 'user-program-events' })
  const queryClient = useQueryClient()

  const reload = () => {
    queryClient.refetchQueries({ queryKey: [`api/user-programs/${uid}`] }).catch(captureException)
    queryClient.refetchQueries({ queryKey: ['user-programs'] }).catch(captureException)
  }

  const onPublishClick = () => {
    const goalsSetted = modules.every(module => module.goals !== null)
    if (goalsSetted) {
      setPublishModal(true)
    } else {
      setOpenErrorModal(true)
    }
  }

  const onRestartClick = () => {
    setRestartModal(true)
  }

  const onUnblockClick = () => {
    setUnblockModal(true)
  }

  const onFinishClick = () => {
    setFinishModal(true)
  }

  const onFinishHandler = async () => {
    try {
      await finishEntry({
        userProgram
      })
      reload()
      setFinishModal(false)
    } catch (e) {
      const err = e as UseMutationResult<ApiReponseError>
      const errorCode = err.data?.errorCode ?? 'generic'
      setErrorMessage(translateError(errorCode))
    }
  }

  const onRestartHandler = async () => {
    try {
      await finishModule({
        action: USER_PROGRAM_EVENT.FINISH_MODULE,
        module: `/api/program-modules/${modules[currentModuleNumber - 1].uid}`,
        userProgram
      })
      await editUserProgram({
        action: USER_PROGRAM_EVENT.EDIT,
        status: Status.STATUS_PUBLISHED,
        userProgram
      })
      reload()
      setRestartModal(false)
    } catch (e) {
      const err = e as UseMutationResult<ApiReponseError>
      const errorCode = err.data?.errorCode ?? 'generic'
      setErrorMessage(translateError(errorCode))
    }
  }

  const onUnblockHandler = async () => {
    try {
      await editUserProgram({
        action: USER_PROGRAM_EVENT.EDIT,
        isStopped: true,
        status: Status.STATUS_PUBLISHED,
        userProgram
      })
      reload()
      setUnblockModal(false)
    } catch (e) {
      const err = e as UseMutationResult<ApiReponseError>
      const errorCode = err.data?.errorCode ?? 'generic'
      setErrorMessage(translateError(errorCode))
    }
  }

  const onCancelHandler = () => {
    setPublishModal(false)
    setFinishModal(false)
    setUnblockModal(false)
  }

  const { handleSubmit, isSubmitting, setFieldValue, setSubmitting } = useFormik({
    initialValues: { startAt: '' },
    onSubmit: async (values) => {
      setSubmitting(true)

      if (values.startAt) {
        try {
          await publishEntry({ startAt: values.startAt })
          reload()
          setPublishModal(false)
        } catch (e) {
          const err = e as UseMutationResult<ApiReponseError>
          const errorCode = err.data?.errorCode ?? 'generic'
          setErrorMessage(translateError(errorCode))
        }
      }
    }
  })

  const handleChange = async (e) => {
    await setFieldValue('startAt', e.target.value)
  }

  return (
    <div className='flex justify-between flex-col lg:flex-row gap-4'>
      <ResourceHead description={description} image={image} status={status} title={title}>
        {isStopped && (
          <Tag
            classNames={'bg-green-400'}
            text={'Programme débloqué'}
          />
        )}
      </ResourceHead>

      {/* Buttons */}

      <div className='flex lg:flex-col items-end gap-2'>
        {status === Status.STATUS_DRAFT && (
          <ContextualButton icon={Edit} onClick={onPublishClick}>
            {translateActions('publish')}
          </ContextualButton>
        )}

        {status === Status.STATUS_AWAITING_COACH && (
          <>
            <ContextualButton icon={Lock} onClick={onUnblockClick}>
              {translateActions('unblockResource')}
            </ContextualButton>
            <ContextualButton icon={Play} onClick={onRestartClick}>
              {translateActions('restartProgram')}
            </ContextualButton>
          </>
        )}

        {status === Status.STATUS_PUBLISHED && (
          <ContextualButton icon={Lock} onClick={onFinishClick}>
            {translateActions('finish')}
          </ContextualButton>
        )}

        {analysePeriod && (
          <a className='bg-primary text-white fill-white hover:bg-gray-600 hover:text-white flex gap-2 items-center rounded-lg px-4 py-2 text-lg font-semibold tracking-wide' href='#analysePeriod'>
            <ArrowLeft className='-rotate-90' />

            {translateAnalyse('title')}
          </a>
        )}

        <Modal center open={openErrorModal} setOpen={setOpenErrorModal} size='normal' title='Objectifs vides'>
          <div className='py-4 px-2.5'>
            <div className='text-red-700 flex font-medium px-2'>Vous devez remplir un objectif pour chaque semaine.</div>
          </div>
        </Modal>

        <Modal center open={publishModal} setOpen={setPublishModal} title={translateActions('publishResource')}>
          <div className='flex flex-col items-center p-2 py-4'>
            <div className='text-center text-xl'>{translateActions('publishConfirmation')}</div>

            <form className='flex flex-col items-center mt-4' onSubmit={handleSubmit}>
              <label className='block font-medium text-gray-700 w-full' htmlFor='startAt'>{translateLabel('startAt')}</label>

              <input className='border-2 py-2 px-4 w-48 rounded-md mt-1 self-start' name='startAt' onChange={handleChange} required type='date' />

              <button className='flex gap-2 items-center rounded-lg px-4 py-2 mt-6 text-lg font-semibold tracking-wide bg-primary text-white fill-white hover:bg-gray-600 hover:text-white' type='submit'>
                {isSubmitting ? <ButtonLoader /> : translateActions('publish')}
              </button>
            </form>
          </div>
        </Modal>

        <Modal center open={finishModal} setOpen={setFinishModal} title={translateActions('finishResource')}>
          <div className='flex flex-col items-center p-2'>
            <div className='text-center text-xl'>{translateActions('finishConfirmation')}</div>

            <div className='flex mt-6 gap-8'>
              <ContextualButton onClick={onFinishHandler}>{translateActions('finish')}</ContextualButton>

              <ContextualButton onClick={onCancelHandler}>{translateActions('cancel')}</ContextualButton>
            </div>

            {errorMessage && <div className='text-red-700 flex font-medium my-2 self-center text-lg border border-red-700 px-2 rounded-md'>{errorMessage}</div>}
          </div>
        </Modal>

        <Modal center open={unblockModal} setOpen={setUnblockModal} title={translateActions('unblockResource')}>
          <div className='flex flex-col items-center p-2'>
            <div className='text-center text-xl'>{translateActions('unblockConfirmation')}</div>

            <div className='flex mt-6 gap-8'>
              <ContextualButton onClick={onUnblockHandler}>{translateActions('unblock')}</ContextualButton>

              <ContextualButton onClick={onCancelHandler}>{translateActions('cancel')}</ContextualButton>
            </div>

            {errorMessage && <div className='text-red-700 flex font-medium my-2 self-center text-lg border border-red-700 px-2 rounded-md'>{errorMessage}</div>}
          </div>
        </Modal>

        <Modal center open={restartModal} setOpen={setRestartModal} title={translateActions('restartProgram')}>
          <div className='flex flex-col items-center p-2'>
            <div className='text-center text-xl'>{translateActions('restartConfirmation')}</div>

            <div className='text-center text-sm'>{translateActions('restartConfirmationDescription')}</div>

            <div className='flex mt-6 gap-8'>
              <ContextualButton onClick={onRestartHandler}>{translateActions('restart')}</ContextualButton>

              <ContextualButton onClick={onCancelHandler}>{translateActions('cancel')}</ContextualButton>
            </div>

            {errorMessage && <div className='text-red-700 flex font-medium my-2 self-center text-lg border border-red-700 px-2 rounded-md'>{errorMessage}</div>}
          </div>
        </Modal>
      </div>
    </div>
  )
}

export default UserProgramHead
