import { CogIcon } from '@heroicons/react/24/outline'
import moment from 'moment'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import BookingSlot from '@components/calendar/booking-slot'
import BusySlot from '@components/calendar/busy-slot'
import EmptySlot from '@components/calendar/empty-slot'
import InformationPopup from '@components/information-popup/information-popup'
import useCalendarDaysListQuery from '@services/api/calendar/use-calendar-days-list-query'
import { captureException } from '@services/exceptions/capture-exception'
import { getHoursList } from '@services/tools/api-resources/get-hours-list'
import { Calendar as CalendarIcon, ChevronDown } from '@svg/icons'

moment.locale('fr')

const Calendar = () => {
  const { t: translateCalendar } = useTranslation('calendar')
  const [active, setActive] = useState(false)
  const navigate = useNavigate()

  const today = moment()

  const [{
    end,
    start
  }, setDates] = useState({
    end: today.endOf('week').format(),
    start: today.startOf('week').format()
  })

  const onTodayPressed = () => {
    setDates(() => ({
      end: today.endOf('week').format(),
      start: today.startOf('week').format()
    }))
  }

  const onNextPressed = () => {
    setDates(({ end, start }) => ({
      end: moment(end).add(1, 'week').format(),
      start: moment(start).add(1, 'week').format()
    }))
  }

  const onBackPressed = () => {
    setDates(({ end, start }) => ({
      end: moment(end).subtract(1, 'week').format(),
      start: moment(start).subtract(1, 'week').format()
    }))
  }

  const mondayDate = moment(start)
  const tuesdayDate = moment(start).add(1, 'days')
  const wednesdayDate = moment(start).add(2, 'days')
  const thursdayDate = moment(start).add(3, 'days')
  const fridayDate = moment(start).add(4, 'days')
  const saturdayDate = moment(start).add(5, 'days')
  const sundayDate = moment(start).add(6, 'days')

  const headers = [
    {
      date: mondayDate,
      day: mondayDate.format('DD'),
      name: translateCalendar('days.monday')
    },
    {
      date: tuesdayDate,
      day: tuesdayDate.format('DD'),
      name: translateCalendar('days.tuesday')
    },
    {
      date: wednesdayDate,
      day: wednesdayDate.format('DD'),
      name: translateCalendar('days.wednesday')
    },
    {
      date: thursdayDate,
      day: thursdayDate.format('DD'),
      name: translateCalendar('days.thursday')
    },
    {
      date: fridayDate,
      day: fridayDate.format('DD'),
      name: translateCalendar('days.friday')
    },
    {
      date: saturdayDate,
      day: saturdayDate.format('DD'),
      name: translateCalendar('days.saturday')
    },
    {
      date: sundayDate,
      day: sundayDate.format('DD'),
      name: translateCalendar('days.sunday')
    }
  ]

  const startMonth = moment(start).format('MMMM YYYY')
  const endMonth = moment(end).format('MMMM YYYY')
  const hours = getHoursList(8, 20, 30)

  const {
    data: {
      data: calendarEntries = []
    } = {},
    refetch
  } = useCalendarDaysListQuery({
    parameters: {
      end_date: end.slice(0, 10),
      start_date: start.slice(0, 10),
      timezone: 'Europe/Paris'
    }
  })

  const onReload = () => {
    refetch().catch(captureException)
  }

  const handleClick = () => {
    navigate('/calendar-availabilities')
  }

  return (
    <div className='mb-6 mx-auto px-4 sm:px-6 md:px-8 w-full'>
      <InformationPopup active={active} setActive={setActive}>
        <div>{translateCalendar('booking.cancelPopup')}</div>
      </InformationPopup>

      <div className='flex h-full flex-col max-h-[85vh]'>
        <header className='flex flex-col lg:flex-row flex-none lg:items-center justify-between gap-4 lg:gap-8 pb-8'>
          <div className='flex gap-4 items-start'>
            <div className='p-2.5 bg-primary rounded-lg'>
              <CalendarIcon className='w-8 h-8 fill-white' />
            </div>

            <div className='flex flex-col'>
              <div className='font-bold text-gray-900 text-4xl flex gap-4 items-center'>
                {translateCalendar('title')}

                {/* Setting Modal */}

                <button className='rounded-lg border p-1.5 shadow border-1 border-gray-50 shadow-gray-200 bg-white text-gray-700 hover:border-gray-700 hover:bg-gray-800 hover:text-white'>
                  <CogIcon className='w-5 h-5' onClick={handleClick} />
                </button>
              </div>

              <div className='text-gray-500 text-xl font-light'>{translateCalendar('description')}</div>
            </div>
          </div>

          <div className='flex flex-col lg:items-end lg:justify-end gap-2'>
            <div className='text-xl font-normal text-gray-700'>
              <div className='uppercase'>
                {startMonth === endMonth ? startMonth : `${startMonth} - ${endMonth}`}
              </div>
            </div>

            <div className='flex items-center'>
              <div className='flex items-center rounded-md md:items-stretch'>
                <button
                  className='flex items-center justify-center rounded-l-xl border-2 border-r-0 border-gray-100 bg-white py-2 pl-3 pr-4 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50'
                  onClick={onBackPressed}
                  type='button'
                >
                  <span className='sr-only'>{translateCalendar('previousWeek')}</span>

                  <ChevronDown aria-hidden='true' className='h-5 w-5 rotate-90' />
                </button>

                <button
                  className='hidden border-t-2 border-b-2 border-gray-100 bg-white px-3.5 text-sm font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900 focus:relative md:block'
                  onClick={onTodayPressed}
                  type='button'
                >
                  {translateCalendar('today')}
                </button>

                <span className='relative -mx-px h-5 w-px bg-gray-300 md:hidden' />

                <button
                  className='flex items-center justify-center rounded-r-xl border-2 border-l-0 border-gray-100 bg-white py-2 pl-4 pr-3 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50'
                  onClick={onNextPressed}
                  type='button'
                >
                  <span className='sr-only'>{translateCalendar('nextWeek')}</span>

                  <ChevronDown aria-hidden='true' className='h-5 w-5 -rotate-90' />
                </button>
              </div>
            </div>
          </div>
        </header>

        <div className='isolate flex flex-auto flex-col overflow-auto xl:overflow-x-hidden bg-white scrollbar-hide'>
          <div className='flex flex-none flex-col max-w-none lg:max-w-full' style={{ width: '250%' }}>

            {/* Headers */}

            <div className='sticky top-0 z-30 flex-none bg-white'>
              <div className='-mr-px grid-cols-1 text-sm leading-6 text-gray-500 grid'>
                <div className='col-end-1 w-12 sticky left-0' />

                <div className='grid grid-cols-7 bg-white shadow border-1 border-black border-opacity-5 border-l-0 rounded-lg ring-'>
                  {headers.map((head) => {
                    const isToday = moment().format('l') === head.date.format('l')

                    return (
                      <div className='flex items-center justify-center p-1.5' key={head.name}>
                        <div className={`w-full text-center tracking-normal font-normal text-gray-900 py-2 flex flex-col md:flex-row justify-center items-center ${isToday ? 'bg-white rounded-lg' : 'rounded-none'}`}>
                          {head.name}

                          <div className={`items-center justify-center font-medium text-lg h-7 w-7 flex ${isToday ? 'rounded-full bg-primary text-white ml-2' : 'text-gray-900 ml-1'}`}>{head.day}</div>
                        </div>
                      </div>
                    )
                  })}
                </div>

              </div>
            </div>

            <div className='flex flex-col flex-auto border-r-4 border-gray-100'>
              <div className='-mr-px grid-cols-7 divide-x divide-gray-200 text-sm leading-6 text-gray-500 grid h-16'>
                <div className='col-end-1 w-12 flex items-end justify-center sticky left-0' />

                {headers.map((head) => {
                  return (
                    <div className='bg-gray-100' key={head.name} />
                  )
                })}
              </div>

              {/* Hours list */}

              {hours.map((hour) => {
                return (
                  <div className='-mr-px grid-cols-7 divide-x divide-y divide-gray-200 text-sm leading-6 text-gray-500 grid h-16' key={hour}>
                    <div className='col-end-1 w-12 flex justify-center text-gray-500 font-medium -mt-3 sticky left-0'>
                      {hour}
                    </div>

                    {headers.map((head) => {
                      const date = head.date.format().slice(0, 10)
                      const day = calendarEntries.find(day => day.date === date)
                      const timeSlot = day?.timeSlots?.find(slot => slot.start === hour)

                      if (timeSlot?.isFree) {
                        return (
                          <EmptySlot date={moment(date).format('LL')} key={timeSlot.ref} onReload={onReload} timeSlot={timeSlot} />
                        )
                      } else if (timeSlot?.busySlot) {
                        return (
                          <BusySlot busySlot={timeSlot.busySlot} key={timeSlot.busySlot.id} onReload={onReload} />
                        )
                      } else if (timeSlot?.booking) {
                        return (
                          <BookingSlot bookingSlot={timeSlot.booking} key={timeSlot.booking.uid} onReload={onReload} setActive={setActive} />
                        )
                      } else {
                        return (
                          <div className='bg-gray-100' key={head.name} />
                        )
                      }
                    })}
                  </div>
                )
              })}
            </div>

            <div className='sticky bottom-0 border border-gray-100 ml-12' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Calendar
