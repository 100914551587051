import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import ApiResourceSingleEntry from '@components/api-resource/api-resource-single-entry'
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import Mealplan from '@components/mealplan/mealplan'
import { mealplansRoles } from '@services/roles/roles'

const MealplanEntry = () => {
  const [apiResponse, setApiResponse] = useState('')
  const { t: translateMealplan } = useTranslation('mealplan', { keyPrefix: 'entry' })
  const { t: bc } = useTranslation('breadcrumbs')
  const { id = '' } = useParams()

  const breadcrumbs = [
    { href: '/mealplans', name: bc('mealplans') },
    { href: '', name: apiResponse ?? id }
  ]

  const onSuccess = (res) => {
    setApiResponse(res.title)
  }

  return (
    <Layout description={translateMealplan('description')} title={`${translateMealplan('title')} - ${apiResponse}`}>
      <Guard acceptedRoles={mealplansRoles}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <>
          {id && (
            <ApiResourceSingleEntry
              component={Mealplan}
              id={id}
              onSuccess={onSuccess}
              path={'mealplans/{uid}'}
            />
          )}
        </>
      </Guard>
    </Layout>
  )
}

export default MealplanEntry
