import { PlusIcon, XCircleIcon } from '@heroicons/react/24/outline'
import { type UseMutationResult } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'

import ModalAddEntry from '@components/modals/modal-add-entry'
import ExerciseEntry from '@components/program/exercise-entry'
import { type ExerciseListProps } from '@components/program/exercise-list/index'
import SkeletonLoader from '@components/skeleton-loader/skeleton-loader'
import { type UserProgramCourseExercise } from '@components/user-program-entry/user-program-entry-interfaces'
import { type Course } from '@interfaces/api/course'
import { type ExerciseEntry as ExerciseEntryType, type ExercisesListEntry } from '@interfaces/api/exercise/exercise-entry'
import { type ApiReponseError } from '@root/interfaces/api'
import useApiResource from '@services/api/definition'
import {
  appendExercise,
  deleteExercise, editExercices
} from '@services/api/exercise/list-tools'
import useApiResourceItemQuery from '@services/api/resources/item-query'
import usePatchEntry from '@services/api/resources/patch-entry-query'
import { captureException } from '@services/exceptions/capture-exception'
import { useDraggableStore } from '@services/stores/draggable/draggable'

const ExerciseList = ({ courseId }: ExerciseListProps) => {
  const { t: translateProgram } = useTranslation('program')
  const { t: translateError } = useTranslation('errors')
  const { isDraggable } = useDraggableStore()
  const [errorMessage, setErrorMessage] = useState<string>()

  const {
    data: {
      courseExercises
    } = {},
    isFetching,
    isLoading,
    refetch
  } = useApiResourceItemQuery<Course>({
    id: courseId,
    path: 'program-courses/{uid}'
  })

  const exerciseDefinition = useApiResource('exercises')

  const reload = () => {
    refetch().catch((err) => {
      captureException(err as Error)
    })
  }

  useEffect(() => {
    reload()
  }, [])

  const resourceEntry = {
    id: courseId,
    path: 'program-courses/{uid}/exercises'
  }
  const { mutateAsync: editResourceEntry } = usePatchEntry(resourceEntry)

  const [open, setOpen] = useState(false)
  const openExerciseAddModal = () => {
    setOpen(true)
  }

  const onExerciseAdd = (exercisePayload: ExercisesListEntry) => {
    const formattedCourseExercises = courseExercises?.map(ce => ({ ...ce, isWarmup: false }))
    const newExerciseList = appendExercise(exercisePayload, formattedCourseExercises)
    editResourceEntry({
      courseExercises: newExerciseList
    }).then(() => {
      reload()
      setOpen(false)
    }).catch((e) => {
      const err = e as UseMutationResult<ApiReponseError>
      const errorCode = err.data?.errorCode ?? 'generic'
      setErrorMessage(translateError(errorCode))
    })
  }

  const onExerciseDelete = (exerciseId: string) => () => {
    const newExerciseList = deleteExercise(exerciseId, courseExercises)
    editResourceEntry({
      courseExercises: newExerciseList
    }).then(() => {
      reload()
    }).catch((err) => {
      captureException(err as Error)
    })
  }

  const exercisesCheck: ExerciseEntryType[] = []

  courseExercises?.forEach((exercise) => {
    if (exercise.setCount === 0 || (exercise.effortDuration === 0 && exercise.repetitionCount === 0)) {
      exercisesCheck.push(exercise)
    }
  })

  const onExerciseSubmit = (setSubmitting, values, exercise, setExerciseOpen) => {
    const newExerciseList = editExercices(exercise.uid, values, courseExercises)

    editResourceEntry({
      courseExercises: newExerciseList
    }).then(() => {
      reload()
      setExerciseOpen(false)
    }).catch((e) => {
      const err = e as UseMutationResult<ApiReponseError>
      const errorCode = err.data?.errorCode ?? 'generic'
      setErrorMessage(translateError(errorCode))
    }).finally(() => {
      setSubmitting(false)
    })
  }

  return (
    <>
      {(isLoading || isFetching)
        ? <SkeletonLoader type='content' />
        : (
          <>
            {!!exercisesCheck?.length && (
              <div className='absolute top-2 left-0 right-0 w-5 h-5 rounded-md mx-auto flex items-center justify-center group'>
                <XCircleIcon className='text-red-500' />

                <div className='group-hover:flex hidden w-[15vw] absolute text-sm -bottom-14 md:ml-auto bg-red-500 rounded-md px-2 py-1 text-white font-bold z-10'>{translateProgram('checkExercise')}</div>
              </div>
            )}

            <Droppable droppableId={courseId}>
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  className={`flex flex-col ${!courseExercises?.length ? 'pb-0' : 'pb-4'}`}
                  ref={provided.innerRef}
                >
                  {!!courseExercises?.length && !isFetching
                    ? (
                      <>
                        {courseExercises?.map((exercise, index) => (
                          <Draggable draggableId={exercise.uid} index={index} isDragDisabled={!isDraggable} key={exercise.uid}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={`rounded-lg p-1.5 bg-white ${snapshot.isDragging ? 'drop-shadow-md' : 'shadow-none'}`}
                              >
                                <ExerciseEntry
                                  courseExercises={courseExercises}
                                  errorMessage={errorMessage}
                                  exercise={exercise as UserProgramCourseExercise}
                                  onDelete={onExerciseDelete(exercise.uid)}
                                  onSubmitCallback={onExerciseSubmit}
                                />
                              </div>
                            )}
                          </Draggable>
                        ))}
                      </>
                    )
                    : (
                      <div className='flex flex-col py-[14px] px-5 text-center'>
                        <div className='text-gray-900 text-sm'>{translateProgram('noExercise')}</div>

                        <div className='text-gray-500 text-xs'>{translateProgram('addExercise')}</div>
                      </div>
                    )}

                  {provided.placeholder}
                </div>
              )}
            </Droppable>

            <button className='w-full flex items-center justify-center bg-gray-100 rounded-lg p-1' onClick={openExerciseAddModal}>
              <PlusIcon className='w-4 h-4' />
            </button>

            {exerciseDefinition && (
              <ModalAddEntry
                definition={exerciseDefinition}
                errorMessage={errorMessage}
                onAdd={onExerciseAdd}
                open={open}
                setOpen={setOpen}
              />
            )}
          </>
        )
      }
    </>
  )
}

export default ExerciseList
