import { create } from 'zustand'

interface DraggableStore {
  isDraggable: boolean
  setIsDraggable: (draggable: boolean) => void
}

export const useDraggableStore = create<DraggableStore>((set) => ({
  isDraggable: true,
  setIsDraggable: (isDraggable) => {
    set({ isDraggable })
  }
}))
