import { Roles } from '@interfaces/api/roles'

export const programsRoles = [Roles.ROLE_ADMIN, Roles.ROLE_SUPER_ADMIN, Roles.ROLE_EDITOR, Roles.ROLE_COACH]
export const recipesRoles = [Roles.ROLE_ADMIN, Roles.ROLE_SUPER_ADMIN, Roles.ROLE_EDITOR, Roles.ROLE_COACH]
export const exercisesRoles = [Roles.ROLE_ADMIN, Roles.ROLE_SUPER_ADMIN, Roles.ROLE_EDITOR, Roles.ROLE_COACH]
export const videosRoles = [Roles.ROLE_ADMIN, Roles.ROLE_SUPER_ADMIN, Roles.ROLE_EDITOR, Roles.ROLE_COACH]
export const playlistsRoles = [Roles.ROLE_ADMIN, Roles.ROLE_SUPER_ADMIN, Roles.ROLE_EDITOR, Roles.ROLE_COACH]
export const audioRoles = [Roles.ROLE_ADMIN, Roles.ROLE_SUPER_ADMIN, Roles.ROLE_EDITOR, Roles.ROLE_COACH]
export const mealplansRoles = [Roles.ROLE_ADMIN, Roles.ROLE_SUPER_ADMIN, Roles.ROLE_EDITOR, Roles.ROLE_COACH]
export const ingredientsRoles = [Roles.ROLE_ADMIN, Roles.ROLE_SUPER_ADMIN, Roles.ROLE_EDITOR, Roles.ROLE_COACH]
export const userListRoles = [Roles.ROLE_ADMIN, Roles.ROLE_SUPER_ADMIN, Roles.ROLE_MANAGER, Roles.ROLE_COACH]
export const purchaseListRoles = [Roles.ROLE_ADMIN, Roles.ROLE_SUPER_ADMIN, Roles.ROLE_MANAGER, Roles.ROLE_COACH]
export const userProfileRoles = [Roles.ROLE_ADMIN, Roles.ROLE_SUPER_ADMIN, Roles.ROLE_MANAGER, Roles.ROLE_COACH]
export const calendarRoles = [Roles.ROLE_ADMIN, Roles.ROLE_SUPER_ADMIN, Roles.ROLE_COACH, Roles.ROLE_COACH]
export const configurationRoles = [Roles.ROLE_ADMIN, Roles.ROLE_SUPER_ADMIN, Roles.ROLE_COACH]
export const notificationsRoles = [Roles.ROLE_ADMIN, Roles.ROLE_SUPER_ADMIN, Roles.ROLE_MANAGER, Roles.ROLE_COACH]
export const themeRoles = [Roles.ROLE_SUPER_ADMIN, Roles.ROLE_ADMIN]
