import { useMutation, type UseMutationOptions, type UseMutationResult } from '@tanstack/react-query'

import { type ChatMessage, type ChatMessageUpdateBody } from '@interfaces/api/chat'
import useApi from '@services/api/axios'

const useUpdateChatMessageMutation = (
  options: UseMutationOptions<ChatMessage, unknown, ChatMessageUpdateBody> = {}
): UseMutationResult<ChatMessage, unknown, ChatMessageUpdateBody> => {
  const { put } = useApi()

  return useMutation<ChatMessage, unknown, ChatMessageUpdateBody>({
    mutationFn: async ({ body, uid }) => await put<ChatMessageUpdateBody['body'], ChatMessage>(`api/chat-messages/${uid}`, body),
    ...options
  })
}

export default useUpdateChatMessageMutation
