import { type EmptyStateProps } from '@components/empty-state'

const EmptyState = ({ children }: EmptyStateProps) => {
  return (
    <div className='my-2 shadow shadow-gray-200 text-center bg-white px-4 py-6 rounded-lg'>
      {children}
    </div>
  )
}

export default EmptyState
