import { useQuery, type UseQueryResult } from '@tanstack/react-query'

import { type ApiError, type ApiPlatformListResponse } from '@interfaces/api/api'
import { type UserRequestParameters } from '@interfaces/api/request'
import { type UserMealplan, type UserMealplansResponse } from '@interfaces/api/user-mealplan'
import useAxios from '@services/api/axios'
import { formatApiListResponse } from '@services/tools/api-resources/format-list-response'

const useUserMealplansQuery = ({ page, user }: UserRequestParameters): UseQueryResult<UserMealplansResponse> => {
  const { get } = useAxios()

  return useQuery<UserMealplansResponse, ApiError>({
    queryFn: async () => {
      return await get<UserRequestParameters, ApiPlatformListResponse<UserMealplan>>('api/user-mealplans', {
        page,
        user
      }).then(result => formatApiListResponse<UserMealplan>(result))
    },
    queryKey: ['user-mealplans', page, user],
    retry: false
  })
}

export default useUserMealplansQuery
