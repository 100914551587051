import { useQuery, type UseQueryResult } from '@tanstack/react-query'

import { type AnalysePeriod, type AnalysePeriodsResponse } from '@interfaces/api/analyse-periods'
import { type ApiError, type ApiPlatformListResponse } from '@interfaces/api/api'
import { type UserRequestParameters } from '@interfaces/api/request'
import useAxios from '@services/api/axios'
import { formatApiListResponse } from '@services/tools/api-resources/format-list-response'

const useUserAnalysePeriods = ({ page, user }: UserRequestParameters): UseQueryResult<AnalysePeriodsResponse> => {
  const { get } = useAxios()

  return useQuery<AnalysePeriodsResponse, ApiError>({
    queryFn: async () => {
      return await get<UserRequestParameters, ApiPlatformListResponse<AnalysePeriod>>('api/analyse-periods', {
        page,
        user
      }).then(result => formatApiListResponse<AnalysePeriod>(result))
    },
    queryKey: ['user-analyse-periods', page, user],
    retry: false

  })
}

export default useUserAnalysePeriods
