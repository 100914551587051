import { CheckIcon } from '@heroicons/react/24/outline'

import type StepsInterfaces from '@pages/notifications/steps.interfaces'

const Steps = ({ currentStep, setCurrentStep, steps }: StepsInterfaces) => {
  const changeStep = (step: number) => {
    if (step < currentStep) {
      setCurrentStep(step)
    }
  }

  return (
    <nav aria-label='Progress' className='pl-4 pr-2 sm:pr:4 sm:pl-6 md:pr-6 md:pl-8 w-full sm:w-auto md:w-full'>
      <ol className='bg-white shadow shadow-gray-200 border-1 border-gray-50 md:flex md:divide-y-0 rounded-lg'>
        {steps.map((step, stepIdx: number) => (
          <li className='relative md:flex md:flex-1' key={step.name}>
            {(stepIdx + 1) < currentStep
              ? (
                <button className='group flex w-full items-center' onClick={() => {
                  changeStep(stepIdx + 1)
                }}
                >
                  <span className='flex items-center px-6 py-4 text-sm font-medium'>
                    <span className='flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-secondary'>
                      <CheckIcon aria-hidden='true' className='h-6 w-6 text-white' />
                    </span>

                    <span className='ml-4 text-sm font-medium text-gray-900'>{step.name}</span>
                  </span>
                </button>
              )
              : ((stepIdx + 1) === currentStep)
                ? (
                  <button aria-current='step' className='flex items-center px-6 py-4 text-sm font-medium' onClick={() => {
                    changeStep(stepIdx + 1)
                  }}
                  >
                    <span className='flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-secondary'>
                      <span className='text-primary'>{step.id}</span>
                    </span>

                    <span className='ml-4 text-sm font-medium text-primary'>{step.name}</span>
                  </button>
                )
                : (
                  <button className='group flex items-center' onClick={() => {
                    changeStep(stepIdx + 1)
                  }}
                  >
                    <span className='flex items-center px-6 py-4 text-sm font-medium'>
                      <span className='flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400'>
                        <span className='text-gray-500 group-hover:text-gray-900'>{step.id}</span>
                      </span>

                      <span className='ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900'>{step.name}</span>
                    </span>
                  </button>
                )}

            {stepIdx !== steps.length - 1 && (
              <>
                {/* Arrow separator for lg screens and up */}
                <div aria-hidden='true' className='absolute right-0 top-0 hidden h-full w-5 md:block'>
                  <svg
                    className='h-full w-full text-gray-200'
                    fill='none'
                    preserveAspectRatio='none'
                    viewBox='0 0 22 80'
                  >
                    <path
                      d='M0 -2L20 40L0 82'
                      stroke='currentcolor'
                      strokeLinejoin='round'
                      vectorEffect='non-scaling-stroke'
                    />
                  </svg>
                </div>
              </>
            )}
          </li>
        ))}
      </ol>
    </nav>
  )
}

export default Steps
