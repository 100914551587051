import { useQuery, type UseQueryResult } from '@tanstack/react-query'

import { type ApiPlatformEntity, type ApiPlatformListResponse } from '@interfaces/api/api'
import useAxios from '@services/api/axios'
import { formatApiListResponse } from '@services/tools/api-resources/format-list-response'

interface QueryParameters {
  itemId: string
  path: string
}

const useItemChildrenQuery = <T extends ApiPlatformEntity>({ itemId, path }: QueryParameters) => {
  const { get } = useAxios()
  const uri = path.replace('{uid}', itemId)

  const queryKey = [uri]
  const queryFn = async () => {
    return await get(`api/${uri}`).then(result => result)
  }
  const staleTime = 5 * 60 * 1000

  const { data, isFetching, refetch, ...useQueryVars }: UseQueryResult<ApiPlatformListResponse<T>> = useQuery({
    queryFn,
    queryKey,
    staleTime
  })

  return {
    data: data ? formatApiListResponse(data) : undefined,
    isFetching,
    refetch,
    ...useQueryVars
  }
}

export default useItemChildrenQuery
