import { ErrorMessage, Field, useField } from 'formik'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import DayTimePicker from '@components/form-fields/form-workschedule-field/day-time-picker'
import {
  type FormWorkScheduleFieldProps, type WorkScheduleList
} from '@components/form-fields/form-workschedule-field/form-workschedule-field-interfaces'
import { captureException } from '@services/exceptions/capture-exception'

const FormWorkScheduleField = ({ label, name, required = false }: FormWorkScheduleFieldProps) => {
  const [timesValues, setTimesValues] = useState<WorkScheduleList>([[], [], [], [], [], [], []])
  const { t: translateCalendar } = useTranslation('calendar')
  const days = [...Object.values<string>(translateCalendar('days', { returnObjects: true }))]

  const [, meta, helpers] = useField({ name })
  const { setValue } = helpers
  const { initialValue } = meta

  useEffect(() => {
    if (initialValue.length) {
      setTimesValues(initialValue)
    }
  }, [initialValue])

  useEffect(() => {
    setValue(timesValues).catch(captureException)
  }, [timesValues])

  return (
    <div className='flex flex-col'>
      <label className='block font-medium text-gray-700' htmlFor={name}>{label}</label>

      <Field
        name={name}
        required={required}
        type='hidden'
      />

      <div className='grid grid-cols-3 items-start gap-4 mt-2'>
        {days?.map((day, index) => {
          return (
            <div className='flex flex-col gap-1 relative border-2 border-gray-100 p-2 rounded-lg' key={day}>
              <div className='font-medium text-gray-700'>
                {day}
              </div>

              <DayTimePicker dayIndex={index} initialValues={initialValue} setTimesValues={setTimesValues} timesValues={timesValues} />
            </div>
          )
        })}
      </div>

      <ErrorMessage className='mt-2 text-xs text-red-600 font-medium' component='div' name={name} />
    </div>
  )
}

export default FormWorkScheduleField
