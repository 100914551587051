import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import ApiResourceSingleEntry from '@components/api-resource/api-resource-single-entry'
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import UserMealplanEntry from '@components/user-mealplan-entry/user-mealplan-entry'
import useUserProfilQuery from '@services/api/user/use-user-profile-query'
import { userProfileRoles } from '@services/roles/roles'

const UserMealplan = () => {
  const [apiResponse, setApiResponse] = useState('')
  const { t: translateMealplan } = useTranslation('mealplan', { keyPrefix: 'userMealplan' })
  const { t: bc } = useTranslation('breadcrumbs')
  const { uid = '' } = useParams()
  const { id = '' } = useParams()
  const userId = Number(id)

  const { data } = useUserProfilQuery(userId)

  const fullName = data?.firstName && data?.lastName ? `${data.firstName} ${data.lastName}` : ''

  const breadcrumbs = [
    { href: '/users', name: bc('users') },
    { href: `/users/${id}`, name: fullName },
    { href: '', name: apiResponse ?? uid }
  ]

  const onSuccess = (res) => {
    setApiResponse(res.mealplan.title)
  }

  return (
    <Layout description={translateMealplan('description')} title={`${translateMealplan('title')} - ${apiResponse}`}>
      <Guard acceptedRoles={userProfileRoles}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <>
          {uid && (
            <ApiResourceSingleEntry
              component={UserMealplanEntry}
              id={uid}
              onSuccess={onSuccess}
              path={'user-mealplans/{uid}'}
            />
          )}
        </>
      </Guard>
    </Layout>
  )
}

export default UserMealplan
