import { type StatsTileProps } from '@components/stats-tile/stats-tile-interfaces'

const StatsTile = ({ onClick, title, value }: StatsTileProps) => {
  return (
    <button className='p-2' onClick={onClick} type='button'>
      <div
        className='flex flex-col px-6 py-10 overflow-hidden bg-primary rounded-xl shadow-lg duration-300 hover:shadow-2xl group'
      >

        <h1
          className='text-3xl sm:text-4xl xl:text-5xl font-bold text-white'
        >
          {value}
        </h1>

        <div className='flex flex-row justify-between text-white'>
          <p>{title}</p>
        </div>
      </div>
    </button>
  )
}

export default StatsTile
