import { useQueries, type UseQueryResult } from '@tanstack/react-query'

import { type AudioTemplateCategory } from '@interfaces/api/audio-templates'
import useAxios from '@services/api/axios'

interface CategoriesTemplates {
  categories: AudioTemplateCategory[]
}

const useCategoriesTemplates = ({ categories }: CategoriesTemplates) => {
  const { get } = useAxios()

  const getCategoryTemplates = async (category: AudioTemplateCategory) => {
    const data = await get<unknown, UseQueryResult>(`/api/audio-template-categories/${category.uid}/templates`).then(result => result['hydra:member'])

    return {
      category: category.name,
      templates: data
    }
  }

  return useQueries({
    queries: categories.map((category) => {
      return {
        queryFn: async () => await getCategoryTemplates(category),
        queryKey: ['category-templates', category.uid]
      }
    })
  })
}

export default useCategoriesTemplates
