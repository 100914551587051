import { IngredientCreate } from '@pages'
import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import ApiResource from '@components/api-resource'
import ApiResourceEdit from '@components/api-resource/api-resource-edit'
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import Modal from '@components/modals/modal'
import SectionTitle from '@components/section-title/section-title'
import { type ApiPlatformEntity } from '@interfaces/api'
import { type Ingredient } from '@interfaces/api/ingredient'
import useApiResource from '@services/api/definition'
import { captureException } from '@services/exceptions/capture-exception'
import { ingredientsRoles } from '@services/roles/roles'
import { Layers } from '@svg/icons'

const IngredientsList = () => {
  const { t: translateResource } = useTranslation('apiResources')
  const { t: translateIngredient } = useTranslation('ingredient')
  const { t: bc } = useTranslation('breadcrumbs')
  const [openCreate, setOpenCreate] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [ingredient, setIngredient] = useState<Ingredient>()

  const queryClient = useQueryClient()
  const definition = useApiResource('ingredients/{uid}')

  const breadcrumbs = [
    { href: '', name: bc('ingredients') }
  ]

  const onReload = () => {
    queryClient.refetchQueries({ queryKey: ['ingredients'] }).catch((err) => {
      captureException(err as Error)
    })
  }

  const onEntryClick = (entry: ApiPlatformEntity) => {
    const ingredient = entry as Ingredient
    setIngredient(ingredient)
    setOpenEdit(true)
  }

  const onCreateClick = () => {
    setOpenCreate(true)
  }

  const onEdit = () => {
    onReload()
    setOpenEdit(false)
  }

  const onCreate = () => {
    onReload()
    setOpenCreate(false)
  }

  return (
    <Layout description={translateIngredient('description')} title={translateIngredient('title')}>
      <Guard acceptedRoles={ingredientsRoles}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <SectionTitle description={translateIngredient('description')} icon={Layers} title={translateIngredient('title')} />

        <ApiResource name={'ingredients'} onClick={onEntryClick} withCreateOption={onCreateClick} withDeleteOption />

        <Modal center open={openCreate} setOpen={setOpenCreate} title={translateResource('create.ingredients')}>
          <IngredientCreate onCreate={onCreate} />
        </Modal>

        <>
          {ingredient && (
            <Modal center open={openEdit} setOpen={setOpenEdit} title={translateResource('edit.ingredients')}>
              {definition && <ApiResourceEdit definition={definition} id={ingredient.uid} onEdit={onEdit} />}
            </Modal>
          )}
        </>
      </Guard>
    </Layout>
  )
}

export default IngredientsList
