import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import Modal from '@components/modals/modal'
import { type DataStore } from '@interfaces/api/data-store'
import DataStoreForm from '@pages/configuration/components/configuration-form/data-store-form'
import { FormMode } from '@pages/configuration/components/configuration-form/data-store-form.interfaces'
import DataStoreList from '@pages/configuration/components/data-store-list/data-store-list'
import useCreateEntry from '@services/api/data-store/create-data-store'
import usePutEntry from '@services/api/resources/put-entry-query'
import { captureException } from '@services/exceptions/capture-exception'
import { configurationRoles } from '@services/roles/roles'

const Configuration = () => {
  const { t: translateConfig } = useTranslation('configuration')
  const { t: bc } = useTranslation('breadcrumbs')
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [dataStore, setDataStore] = useState<DataStore>()

  const queryClient = useQueryClient()

  const breadcrumbs = [
    { href: '', name: bc('configuration') }
  ]
  const { isPending: isCreating, mutateAsync: createResourceEntry } = useCreateEntry('api/data-store')
  const { isPending: isEditing, mutateAsync: editResourceEntry } = usePutEntry(`api/data-store/${dataStore?.key ?? ''}`)

  const onCreate = () => {
    setShowCreateModal(true)
  }

  const onEditClick = (entry) => {
    setDataStore(entry)
    setShowEditModal(true)
  }

  const onSubmitEdition = async (values) => {
    await editResourceEntry(values).catch(captureException)
    setShowEditModal(false)
    queryClient.refetchQueries({ queryKey: ['data-store'] }).catch(console.log)
  }

  const onSubmitCreation = async (values) => {
    await createResourceEntry(values).catch(captureException)
    setShowCreateModal(false)
    queryClient.refetchQueries({ queryKey: ['data-store'] }).catch(console.log)
  }

  return (
    <Layout description={translateConfig('description')} title={translateConfig('title')}>
      <Guard acceptedRoles={configurationRoles}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <DataStoreList onCreateClick={onCreate} onEditClick={onEditClick} />

        <>
          {!!dataStore?.key && (
            <Modal center open={showEditModal} setOpen={setShowEditModal} size='large' title={'Edition'} >
              <DataStoreForm
                initialValues={{ data: dataStore.data, key: dataStore.key }}
                isLoading={isCreating}
                mode={FormMode.edition}
                onSubmit={onSubmitEdition}
              />
            </Modal>
          )}
        </>

        <Modal center open={showCreateModal} setOpen={setShowCreateModal} size='large' title={'Création'} >
          <DataStoreForm
            initialValues={{ data: '', key: '' }}
            isLoading={isEditing}
            mode={FormMode.creation}
            onSubmit={onSubmitCreation}
          />
        </Modal>

      </Guard>
    </Layout>
  )
}

export default Configuration
