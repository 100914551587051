import React from 'react'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'

import StatusTag from '@components/status-tag/status-tag'
import TableEntry from '@components/table/table-data/table-entry'
import { type AnalysePeriod } from '@interfaces/api/analyse-periods'
import useAnalysePeriodDaysQuery from '@services/api/analyse-period-days/use-analyse-period-days-query'

const UserAnalysePeriodsTableEntry = (analysePeriod: AnalysePeriod) => {
  const { id = '' } = useParams()
  const navigate = useNavigate()
  const {
    createdAt,
    startAt,
    status,
    uid
  } = analysePeriod

  const onClick = () => {
    navigate(`/users/${id}/analyse-period/${uid}`)
  }

  // Not optimized at all @TODO add infos to analysePeriod Query
  const {
    data: {
      data: analysePeriodDays
    } = {}
  } = useAnalysePeriodDaysQuery(uid)

  const daysFinished = analysePeriodDays ? analysePeriodDays?.filter(d => d?.closed)?.length : 0

  return (
    <TableEntry onClick={onClick}>
      <td className='p-4 tabular-nums'>
        {(new Date(createdAt)).toLocaleDateString()}
      </td>

      <td className='p-4 tabular-nums'>
        {startAt && (new Date(startAt)).toLocaleDateString()}
      </td>

      <td className='p-4 tabular-nums'>
        <span className='text-xl text-white font-semibold bg-primary rounded-full px-2'>{`${daysFinished}/${3}`}</span>
      </td>

      <td className='p-4'>
        <StatusTag status={status} />
      </td>
    </TableEntry>
  )
}

export default UserAnalysePeriodsTableEntry
