import {
  PencilSquareIcon,
  PlusCircleIcon
} from '@heroicons/react/24/outline'
import { type UseMutationResult } from '@tanstack/react-query'
import {
  Form,
  Formik
} from 'formik'
import {
  type ReactElement,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'

import { type ApiResourceFormProps } from '@components/api-resource/api-resource-form/index'
import ButtonLoader from '@components/buttons/button-loader/button-loader'
import FormAutocompleteField from '@components/form-fields/form-autocomplete-field'
import FormFileField from '@components/form-fields/form-file-field'
import FormNumberField from '@components/form-fields/form-number-field'
import FormSelectField from '@components/form-fields/form-select-field'
import FormTextField from '@components/form-fields/form-text-field'
import FormTextAreaField from '@components/form-fields/form-textarea-field'
import FormTimezoneField from '@components/form-fields/form-timezone-field'
import FormToggleField from '@components/form-fields/form-toggle-field'
import FormVideoField from '@components/form-fields/form-video-field'
import FormWorkScheduleField from '@components/form-fields/form-workschedule-field'
import { NotificationType } from '@components/notification/notification.interfaces'
import SkeletonLoader from '@components/skeleton-loader/skeleton-loader'
import {
  type ApiPlatformEntity,
  type ApiReponseError
} from '@interfaces/api/api'
import { RecipeType } from '@interfaces/api/recipe'
import { Status } from '@interfaces/api/status'
import SearchMap from '@services/api/definition/search-map'
import { useUploadImage } from '@services/api/upload-image/upload-image'
import { captureException } from '@services/exceptions/capture-exception'
import { useNotificationStore } from '@services/stores/notification/notification'
import { useWorkScheduleInputStore } from '@services/stores/work-schedule-input/work-schedule-input'
import { getInitialValues } from '@services/tools/api-resources/formik'
import { handleImageViolations } from '@services/tools/api-resources/violations'
import countriesJson from '@services/translations/fr/countries.json'

const IMAGE_FIELDS = ['cover', 'photo', 'image', 'picture']
const VIDEO_FIELDS = ['video']
const MEDIA_FIELDS = [...IMAGE_FIELDS, ...VIDEO_FIELDS]

const ApiResourceForm = <T extends ApiPlatformEntity>({
  createMode = false,
  data,
  definition,
  disableNotification,
  excludedFields,
  fieldsToDisplay,
  isFetching,
  requestFields,
  submitEntry
}: ApiResourceFormProps<T>) => {
  const { t } = useTranslation('apiResources')
  const { t: status } = useTranslation('status')

  const [isUploading, setIsUploading] = useState(false)
  const { inputIsInvalid } = useWorkScheduleInputStore()
  const { displayNotification } = useNotificationStore()

  const { mutateAsync: uploadImageAsync } = useUploadImage()
  const onSubmit = async (values, {
    setErrors,
    setSubmitting
  }) => {
    let errorMessage = ''

    for (const key in values) {
      const field = requestFields.find(field => field.name === key)
      const fieldIsTranslatedData = field?.name.toLowerCase().includes('translateddata') ?? false
      const fieldIsEmpty = values[key] === ''

      // Sets any non-supported iri-reference to null
      if (fieldIsEmpty && field && 'type' in field.properties && field.properties.format === 'iri-reference') {
        values[key] = null
      }

      if (createMode && field && 'type' in field.properties && field.properties.type === 'array') {
        if (fieldIsTranslatedData) {
          values[key] = {
            en: {
              description: values.description,
              fullName: values.fullName,
              name: values.name,
              title: values.title
            }
          }
        } else {
          values[key] = {}
        }
      }

      if (values.video && key === 'video' && typeof values.video === 'object') {
        values[key] = values.video['@id']
      }

      if (values.introVideo && key === 'introVideo' && typeof values.introVideo === 'object') {
        values[key] = values.introVideo['@id']
      }

      if (IMAGE_FIELDS.includes(key) && values[key]) {
        if ('@id' in values[key]) {
          values[key] = values[key]['@id']
        } else {
          const formData = new FormData()
          formData.append('file', values[key])
          try {
            values[key] = await uploadImageAsync(formData)
          } catch (e) {
            const err = e as UseMutationResult<ApiReponseError>
            errorMessage = handleImageViolations(err?.data?.violations ?? [])
            setErrors({
              [key]: errorMessage
            })
          }
        }
      }
    }

    if (!errorMessage) {
      setSubmitting(true)
      try {
        await submitEntry(values)
        setSubmitting(false)
        !disableNotification && displayNotification(t(`${definition.name}.message.${createMode ? 'create' : 'update'}.success.title`), t(`${definition.name}.message.${createMode ? 'create' : 'update'}.success.description`), NotificationType.success)
      } catch (err) {
        captureException(err as Error)
      }
    }
  }

  const initialValues = getInitialValues<T>(requestFields, data ?? [])

  // Build the list of form inputs
  const renderMediaFields = (setFieldValue) => {
    const fields: ReactElement[] = []

    requestFields.forEach((field, index) => {
      let fieldElement

      if ('type' in field.properties) {
        if (IMAGE_FIELDS.includes(field.name)) {
          fieldElement = <FormFileField key={field.name} label={t(`labels.${field.name}`)} name={field.name} required={field.required ?? false} setFieldValue={setFieldValue} value={initialValues[field.name]} />
        } else if (VIDEO_FIELDS.includes(field.name.toLowerCase()) && field.properties.format === 'iri-reference') {
          fieldElement = <FormVideoField isUploading={isUploading} key={field.name} label={t(`labels.${field.name}`)} name={field.name} setIsUploading={setIsUploading} />
        }
      }

      if (fieldElement) {
        fields.push(
          <div className='flex' key={`wrapper-${field.name}`}>
            {fieldElement}
          </div>
        )
      }
    })

    return fields
  }

  const renderBooleanFields = () => {
    const fields: ReactElement[] = []

    requestFields.forEach((field, index) => {
      if ('type' in field.properties) {
        if (field.properties.type === 'boolean') {
          fields.push(<FormToggleField key={field.name} label={t(`labels.${field.name}`)} name={field.name} />)
        }
      }
    })

    return fields
  }

  const renderDefaultFields = () => {
    const fields: ReactElement[] = []

    // Créez un objet pour associer les noms de champs aux composants correspondants
    const fieldComponents = {}

    requestFields.forEach((field, index) => {
      if ('type' in field.properties) {
        if (fieldsToDisplay && !fieldsToDisplay?.includes(field.name)) {
          return
        }

        if (field.name === 'country' || field.name === 'nationality') {
          const sectionTypeOptions = Object.entries(countriesJson).map(([key, name]) => ({
            key,
            name
          }))
          fieldComponents[field.name] = <FormSelectField defaultValue={data?.[field.name]} key={field.name} label={t(`labels.${field.name}`)} name={field.name} options={sectionTypeOptions} />
        } else if (field.name === 'type' && definition.name === 'recipes') {
          const mealTypeOptions = Object.values(RecipeType).map(value => ({
            key: value,
            name: t(`labels.recipe.types.${value}`)
          }))
          fieldComponents[field.name] = <FormSelectField defaultValue={data?.[field.name]} key={field.name} label={t(`labels.${field.name}`)} name={field.name} options={mealTypeOptions} />
        } else if (field.properties.type === 'integer') {
          fieldComponents[field.name] = <FormNumberField key={field.name} label={t(`labels.${field.name}`)} name={field.name} required={field.required ?? false} />
        } else if (field.properties.type === 'string' && field.name === 'description') {
          fieldComponents[field.name] = <FormTextAreaField key={field.name} label={t(`labels.${field.name}`)} name={field.name} required={field.required ?? false} />
        } else if (field.properties.type === 'string' && field.properties.format === 'date-time') {
          fieldComponents[field.name] = <FormTextField key={field.name} label={t(`labels.${field.name}`)} name={field.name} required={field.required ?? false} type={'date'} />
        } else if (field.properties.type === 'string' && field.properties.format !== 'iri-reference' && field.name !== 'timezone' && field.name !== 'status') {
          fieldComponents[field.name] = <FormTextField key={field.name} label={t(`labels.${field.name}`)} name={field.name} required={field.required ?? false} />
        } else if (field.name === 'timezone') {
          fieldComponents[field.name] = <FormTimezoneField key={field.name} label={t(`labels.${field.name}`)} name={field.name} required={field.required ?? false} />
        }
      }
    })

    // Parcourez fieldsToDisplay si défini, sinon ajoutez les champs dans l'ordre d'origine
    if (fieldsToDisplay) {
      fieldsToDisplay.forEach((fieldName) => {
        if (fieldComponents[fieldName]) {
          fields.push(fieldComponents[fieldName])
        }
      })
    } else {
      Object.keys(fieldComponents).forEach((fieldName) => {
        fields.push(fieldComponents[fieldName])
      })
    }

    return fields
  }

  const renderIriReferenceFields = () => {
    const fields: ReactElement[] = []

    requestFields.forEach((field, index) => {
      if (
        'type' in field.properties &&
        field.properties.type === 'string' &&
        field.properties.format === 'iri-reference' &&
        !MEDIA_FIELDS.includes(field.name) &&
        !excludedFields?.includes(field.name) &&
        data
      ) {
        if ((fieldsToDisplay && !fieldsToDisplay?.includes(field.name)) ?? !(field.name in SearchMap)) {
          return
        }
        const value = data[field.name]
        fields.push(
          <FormAutocompleteField
            key={field.name}
            label={t(`labels.${field.name}`)}
            name={field.name}
            required={field.required ?? false}
            value={value}
          />
        )
      }
    })

    return fields
  }

  const renderTimeScheduleFields = () => {
    const fields: ReactElement[] = []

    requestFields.forEach((field, index) => {
      if ('type' in field.properties) {
        if (field.name === 'workSchedule') {
          fields.push(<FormWorkScheduleField key={field.name} label={t(`labels.${field.name}`)} name={field.name} required={field.required ?? false} />)
        }
      }
    })

    return fields
  }

  let statusField: ReactElement = <></>

  requestFields.forEach((field, index) => {
    if ('type' in field.properties) {
      if (field.properties.type === 'string' && field.name === 'status') {
        const options = [
          {
            key: Status.STATUS_DRAFT,
            name: status(Status.STATUS_DRAFT)
          },
          {
            key: Status.STATUS_PUBLISHED,
            name: status(Status.STATUS_PUBLISHED)
          },
          {
            key: Status.STATUS_UNPUBLISHED,
            name: status(Status.STATUS_UNPUBLISHED)
          }
        ]

        statusField = <FormSelectField defaultValue={data?.[field.name] ?? Status.STATUS_DRAFT} key={field.name} label={t(`labels.${field.name}`)} name={field.name} options={options} size='small' />
      }
    }
  })

  if (isFetching) {
    return (
      <div className='py-3 px-4 w-full'>
        <SkeletonLoader type='form' />
      </div>
    )
  }

  return (
    <div className='py-3 px-4 w-full'>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({
          isSubmitting,
          setFieldValue
        }) => {
          return (
            <Form className='space-y-6'>
              {!!renderTimeScheduleFields().length &&
                <div>{renderTimeScheduleFields()}</div>
              }

              <div className='flex gap-4'>
                <div className='flex flex-col gap-3 w-full'>
                  {renderDefaultFields().map((field) => {
                    if (field.props.name === 'title') {
                      return (
                        <div className='flex items-center gap-4' key={field.props.name}>
                          {field}

                          {statusField}
                        </div>
                      )
                    }

                    return field
                  })}

                  {renderIriReferenceFields()}

                  <div className='flex gap-4'>
                    {renderBooleanFields()}
                  </div>

                  {!!renderMediaFields(setFieldValue).length && (
                    <div className='flex flex-col gap-3 border-t border-gray-900/10 mt-4 pt-4'>
                      <h2 className='text-base font-semibold leading-7 text-gray-900'>Medias</h2>

                      <div className='flex flex-col'>
                        {renderMediaFields(setFieldValue)}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='flex justify-end'>
                <button
                  className={`flex gap-2 items-center justify-center rounded-lg px-4 py-2 text-lg font-semibold tracking-wide bg-primary text-white fill-white hover:bg-gray-600 hover:text-white ${isUploading || inputIsInvalid ? 'cursor-not-allowed bg-slate-400' : 'bg-slate-800 hover:bg-slate-700'}`}
                  disabled={isSubmitting || isUploading || inputIsInvalid}
                  type='submit'
                >
                  {t(`buttons.${createMode ? 'create' : 'update'}`)}

                  {isSubmitting ? <ButtonLoader /> : createMode ? <PlusCircleIcon className='w-5 h-5' /> : <PencilSquareIcon className='w-5 h-5' />}
                </button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default ApiResourceForm
