import { useQuery, type UseQueryResult } from '@tanstack/react-query'

import { type ApiError, type ApiPlatformListResponse } from '@interfaces/api/api'
import { type Purchase, type UserPurchasesResponse } from '@interfaces/api/purchase'
import { type PurchaseRequestParameters } from '@interfaces/api/request'
import useAxios from '@services/api/axios'
import { formatApiListResponse } from '@services/tools/api-resources/format-list-response'

const useUserPurchasesQuery = ({ page, user }: PurchaseRequestParameters): UseQueryResult<UserPurchasesResponse> => {
  const { get } = useAxios()

  return useQuery<UserPurchasesResponse, ApiError>({
    queryFn: async () => {
      return await get<PurchaseRequestParameters, ApiPlatformListResponse<Purchase>>('api/purchases', {
        page,
        user
      }).then(result => formatApiListResponse<Purchase>(result))
    },
    queryKey: ['user-purchases', page, user],
    retry: false
  })
}

export default useUserPurchasesQuery
