import { Field, Form, Formik } from 'formik'
import { useEffect, useRef, useState } from 'react'

import { type MealIngredientProps } from '@components/mealplan/meal-ingredient/meal-ingredient-interfaces'
import { captureException } from '@services/exceptions/capture-exception'
import { convertMealsToPayload } from '@services/tools/convert-meals-to-payload'
import { Delete } from '@svg/icons'

const MealIngredient = ({ editResourceEntry, ingredient, meal, onDeleteEntry, onReload, type }: MealIngredientProps) => {
  const [updateQuantity, setUpdateQuantity] = useState(false)
  const inputRef = useRef<HTMLDivElement>(null)

  const onDelete = (uid) => () => {
    onDeleteEntry(uid)
  }

  const onSubmit = async (values, { setSubmitting }) => {
    const meals = [...meal.mealIngredients]
    const mealIngredientIndex = meals.findIndex(meal => meal.uid === ingredient.uid)
    meals[mealIngredientIndex].quantity = values.quantity

    const newMealIngredients = convertMealsToPayload(meals)

    const payload = {
      [type]: {
        mealIngredients: newMealIngredients,
        name: meal.name
      }
    }

    try {
      await editResourceEntry(payload)
      setSubmitting(false)
      onReload()
      setUpdateQuantity(false)
    } catch (err) {
      captureException(err as Error)
    }
  }

  const initialValues = {
    quantity: ingredient.quantity
  }

  const handleQuantityClick = (e) => {
    e.stopPropagation()
    setUpdateQuantity(true)
  }

  useEffect(() => {
    if (inputRef?.current) {
      const clickEvent = (event) => {
        if (!inputRef.current?.contains(event.target) && updateQuantity) {
          setUpdateQuantity(false)
        }
      }

      document.addEventListener('click', clickEvent)

      return () => {
        document.removeEventListener('click', clickEvent)
      }
    }
  }, [inputRef?.current, updateQuantity])

  return (
    <div className='flex items-center justify-between gap-2'>
      <div className='line-clamp-1 text-gray-700 text-xs'>{ingredient.ingredient.name}</div>

      <div className='flex items-center'>
        {updateQuantity
          ? (
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
              {({ values }) => {
                return (
                  <Form>
                    <div ref={inputRef}>
                      <Field
                        autoComplete='off'
                        className='border-2 border-gray-100 rounded-md px-1 w-12 h-[22px] text-xs text-center text-gray-700 focus:outline-none'
                        name='quantity'
                        type='text'
                      />
                    </div>
                  </Form>
                )
              }}
            </Formik>
          )
          : <button className='text-gray-500 text-xs px-1 h-6 border-2 border-transparent' onClick={handleQuantityClick}>{ingredient.quantity}</button>
        }

        <button className='p-1 group flex' onClick={onDelete(ingredient.uid)}>
          <Delete className='w-3.5 h-3.5 fill-gray-300 group-hover:fill-gray-900' />
        </button>
      </div>
    </div>
  )
}

export default MealIngredient
