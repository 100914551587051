import { useTranslation } from 'react-i18next'

import { type PhotoContainerProps } from '@components/photo-container/photo-container-interfaces'
import { Close, Image } from '@svg/icons'

const PhotoContainer = ({ pictureList, title, withCheckbox = false }: PhotoContainerProps) => {
  const { t } = useTranslation('analysePeriods', { keyPrefix: 'day' })

  return (
    <div className='mt-2'>
      <div className='text-sm text-gray-700'>{title}</div>

      <div className='mt-2 grid items-start gap-2.5 grid-cols-2 lg:grid-cols-4'>
        {pictureList.map((picture) => (
          <div className='flex flex-col gap-1 h-full' key={picture.name}>
            <div className='text-xs text-gray-500'>{picture.name}</div>

            <div className='overflow-hidden rounded-lg bg-gray-100 w-full h-full min-h-[128px] aspect-square'>
              {withCheckbox && (
                <>
                  {picture.checkbox
                    ? (
                      <div className='flex flex-col bg-gray-200 w-full h-full gap-2 justify-center items-center p-2.5'>
                        <Close className='fill-gray-500 w-4' />

                        <div className='text-[10px] text-gray-500 text-center'>{t('noMeal')}</div>
                      </div>
                    )
                    : picture.imageSrc
                      ? <img alt={picture.name} className='object-cover object-center w-full h-full bg-gray-200' src={picture.imageSrc} />
                      : (
                        <div className='flex flex-col items-center justify-center bg-gray-200 w-full h-full'>
                          <Image className='w-4 h-4 fill-gray-500' />
                        </div>
                      )
                  }
                </>
              )}

              {picture.imageSrc && !withCheckbox
                ? <img alt={picture.name} className='object-cover object-center w-full h-full bg-gray-200' src={picture.imageSrc} />
                : (
                  <div className='flex flex-col items-center justify-center bg-gray-200 w-full h-full'>
                    <Image className='w-4 h-4 fill-gray-500' />
                  </div>
                )
              }
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default PhotoContainer
