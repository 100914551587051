import { type DateProps } from '@components/api-resource/resource-entry-fields/date/index'

const DateField = ({ datetimeEnabled = false, value }: DateProps) => {
  const dateObject = new Date(value)
  const date = dateObject.toLocaleDateString()
  const dateTime = dateObject.toLocaleString()

  return (
    <div
      className='inline-flex rounded-md text-gray-700 text-[15px]'
      title={dateTime}
    >
      {datetimeEnabled ? dateTime : date}
    </div>
  )
}

export default DateField
