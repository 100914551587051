import moment from 'moment'

export const updateDuration = (duration, direction = 1) => {
  if (direction === -1) {
    const newDuration = moment.duration(duration).subtract(0.5, 'hours').asMilliseconds()
    const formattedDuration = moment.utc(newDuration).format('HH:mm')

    return formattedDuration
  } else {
    const newDuration = moment.duration(duration).add(0.5, 'hours').asMilliseconds()
    const formattedDuration = moment.utc(newDuration).format('HH:mm')

    return formattedDuration
  }
}
