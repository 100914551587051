import { CalendarAvailabilityCreate } from '@pages'
import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import ApiResource from '@components/api-resource'
import ApiResourceEdit from '@components/api-resource/api-resource-edit'
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import ContextualButton from '@components/buttons/contextual-button'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import Modal from '@components/modals/modal'
import SectionTitle from '@components/section-title/section-title'
import { type CalendarAvailability } from '@interfaces/api/calendar'
import useApiResource from '@services/api/definition'
import useDeleteEntry from '@services/api/resources/delete-entry-query'
import usePatchEntry from '@services/api/resources/patch-entry-query'
import { captureException } from '@services/exceptions/capture-exception'
import { calendarRoles } from '@services/roles/roles'
import { Calendar } from '@svg/icons'

const CalendarAvailabilities = () => {
  const { t: translateActions } = useTranslation('apiResources', { keyPrefix: 'actions' })
  const { t: translateCalendar } = useTranslation('calendar')
  const { t: bc } = useTranslation('breadcrumbs')
  const [openCreate, setOpenCreate] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [data, setData] = useState<CalendarAvailability>()

  const resourceParameters = { id: data?.id ?? '', path: 'calendar-availabilities/{uid}' }
  const { mutateAsync: deleteResourceEntry } = useDeleteEntry(resourceParameters)
  const { mutateAsync: editResourceEntry } = usePatchEntry(resourceParameters)
  // const days = [...Object.values<string>(translateCalendar('days', { returnObjects: true }))]

  const breadcrumbs = [
    { href: '/calendar', name: bc('calendar') },
    { href: '', name: bc('availabilities') }
  ]

  const queryClient = useQueryClient()

  const reload = () => {
    queryClient.refetchQueries({ queryKey: ['calendar-availabilities'] }).catch((err) => {
      captureException(err as Error)
    })
  }

  const reloadCalendar = () => {
    queryClient.refetchQueries({ queryKey: ['calendar-days'] }).catch((err) => {
      captureException(err as Error)
    })
  }

  const onEntryClick = (entry) => {
    setOpenEdit(true)
    setData(entry)
  }

  const onDeleteHandler = () => {
    deleteResourceEntry({}).then(() => {
      setOpenEdit(false)
      reload()
      reloadCalendar()
    }).catch((err) => {
      captureException(err as Error)
    })
  }

  const onCreateClick = () => {
    setOpenCreate(true)
  }

  const onCreate = () => {
    reload()
    setOpenCreate(false)
  }

  const definition = useApiResource('calendar-availabilities/{id}')

  const onEdit = () => {
    reloadCalendar()
    setOpenEdit(false)
  }

  const onDefaultClick = () => {
    editResourceEntry({ isDefault: true }).then(() => {
      setOpenEdit(false)
      reload()
      reloadCalendar()
    }).catch((err) => {
      captureException(err as Error)
    })
  }

  return (
    <Layout description={translateCalendar('availability.description')} title={translateCalendar('availability.title')}>
      <Guard acceptedRoles={calendarRoles}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <SectionTitle description={translateCalendar('availability.description')} icon={Calendar} title={translateCalendar('availability.title')} />

        <ApiResource name={'calendar-availabilities'} onClick={onEntryClick} withCreateOption={onCreateClick} />

        <Modal center open={openCreate} setOpen={setOpenCreate} size='large' title={translateCalendar('createAvailability')}>
          <CalendarAvailabilityCreate onCreate={onCreate} />
        </Modal>

        <>
          {data && (
            <Modal center open={openEdit} setOpen={setOpenEdit} size='large' title={translateCalendar('updateAvailability')}>
              <div className='flex flex-col gap-4 items-center'>

                {definition && <ApiResourceEdit definition={definition} id={data.id} onEdit={onEdit} />}

                <div className='flex flex-col items-center w-full gap-2 mb-4'>
                  <div className='flex gap-2'>
                    <ContextualButton onClick={onDefaultClick}>{translateActions('setDefault')}</ContextualButton>

                    <ContextualButton onClick={onDeleteHandler} style='warning'>{translateActions('delete')}</ContextualButton>
                  </div>
                </div>
              </div>
            </Modal>
          )}
        </>
      </Guard>
    </Layout>
  )
}

export default CalendarAvailabilities
