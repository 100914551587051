import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import Table from '@components/table'
import PlaylistVideoEntry from '@components/video/playlist-video/playlist-video-entry'
import { type PlaylistVideoListProps } from '@components/video/playlist-video-list/playlist-video-list-interfaces'
import { type DroppableType } from '@interfaces/api/droppable'
import { type PlaylistVideo } from '@interfaces/api/video'
import useItemChildrenQuery from '@services/api/resources/item-children'
import usePutEntry from '@services/api/resources/put-entry-query'
import { captureException } from '@services/exceptions/capture-exception'
import { useDraggableStore } from '@services/stores/draggable/draggable'

const PlaylistVideoList = ({ definition, onClick, playlistUid }: PlaylistVideoListProps) => {
  const { t: translateResource } = useTranslation('apiResources')
  const { setIsDraggable } = useDraggableStore()

  const fieldsToDisplay = definition.methods.list.getFieldsToDisplay().filter(entry => !entry.name.includes('category'))
  const headers = fieldsToDisplay.map(field => translateResource(`labels.${field.name}`))
  headers.push(translateResource('labels.actions'))

  // Search Params
  const [searchParams, setSearchParams] = useSearchParams({ page: '1' })
  const pageIndex = Number(searchParams.get('page') ?? '1')
  const setPageIndex = (index: number) => {
    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      page: `${index}`
    })
  }

  const {
    data: {
      data: playlistVideos,
      pagination,
      totalItems
    } = {},
    isLoading,
    refetch
  } = useItemChildrenQuery<PlaylistVideo>({
    itemId: playlistUid,
    path: 'playlists/{uid}/videos'
  })

  const { mutateAsync: changeOrder } = usePutEntry(`api/playlists/${playlistUid}/videos/change-order`)

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    const destination: DroppableType = result.destination
    const source: DroppableType = result.source

    // Prevent move on the same place
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return
    }

    if (playlistVideos) {
      const videos = [...playlistVideos]
      const video = videos?.find(video => video.uid === result.draggableId)
      if (video) {
        videos.splice(source.index, 1)
        videos.splice(destination.index, 0, video)
      }

      setIsDraggable(false)

      const newOrder = videos.map(video => video['@id'])
      changeOrder({ order: newOrder })
        .then(() => {
          refetch().catch(captureException)
        })
        .catch(captureException)
        .finally(() => {
          setIsDraggable(true)
        })
    }
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='droppable'>
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            <Table
              headers={headers}
              isLoading={isLoading}
              pageIndex={pageIndex}
              pagination={pagination}
              setPageIndex={setPageIndex}
              totalItems={totalItems}
            >

              {playlistVideos?.map((entry, index) => {
                return (
                  <PlaylistVideoEntry entry={entry} fieldsToDisplay={fieldsToDisplay} index={index} key={index} onClick={onClick} playlistUid={playlistUid} />
                )
              })
              }

              {!playlistVideos?.length && (
                <tr className='text-center'>
                  <td className='p-6' colSpan={headers.length}>
                    <div className='text-gray-900 text-sm'>{translateResource('empty.videos')}</div>

                    <div className='text-gray-500 text-xs'>{translateResource('empty.createvideos')}</div>
                  </td>
                </tr>
              )}

              {provided.placeholder}
            </Table>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default PlaylistVideoList
