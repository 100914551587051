import { XMarkIcon } from '@heroicons/react/24/outline'
import { IngredientCreate } from '@pages'
import { Form, Formik } from 'formik'
import { useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import ButtonLoader from '@components/buttons/button-loader/button-loader'
import FormTextField from '@components/form-fields/form-text-field'
import Modal from '@components/modals/modal'
import {
  type ModalAddIngredientEntryProps
} from '@components/modals/modal-add-ingredient-entry/modal-add-ingredient-entry-interfaces'
import { type Ingredient } from '@interfaces/api/ingredient'
import useApiResourceListFormatQuery from '@services/api/resources/list-format-query'
import { captureException } from '@services/exceptions/capture-exception'
import { CircleAdd, Search } from '@svg/icons'

const ModalAddIngredientEntry = ({ definition, errorMessage, mealName, onAdd, open, setOpen }: ModalAddIngredientEntryProps) => {
  const { t: translateResource } = useTranslation('apiResources')
  const { t: translateCategory } = useTranslation('ingredient', { keyPrefix: 'category' })

  const addLabel = `${translateResource('actions.add')} ${translateResource(`resourceSingular.${definition.name}`)}`
  const createLabel = translateResource('create.createIngredients')

  const [titleFilter, setTitleFilter] = useState<string>('')
  const [ingredientIri, setIngredientIri] = useState<string>('')
  const [openCreate, setOpenCreate] = useState<boolean>(false)
  const filterRef = useRef<HTMLInputElement>(null)

  const path = {
    name: `${definition.name}?pagination=false`,
    url: ''
  }

  const {
    data: {
      data: items
    } = {},
    refetch
  } = useApiResourceListFormatQuery<Ingredient>({ definition: path })

  // Filters

  const filteredItems = useMemo(() =>
    items?.filter(item => item.name.toLowerCase().includes(titleFilter.toLowerCase()))
  , [items, titleFilter])

  const handleChange = (e) => {
    setTitleFilter(e.target.value)
  }

  const handleResetFilter = () => {
    setTitleFilter('')
    if (filterRef?.current) {
      filterRef.current.value = ''
    }
  }

  // Change to create mode

  const handleModeCreate = () => {
    setOpenCreate(true)
  }

  const onReload = () => {
    refetch().catch(captureException)
  }

  const onCreateIngredient = () => {
    onReload()
    setOpenCreate(false)
  }

  const renderExercisesList = () => {
    if (filteredItems?.length) {
      return (
        <>
          {filteredItems.map((item) => (
            <button className={`py-4 px-6 grid grid-cols-2 gap-3 w-full rounded-lg ${ingredientIri === item['@id'] ? 'bg-gray-100' : 'bg-white hover:bg-gray-50'}`} key={item.uid} onClick={handleClick(item)}>
              <div className='text-gray-700 text-sm text-start'>
                {item.name}
              </div>

              <div className='text-end text-sm text-gray-500'>
                {translateCategory(item.category)}
              </div>
            </button>
          ))}
        </>
      )
    }

    return (
      <div className='text-center flex items-center justify-center h-full'>
        <div className='p-6'>
          <div className='text-gray-900 text-sm'>{translateResource(`empty.${definition.name}`)}</div>

          <div className='text-gray-500 text-xs'>{translateResource(`empty.create${definition.name}`)}</div>
        </div>
      </div>
    )
  }

  // Create new meal ingredient

  const handleClick = (entry: Ingredient) => () => {
    setIngredientIri(entry['@id'])
  }

  const initialValues = {
    quantity: ''
  }

  const onSubmit = (values, { setSubmitting }) => {
    if (ingredientIri) {
      setSubmitting(true)
      const ingredientPayload = { ...values, ingredient: ingredientIri }
      onAdd(ingredientPayload, mealName ?? '')
      setSubmitting(false)
    }
  }

  return (
    <Modal center open={open} setOpen={setOpen} size='medium' title={addLabel}>
      <div className='px-4 py-3 flex gap-4 items-start'>
        <button className='border-2 border-gray-100 px-4 py-6 rounded-lg flex flex-col justify-center items-center text-center min-w-[224px]' onClick={handleModeCreate}>
          <CircleAdd />

          <div className='font-medium mt-1.5'>{translateResource('create.createIngredients')}</div>

          <div className='text-gray-500 text-xs'>{translateResource('create.ingredientsDescription')}</div>
        </button>

        {/* Find Ingredient */}

        <div className='flex flex-col gap-3 w-full'>
          <div className='font-medium -mb-2 text-gray-700'>{translateResource('labels.findIngredient')}</div>

          <div className='flex items-center self-start shadow shadow shadow-gray-200 rounded-lg px-4 relative bg-whitew-full'>
            <Search className='w-6 h-6 fill-gray-500' />

            <input
              className='appearance-none block w-full pl-3 py-2 placeholder-gray-300 placeholder:text-md focus:outline-none sm:text-md'
              id='title'
              onChange={handleChange}
              placeholder={`${translateResource('labels.search')} ${translateResource('labels.name')}`}
              ref={filterRef}
              type='text'
            />

            <button className={`absolute right-4 rounded-full group hover:bg-red-800 ${!titleFilter.length ? 'hidden' : 'flex'}`} onClick={handleResetFilter} >
              <XMarkIcon className='w-6 h-6 text-red-800 group-hover:text-white font-bold' />
            </button>
          </div>

          <div className='p-0.5 border-2 border-gray-100 rounded-lg h-60 overflow-scroll scrollbar-hide'>
            {renderExercisesList()}
          </div>

          <div>
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
              {({ isSubmitting }) => {
                return (
                  <Form className='flex flex-col gap-4'>
                    <FormTextField label={translateResource('labels.quantity')} name='quantity' placeholder={translateResource('placeholder.quantity')} required />

                    <button
                      className={`flex self-end gap-2 items-center rounded-lg px-4 py-2 text-lg font-semibold tracking-wide text-white fill-white hover:bg-gray-600 hover:text-white ${!ingredientIri ? 'cursor-not-allowed bg-gray-600' : 'cursor-pointer  bg-primary'}`}
                      disabled={isSubmitting || !ingredientIri}
                      type='submit'
                    >
                      {isSubmitting
                        ? <ButtonLoader />
                        : (
                          <div className='flex items-center gap-2'>
                            <CircleAdd />

                            {addLabel}
                          </div>
                        )
                      }
                    </button>
                  </Form>
                )
              }}
            </Formik>
          </div>

          {errorMessage && <div className='text-red-700 flex font-medium mb-2 self-center text-lg border border-red-700 px-2 rounded-md'>{errorMessage}</div>}
        </div>
      </div>

      {openCreate && (
        <Modal center open={openCreate} setOpen={setOpenCreate} title={createLabel} >
          <IngredientCreate onCreate={onCreateIngredient} />
        </Modal>
      )}
    </Modal>
  )
}

export default ModalAddIngredientEntry
