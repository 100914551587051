import { ErrorMessage, Field, useField } from 'formik'

import { type FormToggleFieldProps } from '@components/form-fields/form-toggle-field/index'
import { Check } from '@svg/icons'

const FormToggleField = ({ label, name, required = false }: FormToggleFieldProps) => {
  const [input, ,] = useField({ name })
  const { value } = input

  return (
    <div className='flex items-center gap-3'>
      <div className='relative flex justify-center items-center'>
        <Field
          className='block cursor-pointer relative w-6 h-6 border-2 border-gray-100 rounded-md placeholder-gray-400 focus:outline-none appearance-none outline-none checked:bg-gray-900 checked:border-gray-900 checked:text-white'
          name={name}
          required={required}
          type='checkbox'
        />

        {value && <Check className='absolute pointer-events-none' />}
      </div>

      <label className='block font-medium text-gray-700' htmlFor={name}>{label}</label>

      <ErrorMessage className='mt-2 text-xs text-red-600 font-medium' component='div' name={name} />
    </div>
  )
}

export default FormToggleField
