import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import ContextualButton from '@components/buttons/contextual-button'
import ModalVideoPlayer from '@components/modals/modal-video-player'
import AddPlaylistVideo from '@components/video/add-playlist-video/add-playlist-video'
import EditPlaylist from '@components/video/edit-playlist/edit-playlist'
import { type PlaylistVideoProps } from '@components/video/playlist-video/playlist-video-interfaces'
import PlaylistVideoList from '@components/video/playlist-video-list/playlist-video-list'
import { type PlaylistVideo, type Video } from '@interfaces/api/video'
import useApiResource from '@services/api/definition'
import { CircleAdd, Edit } from '@svg/icons'

const PlaylistVideos = ({ playlistUid }: PlaylistVideoProps) => {
  const { t: translateVideo } = useTranslation('videos', { keyPrefix: 'playlist' })
  const [video, setVideo] = useState<Video>()
  const [isOpen, setIsOpen] = useState(false)
  const [createModal, setCreateModal] = useState(false)
  const [editModal, setEditModal] = useState(false)

  const definition = useApiResource('videos')
  const playlistDefinition = useApiResource('playlists/{uid}')

  const handleCreateClick = () => {
    setCreateModal(true)
  }

  const handleEditClick = () => {
    setEditModal(true)
  }

  const onEntryClick = (entry) => {
    const playlistVideo = entry as PlaylistVideo
    setIsOpen(true)
    setVideo(playlistVideo.video)
  }

  return (
    <div>
      <div className='px-4 sm:px-6 md:px-8'>
        <div className='flex justify-between mb-4'>
          <ContextualButton icon={Edit} onClick={handleEditClick}>
            {translateVideo('editPlaylist')}
          </ContextualButton>

          <ContextualButton icon={CircleAdd} onClick={handleCreateClick}>
            {translateVideo('addVideo')}
          </ContextualButton>
        </div>

        {definition && <PlaylistVideoList definition={definition} onClick={onEntryClick} playlistUid={playlistUid} />}

        {playlistDefinition && <EditPlaylist definition={playlistDefinition} open={editModal} playlistUid={playlistUid} setOpen={setEditModal} />}
      </div>

      {definition && <AddPlaylistVideo definition={definition} open={createModal} playlistUid={playlistUid} setOpen={setCreateModal} />}

      {video && (
        <ModalVideoPlayer
          open={isOpen}
          setOpen={setIsOpen}
          video={video}
        />
      )}

    </div>
  )
}

export default PlaylistVideos
