import {
  AnalysePeriods,
  AudioList,
  Calendar,
  CalendarAvailabilities,
  CalendarAvailabilityEdit,
  ChatRoom,
  Exercises,
  ForgotPassword,
  Home,
  Ingredients,
  Login,
  MealplanEntry,
  Mealplans,
  NewNotification,
  Purchases,
  RecipeEntry,
  Recipes,
  Theme,
  UserEdit,
  UserProgram,
  UserPrograms,
  Users
} from '@pages'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import React from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { useLocation } from 'react-router'
import { Navigate, Route, Routes } from 'react-router-dom'

import { Configuration } from '@pages/configuration'
import Playlists from '@pages/playlists'
import { ProgramEntry, Programs } from '@pages/programs'
import UserMealplan from '@pages/user-mealplan/user-mealplan'
import { Videos } from '@pages/videos'
import { useAuthToken } from '@services/hooks/auth-token'
import { useThemeStore } from '@services/stores/theme/theme'
import { getRGBColor } from '@services/tools/theme'

const DEFAULT_STALE_TIME = 5 * 60 * 1000

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: DEFAULT_STALE_TIME
    }
  }
})

const Protected = ({ children }: { children: JSX.Element }) => {
  const location = useLocation()
  const { value: authToken } = useAuthToken()

  if (!authToken) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they log in, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate replace state={{ from: location }} to='/login' />
  }

  return children
}

function App () {
  const { primary, secondary, secondaryText } = useThemeStore()
  const primaryColor = getRGBColor(primary, 'primary')
  const secondaryColor = getRGBColor(secondary, 'secondary')
  const secondaryTextColor = getRGBColor(secondaryText, 'secondary-text')

  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <Routes>
          <Route element={<Login />} path='/login' />

          <Route element={<ForgotPassword />} path='/forgot-password' />

          <Route element={<Protected><Home /></Protected>} path='/' />

          <Route element={<Protected><Programs /></Protected>} path='/programs' />

          <Route element={<Protected><ProgramEntry /></Protected>} path='/programs/:id' />

          <Route element={<Protected><Exercises /></Protected>} path='/exercises' />

          <Route element={<Protected><Mealplans /></Protected>} path='/mealplans' />

          <Route element={<Protected><MealplanEntry /></Protected>} path='/mealplans/:id' />

          <Route element={<Protected><Ingredients /></Protected>} path='/ingredients' />

          <Route element={<Protected><Recipes /></Protected>} path='/recipes' />

          <Route element={<Protected><RecipeEntry /></Protected>} path='/recipes/:id' />

          <Route element={<Protected><Videos /></Protected>} path='/videos' />

          <Route element={<Protected><Playlists /></Protected>} path='/playlists' />

          <Route element={<Protected><Users /></Protected>} path='/users' />

          <Route element={<Protected><UserEdit /></Protected>} path='/users/:id' />

          <Route element={<Protected><UserPrograms /></Protected>} path='/user-programs' />

          <Route element={<Protected><UserProgram /></Protected>} path='/users/:id/user-program/:uid' />

          <Route element={<Protected><UserMealplan /></Protected>} path='/users/:id/user-mealplan/:uid' />

          <Route element={<Protected><AnalysePeriods /></Protected>} path='/users/:id/analyse-period/:uid' />

          <Route element={<Protected><Videos /></Protected>} path='/video/upload' />

          <Route element={<Protected><AudioList /></Protected>} path='/audio' />

          <Route element={<Protected><ChatRoom /></Protected>} path='/chats/:uid' />

          <Route element={<Protected><Calendar /></Protected>} path='/calendar' />

          <Route element={<Protected><CalendarAvailabilities /></Protected>} path='/calendar-availabilities' />

          <Route element={<Protected><CalendarAvailabilityEdit /></Protected>} path='/calendar-availabilities/:id/edit' />

          <Route element={<Protected><Theme /></Protected>} path='/theme' />

          <Route element={<Protected><Configuration /></Protected>} path='/configuration' />

          <Route element={<Protected><NewNotification /></Protected>} path='/notifications/new' />

          <Route element={<Protected><Purchases /></Protected>} path='/purchases' />
        </Routes>

      </QueryClientProvider>

      <style>
        :root
        {' '}

        {`{${primaryColor}}`}
      </style>

      <style>
        :root
        {' '}

        {`{${secondaryColor}}`}
      </style>

      <style>
        :root
        {' '}

        {`{${secondaryTextColor}}`}
      </style>
    </HelmetProvider>
  )
}

export default App
