import { useQuery, type UseQueryResult } from '@tanstack/react-query'

import { type FormattedApiListResponse, type RequestParameters } from '@interfaces/api'
import { type PriorityChatRoom, type PriorityChatRoomsResponse } from '@interfaces/api/chat'
import useAxios from '@services/api/axios'
import { formatApiListResponse } from '@services/tools/api-resources/format-list-response'

const usePriorityChatRoomListQuery = (): UseQueryResult<FormattedApiListResponse<PriorityChatRoom>> => {
  const { get } = useAxios()

  return useQuery({
    queryFn: async () => {
      return await get<RequestParameters, PriorityChatRoomsResponse>('api/chat-rooms/priority')
        .then(result => formatApiListResponse<PriorityChatRoom>(result))
    },
    queryKey: ['priority-chat-rooms']
  })
}

export default usePriorityChatRoomListQuery
