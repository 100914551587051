import 'moment/locale/fr'
import { ChatBubbleOvalLeftIcon } from '@heroicons/react/24/outline'
import { useQueryClient } from '@tanstack/react-query'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { useNavigate } from 'react-router-dom'

import SectionTitle from '@components/section-title/section-title'
import { usePriorityChatRoomListQuery } from '@services/api/chat'
import { CHAT_MESSAGE_TYPE } from '@services/api/chat/message/use-create-chat-message-mutation'
import { captureException } from '@services/exceptions/capture-exception'

const ChatRoomList = () => {
  const { t } = useTranslation('chat')
  const { data: { data: chatRooms } = {}, isRefetching, refetch } = usePriorityChatRoomListQuery()
  const navigate = useNavigate()
  const onClick = (id: number) => () => {
    navigate(`/users/${id}`)
  }
  let interval: NodeJS.Timer | null = null
  const queryClient = useQueryClient()
  const location = useLocation()

  const fetchNewEventsInBg = () => {
    interval = setInterval(() => {
      !isRefetching && refetch().catch(captureException)
    }, 5000)
  }

  useEffect(() => {
    refetch().catch(captureException)
  }, [location])

  useEffect(() => {
    fetchNewEventsInBg()

    return () => {
      clearInterval(interval as NodeJS.Timer)
    }
  }, [])

  useEffect(() => {
    queryClient.refetchQueries({ queryKey: ['chat-messages'] }).catch(captureException)
  }, [chatRooms])

  const getFormattedDate = (givenDate) => {
    const hours = moment(givenDate).format('HH:mm')
    const yesterday = moment().subtract(1, 'days')
    const currentDate = givenDate ? moment(givenDate) : moment()
    const past7DaysStartDate = moment().subtract(7, 'days')
    const isInLast7days = currentDate.isAfter(past7DaysStartDate) && currentDate.isBefore(yesterday)
    if (!moment(currentDate).isBefore(moment(), 'day')) {
      return hours
    } else if (currentDate.isSame(yesterday, 'd')) {
      return `${t('room.yesterday')} ${hours}`
    } else if (isInLast7days) {
      return currentDate.format('dddd')
    } else {
      return currentDate.format('Do MMM YYYY')
    }
  }

  return (
    <div>
      <SectionTitle description={t('list.description')} icon={ChatBubbleOvalLeftIcon} title={t('list.title')} />

      <div className='pb-8 px-4 sm:px-6 md:px-8'>
        <div className='flex flex-wrap max-w-3xl justify-center bg-white rounded-lg shadow-sm max-h-[50vh] overflow-auto scrollbar-hide shadow shadow-gray-200 divide-y'>
          <div className='w-full sticky top-0 z-10 text-start font-medium p-4 bg-gray-50'>{t('chatrooms')}</div>

          {chatRooms?.map((chatRoom) => (
            <button className={`relative p-4 flex gap-6 w-full justify-between hover:bg-gray-50 ${chatRoom.isDisabled ? 'bg-slate-50' : 'bg-white'}`} key={chatRoom.uid} onClick={onClick(chatRoom.user.id)}>
              <div className='flex gap-4 items-center truncate'>
                <div className='text-white h-10 w-10 flex-none text-lg flex items-center justify-center bg-secondary rounded-full'>{chatRoom.user.firstName.charAt(0) ?? '-'}</div>

                <div className='flex flex-col truncate text-start'>
                  <div className='font-normal text-gray-700'>
                    {`${chatRoom.user.firstName ?? ''} ${chatRoom.user.lastName ?? ''}`}

                    {chatRoom.isDisabled && <span className='ml-2 text-gray-500 font-normal italic'>{t('room.disabled')}</span>}
                  </div>

                  <div className={`truncate ${chatRoom.lastMessage.isDeleted || chatRoom.lastMessage.type !== CHAT_MESSAGE_TYPE.TYPE_TEXT ? 'italic text-gray-400' : 'text-gray-500'}`}>
                    {chatRoom.lastMessage.isDeleted && t('room.messageDeleted')}

                    {chatRoom.lastMessage.type === CHAT_MESSAGE_TYPE.TYPE_TEXT && !chatRoom.lastMessage.isDeleted && chatRoom.lastMessage.text}

                    {chatRoom.lastMessage.type === CHAT_MESSAGE_TYPE.TYPE_ATTACHMENT && !chatRoom.lastMessage.isDeleted && t('room.image')}

                    {chatRoom.lastMessage.type === CHAT_MESSAGE_TYPE.TYPE_AUDIO && !chatRoom.lastMessage.isDeleted && t('room.audioMessage')}
                  </div>
                </div>
              </div>

              <div className='text-gray-500 min-w-max'>{getFormattedDate(chatRoom.lastMessageAt)}</div>

              {!chatRoom.lastMessage.isRead && !chatRoom.lastMessage.isFromAdmin && (
                <span className='flex absolute right-4 bottom-4'>
                  <span className='animate-ping absolute inline-flex h-full w-full rounded-full bg-red-500 opacity-75' />

                  <span className='relative inline-flex rounded-full px-2 text-sm text-white bg-red-400'>{chatRoom.unreadCount}</span>
                </span>
              )}
            </button>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ChatRoomList
