import moment from 'moment'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import ButtonLoader from '@components/buttons/button-loader/button-loader'
import ContextualButton from '@components/buttons/contextual-button'
import { type BookingSlotProps } from '@components/calendar/calendar-interfaces'
import Modal from '@components/modals/modal'
import { type BookingSlot as BookingSlotType } from '@interfaces/api/calendar'
import { Status } from '@interfaces/api/status'
import useApiResourceItemQuery from '@services/api/resources/item-query'
import usePutEntry from '@services/api/resources/put-entry-query'
import useUserProfilQuery from '@services/api/user/use-user-profile-query'
import { captureException } from '@services/exceptions/capture-exception'

const BookingSlot = ({ bookingSlot, onReload, setActive }: BookingSlotProps) => {
  const { t: translateCalendar } = useTranslation('calendar', { keyPrefix: 'booking' })
  const { mutateAsync: cancelEntry } = usePutEntry(`/api/calendar-bookings/${bookingSlot.uid}/cancel`)

  const [openModal, setOpenModal] = useState(false)
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const { data, isLoading } = useApiResourceItemQuery<BookingSlotType>({ id: bookingSlot.uid, path: 'calendar-bookings/{uid}' })
  const { data: userProfile } = useUserProfilQuery(data ? data?.user.id : null)

  const fullName = data?.user.firstName && data?.user.lastName ? `${data.user.firstName} ${data.user.lastName}` : '-'

  const handleClick = () => {
    setOpenModal(true)
  }

  const handleCancelClick = () => {
    setOpenModal(false)
    setOpenConfirmation(true)
  }

  const onCancelBookingHandler = async () => {
    try {
      await cancelEntry({})
    } catch (err) {
      captureException(err as Error)
    } finally {
      onReload()
      setOpenConfirmation(false)
      setActive(true)
    }
  }

  return (
    <>
      <div className='p-[1px]'>
        <button className='bg-gray-800 hover:bg-gray-500 text-white text-sm flex items-center h-full w-full rounded-md' disabled={isLoading} onClick={handleClick}>
          {isLoading
            ? <ButtonLoader />
            : (
              <div className='flex flex-col gap-1 px-2.5 py-2 truncate'>
                <div className='text-start font-bold truncate'>{fullName}</div>

                <div className='truncate'>{translateCalendar(bookingSlot.type)}</div>
              </div>
            )}
        </button>
      </div>

      <Modal center open={openModal} setOpen={setOpenModal} title={translateCalendar('updateBooking')}>
        <div className='flex flex-col gap-4 items-center p-8'>
          <div className='text-2xl font-bold text-center'>{`${translateCalendar('bookedAt')} ${moment(bookingSlot.createdAt).format('LL')}`}</div>

          <div className='text-lg font-bold'>
            {translateCalendar('user')}

            <Link className='ml-2 font-normal underline' to={`/users/${data?.user.id}`}>{fullName}</Link>
          </div>

          <div className='text-lg font-bold'>
            {translateCalendar('phone')}

            <a className='ml-2 font-normal underline' href={`tel:${userProfile?.profile.phoneNumber}`}>{userProfile?.profile.phoneNumber}</a>
          </div>

          <div className='text-lg font-bold'>
            {translateCalendar('duration')}

            <span className='ml-2 font-normal'>{`${bookingSlot.duration} ${translateCalendar('min')}`}</span>
          </div>

          <div className='text-lg font-bold'>
            {translateCalendar('status')}

            <span className={`px-2 py-1 rounded-md text-white font-bold ml-2 uppercase ${bookingSlot.status === Status.STATUS_BOOKED ? 'bg-green-500' : 'bg-red-500'}`}>{translateCalendar(bookingSlot.status)}</span>
          </div>

          <div className='text-lg font-bold'>
            {translateCalendar('type')}

            <span className='ml-2 font-normal'>{translateCalendar(bookingSlot.type)}</span>
          </div>

          {bookingSlot.status !== Status.STATUS_CANCELLED && (
            <div className='flex mt-4 gap-8'>
              <ContextualButton onClick={handleCancelClick} style='warning'>{translateCalendar('cancelBooking')}</ContextualButton>
            </div>
          )}

        </div>
      </Modal>

      <Modal center open={openConfirmation} setOpen={setOpenConfirmation} title={translateCalendar('cancelBooking')}>
        <div className='flex flex-col gap-4 items-center p-8'>
          <div className='text-2xl font-medium text-center'>{translateCalendar('cancelConfirmation')}</div>

          <div className='flex mt-4 gap-8'>
            <ContextualButton onClick={onCancelBookingHandler} style='warning'>{translateCalendar('cancelBooking')}</ContextualButton>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default BookingSlot
