import React from 'react'

import {
  type AudioTemplateButtonProps
} from '@components/audio/audio-gallery/audio-gallery-templates-interfaces'
import { type AudioTemplate } from '@interfaces/api/audio-templates'
import { useAudioTemplateStore } from '@services/stores/audio-template/audio-template'
import { Play } from '@svg/icons'

const AudioTemplateButton = ({ onSelect, template }: AudioTemplateButtonProps) => {
  const { setAudioTemplate } = useAudioTemplateStore()

  const handleAudioClick = (template: AudioTemplate) => () => {
    setAudioTemplate(template)
    onSelect()
  }

  return (
    <button className='w-max flex items-center group pr-3' key={template.uid} onClick={handleAudioClick(template)}>
      <div className='bg-gray-100 rounded-l-full border-2 border-gray-100 py-1 pl-[5px] pr-[3px]'>
        <Play className='w-4 h-4' />
      </div>

      <div className='border-2 border-gray-100 rounded-r-full group-hover:bg-gray-100 pl-1.5 pr-3 py-1 text-xs max-w-[250px] truncate'>{template.title}</div>
    </button>
  )
}

export default AudioTemplateButton
