export enum FormMode {
  creation = 'creation',
  edition = 'edition'
}

export interface DataStoreFormValues {
  data: string
  key: string
}

interface SubmittedFormValues {
  data: string[]
  key: string
}

export interface ConfigurationFormProps {
  initialValues: DataStoreFormValues
  isLoading?: boolean
  mode: FormMode
  onSubmit: (values: SubmittedFormValues) => void
}
