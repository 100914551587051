interface ColorsType {
  primary: string
  secondary: string
  secondaryText: string
}

interface ThemeConfigType {
  colors: ColorsType
  projectName: string
}

const getConfig = (): ThemeConfigType => {
  const themeFolder = process.env.REACT_APP_THEME ?? 'default'

  return require(`./${themeFolder}/config.json`)
}

export const themeConfig = getConfig()
export const { colors, projectName } = themeConfig
