import ApiResourceCreate from '@components/api-resource/api-resource-create'
import useApiResource from '@services/api/definition'

interface ExerciseCreateProps {
  onCreate: () => void
}

const ExerciseCreate = ({ onCreate }: ExerciseCreateProps) => {
  const definition = useApiResource('exercises')

  const onCreateResult = () => {
    onCreate()
  }

  return (
    <div>
      {definition && <ApiResourceCreate definition={definition} onEdit={onCreateResult} />}
    </div>
  )
}

export default ExerciseCreate
