import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import ApiResource from '@components/api-resource'
import ApiResourceCreate from '@components/api-resource/api-resource-create'
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import ContextualButton from '@components/buttons/contextual-button'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import Modal from '@components/modals/modal'
import ModalVideoPlayer from '@components/modals/modal-video-player'
import SectionTitle from '@components/section-title/section-title'
import BatchUploader from '@components/video/batch-uploader'
import EditVideo from '@components/video/edit-video'
import { type ApiPlatformEntity } from '@interfaces/api/api'
import { type Video, type VideoCategory } from '@interfaces/api/video'
import useApiResource from '@services/api/definition'
import useApiResourceListFormatQuery from '@services/api/resources/list-format-query'
import { captureException } from '@services/exceptions/capture-exception'
import { videosRoles } from '@services/roles/roles'
import { Camera, CircleAdd } from '@svg/icons'

const VideosList = () => {
  const { t } = useTranslation('videos')
  const { t: bc } = useTranslation('breadcrumbs')

  // Opens a preview when clicking on a video entry
  const [video, setVideo] = useState<Video>()
  const [isOpen, setIsOpen] = useState(false)
  const [editModal, setEditModal] = useState(false)
  const [createModal, setCreateModal] = useState(false)

  const breadcrumbs = [
    { href: '', name: bc('videos') }
  ]

  const onEntryClick = (entry: ApiPlatformEntity) => {
    setIsOpen(true)
    setVideo(entry as Video)
  }

  // Create and update video categories
  const categoriesDefinition = useApiResource('video-categories')
  const categoriesPath = {
    name: 'video-categories',
    url: ''
  }

  const {
    data: {
      data: categories
    } = {},
    refetch
  } = useApiResourceListFormatQuery<VideoCategory>({ definition: categoriesPath })

  const onEditClick = (entry: ApiPlatformEntity) => {
    setVideo(entry as Video)
    setEditModal(true)
  }

  const onEdit = () => {
    setEditModal(false)
  }

  const onCreate = () => {
    refetch().catch(captureException)
    setCreateModal(false)
  }

  const handleCreateCategory = () => {
    setCreateModal(true)
  }

  return (
    <Layout description={t('description')} title={t('title')}>
      <Guard acceptedRoles={videosRoles}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <SectionTitle description={t('description')} icon={Camera} title={t('title')} />

        <div className='m-8 flex gap-4'>
          <ContextualButton icon={CircleAdd} onClick={handleCreateCategory}>{t('createCategory')}</ContextualButton>
        </div>

        <BatchUploader />

        <ApiResource createdAt={'desc'} name={'videos'} onClick={onEntryClick} withDeleteOption withEditOption={onEditClick} />

        <Modal center open={createModal} setOpen={setCreateModal} title={t('createCategory')}>
          {categoriesDefinition && <ApiResourceCreate definition={categoriesDefinition} onEdit={onCreate} />}
        </Modal>

        <>
          {video && (
            <ModalVideoPlayer
              open={isOpen}
              setOpen={setIsOpen}
              video={video}
            />
          )}
        </>

        <>
          {video && (
            <Modal center open={editModal} setOpen={setEditModal} title={video.originalName}>
              {categories && <EditVideo categories={categories} onEdit={onEdit} video={video} />}
            </Modal>
          )}
        </>
      </Guard>
    </Layout>
  )
}

export default VideosList
