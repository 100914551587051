import { useMutation, type UseMutationOptions } from '@tanstack/react-query'

import useApi from '@services/api/axios'

const useDeleteAudioTemplatesMutation = (
  options: UseMutationOptions<undefined, unknown, string> = {}
) => {
  const { del } = useApi()

  return useMutation<undefined, unknown, string>({
    mutationFn: async (uid: string) => {
      await del<undefined>(`api/audio-templates/${uid}`)

      return undefined
    },
    ...options
  })
}

export default useDeleteAudioTemplatesMutation
