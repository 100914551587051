import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import ApiResourceCreate from '@components/api-resource/api-resource-create'
import ContextualButton from '@components/buttons/contextual-button'
import Modal from '@components/modals/modal'
import { type Resource } from '@services/api/definition/resource'
import { CircleAdd } from '@svg/icons'

interface AddUserAnalysePeriodProps {
  definition: Resource
  hasPeriodInProgress: boolean
  onSuccess: () => void
  userId: number
}

const AddUserAnalysePeriod = ({ definition, hasPeriodInProgress, onSuccess, userId }: AddUserAnalysePeriodProps) => {
  const { t: translateUserProgram } = useTranslation('user', { keyPrefix: 'analysePeriods' })
  const [openModal, setOpenModal] = useState(false)
  const [openErrorModal, setOpenErrorModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>()

  const handleClick = () => {
    if (hasPeriodInProgress) {
      setErrorMessage('Une période d\'analyse est déjà en cours pour cet utilisateur. Vous devez la clôturer avant d\'en réaffecter une nouvelle')
      setOpenErrorModal(true)
    } else {
      setOpenModal(true)
    }
  }

  const afterSubmit = () => {
    setOpenModal(false)
    onSuccess()
  }

  const beforeSubmit = (values) => {
    values.user = `/api/users/${userId}`

    return values
  }

  return (
    <div className='flex justify-end mt-2'>
      <ContextualButton icon={CircleAdd} onClick={handleClick}>
        {translateUserProgram('addAnalysePeriod')}
      </ContextualButton>

      <Modal center open={openErrorModal} setOpen={setOpenErrorModal} size='normal' title="Période d'analyse déjà en cours">
        <div className='py-4 px-2.5'>
          {errorMessage && <div className='text-red-700 flex font-medium px-2'>{errorMessage}</div>}
        </div>
      </Modal>

      <Modal center open={openModal} setOpen={setOpenModal} size='large' title={translateUserProgram('addAnalysePeriod')}>
        <div className='flex relative flex-col pt-8 pb-4 max-h-[80vh]'>
          <ApiResourceCreate beforeSubmit={beforeSubmit} definition={definition} onEdit={afterSubmit} />
        </div>
      </Modal>
    </div>
  )
}

export default AddUserAnalysePeriod
