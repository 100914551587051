import { useMutation, type UseMutationOptions, type UseMutationResult } from '@tanstack/react-query'

import { type ChatMessage, type ChatMessageCreateBody } from '@interfaces/api/chat'
import useApi from '@services/api/axios'

export enum CHAT_MESSAGE_TYPE {
  TYPE_ATTACHMENT = 'ATTACHMENT',
  TYPE_AUDIO = 'AUDIO',
  TYPE_TEXT = 'TEXT'
}

const useCreateChatMessageMutation = (
  options: UseMutationOptions<ChatMessage, unknown, ChatMessageCreateBody> = {}
): UseMutationResult<ChatMessage, unknown, ChatMessageCreateBody> => {
  const { post } = useApi()

  return useMutation<ChatMessage, unknown, ChatMessageCreateBody>({
    mutationFn: async (body: ChatMessageCreateBody) => await post<ChatMessageCreateBody, ChatMessage>('api/chat-messages', body),
    ...options
  })
}

export default useCreateChatMessageMutation
