import { useQuery, type UseQueryResult } from '@tanstack/react-query'

import { type ApiError, type ApiPlatformListResponse } from '@interfaces/api/api'
import { type UserRequestParameters } from '@interfaces/api/request'
import { type UserProgram, type UserProgramsResponse } from '@interfaces/api/user-program'
import useAxios from '@services/api/axios'
import { formatApiListResponse } from '@services/tools/api-resources/format-list-response'

const useUserProgramsQuery = ({ page, status, user }: UserRequestParameters): UseQueryResult<UserProgramsResponse> => {
  const { get } = useAxios()

  return useQuery<UserProgramsResponse, ApiError>({
    queryFn: async () => {
      return await get<UserRequestParameters, ApiPlatformListResponse<UserProgram>>('api/user-programs', {
        page,
        status,
        user
      }).then(result => formatApiListResponse<UserProgram>(result))
    },
    queryKey: ['user-programs', page, user, status],
    retry: false
  })
}

export default useUserProgramsQuery
