import { PlusIcon } from '@heroicons/react/24/outline'
import { type UseMutationResult, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import DeleteButton from '@components/buttons/delete-button'
import ModalAddIngredientEntry from '@components/modals/modal-add-ingredient-entry'
import {
  type UserMealplanMealEntryProps
} from '@components/user-mealplan-entry/user-mealplan-meal-entry/user-mealplan-meal-entry-interfaces'
import UserMealplanMealIngredient
  from '@components/user-mealplan-entry/user-mealplan-meal-ingredient/user-mealplan-meal-ingredient'
import { type ApiReponseError } from '@interfaces/api'
import { type IngredientListEntry } from '@interfaces/api/ingredient'
import { MealType } from '@interfaces/api/meal-type'
import { Status } from '@interfaces/api/status'
import { type UserMealplan } from '@interfaces/api/user-mealplan'
import useApiResource from '@services/api/definition'
import useCreateEntry from '@services/api/resources/create-entry-query'
import { captureException } from '@services/exceptions/capture-exception'
import { convertUserMealsToPayload, type MealPayload } from '@services/tools/convert-meals-to-payload'

const UserMealplanMealEntry = ({ dayInfo, label, meal }: UserMealplanMealEntryProps) => {
  const { uid = '' } = useParams()
  const { t: translateMealplan } = useTranslation('mealplan', { keyPrefix: 'dayInfo' })
  const { t: translateError } = useTranslation('errors')

  const { mutateAsync: editResourceEntry } = useCreateEntry({ path: 'user-mealplan-events/alter-ingredients' })
  const [open, setOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>()

  const ingredientsDefinition = useApiResource('ingredients')
  const queryClient = useQueryClient()
  const userMealplan = queryClient.getQueryData<UserMealplan>([`api/user-mealplans/${uid}`])
  const userMealplanIri = `/api/user-mealplans/${uid}`
  const dayIri = `/api/mealplan-days/${dayInfo.uid}`

  const reload = () => {
    queryClient.refetchQueries({ queryKey: [`api/user-mealplans/${uid}`] }).catch(captureException)
  }

  const onEditHandler = () => {
    setOpen(true)
  }

  const onIngredientAdd = async (ingredientPayload: IngredientListEntry, mealName: string) => {
    let mealIngredients: MealPayload[]

    if (meal) {
      const meals = meal.mealIngredients
      mealIngredients = convertUserMealsToPayload(meals)

      mealIngredients.push({ ingredient: ingredientPayload.ingredient, quantity: ingredientPayload.quantity })
    } else {
      mealIngredients = [ingredientPayload]
    }

    const payload = {
      day: dayIri,
      mealIngredients,
      mealName,
      mealType: MealType[label],
      userMealplan: userMealplanIri
    }

    try {
      await editResourceEntry(payload)
      reload()
      setOpen(false)
    } catch (e) {
      const err = e as UseMutationResult<ApiReponseError>
      const errorCode = err.data?.errorCode ?? 'generic'
      setErrorMessage(translateError(errorCode))
    }
  }

  const onDeleteAll = async () => {
    const payload = {
      day: dayIri,
      mealIngredients: [],
      mealType: MealType[label],
      userMealplan: userMealplanIri
    }

    try {
      await editResourceEntry(payload)
      reload()
    } catch (err) {
      captureException(err as Error)
    }
  }

  const onDeleteEntry = async (position) => {
    const meals = meal.mealIngredients
    const filteredMeals = meals.filter(meal => meal.position !== position)
    const mealIngredients = convertUserMealsToPayload(filteredMeals)

    const payload = {
      day: dayIri,
      mealIngredients,
      mealType: MealType[label],
      userMealplan: userMealplanIri
    }

    try {
      await editResourceEntry(payload)
      reload()
    } catch (err) {
      captureException(err as Error)
    }
  }

  return (
    <div className='bg-white rounded-lg p-1'>
      <div className='group flex justify-between px-2 py-1 text-gray-700 font-medium'>
        {translateMealplan(label)}

        {userMealplan?.status === Status.STATUS_DRAFT && (
          <div className='flex gap-2'>
            <div className='group-hover:visible invisible flex gap-1'>
              <DeleteButton onClick={onDeleteAll} />
            </div>
          </div>
        )}
      </div>

      <div className=''>
        {/* <UserMealName day={dayIri} editResourceEntry={editResourceEntry} label={label} meal={meal} onReload={reload} userMealplan={userMealplanIri} /> */}

        {meal
          ? (
            <div className='flex flex-col mb-1 border-2 pl-2 py-0.5 gap-0.5 border-gray-100 rounded-[10px]'>
              {meal.mealIngredients?.map((ingredient, index) => {
                return (
                  <UserMealplanMealIngredient
                    dayIri={dayIri}
                    editResourceEntry={editResourceEntry}
                    ingredient={ingredient}
                    key={index}
                    meal={meal}
                    onDeleteEntry={onDeleteEntry}
                    onReload={reload}
                    statusDraft={userMealplan?.status === Status.STATUS_DRAFT}
                    type={label}
                    userMealplanIri={userMealplanIri}
                  />
                )
              })}
            </div>
          )
          : (
            <div className='flex flex-col p-6 text-center'>
              <div className='text-gray-900 text-sm'>{translateMealplan('noMeal')}</div>

              {userMealplan?.status === Status.STATUS_DRAFT &&
              <div className='text-gray-500 text-xs'>{translateMealplan('addMeal')}</div>
              }
            </div>
          )
        }

        {userMealplan?.status === Status.STATUS_DRAFT && (
          <button className='w-full flex items-center justify-center bg-gray-100 rounded-lg p-1'
            onClick={onEditHandler}
          >
            <PlusIcon className='w-4 h-4' />
          </button>
        )}

        {ingredientsDefinition &&
          <ModalAddIngredientEntry definition={ingredientsDefinition} errorMessage={errorMessage} mealName={meal?.name} onAdd={onIngredientAdd} open={open} setOpen={setOpen} />
        }
      </div>
    </div>
  )
}

export default UserMealplanMealEntry
