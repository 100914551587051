import analysePeriods from '@services/translations/fr/analyse-periods.json'
import apiResources from '@services/translations/fr/api-resources.json'
import audio from '@services/translations/fr/audio.json'
import breadcrumbs from '@services/translations/fr/breadcrumbs.json'
import calendar from '@services/translations/fr/calendar.json'
import chat from '@services/translations/fr/chat.json'
import configuration from '@services/translations/fr/configuration.json'
import errors from '@services/translations/fr/errors.json'
import exercise from '@services/translations/fr/exercise.json'
import general from '@services/translations/fr/general.json'
import ingredient from '@services/translations/fr/ingredient.json'
import mealplan from '@services/translations/fr/mealplan.json'
import notification from '@services/translations/fr/notification.json'
import program from '@services/translations/fr/program.json'
import purchase from '@services/translations/fr/purchase.json'
import recipe from '@services/translations/fr/recipe.json'
import status from '@services/translations/fr/status.json'
import theme from '@services/translations/fr/theme.json'
import user from '@services/translations/fr/user.json'
import videos from '@services/translations/fr/videos.json'

const translation = {
  analysePeriods,
  apiResources,
  audio,
  breadcrumbs,
  calendar,
  chat,
  configuration,
  errors,
  exercise,
  general,
  ingredient,
  mealplan,
  notification,
  program,
  purchase,
  recipe,
  status,
  theme,
  user,
  videos
}

export default translation
