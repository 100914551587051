const getHoursList = (start, end, interval) => {
  const times: string[] = []
  let startTime = start * 60
  const endTime = end * 60

  for (let i = 0; startTime < endTime; i++) {
    const hours = Math.floor(startTime / 60)
    const minutes = startTime % 60
    times[i] = ('0' + (hours % 24).toString()).slice(-2) + ':' + ('0' + minutes.toString()).slice(-2)
    startTime = startTime + Number(interval)
  }

  return times
}

export { getHoursList }
