import React, { useState } from 'react'

import { type ResourceHeadProps } from '@components/api-resource/resource-head/resource-head-interfaces'
import ImageEmptyState from '@components/image-empty-state/image-empty-state'
import ModalVideoPlayer from '@components/modals/modal-video-player'
import StatusTag from '@components/status-tag/status-tag'
import { Play } from '@svg/icons'

const ResourceHead = ({ children, description, image, status, title, video }: ResourceHeadProps) => {
  const [videoIsOpen, setVideoIsOpen] = useState(false)

  const handleVideoClick = () => {
    setVideoIsOpen(true)
  }

  return (
    <div className='flex gap-4'>
      {video
        ? (
          <button className='w-40 h-24 rounded-lg overflow-hidden relative flex justify-center items-center' onClick={handleVideoClick}>
            <img alt='' className='w-full h-full object-cover bg-gray-900' src={video?.image?.url} />

            <Play className='w-12 h-12 fill-white absolute' />
          </button>
        )
        : image
          ? <img alt='' className='w-40 h-24 object-cover rounded-lg' src={image?.url} />

          : <ImageEmptyState />
      }

      <>
        {video && (
          <ModalVideoPlayer
            open={videoIsOpen}
            setOpen={setVideoIsOpen}
            video={video}
          />
        )}
      </>

      <div className='flex flex-col gap-2'>
        <span className='text-4xl text-gray-900 font-bold'>{title}</span>

        <div className='flex flex-col-reverse items-start lg:flex-row lg:items-center gap-1 lg:gap-4'>
          {status && <StatusTag status={status} />}

          {description && <div className='text-gray-500 max-w-md truncate'>{description}</div>}
        </div>

        {children && (
          <div>
            {children}
          </div>
        )}

      </div>
    </div>
  )
}

export default ResourceHead
