import {
  type DefaultError,
  type InfiniteData,
  type QueryKey,
  useInfiniteQuery
} from '@tanstack/react-query'

import {
  type FormattedApiListResponse,
  type RoomRequestParameters
} from '@interfaces/api'
import {
  type ChatEvent,
  type ChatEventsResponse
} from '@interfaces/api/chat'
import useAxios from '@services/api/axios'
import { formatApiListResponse } from '@services/tools/api-resources/format-list-response'

export enum CHAT_EVENT_TYPE {
  MESSAGE_DELETE = 'MESSAGE_DELETE',
  NEW_MESSAGE = 'NEW_MESSAGE'
}

const useChatEventListQuery = ({ room }: RoomRequestParameters) => {
  const { get } = useAxios()
  const url = `/api/chat-events?room=${room}`

  return useInfiniteQuery<FormattedApiListResponse<ChatEvent>, DefaultError, InfiniteData<FormattedApiListResponse<ChatEvent>, string>, QueryKey, string>({
    getNextPageParam: (lastPageObj) => lastPageObj?.pagination?.['hydra:previous'] ?? url,
    initialPageParam: '',
    queryFn: async ({ pageParam = url }) => {
      return await get<RoomRequestParameters, ChatEventsResponse>(pageParam).then(result => formatApiListResponse<ChatEvent>(result))
    },
    queryKey: ['chat-events', room],
    retry: false
  })
}

export default useChatEventListQuery
