import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Tabs from '@components/tabs/tabs'
import CoachProfile from '@components/user/coach-profile/coach-profile'
import Profile from '@components/user/profile'
import UserAnalysePeriods from '@components/user/user-analyse-periods/user-analyse-periods'
import UserMealplans from '@components/user/user-mealplans/user-mealplans'
import UserProgram from '@components/user/user-programs/user-programs'
import UserPurchases from '@components/user/user-purchases/user-purchases'
import { type UserProfileProps } from '@pages/user-profile/user-profile-interfaces'

const UserProfile = ({ user }: UserProfileProps) => {
  const { t: translateTabs } = useTranslation('user', { keyPrefix: 'tabs' })

  const [currentTab, setCurrentTab] = useState(1)

  const tabs = [
    { id: 1, name: translateTabs('programs') },
    { id: 2, name: translateTabs('mealplans') },
    { id: 3, name: translateTabs('analysePeriods') },
    { id: 4, name: translateTabs('purchases') }
  ]

  return (
    <div className='flex flex-col mb-6'>
      <div className='flex'>
        <Profile {...user} />

        {!user.isCoach &&
          <CoachProfile coach={user.coach} userId={user.id} />
        }
      </div>

      <Tabs currentTab={currentTab} setCurrentTab={setCurrentTab} tabs={tabs} />

      {currentTab === 1 && (
        <UserProgram userId={user.id} />
      )}

      {currentTab === 2 && (
        <UserMealplans userId={user.id} />
      )}

      {currentTab === 3 && (
        <UserAnalysePeriods userId={user.id} />
      )}

      {currentTab === 4 && (
        <UserPurchases userId={user.id} />
      )}
    </div>
  )
}

export default UserProfile
