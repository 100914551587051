import {
  useMutation
} from '@tanstack/react-query'

import { type ResourceItemParameters } from '@interfaces/api/definition/resource'
import useAxios from '@services/api/axios'

const useDeleteEntrySimple = () => {
  const { del } = useAxios()

  return useMutation({
    mutationFn: async ({ id, path }: ResourceItemParameters) => {
      const url = `api/${path.replace('{uid}', id)}`

      await del(url).then((body) => body)
    }
  })
}

export default useDeleteEntrySimple
