import {
  useMutation,
  type UseMutationResult
} from '@tanstack/react-query'

import { type ResourceItemParameters } from '@interfaces/api/definition/resource'
import useAxios from '@services/api/axios'

const usePatchEntry = ({ id, path }: ResourceItemParameters): UseMutationResult => {
  const { patch } = useAxios()
  let url = `api/${path.replace('{uid}', id)}`

  if (path.includes('{id}')) {
    url = `api/${path.replace('{id}', id)}`
  }

  return useMutation({
    mutationFn: async (data) => await patch<never, never>(url, data as never)
  })
}

export default usePatchEntry
