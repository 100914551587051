import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import Table from '@components/table'
import UserAnalysePeriodsTableEntry from '@components/table/table-data/user-analyse-periods-entry'
import AddUserAnalysePeriod from '@components/user/user-analyse-period/add-user-analyse-period'
import { Status } from '@interfaces/api/status'
import { type UserIdProps } from '@pages/user-profile/user-profile-interfaces'
import useApiResource from '@services/api/definition'
import useUserAnalysePeriods from '@services/api/user/use-user-analyse-periods-query'
import { captureException } from '@services/exceptions/capture-exception'

const UserAnalysePeriods = ({ userId }: UserIdProps) => {
  const { t: translateAnalysePeriods } = useTranslation('user', { keyPrefix: 'analysePeriods' })
  const [searchParams, setSearchParams] = useSearchParams({ page: '1' })
  const pageIndex = Number(searchParams.get('page') ?? '1')
  const definition = useApiResource('analyse-periods')

  const onAddAnalysePeriod = () => {
    refetch().catch(captureException)
  }
  const setPageIndex = (index: number) => {
    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      page: `${index}`
    })
  }

  const {
    data: {
      data: userAnalysePeriods,
      pagination,
      totalItems
    } = {},
    isFetched,
    isLoading,
    refetch
  } = useUserAnalysePeriods({ page: pageIndex, user: userId })

  const hasPeriodInProgress = userAnalysePeriods?.some(uP => uP.status !== Status.STATUS_FINISHED)

  return (
    <div className='mt-6'>
      <Table
        headers={[translateAnalysePeriods('create'), translateAnalysePeriods('start'), translateAnalysePeriods('progress'), translateAnalysePeriods('status')]}
        isLoading={isLoading}
        pageIndex={pageIndex}
        pagination={pagination}
        setPageIndex={setPageIndex}
        totalItems={totalItems}
      >
        {
          userAnalysePeriods?.map((analysePeriod, index) => (
            <UserAnalysePeriodsTableEntry key={index} {...analysePeriod} />
          ))
        }

        {!userAnalysePeriods?.length && (
          <tr className='text-center'>
            <td className='p-6' colSpan={3}>
              <div className='text-gray-900 text-sm'>{translateAnalysePeriods('noAnalysePeriod')}</div>
            </td>
          </tr>
        )}
      </Table>

      {!isLoading && isFetched && definition && userId && <AddUserAnalysePeriod definition={definition} hasPeriodInProgress={hasPeriodInProgress ?? false} onSuccess={onAddAnalysePeriod} userId={userId} />}

    </div>
  )
}

export default UserAnalysePeriods
