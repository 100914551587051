import { useTranslation } from 'react-i18next'

import { type AnalyseMoreDataProps } from '@components/analyse-day/analyse-more-data/analyse-more-data-interfaces'

const AnalyseMoreData = ({ moreData }: AnalyseMoreDataProps) => {
  const { t } = useTranslation('analysePeriods', { keyPrefix: 'day' })

  return (
    <div className='flex flex-col items-start gap-1'>
      <div className='text-lg font-normal text-gray-700'>{t('strengthTests')}</div>

      <div className='grid grid-cols-1 lg:grid-cols-2 gap-x-2 gap-y-1'>
        <div className='bg-gray-100 p-1 rounded-full flex justify-between items-center text-sm gap-2'>
          <div className='text-gray-500 pl-2'>{t('plankMax')}</div>

          <div className='rounded-full bg-white px-2 py-1'>{moreData.plankMax ?? '-'}</div>
        </div>

        <div className='bg-gray-100 p-1 rounded-full flex justify-between items-center text-sm gap-2'>
          <div className='text-gray-500 pl-2'>{t('pushUpMax')}</div>

          <div className='rounded-full bg-white px-2 py-1'>{moreData.pushUpMax ?? '-'}</div>
        </div>

        <div className='bg-gray-100 p-1 rounded-full flex justify-between items-center text-sm gap-2'>
          <div className='text-gray-500 p-1 pl-2'>{t('chairMax')}</div>

          <div className='rounded-full bg-white px-2 py-1'>{moreData.chairMax ?? '-'}</div>
        </div>

        <div className='bg-gray-100 p-1 rounded-full flex justify-between items-center text-sm gap-2'>
          <div className='text-gray-500 p-1 pl-2'>{t('pullUpMax')}</div>

          <div className='rounded-full bg-white px-2 py-1'>{moreData.pullUpMax ?? '-'}</div>
        </div>

        <div className='bg-gray-100 p-1 rounded-full flex justify-between items-center text-sm gap-2'>
          <div className='text-gray-500 p-1 pl-2'>{t('benchPressMax')}</div>

          <div className='rounded-full bg-white px-2 py-1'>{moreData.benchPressMax ?? '-'}</div>
        </div>
      </div>
    </div>
  )
}

export default AnalyseMoreData
