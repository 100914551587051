export enum Status {
  STATUS_AWAITING_COACH = 'AWAITING_COACH',
  STATUS_BOOKED = 'BOOKED',
  STATUS_CANCELLED = 'CANCELLED',
  STATUS_DRAFT = 'DRAFT',
  STATUS_FINISHED = 'FINISHED',
  STATUS_ONGOING = 'ONGOING',
  STATUS_PUBLISHED = 'PUBLISHED',
  STATUS_SUBMITTED = 'SUBMITTED',
  STATUS_UNPUBLISHED = 'UNPUBLISHED'
}
