import { type CloneButtonProps } from '@components/buttons/clone-button/index'
import IconButton from '@components/buttons/icon-button'
import { Copy } from '@svg/icons'

const CloneButton = ({ onClick }: CloneButtonProps) => {
  return (
    <IconButton icon={Copy} onClick={onClick} />
  )
}

export default CloneButton
