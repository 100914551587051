import { useMutation, type UseMutationOptions, type UseMutationResult } from '@tanstack/react-query'

import { type ChatAttachment } from '@interfaces/api/chat'
import useApi from '@services/api/axios'

const useCreateChatAttachmentMutation = (
  options: UseMutationOptions<ChatAttachment, unknown, FormData> = {}
): UseMutationResult<ChatAttachment, unknown, FormData> => {
  const { postFile } = useApi()

  return useMutation<ChatAttachment, unknown, FormData>({
    mutationFn: async (body: FormData) => await postFile<FormData, ChatAttachment>('api/chat-attachments', body),
    ...options
  })
}

export default useCreateChatAttachmentMutation
