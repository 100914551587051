import ModuleList from '@components/program/module-list'
import ProgramHead from '@components/program/program-head'
import { type ProgramProps } from '@pages/program/index'

const Program = ({ data }: ProgramProps) => {
  const { uid } = data

  return (
    <>
      <ProgramHead program={data} />

      {/* Module list */}

      <div className='mt-12 mb-8'>
        <ModuleList programId={uid} />
      </div>
    </>
  )
}

export default Program
