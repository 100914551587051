import { PlusIcon } from '@heroicons/react/24/outline'
import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'

import ButtonLoader from '@components/buttons/button-loader/button-loader'
import CourseEntry from '@components/program/course-entry'
import { type CourseListProps } from '@components/program/course-list/index'
import { type Course, type CourseEntry as CourseEntryType } from '@interfaces/api/course'
import { type DroppableType } from '@interfaces/api/droppable'
import SkeletonLoader from '@root/components/skeleton-loader/skeleton-loader'
import useAxios from '@services/api/axios'
import useApiResource from '@services/api/definition'
import { convertExercisesToPayload } from '@services/api/exercise/list-tools'
import useCreateEntry from '@services/api/resources/create-entry-query'
import useItemChildrenQuery from '@services/api/resources/item-children'
import { captureException } from '@services/exceptions/capture-exception'
import { useDraggableStore } from '@services/stores/draggable/draggable'
import { getInitialValues } from '@services/tools/api-resources/formik'
import { getIdList } from '@services/tools/api-resources/get-id-list'
import { getReorderList } from '@services/tools/drag-reorder'

const CourseList = ({ moduleId }: CourseListProps) => {
  const { patch, put } = useAxios()
  const { t: translateProgram } = useTranslation('program')
  const { isDraggable, setIsDraggable } = useDraggableStore()
  const queryClient = useQueryClient()
  const [isCreatingDay, setIsCreatingDay] = useState(false)
  const {
    data: {
      data: courses
    } = {},
    isFetching,
    refetch
  } = useItemChildrenQuery<CourseEntryType>({
    itemId: moduleId,
    path: 'program-modules/{uid}/courses'
  })

  const { mutateAsync: createResourceEntry } = useCreateEntry({ path: 'program-courses' })

  const courseIds = courses ? getIdList(courses) : []

  const reload = () => {
    refetch().catch((err) => {
      captureException(err as Error)
    })
  }

  const definition = useApiResource('program-courses')
  const requestFields = definition?.methods.post.getRequestFields() ?? []
  const initialValues = getInitialValues(requestFields)

  const onCourseAdd = () => {
    setIsCreatingDay(true)
    const values = initialValues
    values.title = `${translateProgram('course')}_${courseIds?.length + 1}_${moduleId}`
    values.module = `/api/program-modules/${moduleId}`
    values.image = null
    values.video = null

    createResourceEntry(values).then(() => {
      setIsCreatingDay(false)
      reload()
    }).catch(captureException)
  }

  const onEntryUpdate = () => {
    reload()
  }

  const updateQueryCache = (url, updatedData) => {
    queryClient.setQueryData<Course>([url], (data) => {
      if (data) {
        const newData = { ...data }
        newData.courseExercises = updatedData

        return newData
      }
    })
  }

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return
    }

    const destination: DroppableType = result.destination
    const source: DroppableType = result.source

    // Prevent move on the same place
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return
    }

    setIsDraggable(false)

    if (result.type === 'column') {
      // reorder courses
      const newCoursesReorder = getReorderList(result.source.index, result.destination.index, courses ?? [])
      const newCoursesList = getIdList(newCoursesReorder)
      const url = `api/program-modules/${moduleId}/courses/change-order`
      await put(url, { order: newCoursesList }).catch(captureException).finally(() => {
        setIsDraggable(true)
      })
    } else {
      const sourceCache = queryClient.getQueryData<Course>([`api/program-courses/${source.droppableId}`])
      const destinationCache = queryClient.getQueryData<Course>([`api/program-courses/${destination.droppableId}`])

      if (destination.droppableId === source.droppableId) {
        // Reorder exercises in the current course
        const newExercisesReorder = getReorderList(result.source.index, result.destination.index, sourceCache?.courseExercises ?? [])
        const newExercisesList = newExercisesReorder.map(exercise => convertExercisesToPayload(exercise))
        const url = `api/program-courses/${source.droppableId}/exercises`
        updateQueryCache(`api/program-courses/${source.droppableId}`, newExercisesReorder)

        await patch(url, { courseExercises: newExercisesList }).catch((err) => {
          captureException(err as Error)
          // Reset cache on error
          updateQueryCache(`api/program-courses/${source.droppableId}`, sourceCache?.courseExercises ?? [])
        }).finally(() => {
          setIsDraggable(true)
        })
      } else {
        // Reorder exercises between courses
        const destinationUrl = `api/program-courses/${destination.droppableId}/exercises`
        const sourceUrl = `api/program-courses/${source.droppableId}/exercises`
        const reorderedExercise = sourceCache?.courseExercises.find(item => item.uid === result.draggableId)

        if (reorderedExercise) {
          // Update source exercises list
          const sourceCacheExercises = sourceCache?.courseExercises ?? []
          const sourceList = [...sourceCacheExercises]
          sourceList.splice(result.source.index, 1)
          const newSourceList = sourceList.map(exercise => convertExercisesToPayload(exercise))
          updateQueryCache(`api/program-courses/${source.droppableId}`, sourceList)

          // Update destination exercises list
          const destinationCacheExercises = destinationCache?.courseExercises ?? []
          const destinationList = [...destinationCacheExercises]
          destinationList.splice(result.destination.index, 0, reorderedExercise)
          const newDestinationList = destinationList.map(exercise => convertExercisesToPayload(exercise))
          updateQueryCache(`api/program-courses/${destination.droppableId}`, destinationList)

          await patch(sourceUrl, { courseExercises: newSourceList }).catch((err) => {
            captureException(err as Error)
            // Reset cache on error
            updateQueryCache(`api/program-courses/${source.droppableId}`, sourceCache?.courseExercises ?? [])
          }).finally(() => {
            setIsDraggable(true)
          })

          await patch(destinationUrl, { courseExercises: newDestinationList }).catch((err) => {
            captureException(err as Error)
            // Reset cache on error
            updateQueryCache(`api/program-courses/${destination.droppableId}`, destinationCache?.courseExercises ?? [])
          }).finally(() => {
            setIsDraggable(true)
          })
        }
      }
    }
  }

  return (
    <>
      {isFetching
        ? (
          <SkeletonLoader type='card' />
        )
        : (
          <>
            {!!courses?.length && !isFetching
              ? (
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable direction='horizontal' droppableId={moduleId} type='column'>
                    {(provided) => (
                      <div
                        {...provided.droppableProps}
                        className='flex overflow-x-scroll scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 scrollbar-rounded scrollbar-height gap-1 px-2 py-10 items-start'
                        ref={provided.innerRef}
                      >
                        {courses.map((course, index) => (
                          <Draggable draggableId={course.uid} index={index} isDragDisabled={!isDraggable} key={course.uid}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={`${snapshot.isDragging ? 'drop-shadow-md' : 'shadow-none'}`}
                              >
                                <CourseEntry
                                  courseEntry={course}
                                  idList={courseIds}
                                  key={course.uid}
                                  onClone={onEntryUpdate}
                                  onDelete={onEntryUpdate}
                                  onReorder={onEntryUpdate}
                                  onSetOptionnal={onEntryUpdate}
                                  position={index + 1}
                                />
                              </div>
                            )}
                          </Draggable>
                        ))}

                        {provided.placeholder}

                        {courses.length < 7 && (
                          <button className='flex flex-col flex-none justify-start items-center border border-gray-200 rounded-lg p-1 w-[250px]' onClick={onCourseAdd}>
                            <div className='text-gray-900 text-sm px-5 py-[45px] text-center'>{translateProgram('addCourse')}</div>

                            {isCreatingDay
                              ? <ButtonLoader dark />
                              : (
                                <div className='w-full flex items-center justify-center bg-white rounded-lg p-1'>
                                  <PlusIcon className='w-4 h-4' />
                                </div>
                              )
                            }
                          </button>
                        )}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              )
              : (
                <div className='flex gap-1 items-start'>
                  <div className='flex flex-col justify-start border border-gray-200 rounded-lg p-1 w-[250px]'>
                    <div className='text-gray-900 text-sm px-5 py-[45px] text-center'>{translateProgram('addCourse')}</div>

                    <button className='w-full flex items-center justify-center bg-gray-200 rounded-lg p-1' onClick={onCourseAdd}>
                      <PlusIcon className='w-4 h-4' />
                    </button>
                  </div>
                </div>
              )}
          </>
        )
      }
    </>
  )
}

export default CourseList
