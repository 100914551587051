import React, { useRef } from 'react'
import { useAudioPlayer, useAudioPosition } from 'react-use-audio-player'

import { type AudioPlayerProps } from '@components/chat-message/chat-message-interfaces'
import { Pause, Play } from '@svg/icons'

const AudioPlayer = ({ file }: AudioPlayerProps) => {
  const ref = useRef<HTMLInputElement>(null)
  const { playing, togglePlayPause } = useAudioPlayer({
    autoplay: false,
    format: 'mp3',
    src: file
  })

  const { duration, percentComplete, position, seek } = useAudioPosition({ highRefreshRate: true })

  const goToPosition = React.useCallback((percentage) => {
    seek(duration * percentage)
  }, [duration, seek])

  const handleChange = () => {
    if (ref?.current) {
      goToPosition(Number(ref?.current?.value) / 100)
    }
  }

  const formatTime = (time) => {
    if (time && !isNaN(time)) {
      const minutes = Math.floor(time / 60)
      const formatMinutes =
        minutes < 10 ? `0${minutes}` : `${minutes}`
      const seconds = Math.floor(time % 60)
      const formatSeconds =
        seconds < 10 ? `0${seconds}` : `${seconds}`

      return `${formatMinutes}:${formatSeconds}`
    }

    return '00:00'
  }

  return (
    <div className='flex flex-col gap-4 items-center justify-center'>
      <button className='p-3 bg-primary rounded-full' onClick={togglePlayPause}>
        {playing
          ? <Pause className='w-8 h-8 fill-white' />

          : <Play className='w-8 h-8 fill-white' />
        }
      </button>

      <div className='flex flex-col'>
        <div className='flex justify-between text-xs text-gray-500'>
          <span>{formatTime(position)}</span>

          <span>{formatTime(duration)}</span>
        </div>

        <input className='bg-gray-200 overflow-hidden appearance-none rounded-lg max-w-[90px]' onChange={handleChange} ref={ref} type='range' value={percentComplete} />
      </div>
    </div>
  )
}

export default AudioPlayer
