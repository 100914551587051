import { VideoCameraSlashIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { ExerciseCreate } from '@pages'
import { Form, Formik } from 'formik'
import { useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import ButtonLoader from '@components/buttons/button-loader/button-loader'
import FormNumberField from '@components/form-fields/form-number-field'
import FormToggleField from '@components/form-fields/form-toggle-field'
import Modal from '@components/modals/modal'
import { type ModalAddEntryProps } from '@components/modals/modal-add-entry/index'
import { CourseExerciseType, type Exercise } from '@interfaces/api/exercise'
import useApiResourceListFormatQuery from '@services/api/resources/list-format-query'
import { captureException } from '@services/exceptions/capture-exception'
import { CircleAdd, Search } from '@svg/icons'

const ModalAddEntry = ({ definition, errorMessage, onAdd, open, setOpen }: ModalAddEntryProps) => {
  const [titleFilter, setTitleFilter] = useState<string>('')
  const [exerciseIri, setExerciseIri] = useState<string>('')
  const [mode, setMode] = useState<'create' | 'normal'>('normal')
  const [type, setType] = useState<CourseExerciseType>(CourseExerciseType.TYPE_REPS)
  const filterRef = useRef<HTMLInputElement>(null)

  const { t: translateResource } = useTranslation('apiResources')
  const { t: translateExercise } = useTranslation('exercise', { keyPrefix: 'entry' })
  const addLabel = `${translateResource('actions.add')} ${translateResource(`resourceSingular.${definition.name}`)}`
  const createLabel = translateResource('create.exercises')

  const path = {
    name: `${definition.name}?pagination=false`,
    url: ''
  }

  const {
    data: {
      data: items
    } = {},
    refetch
  } = useApiResourceListFormatQuery<Exercise>({ definition: path })

  // Filters

  const filteredItems = useMemo(() =>
    items?.filter(item => item.title.toLowerCase().includes(titleFilter.toLowerCase()))
  , [items, titleFilter])

  const handleChange = (e) => {
    setTitleFilter(e.target.value)
  }

  const handleResetFilter = () => {
    setTitleFilter('')
    if (filterRef?.current) {
      filterRef.current.value = ''
    }
  }

  // Change mode

  const handleModeCreate = () => {
    setMode('create')
  }

  const handleModeNormal = () => {
    setMode('normal')
  }

  const onReload = () => {
    refetch().catch(captureException)
  }

  const onCreateExercise = () => {
    onReload()
    setMode('normal')
  }

  const renderExercisesList = () => {
    if (filteredItems?.length) {
      return (
        <>
          {filteredItems.map((item) => (
            <button className={`p-1.5 flex items-center gap-3 w-full rounded-lg ${exerciseIri === item['@id'] ? 'bg-gray-100' : 'bg-white hover:bg-gray-50'}`} key={item.uid} onClick={handleClick(item)}>
              <div className='flex items-center justify-center bg-gray-200 w-[52px] h-[52px] rounded-lg flex-shrink-0 overflow-hidden'>
                {item.image?.url ? <img alt='' className='w-full h-full object-cover' src={item.image.url} /> : <VideoCameraSlashIcon className='h-4 w-4 text-gray-500' />}
              </div>

              <div className='text-gray-700 text-sm'>
                {item.title}
              </div>
            </button>
          ))}
        </>
      )
    }

    return (
      <div className='text-center flex items-center justify-center h-full'>
        <div className='p-6'>
          <div className='text-gray-900 text-sm'>{translateResource(`empty.${definition.name}`)}</div>

          <div className='text-gray-500 text-xs'>{translateResource(`empty.create${definition.name}`)}</div>
        </div>
      </div>
    )
  }

  // Create new program exercise

  const handleClick = (entry: Exercise) => () => {
    setExerciseIri(entry['@id'])
  }

  const initialValues = {
    effortDuration: 0,
    isWarmup: false,
    repetitionCount: 0,
    restDuration: 1,
    setCount: 1,
    type: CourseExerciseType.TYPE_REPS
  }

  const onSubmit = (values, { setSubmitting }) => {
    if (exerciseIri) {
      setSubmitting(true)
      const exercisePayload = { ...values, exercise: exerciseIri, type }
      onAdd(exercisePayload)
      setSubmitting(false)
    }
  }

  return (
    <Modal center onClose={handleModeNormal} onReturn={mode === 'create' ? handleModeNormal : undefined} open={open} setOpen={setOpen} size={mode === 'normal' ? 'medium' : 'large'} title={mode === 'normal' ? addLabel : createLabel}>
      {mode === 'normal' && (
        <div className='px-4 py-3 flex flex-col gap-4 items-start'>
          <button className='border-2 border-gray-100 px-4 py-6 rounded-lg flex flex-col justify-center items-center text-center min-w-[224px]' onClick={handleModeCreate}>
            <CircleAdd />

            <div className='font-medium mt-1.5'>{translateResource('create.exercises')}</div>

            <div className='text-gray-500 text-xs'>{translateResource('create.exercisesDescription')}</div>
          </button>

          {/* Find Exercise */}

          <div className='flex flex-col gap-3 w-full'>
            <div className='font-medium -mb-2 text-gray-700'>{translateResource('labels.findExercise')}</div>

            <div className='flex items-center self-start shadow shadow shadow-gray-200 rounded-lg px-4 relative bg-white w-full'>
              <Search className='w-6 h-6 fill-gray-500' />

              <input
                className='appearance-none block w-full pl-3 py-2 placeholder-gray-300 placeholder:text-md focus:outline-none sm:text-md'
                id='title'
                onChange={handleChange}
                placeholder={`${translateResource('labels.search')} ${translateResource('labels.title')}`}
                ref={filterRef}
                type='text'
              />

              <button className={`absolute right-4 rounded-full group hover:bg-red-800 ${!titleFilter.length ? 'hidden' : 'flex'}`} onClick={handleResetFilter} >
                <XMarkIcon className='w-6 h-6 text-red-800 group-hover:text-white font-bold' />
              </button>
            </div>

            <div className='p-0.5 border-2 border-gray-100 rounded-lg h-60 overflow-scroll scrollbar-hide'>
              {renderExercisesList()}
            </div>

            <div>
              <Formik initialValues={initialValues} onSubmit={onSubmit}>
                {({ isSubmitting, setFieldValue }) => {
                  const onTypeClick = (type) => {
                    setType(type)
                  }

                  return (
                    <Form className='flex flex-col gap-2'>
                      <div className='mb-2'>
                        <FormToggleField label={translateExercise('warmup')} name='isWarmup' />
                      </div>

                      <div className='flex gap-2'>
                        {Object.values(CourseExerciseType).map((metric) => (
                          <button
                            className={`rounded-full py-1 px-4 ${type === metric ? 'bg-gray-300' : 'bg-gray-100'}`}
                            onClick={() => {
                              onTypeClick(metric)
                            }}
                            type='button'
                          >
                            {translateExercise(`type.${metric}`)}
                          </button>
                        ))}
                      </div>

                      <div className='flex flex-col gap-4'>
                        <div className='relative gap-6 flex'>
                          <FormNumberField label={translateExercise('setCount')} name='setCount' />

                          {[CourseExerciseType.TYPE_REPS, CourseExerciseType.TYPE_DISTANCE, CourseExerciseType.TYPE_STEPS].includes(type) && <FormNumberField label={translateExercise(`type.${type}`)} name='repetitionCount' unit={translateExercise(`typesUnit.${type}`)} />}

                          {type === CourseExerciseType.TYPE_DURATION && <FormNumberField label={translateExercise(`type.${type}`)} name='effortDuration' unit={translateExercise(`typesUnit.${type}`)} />}

                          <FormNumberField label={translateExercise('restDuration')} name='restDuration' unit='secs' />
                        </div>

                        <button
                          className={`flex self-end gap-2 items-center rounded-lg px-4 py-2 justify-center text-lg font-semibold tracking-wide text-white fill-white hover:bg-gray-600 hover:text-white ${!exerciseIri ? 'cursor-not-allowed bg-gray-600' : 'cursor-pointer  bg-primary'}`}
                          disabled={isSubmitting || !exerciseIri}
                          type='submit'
                        >
                          {isSubmitting
                            ? <ButtonLoader />
                            : (
                              <div className='flex items-center gap-2'>
                                <CircleAdd />

                                {addLabel}
                              </div>
                            )
                          }
                        </button>
                      </div>
                    </Form>
                  )
                }}
              </Formik>
            </div>

            {errorMessage && <div className='text-red-700 text-center flex font-medium mb-2 self-center text-lg border border-red-700 px-2 rounded-md'>{errorMessage}</div>}
          </div>
        </div>
      )}

      {mode === 'create' &&
        <ExerciseCreate onCreate={onCreateExercise} />
      }
    </Modal>
  )
}

export default ModalAddEntry
