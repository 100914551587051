import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { type PaginationProps } from '@components/pagination/pagination.interfaces'
import { ChevronDown } from '@svg/icons'

const Pagination = ({ pageIndex, pagination, setPageIndex, totalItems }: PaginationProps) => {
  const { t: translateGeneral } = useTranslation('general')
  const [lastPage, setLastPage] = useState(1)
  const [firstPage, setFirstPage] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)

  const extractPageNumber = (url: string): number => {
    if (!url) {
      return 0
    }

    const urlObj = new URL(url, process.env.REACT_APP_API_URL)
    const page = new URLSearchParams(urlObj.search).get('page')

    return page ? parseInt(page) : 1
  }

  useEffect(() => {
    const last = pagination['hydra:last']
    const first = pagination['hydra:first']
    const current = pagination['@id']

    if (last && first && current) {
      setCurrentPage(extractPageNumber(current))
      setLastPage(extractPageNumber(last))
      setFirstPage(extractPageNumber(first))
    }
  }, [pagination])

  const goToPrevious = () => {
    if (pageIndex - 1 >= firstPage) {
      setPageIndex(pageIndex - 1)
    }
  }

  const goToNext = () => {
    if (pageIndex + 1 <= lastPage) {
      setPageIndex(pageIndex + 1)
    }
  }

  const goToPage = (pageNumber: number) => () => {
    setPageIndex(pageNumber)
  }

  const renderPages = () => {
    return (
      [...Array(lastPage)].map((x, i) => {
        const pageNumber = i + 1
        const classes = classNames({
          'bg-white hover:bg-gray-100': currentPage !== pageNumber,
          'relative items-center rounded-md p-1 text-sm w-6 h-6 flex items-center justify-center': true,
          'z-10 bg-gray-100 ': currentPage === pageNumber
        })

        return (
          <button
            aria-current='page'
            className={classes}
            key={pageNumber}
            onClick={goToPage(pageNumber)}
            type='button'
          >
            {pageNumber}
          </button>
        )
      })
    )
  }

  return (
    <div className='flex items-center justify-between'>
      <div className='flex-1 flex items-center justify-between'>
        <div>
          <nav aria-label='Pagination' className='relative z-0 inline-flex rounded-[10px] gap-0.5 border-2 border-gray-100 overflow-hidden p-0.5'>
            {pageIndex !== 1 && (
              <button
                className='relative w-6 h-6 flex items-center justify-center text-sm font-medium rounded-md hover:bg-gray-100'
                onClick={goToPrevious}
                type='button'
              >
                <span className='sr-only'>{translateGeneral('previous')}</span>

                <ChevronDown aria-hidden='true' className='h-4 w-4 rotate-90' />
              </button>
            )}

            {renderPages()}

            {pageIndex !== lastPage && (
              <button
                className='relative w-6 h-6 flex items-center justify-center text-sm rounded-md font-medium hover:bg-gray-100'
                onClick={goToNext}
                type='button'
              >
                <span className='sr-only'>{translateGeneral('next')}</span>

                <ChevronDown aria-hidden='true' className='h-4 w-4 -rotate-90' />
              </button>
            )}
          </nav>
        </div>
      </div>
    </div>
  )
}

export default Pagination
