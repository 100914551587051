import moment from 'moment'
import * as React from 'react'

import { type NotificationProps } from '@components/mobile-mockup-notification/mobile-mockup-notification.interfaces'

const MobileMockupNotification = ({ content, imagePreview, title }: NotificationProps) => {
  return (
    <div
      className='md:px-10 min-h-screen flex justify-center'
    >
      {/**  PHONE FRAME **/}

      <div className='relative bg-black shadow-xl overflow-hidden border-black'
        style={{
          borderRadius: '55px',
          borderWidth: '12px',
          height: '712px',
          width: '350px'
        }}
      >
        <img
          alt='iPhone wallpaper'
          className='absolute inset-0 h-full w-full object-cover' src='https://9to5mac.com/wp-content/uploads/sites/6/2021/06/1882.WWDC_2021_Dark-428w-926h@3xiphone.jpg'
        />

        {/**  Notch **/}

        <div className='absolute inset-x-0 top-0'>
          <div className='flex flex-row justify-center items-center space-x-2 h-4 w-20 mx-auto rounded-b-2xl bg-grey bg-opacity-70'>
            <div className='h-2 w-2 bg-gray-400 bg-opacity-20 rounded-full' />

            <div className='h-2 w-2 bg-gray-400 bg-opacity-20 rounded-full' />

            <div className='flex flex-row justify-center items-center h-2 w-2 rounded-full bg-gray-400 bg-opacity-50'>
              <div className='h-1 w-1 bg-blue-500 rounded-full' />
            </div>

            <div className='h-2 w-2 bg-gray-400 bg-opacity-20 rounded-full' />
          </div>
        </div>

        {/**  Right icons **/}

        <div className='relative'>
          <div className='mt-2 mr-5 flex justify-end space-x-1'>
            <svg className='h-4 w-4 text-white' fill='none' viewBox='0 0 21 14'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path clipRule='evenodd' d='M16.5 2H17.5C18.0523 2 18.5 2.44772 18.5 3V11C18.5 11.5523 18.0523 12 17.5 12H16.5C15.9477 12 15.5 11.5523 15.5 11V3C15.5 2.44772 15.9477 2 16.5 2Z'
                fill='white'
                fillRule='evenodd'
              />

              <path
                d='M13 4H12C11.4477 4 11 4.44772 11 5V11C11 11.5523 11.4477 12 12 12H13C13.5523 12 14 11.5523 14 11V5C14 4.44772 13.5523 4 13 4Z'
                fill='white'
              />

              <path
                d='M7.5 6H8.5C9.05228 6 9.5 6.44772 9.5 7V11C9.5 11.5523 9.05228 12 8.5 12H7.5C6.94772 12 6.5 11.5523 6.5 11V7C6.5 6.44772 6.94772 6 7.5 6Z'
                fill='white'
              />

              <path
                d='M3 7.5H4C4.55228 7.5 5 7.94772 5 8.5V11C5 11.5523 4.55228 12 4 12H3C2.44772 12 2 11.5523 2 11V8.5C2 7.94772 2.44772 7.5 3 7.5Z'
                fill='white'
              />
            </svg>

            <svg className='h-4 w-4 text-white' fill='none' viewBox='0 0 15 14'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path clipRule='evenodd' d='M13.9828 4.52294C12.0717 2.89057 9.6574 2 7.13272 2C4.60056 2 2.17953 2.89589 0.266142 4.53708L0.103645 4.67646C-0.0277638 4.78918 -0.0353068 4.98954 0.0872572 5.11176L1.2142 6.23557C1.32359 6.34466 1.4985 6.3521 1.6168 6.2527L1.75641 6.13541C3.26327 4.86943 5.15517 4.18035 7.13272 4.18035C9.10354 4.18035 10.9894 4.86472 12.494 6.12283L12.6336 6.23952C12.7519 6.33847 12.9264 6.33084 13.0356 6.22195L14.1627 5.09797C14.2855 4.97558 14.2777 4.77488 14.1459 4.66228L13.9828 4.52294ZM7.13272 5.46979C8.72355 5.46979 10.251 5.99754 11.4953 6.97486L11.6711 7.11298C11.8113 7.2231 11.8236 7.43059 11.6974 7.55643L10.5667 8.68407C10.4621 8.78836 10.2966 8.80034 10.178 8.7122L10.0401 8.60976C9.19975 7.98518 8.18639 7.65015 7.13272 7.65015C6.07256 7.65015 5.05328 7.98934 4.2101 8.62109L4.07212 8.72448C3.95351 8.81334 3.78742 8.80164 3.68252 8.69703L2.55216 7.56981C2.42623 7.44423 2.43819 7.23727 2.57774 7.12694L2.75259 6.98871C3.99984 6.00267 5.53421 5.46979 7.13272 5.46979ZM7.13272 8.93959C7.77222 8.93959 8.3936 9.10763 8.94112 9.42805L9.16295 9.55788C9.33114 9.65631 9.36071 9.88666 9.22282 10.0242L7.32874 11.913C7.2124 12.029 7.02377 12.029 6.90743 11.913L5.02552 10.0363C4.88824 9.8994 4.91685 9.67022 5.08361 9.57107L5.30296 9.44065C5.8555 9.11212 6.48478 8.93959 7.13272 8.93959Z'
                fill='white'
                fillRule='evenodd'
              />
            </svg>

            <svg className='h-4 w-4 text-white' fill='none' viewBox='0 0 108 50'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M84.8214 0H10.7143C4.82143 0 0 4.82143 0 10.7143V39.2857C0 45.1786 4.82143 50 10.7143 50H84.8214C90.7143 50 95.5357 45.1786 95.5357 39.2857V10.7143C95.5357 4.82143 90.7143 0 84.8214 0ZM89.2857 39.2857C89.2857 41.7411 87.2768 43.75 84.8214 43.75H10.7143C8.25893 43.75 6.25 41.7411 6.25 39.2857V10.7143C6.25 8.25893 8.25893 6.25 10.7143 6.25H84.8214C87.2768 6.25 89.2857 8.25893 89.2857 10.7143V39.2857ZM100 13.5268V36.4732C103.571 36.4732 107.143 30.2902 107.143 25C107.143 19.7098 103.571 13.5268 100 13.5268V13.5268Z'
                fill='white'
              />

              <path
                d='M82.1429 10.7143H13.3929C11.9197 10.7143 10.7143 11.9197 10.7143 13.3929V36.6072C10.7143 38.0804 11.9197 39.2857 13.3929 39.2857H82.1429C83.6161 39.2857 84.8214 38.0804 84.8214 36.6072V13.3929C84.8214 11.9197 83.6161 10.7143 82.1429 10.7143Z'
                fill='white'
              />
            </svg>
          </div>

          {/**  Bar under icons **/}

          <div className='h-0.5 w-10 bg-white mr-7 ml-auto mt-1.5 rounded' />

          {/**  Time section **/}

          <div className='mt-2 flex flex-col items-center'>
            <svg className='h-8 w-8 text-white' fill='none' viewBox='0 0 24 35'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M3.375 34.2949H20.1094C22.377 34.2949 23.4844 33.1699 23.4844 30.709V17.8066C23.4844 15.5918 22.5703 14.4492 20.6895 14.2559V9.82617C20.6895 3.19922 16.3477 0 11.7422 0C7.13672 0 2.79492 3.19922 2.79492 9.82617V14.3438C1.07227 14.6074 0 15.7324 0 17.8066V30.709C0 33.1699 1.10742 34.2949 3.375 34.2949ZM5.625 9.45703C5.625 5.04492 8.45508 2.70703 11.7422 2.70703C15.0293 2.70703 17.8594 5.04492 17.8594 9.45703V14.2383L5.625 14.2559V9.45703Z'
                fill='white'
              />
            </svg>

            <p className='mt-3 text-white text-6xl font-extralight'>{moment().format('HH:mm')}</p>

            <p className='mt-1 text-white text-lg font-light capitalize'>{moment().format('dddd D MMMM')}</p>
          </div>

          {/**  Notification summary section **/}

          <div
            className='mt-4 mx-2 relative'
          >
            {/**  Background cards **/}

            <div className='absolute -bottom-4 inset-x-0 h-full backdrop-blur-sm rounded-2xl origin-bottom'
              style={{
                backgroundColor: 'rgba(37, 37, 37, 0.35)',
                transform: 'scale(0.35)',
                zIndex: -1
              }}
            />

            <div className='absolute -bottom-2 inset-x-0 h-full rounded-3xl scale-95 origin-bottom shadow-sm'
              style={{
                backgroundColor: 'rgba(37, 37, 37, 0.60)',
                zIndex: -1
              }}
            />

            <div className='p-4 rounded-2xl shadow-sm'
              style={{
                backdropFilter: 'blur(7px)',
                backgroundColor: 'rgba(37, 37, 37, 0.70)'
              }}
            >

              {/**  Small cards grid **/}

              <div className='flex space-x-4 items-center'>
                <div className='flex justify-center bg-white rounded-full overflow-hidden p-1'>
                  <img
                    alt='Logo'
                    className='h-7 w-7 object-cover' src='/assets/theme/favicon-180.png'
                  />
                </div>

                <div className='flex-1 text-white'>
                  <div className='flex justify-between'>
                    <h3 className='h-4 w-44 text-sm font-semibold line-clamp-1 overflow-hidden'>
                      {title}
                    </h3>
                  </div>

                  <p className='text-xs line-clamp-4' style={{ color: 'rgba(255, 255, 255, 0.90)', marginTop: '5px' }}>
                    {content}
                  </p>
                </div>

                <div className='flex flex-col text-white rounded-md overflow-hidden mt-4 self-baseline'>
                  {!!imagePreview && (
                    <img alt='Company banner' className='h-7 w-7 object-cover' src={imagePreview} />
                  )}
                </div>

                <div className='absolute top-2 text-white right-4'>
                  <p className='text-xs font-extralight'>il y a 1min</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/**  Bottom tray **/}

        <div className='absolute bottom-0 inset-x-0 h-20'>
          <div className='flex justify-between px-10'>
            <div className='bg-gray-800 bg-opacity-40 p-3 rounded-full backdrop-blur-md'>
              <svg className='h-5 w-5 text-white' fill='none' viewBox='0 0 11 23'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M0 2.39941H10.377V1.90723C10.377 0.615234 9.74121 0 8.51074 0H1.86621C0.635742 0 0 0.615234 0 1.90723V2.39941ZM3.95801 22.9585H6.41895C7.64941 22.9585 8.2749 22.3433 8.2749 21.0513V10.1616C8.2749 9.08496 8.53125 8.30566 8.9209 7.71094L9.5874 6.72656C10.0591 5.99854 10.377 5.31152 10.377 4.43994V3.59912H0V4.43994C0 5.31152 0.317871 5.99854 0.789551 6.72656L1.4458 7.71094C1.8457 8.30566 2.0918 9.08496 2.0918 10.1616V21.0513C2.0918 22.3433 2.72754 22.9585 3.95801 22.9585ZM5.18848 15.0732C4.34766 15.0732 3.8042 14.458 3.8042 13.5967V10.7871C3.8042 9.91553 4.34766 9.33105 5.18848 9.35156C6.01904 9.36182 6.57275 9.94629 6.57275 10.7871V13.5967C6.57275 14.458 6.01904 15.0732 5.18848 15.0732ZM5.18848 14.3452C5.63965 14.3452 6.0293 13.9658 6.0293 13.5044C6.0293 13.043 5.63965 12.6533 5.18848 12.6533C4.72705 12.6533 4.3374 13.043 4.3374 13.5044C4.3374 13.9658 4.72705 14.3452 5.18848 14.3452Z'
                  fill='white'
                />
              </svg>
            </div>

            <div className='bg-gray-800 bg-opacity-40 p-3 rounded-full backdrop-blur-md'>
              <svg className='h-5 w-5 text-white' fill='none' viewBox='0 0 26 20'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M3.21973 19.5542H21.9639C24.0967 19.5542 25.1836 18.4878 25.1836 16.3755V5.63965C25.1836 3.52734 24.0967 2.47119 21.9639 2.47119H19.0312C18.2212 2.47119 17.9751 2.30713 17.5137 1.79443L16.6934 0.871582C16.1807 0.307617 15.6577 0 14.6016 0H10.5103C9.4541 0 8.93115 0.307617 8.41846 0.871582L7.59814 1.79443C7.13672 2.29688 6.88037 2.47119 6.08057 2.47119H3.21973C1.07666 2.47119 0 3.52734 0 5.63965V16.3755C0 18.4878 1.07666 19.5542 3.21973 19.5542ZM12.5918 16.5293C9.48486 16.5293 6.99316 14.0479 6.99316 10.9204C6.99316 7.80322 9.48486 5.32178 12.5918 5.32178C15.6987 5.32178 18.1802 7.80322 18.1802 10.9204C18.1802 14.0479 15.6885 16.5293 12.5918 16.5293ZM18.8672 7.14697C18.8672 6.44971 19.4824 5.83447 20.2002 5.83447C20.9077 5.83447 21.5127 6.44971 21.5127 7.14697C21.5127 7.875 20.9077 8.45947 20.2002 8.46973C19.4824 8.46973 18.8672 7.88525 18.8672 7.14697ZM12.5918 14.9707C14.8271 14.9707 16.6318 13.1763 16.6318 10.9204C16.6318 8.6748 14.8271 6.87012 12.5918 6.87012C10.3564 6.87012 8.5415 8.6748 8.5415 10.9204C8.5415 13.1763 10.3667 14.9707 12.5918 14.9707Z'
                  fill='white'
                />
              </svg>
            </div>
          </div>
        </div>

        {/**  Bottom nav pill **/}

        <div className='absolute bottom-1 inset-x-0'>
          <div className='mx-auto h-1 w-28 rounded bg-white' />
        </div>
      </div>
    </div>
  )
}

export default MobileMockupNotification
