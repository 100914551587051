import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import Table from '@components/table'
import UserMealplansTableEntry from '@components/table/table-data/user-mealplans-table-entry'
import AddUserMealplans from '@components/user/user-mealplans/add-user-mealplans'
import { type UserIdProps } from '@pages/user-profile/user-profile-interfaces'
import useApiResource from '@services/api/definition'
import useUserMealplansQuery from '@services/api/user/use-user-mealplans-query'
import { captureException } from '@services/exceptions/capture-exception'

const UserMealplans = ({ userId }: UserIdProps) => {
  const { t: translateUserMealplans } = useTranslation('user', { keyPrefix: 'mealplans' })
  const definition = useApiResource('mealplans')
  const [unfinished, setUnfinished] = useState<boolean>(false)

  const [searchParams, setSearchParams] = useSearchParams({ page: '1' })
  const pageIndex = Number(searchParams.get('page') ?? '1')
  const setPageIndex = (index: number) => {
    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      page: `${index}`
    })
  }

  const {
    data: {
      data: userMealplans,
      pagination,
      totalItems
    } = {},
    isLoading,
    refetch
  } = useUserMealplansQuery({ page: pageIndex, user: userId })

  const onSuccess = () => {
    refetch().catch(captureException)
  }

  useEffect(() => {
    userMealplans?.forEach((program) => {
      if (!program.finishedAt) {
        setUnfinished(true)
      }
    })
  }, [userMealplans])

  return (

    <div className='mt-6'>
      <Table
        headers={[translateUserMealplans('title'), translateUserMealplans('createdAt'), translateUserMealplans('finishedAt'), translateUserMealplans('status')]}
        isLoading={isLoading}
        pageIndex={pageIndex}
        pagination={pagination}
        setPageIndex={setPageIndex}
        totalItems={totalItems}
      >
        {
          userMealplans?.map((userMealplan, index) => (
            <UserMealplansTableEntry key={index} {...userMealplan} />
          ))
        }

        {!userMealplans?.length && (
          <tr className='text-center'>
            <td className='p-6' colSpan={4}>
              <div className='text-gray-900 text-sm'>{translateUserMealplans('noMealplans')}</div>

              <div className='text-gray-500 text-xs'>{translateUserMealplans('noMealplansDescription')}</div>
            </td>
          </tr>
        )}
      </Table>

      {!isLoading && !unfinished && definition && <AddUserMealplans definition={definition} onSuccess={onSuccess} userId={userId} />}
    </div>
  )
}

export default UserMealplans
