import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import ApiResourceOrder from '@components/api-resource/api-resource-order'
import DropdownMenu from '@components/dropdown-menu/dropdown-menu'
import ModalDeleteEntry from '@components/modals/modal-delete-entry/modal-delete-entry'
import CourseList from '@components/program/course-list'
import AddModulePlaylist from '@components/program/module-entry/add-module-playlist/add-module-playlist'
import { type ModuleEntryProps } from '@components/program/module-entry/index'
import useApiResource from '@services/api/definition'
import useCloneEntry from '@services/api/resources/clone-entry-query'
import { captureException } from '@services/exceptions/capture-exception'

const ModuleEntry = ({ idList, module, onModuleChange, position, programId }: ModuleEntryProps) => {
  const { t: translateProgram } = useTranslation('program')
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [openPlaylistModal, setOpenPlaylistModal] = useState<boolean>(false)
  const { mutateAsync: cloneResourceEntry } = useCloneEntry('api/program-modules/clone')
  const definition = useApiResource('program-modules/{uid}')
  const moduleName = `${translateProgram('module')} ${position}`
  const [visible, setVisible] = useState<boolean>(position === 1)

  const onDeleteHandler = () => {
    onModuleChange()
  }

  const onReorderHandler = () => {
    onModuleChange()
  }

  const onToggleClick = () => {
    setVisible(!visible)
  }

  const onCloneHandler = async () => {
    try {
      await cloneResourceEntry({ original: module['@id'] })
      onModuleChange()
    } catch (err) {
      captureException(err as Error)
    }
  }

  const handleDeleteClick = () => {
    setOpenDeleteModal(true)
  }

  const handlePlaylistClick = (e) => {
    e.stopPropagation()
    setOpenPlaylistModal(true)
  }

  const moduleOrderPath = `programs/${programId}/modules/change-order`

  return (
    <div
      className='p-1 bg-slate-200 rounded-lg'
    >
      <button className='flex items-center w-full group py-2 px-4  gap-2 text-gray-900 hover:cursor-pointer' onClick={onToggleClick}>
        <span className='font-medium text-gray-700'>{moduleName}</span>

        <div className='ml-2'>
          {definition && (
            <ApiResourceOrder
              id={module['@id']}
              idList={idList}
              onReorder={onReorderHandler}
              resourcePath={moduleOrderPath}
            />
          )}
        </div>

        <AddModulePlaylist module={module} onModuleChange={onModuleChange} open={openPlaylistModal} setOpen={setOpenPlaylistModal} />

        <div className='ml-auto flex gap-1'>

          <DropdownMenu items={[
            { label: 'Associer une playlist', onClick: handlePlaylistClick },
            { label: 'Cloner', onClick: onCloneHandler },
            { label: 'Supprimer', onClick: handleDeleteClick }
          ]}
          />

          {definition && (
            <ModalDeleteEntry
              definition={definition}
              entryId={module.uid}
              onDelete={onDeleteHandler}
              open={openDeleteModal}
              setOpen={setOpenDeleteModal}
            />
          )}
        </div>
      </button>

      {visible && (
        <CourseList moduleId={module.uid} />
      )}
    </div>
  )
}

export default ModuleEntry
