import { XMarkIcon } from '@heroicons/react/24/outline'
import { useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import Table from '@components/table'
import AudioTableEntry from '@components/table/table-data/audio-table-entry'
import { type AudioTemplate } from '@interfaces/api/audio-templates'
import { type AudioCategoryListProps } from '@pages/audio-category-list/audio-category-list-interfaces'
import useItemChildrenQuery from '@services/api/resources/item-children'
import { Search } from '@svg/icons'

const AudioCategoryList = ({ categoryUid }: AudioCategoryListProps) => {
  const [titleFilter, setTitleFilter] = useState<string>('')
  const filterRef = useRef<HTMLInputElement>(null)
  const { t: translateResource } = useTranslation('apiResources')
  const headers = [translateResource('labels.title'), translateResource('labels.audio'), translateResource('labels.createdAt'), translateResource('labels.actions')]

  // Search Params
  const [searchParams, setSearchParams] = useSearchParams({ page: '1' })
  const pageIndex = Number(searchParams.get('page') ?? '1')
  const setPageIndex = (index: number) => {
    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      page: `${index}`
    })
  }

  const {
    data: {
      data: categoryTemplates,
      pagination,
      totalItems
    } = {},
    dataUpdatedAt,
    isLoading
  } = useItemChildrenQuery<AudioTemplate>({
    itemId: categoryUid,
    path: 'audio-template-categories/{uid}/templates'
  })

  const handleChange = (e) => {
    setTitleFilter(e.target.value)
  }

  const handleResetFilter = () => {
    setTitleFilter('')
    if (filterRef?.current) {
      filterRef.current.value = ''
    }
  }

  const filteredCategoryTemplates = useMemo(() =>
    categoryTemplates?.filter(template => template.title.toLowerCase().includes(titleFilter.toLowerCase()))
  , [categoryTemplates, titleFilter, dataUpdatedAt])

  return (
    <div className='flex flex-col gap-4'>
      <div className='flex items-center self-start shadow shadow shadow-gray-200 rounded-lg px-4 relative bg-white'>
        <Search className='w-6 h-6 fill-gray-500' />

        <input
          className='appearance-none block w-full pl-3 py-2 placeholder-gray-300 placeholder:text-md focus:outline-none sm:text-md'
          id='title'
          onChange={handleChange}
          placeholder={`${translateResource('labels.search')} ${translateResource('labels.title')}`}
          ref={filterRef}
          type='text'
        />

        <button className={`absolute right-4 rounded-full group hover:bg-red-800 ${!titleFilter.length ? 'hidden' : 'flex'}`} onClick={handleResetFilter} >
          <XMarkIcon className='w-6 h-6 text-red-800 group-hover:text-white font-bold' />
        </button>
      </div>

      <Table
        headers={headers}
        isLoading={isLoading}
        noScroll
        pageIndex={pageIndex}
        pagination={pagination}
        setPageIndex={setPageIndex}
        totalItems={totalItems}
      >
        {
          filteredCategoryTemplates?.reverse().map((entry, index) => {
            return (
              <AudioTableEntry {...entry} categoryUid={categoryUid} key={index} />
            )
          })
        }

        {!filteredCategoryTemplates?.length && (
          <tr className='text-center'>
            <td className='p-6' colSpan={headers.length}>
              <div className='text-gray-900 text-sm'>{translateResource('empty.audio')}</div>

              <div className='text-gray-500 text-xs'>{translateResource('empty.createaudio')}</div>
            </td>
          </tr>
        )}
      </Table>
    </div>
  )
}

export default AudioCategoryList
