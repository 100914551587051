import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import CloneButton from '@components/buttons/clone-button'
import DeleteButton from '@components/buttons/delete-button'
import { type MealplanDayEntryProps } from '@components/mealplan/mealplan-day-entry/mealplan-day-entry-interfaces'
import MealplanDayInfo from '@components/mealplan/mealplan-day-info/mealplan-day-info'
import ModalDeleteEntry from '@components/modals/modal-delete-entry/modal-delete-entry'
import SkeletonLoader from '@components/skeleton-loader/skeleton-loader'
import { type MealplanDay } from '@interfaces/api/mealplan'
import useApiResource from '@services/api/definition'
import useCloneEntry from '@services/api/resources/clone-entry-query'
import useApiResourceItemQuery from '@services/api/resources/item-query'
import { captureException } from '@services/exceptions/capture-exception'

const MealplanDayEntry = ({ dayEntry, idList, onClone, onDelete, position }: MealplanDayEntryProps) => {
  const { t: translateDays } = useTranslation('calendar', { keyPrefix: 'days' })
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const definition = useApiResource('mealplan-days/{uid}')
  const { mutateAsync: cloneResourceEntry } = useCloneEntry('api/mealplan-days/clone')

  const { data, isFetching, refetch } = useApiResourceItemQuery<MealplanDay>({ id: dayEntry.uid, path: 'mealplan-days/{uid}' })

  const reloadDay = () => {
    refetch().catch((err) => {
      captureException(err as Error)
    })
  }

  const onDeleteHandler = () => {
    onDelete()
  }

  const onCloneHandler = async () => {
    try {
      await cloneResourceEntry({ original: dayEntry['@id'] })
      onClone()
    } catch (err) {
      captureException(err as Error)
    }
  }

  const handleDeleteClick = () => {
    setOpenDeleteModal(true)
  }

  const getDayByIndex = (index) => {
    switch (index) {
      case 1 : return translateDays('monday')
      case 2 : return translateDays('tuesday')
      case 3 : return translateDays('wednesday')
      case 4 : return translateDays('thursday')
      case 5 : return translateDays('friday')
      case 6 : return translateDays('saturday')
      case 7 : return translateDays('sunday')
      default: return null
    }
  }

  return (
    <div className='rounded-lg bg-gray-200 p-1'>
      <div className='group flex items-center gap-4 mb-1 px-2'>
        <span className='font-medium text-gray-700'>{getDayByIndex(position)}</span>

        <div className='ml-auto group-hover:visible invisible flex gap-1'>
          {idList.length < 7 && <CloneButton onClick={onCloneHandler} />}

          <DeleteButton onClick={handleDeleteClick} />

          {definition && (
            <ModalDeleteEntry
              definition={definition}
              entryId={dayEntry.uid}
              onDelete={onDeleteHandler}
              open={openDeleteModal}
              setOpen={setOpenDeleteModal}
            />
          )}
        </div>
      </div>

      {isFetching && <SkeletonLoader type='meal' />}

      {data && !isFetching && <MealplanDayInfo day={data} onReload={reloadDay} />}

    </div>
  )
}

export default MealplanDayEntry
