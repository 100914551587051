import { useNavigate } from 'react-router-dom'

import ApiResourceCreate from '@components/api-resource/api-resource-create'
import useApiResource from '@services/api/definition'

const ProgramCreate = () => {
  const definition = useApiResource('programs')
  const navigate = useNavigate()

  const onCreate = (iriReference: string) => {
    const splitRef = iriReference.split('/')
    const uid = splitRef[splitRef.length - 1]
    navigate(`/programs/${uid}`)
  }

  return (
    <>
      {definition && <ApiResourceCreate definition={definition} onEdit={onCreate} />}
    </>
  )
}

export default ProgramCreate
