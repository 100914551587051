import TableEntry from '@components/table/table-data/table-entry'
import { type Purchase } from '@interfaces/api/purchase'

const UserPurchasesTableEntry = (purchase: Purchase) => {
  const {
    createdAt,
    orderId
  } = purchase

  return (
    <TableEntry>
      <td className='p-4'>
        {orderId}
      </td>

      <td className='p-4 tabular-nums'>
        {(new Date(createdAt)).toLocaleString()}
      </td>

    </TableEntry>
  )
}

export default UserPurchasesTableEntry
