import { useQuery, type UseQueryResult } from '@tanstack/react-query'

import { type AnalyseDay } from '@interfaces/api/analyse-day'
import { type RessourceRequestParameters } from '@interfaces/api/request'
import useAxios from '@services/api/axios'

const useAnalyseDayQuery = (uid: string): UseQueryResult<AnalyseDay> => {
  const { get } = useAxios()

  return useQuery({
    queryFn: async () => {
      return await get<RessourceRequestParameters, AnalyseDay>(`api/analyse-days/${uid}`).then(result => result)
    },
    queryKey: ['analyse-day', uid],
    retry: false
  })
}

export default useAnalyseDayQuery
