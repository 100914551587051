import { Combobox, Dialog, Transition } from '@headlessui/react'
import { FolderIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { type ModalSearchProps } from '@components/modals/modal-search/modal-search.interfaces'
import { type ApiPlatformEntity } from '@interfaces/api'
import useApiResourceListQuery from '@services/api/resources/list-query'

const ModalSearch = <T extends ApiPlatformEntity>({ definition, onSelect, open, renderRow, searchParams, searchTerm, setOpen }: ModalSearchProps<T>) => {
  const [query, setQuery] = useState('')
  const { t: translateResource } = useTranslation('apiResources', { keyPrefix: definition.name })

  const {
    data: { 'hydra:member': resourceEntries = [] } = {}
  } = useApiResourceListQuery<T>({
    definition,
    parameters: {
      ...searchParams,
      pagination: false
    }
  })

  const filteredProjects =
    query === ''
      ? resourceEntries
      : resourceEntries.filter((entry) => {
        return entry[searchTerm].toLowerCase().includes(query.toLowerCase())
      })

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && filteredProjects.length > 0) {
      onSelect(filteredProjects[0])
    }
  }

  return (
    <Transition.Root afterLeave={() => {
      setQuery('')
    }} appear as={Fragment} show={open}
    >
      <Dialog as='div' className='relative z-10' onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 scale-95'
            enterTo='opacity-100 scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 scale-100'
            leaveTo='opacity-0 scale-95'
          >
            <Dialog.Panel className='mx-auto max-w-2xl transform divide-y divide-gray-500 divide-opacity-20 overflow-hidden rounded-xl bg-gray-900 shadow-2xl transition-all'>
              <Combobox onChange={(item) => {}}>
                <div className='relative'>
                  <MagnifyingGlassIcon
                    aria-hidden='true'
                    className='pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-500'
                  />

                  <Combobox.Input
                    className='h-12 w-full bg-transparent pl-11 pr-4 text-white sm:text-sm border-transparent outline-none'
                    onChange={(event) => {
                      setQuery(event.target.value)
                    }}
                    onKeyDown={handleKeyDown}
                    placeholder='Rechercher...'
                  />
                </div>

                {(query === '' || filteredProjects.length > 0) && (
                  <Combobox.Options
                    className='max-h-80 scroll-py-2 divide-y divide-gray-500 divide-opacity-20 overflow-y-auto'
                    static
                  >
                    <li className='p-2'>
                      {query === '' && (
                        <h2 className='mb-2 mt-4 px-3 text-xs font-semibold text-gray-200'>{translateResource('searchTitle')}</h2>
                      )}

                      <ul className='text-sm text-gray-400'>
                        {(query === '' ? resourceEntries : filteredProjects).map((entry) => {
                          return (
                            <Combobox.Option
                              className={({ active }) =>
                                classNames(
                                  'flex select-none items-center rounded-md px-3 py-2 cursor-pointer',
                                  active && 'bg-gray-800 text-white'
                                )
                              }
                              key={entry.uid}
                              onClick={() => {
                                onSelect(entry)
                              }}
                              value={entry}
                            >
                              {({ active }) => {
                                return renderRow(active, entry)
                              }}
                            </Combobox.Option>
                          )
                        })}
                      </ul>
                    </li>
                  </Combobox.Options>
                )}

                {query !== '' && filteredProjects.length === 0 && (
                  <div className='px-6 py-14 text-center sm:px-14'>
                    <FolderIcon aria-hidden='true' className='mx-auto h-6 w-6 text-gray-500' />

                    <p className='mt-4 text-sm text-gray-200'>
                      {translateResource('searchEmpty')}
                    </p>
                  </div>
                )}
              </Combobox>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default ModalSearch
