import React from 'react'

import { type AudioGalleryTemplatesProps } from '@components/audio/audio-gallery/audio-gallery-templates-interfaces'
import AudioTemplateButton from '@components/audio/audio-gallery/audio-template'

const AudioGalleryTemplates = ({ category, onSelect, templates }: AudioGalleryTemplatesProps) => {
  return (
    <div className='flex flex-col items-start gap-1.5'>
      <div className='text-xs text-gray-500'>{category}</div>

      {templates?.map((template) => {
        return (
          <AudioTemplateButton key={template.uid} onSelect={onSelect} template={template} />
        )
      })}
    </div>
  )
}

export default AudioGalleryTemplates
