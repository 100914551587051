import { type ApiPlatformEntity, type DateTime } from '@interfaces/api'
import { type Image } from '@interfaces/api/image'

export enum VideoStatus {
  PUBLISHED = 'PUBLISHED',
  TRANSCODING = 'TRANSCODING'
}

export interface VideoFormat extends ApiPlatformEntity {
  fps: number
  label: string
  path: string
  size: number
  url: string
}

export interface Video extends ApiPlatformEntity {
  category: VideoCategory
  duration: number
  formats: VideoFormat[] | null
  image: Image
  originalName: string
  status: VideoStatus
  tag: string
  uid: string
}

export interface VideoCategory extends ApiPlatformEntity {
  createdAt: DateTime
  name: string
  position: number
}

export interface Playlist extends ApiPlatformEntity {
  createdAt: DateTime
  label: string
}

export interface PlaylistVideo extends ApiPlatformEntity {
  createdAt: DateTime
  position: number
  tag: string
  video: Video
}
