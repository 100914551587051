import { useQueryClient } from '@tanstack/react-query'

import ApiResourceCreate from '@components/api-resource/api-resource-create'
import useApiResource from '@services/api/definition'
import { captureException } from '@services/exceptions/capture-exception'

interface CalendarAvailabilityProps {
  onCreate: () => void
}

const CalendarAvailabilityCreate = ({ onCreate }: CalendarAvailabilityProps) => {
  const definition = useApiResource('calendar-availabilities')
  const queryClient = useQueryClient()

  const reloadCalendar = () => {
    queryClient.refetchQueries({ queryKey: ['calendar-days'] }).catch((err) => {
      captureException(err as Error)
    })
  }

  const onCreateEntry = () => {
    onCreate()
    reloadCalendar()
  }

  return (
    <div>
      {definition && <ApiResourceCreate definition={definition} onEdit={onCreateEntry} />}
    </div>
  )
}

export default CalendarAvailabilityCreate
