import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import AudioUploader from '@components/audio/audio-uploader/audio-uploader'
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import SectionTitle from '@components/section-title/section-title'
import AudioCategoryList from '@pages/audio-category-list'
import useAudioTemplateCategoriesList
  from '@services/api/audio/audio-template-categories/use-audio-template-categories-list'
import { audioRoles } from '@services/roles/roles'
import { Volume } from '@svg/icons'

const AudioList = () => {
  const { t } = useTranslation('audio')
  const { t: bc } = useTranslation('breadcrumbs')

  const { data: { data: audioCategories } = {} } = useAudioTemplateCategoriesList()
  const [currentCategory, setCurrentCategory] = useState<string>('')

  useEffect(() => {
    if (audioCategories?.length) {
      setCurrentCategory(audioCategories[0].uid)
    }
  }, [audioCategories])

  const breadcrumbs = [
    { href: '', name: bc('audio') }
  ]

  const handleClick = (uid: string) => () => {
    setCurrentCategory(uid)
  }

  const onSuccess = (uid: string) => {
    setCurrentCategory(uid)
  }

  return (
    <Layout description={t('description')} title={t('title')}>
      <Guard acceptedRoles={audioRoles}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <SectionTitle description={t('description')} icon={Volume} title={t('title')} />

        <AudioUploader onSuccess={onSuccess} />

        <div className='px-8 mb-4 text-2xl font-medium'>{t('categories')}</div>

        <div className='mx-8 flex flex-wrap gap-4'>
          {audioCategories?.map((category) => (
            <button className={`px-4 py-1 tracking-wider font-normal text-lg rounded-lg ${currentCategory === category.uid ? 'bg-primary text-white' : 'bg-gray-100 hover:bg-gray-200'}`} key={category.uid} onClick={handleClick(category.uid)}>{category.name}</button>
          ))}
        </div>

        <div className='my-8 px-4 sm:px-6 md:px-8'>
          {currentCategory && (<AudioCategoryList categoryUid={currentCategory} />)}
        </div>
      </Guard>
    </Layout>
  )
}

export default AudioList
