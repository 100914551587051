import { useTranslation } from 'react-i18next'

import FinishedTag from '@components/finished-tag/finished-tag'
import UserMealplanDayEntry from '@components/user-mealplan-entry/user-mealplan-day-entry/user-mealplan-day-entry'
import { type UserMealplanDaysProps } from '@components/user-mealplan-entry/user-mealplan-days/user-mealplan-days-interfaces'

const UserMealplanDays = ({ mealplanDays }: UserMealplanDaysProps) => {
  const { t: translateMealplan } = useTranslation('mealplan')
  const { t: translateDays } = useTranslation('calendar', { keyPrefix: 'days' })

  const getDayByIndex = (index) => {
    switch (index) {
      case 1 : return translateDays('monday')
      case 2 : return translateDays('tuesday')
      case 3 : return translateDays('wednesday')
      case 4 : return translateDays('thursday')
      case 5 : return translateDays('friday')
      case 6 : return translateDays('saturday')
      case 7 : return translateDays('sunday')
      default: return null
    }
  }

  return (
    <>
      {mealplanDays?.length
        ? (
          <div className='flex flex-col gap-2'>
            {mealplanDays?.map((day, index) => {
              return (
                <div className='rounded-lg bg-gray-200 p-1' key={index}>
                  <div className='flex items-center gap-2 mb-1 px-3'>
                    <span className='font-medium text-gray-700'>{getDayByIndex(index + 1)}</span>

                    {day.finishedAt && <FinishedTag date={day.finishedAt} />}
                  </div>

                  <UserMealplanDayEntry day={day} />
                </div>
              )
            })}
          </div>
        )
        : (
          <div className='flex flex-col justify-start border border-gray-200 rounded-lg p-1'>
            <div className='text-gray-900 text-sm px-5 py-12 text-center'>{translateMealplan('noDay')}</div>
          </div>
        )}
    </>
  )
}

export default UserMealplanDays
