import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import ButtonLoader from '@components/buttons/button-loader/button-loader'
import ContextualButton from '@components/buttons/contextual-button'
import { type EmptySlotProps } from '@components/calendar/calendar-interfaces'
import Modal from '@components/modals/modal'
import useCreateEntry from '@services/api/resources/create-entry-query'
import { captureException } from '@services/exceptions/capture-exception'

const EmptySlot = ({ date, onReload, timeSlot }: EmptySlotProps) => {
  const { t: translateActions } = useTranslation('apiResources', { keyPrefix: 'actions' })
  const { t: translateCalendar } = useTranslation('calendar')
  const [openModal, setOpenModal] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { mutateAsync: createBusySlot } = useCreateEntry({ path: 'calendar-busy-slots' })

  const handleClick = () => {
    setOpenModal(true)
  }

  const onCancelHandler = () => {
    setOpenModal(false)
  }

  const onCreateHandler = async () => {
    setIsSubmitting(true)
    try {
      await createBusySlot({ ref: timeSlot.ref })
    } catch (err) {
      captureException(err as Error)
    } finally {
      onReload()
      setOpenModal(false)
      setIsSubmitting(false)
    }
  }

  return (
    <>
      <button className='flex items-center justify-center hover:bg-gray-100' onClick={handleClick} />

      <Modal center open={openModal} setOpen={setOpenModal} title={translateCalendar('updateAvailability')}>
        <div className='flex flex-col items-center p-8'>
          <div className='text-center text-xl'>{`${translateActions('disableSlot')} ${date} ${translateActions('from')} ${timeSlot.start} ${translateActions('to')} ${timeSlot.end}`}</div>

          <div className='flex mt-6 gap-8'>
            <ContextualButton onClick={onCreateHandler}>{isSubmitting ? <ButtonLoader /> : translateActions('confirm')}</ContextualButton>

            <ContextualButton onClick={onCancelHandler}>{translateActions('cancel')}</ContextualButton>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default EmptySlot
