import { type ApiPlatformEntity, type ApiPlatformListResponse } from '@interfaces/api'
import { type Image } from '@interfaces/api/image'
import { type Video } from '@interfaces/api/video'

export interface Exercise extends ApiPlatformEntity {
  createdAt: string
  description: string
  image: Image
  isCharged: boolean
  isWarmup: boolean
  slug: string
  title: string
  uid: string
  video: Video
}

export enum CourseExerciseType {
  TYPE_DISTANCE = 'DISTANCE',
  TYPE_DURATION = 'DURATION',
  TYPE_REPS = 'REPS',
  TYPE_STEPS = 'STEPS'
}

export type ExercisesResponse = ApiPlatformListResponse<Exercise>
