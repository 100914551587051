import { useQuery, type UseQueryResult } from '@tanstack/react-query'

import { type UserProfile } from '@interfaces/api/user-profile'
import useAxios from '@services/api/axios'

const useUserProfileQuery = (id: number | null): UseQueryResult<UserProfile> => {
  const { get } = useAxios()

  return useQuery({
    queryFn: async () => {
      return await get<UserProfile, UserProfile>(`api/users/${id}`).then(result => result)
    },
    queryKey: ['users', id]
  })
}

export default useUserProfileQuery
