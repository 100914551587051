import { CheckIcon, UserIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import Date from '@components/api-resource/resource-entry-fields/date'
import Duration from '@components/api-resource/resource-entry-fields/duration'
import { type ApiResourceListEntryProps } from '@components/purchase-list-entry/purchase-list-entry.interfaces'
import { type Coach } from '@interfaces/api/coach'

const PurchaseListEntry = ({ entry, fieldsToDisplay, onClick }: ApiResourceListEntryProps) => {
  const { t: translateRoles } = useTranslation('user', { keyPrefix: 'roles' })

  const fields: ReactElement[] = []
  const onClickHandler = () => {
    if (onClick) {
      onClick(entry)
    }
  }

  fieldsToDisplay.forEach((field, index) => {
    if (entry[field.name] !== undefined) {
      if ('type' in field.properties) {
        if (field.properties.type === 'string') {
          if (field.properties.format === 'date-time' && entry[field.name]) {
            fields.push(<Date datetimeEnabled value={entry[field.name]} />)
          } else {
            fields.push(<div key={index}>{entry[field.name] ?? '-'}</div>)
          }
        }
        if (field.properties.type === 'integer' || field.properties.type === 'number') {
          if (field.name === 'duration') {
            fields.push(<Duration value={entry[field.name]} />)
          } else {
            fields.push(<div key={index}>{entry[field.name]}</div>)
          }
        }
        if (field.properties.type === 'boolean') {
          fields.push(
            <div key={index}>
              {entry[field.name]
                ? (
                  <div className='inline-block w-6 h-6 bg-green-100 rounded p-1.5'>
                    <CheckIcon className='w-3 h-3 text-green-700' />
                  </div>
                )
                : (
                  <div className='inline-block w-6 h-6 bg-gray-100 rounded p-1.5'>
                    <XMarkIcon className='w-3 h-3 text-gray-700' />
                  </div>
                )
              }
            </div>
          )
        }
        if (field.properties.type === 'array') {
          if (field.name === 'roles') {
            fields.push(<div key={index}>
              {entry[field.name].map(entry => {
                return <div key={entry}>{translateRoles(entry)}</div>
              })}
            </div>)
          } else if (field.name === 'data') {
            return fields.push(
              <pre>
                <div>{JSON.stringify(JSON.parse(entry[field.name]), null, 2)}</div>
              </pre>
            )
          } else {
            fields.push(<div key={index}>{(entry[field.name] as never[]).toString()}</div>)
          }
        }
      }

      if ('anyOf' in field.properties || '$ref' in field.properties) {
        if (entry[field.name]) {
          if (entry[field.name]['@type'] === 'User') {
            const user: Coach = entry[field.name]
            const name = `${user.email}`
            fields.push(
              <div className='flex flex-nowrap items-center gap-2' key={index}>
                <div className='inline-block w-6 h-6 bg-gray-100 rounded p-1.5'>
                  <UserIcon className='w-3 h-3 text-gray-700' />
                </div>

                <div className='whitespace-nowrap'>{name}</div>
              </div>
            )
          } else {
            fields.push(<div key={index}>{entry[field.name].name ?? entry[field.name]['@id']}</div>)
          }
        } else {
          fields.push(<div key={index}>-</div>)
        }
      }
    }
  })

  return (
    <tr
      className='bg-white hover:bg-gray-100 cursor-pointer group'
      onClick={onClickHandler}
    >
      {fields.map((field, index) => (
        <td
          className='p-3 text-base'
          key={index}
        >
          {field}
        </td>
      ))}
    </tr>
  )
}

export default PurchaseListEntry
