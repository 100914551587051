import {
  useMutation,
  type UseMutationResult
} from '@tanstack/react-query'

import { type ResourceItemParameters } from '@interfaces/api/definition/resource'
import useAxios from '@services/api/axios'

const useDeleteEntry = ({ id, path }: ResourceItemParameters): UseMutationResult => {
  const { del } = useAxios()
  const url = `api/${path.replace('{uid}', id)}`

  return useMutation({ mutationFn: async () => await del(url).then((body) => body) })
}

export default useDeleteEntry
