import { useQuery, type UseQueryResult } from '@tanstack/react-query'

import { type AnalysePeriod } from '@interfaces/api/analyse-periods'
import { type ApiError } from '@interfaces/api/api'
import { type RessourceRequestParameters } from '@interfaces/api/request'
import useAxios from '@services/api/axios'

const useAnalysePeriod = (uid: string): UseQueryResult<AnalysePeriod> => {
  const { get } = useAxios()

  return useQuery<AnalysePeriod, ApiError>({
    queryFn: async () => {
      return await get<RessourceRequestParameters, AnalysePeriod>(`api/analyse-periods/${uid}`).then(result => result)
    },
    queryKey: ['analyse-period', uid],
    retry: false
  })
}

export default useAnalysePeriod
