import MealEntry from '@components/mealplan/meal/meal'
import { type MealplanDayInfoProps } from '@components/mealplan/mealplan-day-info/mealplan-day-info-interfaces'

const MealplanDayInfo = ({ day, onReload }: MealplanDayInfoProps) => {
  const { breakfast, dinner, lunch, snack } = day

  return (
    <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-1 items-start'>
      <MealEntry dayInfo={day} label={'breakfast'} meal={breakfast} onReload={onReload} />

      <MealEntry dayInfo={day} label={'lunch'} meal={lunch} onReload={onReload} />

      <MealEntry dayInfo={day} label={'snack'} meal={snack} onReload={onReload} />

      <MealEntry dayInfo={day} label={'dinner'} meal={dinner} onReload={onReload} />
    </div>
  )
}

export default MealplanDayInfo
