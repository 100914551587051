import { useTranslation } from 'react-i18next'

import UserAnalysePeriod from '@components/user/user-analyse-period/user-analyse-period'
import { type UserProgramEntryProps } from '@components/user-program-entry/user-program-entry-interfaces'
import UserProgramHead from '@components/user-program-entry/user-program-head'
import UserProgramModules from '@components/user-program-entry/user-program-modules'

const UserProgramEntry = ({ data }: UserProgramEntryProps) => {
  const { analysePeriod, createdAt, currentModuleNumber, isStopped, modules, program, status, uid } = data
  const { t: translateTabs } = useTranslation('user', { keyPrefix: 'tabs' })

  console.log(data)

  return (
    <>
      <UserProgramHead analysePeriod={analysePeriod} createdAt={createdAt} currentModuleNumber={currentModuleNumber} isStopped={isStopped} modules={modules} program={program} status={status} uid={uid} userProgram={data['@id']} />

      {/* Module list */}

      <div className='mt-12 mb-8'>
        <UserProgramModules modules={modules} programUid={uid} status={status} />
      </div>

      {analysePeriod && (
        <div className='mt-12 mb-8' id='analysePeriod'>
          <div className='bg-gray-100 p-1 rounded-lg flex'>
            <div className='py-2.5 px-4 font-medium rounded-lg bg-white'>{translateTabs('analysePeriod')}</div>
          </div>

          <div className='border-2 border-gray-100 border-t-0 rounded-b-xl p-1 pt-6 -mt-2 flex flex-col gap-4'>
            <UserAnalysePeriod analysePeriodUid={analysePeriod.uid} />
          </div>
        </div>
      )}
    </>
  )
}

export default UserProgramEntry
