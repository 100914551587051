import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import ApiResourceEdit from '@components/api-resource/api-resource-edit'
import ContextualButton from '@components/buttons/contextual-button'
import Modal from '@components/modals/modal'
import { type EditPlaylistProps } from '@components/video/edit-playlist/edit-playlist-interfaces'
import useDeleteEntry from '@services/api/resources/delete-entry-query'
import { captureException } from '@services/exceptions/capture-exception'

const EditPlaylist = ({ definition, open, playlistUid, setOpen }: EditPlaylistProps) => {
  const { t: translateResource } = useTranslation('apiResources')
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)

  const resourceParameters = { id: playlistUid, path: 'playlists/{uid}' }
  const { mutateAsync: deleteResourceEntry } = useDeleteEntry(resourceParameters)

  const queryClient = useQueryClient()
  const reloadPlaylist = () => {
    queryClient.refetchQueries({ queryKey: ['playlists'] }).catch(captureException)
  }

  const handleCancelClick = () => {
    setOpenDeleteModal(false)
  }

  const handleDeleteClick = () => {
    setOpenDeleteModal(true)
  }

  const onEdit = () => {
    reloadPlaylist()
    setOpen(false)
  }

  const onDelete = () => {
    deleteResourceEntry(undefined).then(() => {
      reloadPlaylist()
      setOpenDeleteModal(false)
      setOpen(false)
    }).catch(captureException)
  }

  return (
    <Modal center open={open} setOpen={setOpen} title={translateResource('edit.playlists')}>
      <div className='relative'>
        <ApiResourceEdit definition={definition} id={playlistUid} onEdit={onEdit} />

        <div className='absolute bottom-3 left-4'>
          <ContextualButton onClick={handleDeleteClick} style='warning'>{translateResource('actions.delete')}</ContextualButton>

          <Modal center open={openDeleteModal} setOpen={setOpenDeleteModal} title={translateResource('actions.deleteResource')}>
            <div className='flex flex-col items-center p-8'>
              <div className='text-center text-xl'>{translateResource('actions.deleteConfirmation')}</div>

              <div className='flex mt-6 gap-8'>
                <ContextualButton onClick={onDelete} style='warning'>{translateResource('actions.delete')}</ContextualButton>

                <ContextualButton onClick={handleCancelClick}>{translateResource('actions.cancel')}</ContextualButton>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </Modal>
  )
}

export default EditPlaylist
