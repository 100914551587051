import { create } from 'zustand'
import { persist } from 'zustand/middleware'

interface ThemeStore {
  logo: string
  primary: string
  reset: () => void
  secondary: string
  secondaryText: string
  setLogo: (logo: string) => void
  setPrimary: (primary: string) => void
  setSecondary: (secondary: string) => void
  setSecondaryText: (secondaryText: string) => void
}

const initialThemeStore = {
  logo: '',
  primary: '#000000',
  secondary: '#FEED00',
  secondaryText: '#000000'
}

export const useThemeStore = create<ThemeStore>()(
  persist(
    (set, get) => ({
      ...initialThemeStore,
      reset: () => {
        set(initialThemeStore)
      },
      setLogo: (logo) => {
        set({ logo })
      },
      setPrimary: (primary) => {
        set({ primary })
      },
      setSecondary: (secondary) => {
        set({ secondary })
      },
      setSecondaryText: (secondaryText) => {
        set({ secondaryText })
      }
    }),
    {
      name: 'theme-store'
    }
  )
)
