import { useTranslation } from 'react-i18next'

import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import CalendarView from '@components/calendar/calendar'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import { calendarRoles } from '@services/roles/roles'

const Calendar = () => {
  const { t: translateCalendar } = useTranslation('calendar')
  const { t: bc } = useTranslation('breadcrumbs')

  const breadcrumbs = [
    { href: '', name: bc('calendar') }
  ]

  return (
    <Layout description={translateCalendar('description')} title={translateCalendar('title')}>
      <Guard acceptedRoles={calendarRoles}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <CalendarView />
      </Guard>
    </Layout>
  )
}

export default Calendar
