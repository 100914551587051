import { XMarkIcon } from '@heroicons/react/24/outline'
import { useQueryClient } from '@tanstack/react-query'
import { Form, Formik } from 'formik'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import ButtonLoader from '@components/buttons/button-loader/button-loader'
import FormTextField from '@components/form-fields/form-text-field'
import Modal from '@components/modals/modal'
import { type AddPlaylistVideoProps } from '@components/video/add-playlist-video/add-playlist-video-interfaces'
import { type Video } from '@interfaces/api/video'
import useCreateEntry from '@services/api/resources/create-entry-query'
import useApiResourceListFormatQuery from '@services/api/resources/list-format-query'
import { captureException } from '@services/exceptions/capture-exception'
import { CircleAdd, Search } from '@svg/icons'

const AddPlaylistVideo = ({ definition, open, playlistUid, setOpen }: AddPlaylistVideoProps) => {
  const { t: translateVideo } = useTranslation('videos', { keyPrefix: 'playlist' })
  const { t: translateResource } = useTranslation('apiResources')

  const [titleFilter, setTitleFilter] = useState<string>('')
  const [videoIri, setVideoIri] = useState<string>('')
  const filterRef = useRef<HTMLInputElement>(null)
  const playlistIri = `/api/playlists/${playlistUid}`

  const queryClient = useQueryClient()
  const reloadPlaylist = () => {
    queryClient.refetchQueries({ queryKey: [`playlists/${playlistUid}/videos`] }).catch(captureException)
  }

  const { mutateAsync: addVideo } = useCreateEntry({ path: 'playlist-videos' })

  const path = {
    name: `${definition.name}?pagination=false`,
    url: ''
  }

  const {
    data: {
      data: items
    } = {}
  } = useApiResourceListFormatQuery<Video>({ definition: path })

  const renderVideosList = () => {
    if (filteredItems?.length) {
      return (
        <>
          {filteredItems.map((item) => (
            <button className={`p-1.5 flex items-center gap-3 w-full rounded-lg ${videoIri === item['@id'] ? 'bg-gray-100' : 'bg-white hover:bg-gray-50'}`} key={item.uid} onClick={handleClick(item)}>
              <div className='h-[52px] w-[52px] rounded-lg bg-gray-500 overflow-hidden'>
                {item.image && <img alt='' className='w-full h-full object-cover' src={item.image.url} />}
              </div>

              <div className='text-gray-700 text-sm'>
                {item.originalName}
              </div>
            </button>
          ))}
        </>
      )
    }

    return (
      <div className='text-center flex items-center justify-center h-full'>
        <div className='p-6'>
          <div className='text-gray-900 text-sm'>{translateResource(`empty.${definition.name}`)}</div>

          <div className='text-gray-500 text-xs'>{translateResource(`empty.create${definition.name}`)}</div>
        </div>
      </div>
    )
  }

  const handleClick = (entry: Video) => () => {
    setVideoIri(entry['@id'])
  }

  // Filters

  const filteredItems = items?.filter(item => item.originalName.toLowerCase().includes(titleFilter.toLowerCase()))

  const handleChange = (e) => {
    setTitleFilter(e.target.value)
  }

  const handleResetFilter = () => {
    setTitleFilter('')
    if (filterRef?.current) {
      filterRef.current.value = ''
    }
  }

  // Forms

  const initialValues = {
    playlist: playlistIri,
    tag: '',
    video: ''
  }

  const onSubmit = async (values, { setSubmitting }) => {
    if (videoIri) {
      const valuesPayload = {
        ...values,
        video: videoIri
      }

      setSubmitting(true)

      try {
        await addVideo(valuesPayload)
        setSubmitting(false)
        setOpen(false)
      } catch (e) {
        captureException(e as Error)
      } finally {
        reloadPlaylist()
      }
    }
  }

  return (
    <Modal center open={open} setOpen={setOpen} title={translateVideo('addVideo')}>
      <div className='flex flex-col gap-3 w-full px-4 py-3'>
        <div className='font-medium -mb-2 text-gray-700'>{translateResource('labels.findVideo')}</div>

        <div className='flex items-center self-start shadow shadow shadow-gray-200 rounded-lg px-4 relative bg-white w-full'>
          <Search className='w-6 h-6 fill-gray-500' />

          <input
            className='appearance-none block w-full pl-3 py-2 placeholder-gray-300 placeholder:text-md focus:outline-none sm:text-md'
            id='title'
            onChange={handleChange}
            placeholder={`${translateResource('labels.search')} ${translateResource('labels.name')}`}
            ref={filterRef}
            type='text'
          />

          <button className={`absolute right-4 rounded-full group hover:bg-red-800 ${!titleFilter.length ? 'hidden' : 'flex'}`} onClick={handleResetFilter} >
            <XMarkIcon className='w-6 h-6 text-red-800 group-hover:text-white font-bold' />
          </button>
        </div>

        <div className='p-0.5 border-2 border-gray-100 rounded-lg h-60 overflow-scroll scrollbar-hide'>
          {renderVideosList()}
        </div>

        <div>
          <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {({ isSubmitting }) => {
              return (
                <div className='flex flex-col gap-2'>
                  <Form className='flex flex-col gap-4'>

                    <FormTextField label={translateResource('labels.tag')} name='tag' />

                    <button
                      className={`flex self-end gap-2 items-center rounded-lg px-4 py-2 text-lg font-semibold tracking-wide text-white fill-white hover:bg-gray-600 hover:text-white ${!videoIri ? 'cursor-not-allowed bg-gray-600' : 'cursor-pointer  bg-primary'}`}
                      disabled={isSubmitting || !videoIri}
                      type='submit'
                    >
                      {isSubmitting
                        ? <ButtonLoader />
                        : (
                          <div className='flex items-center gap-2'>
                            <CircleAdd />

                            {translateResource('actions.add')}
                          </div>
                        )
                      }
                    </button>
                  </Form>
                </div>
              )
            }}
          </Formik>
        </div>
      </div>
    </Modal>
  )
}

export default AddPlaylistVideo
