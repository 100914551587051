import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import Calendar from '@components/calendar/calendar'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import StatsTile from '@components/stats-tile/stats-tile'
import { Roles } from '@interfaces/api/roles'
import ChatRoomList from '@pages/chat-room-list/chat-room-list'
import useUserProgramsQuery from '@services/api/user/use-user-programs-query'

const Home = () => {
  const { t: translateGeneral } = useTranslation('general')
  const {
    data: {
      totalItems: totalUserProgramsAwaiting
    } = {}
  } = useUserProgramsQuery({ status: 'AWAITING_COACH' })

  const {
    data: {
      totalItems: totalUserProgramsPublished
    } = {}
  } = useUserProgramsQuery({ status: 'PUBLISHED' })

  const navigate = useNavigate()

  const onClick = (status: string) => {
    navigate(`/user-programs?status=${status}`)
  }

  return (
    <Layout title={translateGeneral('home')}>
      <Breadcrumbs />

      <Guard acceptedRoles={[Roles.ROLE_COACH]} errorPage={false}>
        <div className='flex'>
          <ChatRoomList />

          {totalUserProgramsPublished && totalUserProgramsAwaiting && (
            <div className='flex items-start mt-20'>
              <StatsTile onClick={() => {
                onClick('PUBLISHED')
              }} title='Programme(s) en cours' value={`${totalUserProgramsPublished ?? ''}`}
              />

              <StatsTile onClick={() => {
                onClick('AWAITING_COACH')
              }} title='Programme(s) en attente' value={`${totalUserProgramsAwaiting ?? ''}`}
              />
            </div>
          )}
        </div>

        <Calendar />
      </Guard>
    </Layout>
  )
}

export default Home
