import { useState } from 'react'
import { AudioPlayerProvider } from 'react-use-audio-player'

import AudioPlayer from '@components/chat-message/audio-player'
import { type ChatMessageAudioProps } from '@components/chat-message/chat-message-interfaces'
import useAudioQuery from '@services/api/audio/audio/use-audio-query'
import { Volume } from '@svg/icons'

const ChatMessageAudio = ({ audioUid }: ChatMessageAudioProps) => {
  const { data: chatAudio } = useAudioQuery(audioUid)
  const [show, setShow] = useState<boolean>(false)

  const handleClick = () => {
    setShow(true)
  }

  return (
    <AudioPlayerProvider>
      <div className='bg-white p-5 rounded-lg mt-2'>
        {show && chatAudio?.url
          ? <AudioPlayer file={chatAudio.url} />
          : (
            <button className='p-3 bg-primary rounded-full' onClick={handleClick}>
              <Volume className='h-8 w-8 fill-white' />
            </button>
          )
        }
      </div>
    </AudioPlayerProvider>
  )
}

export default ChatMessageAudio
