import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import ApiResourceSingleEntry from '@components/api-resource/api-resource-single-entry'
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import Program from '@pages/program/program'
import { programsRoles } from '@services/roles/roles'

const ProgramsList = () => {
  const [apiResponse, setApiResponse] = useState('')
  const { t: translateProgram } = useTranslation('program', { keyPrefix: 'entry' })
  const { t: bc } = useTranslation('breadcrumbs')
  const { id = '' } = useParams()

  const breadcrumbs = [
    { href: '/programs', name: bc('programs') },
    { href: '', name: apiResponse ?? id }
  ]

  const onSuccess = (res) => {
    setApiResponse(res.title)
  }

  return (
    <Layout description={translateProgram('description')} title={`${translateProgram('title')} - ${apiResponse}`}>
      <Guard acceptedRoles={programsRoles}>

        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <>
          {id && (
            <ApiResourceSingleEntry
              component={Program}
              id={id}
              onSuccess={onSuccess}
              path={'programs/{uid}'}
            />
          )}
        </>
      </Guard>
    </Layout>
  )
}

export default ProgramsList
