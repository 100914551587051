import { create } from 'zustand'

import { type AudioTemplate } from '@interfaces/api/audio-templates'

interface AudioTemplateStore {
  audioTemplate: AudioTemplate | null
  setAudioTemplate: (audioTemplate: AudioTemplate | null) => void
}

export const useAudioTemplateStore = create<AudioTemplateStore>((set) => ({
  audioTemplate: null,
  setAudioTemplate: (audioTemplate) => {
    set({ audioTemplate })
  }
}))
