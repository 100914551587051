import {
  useMutation,
  type UseMutationResult
} from '@tanstack/react-query'

import { type ApiError } from '@interfaces/api/api'
import { type Image } from '@interfaces/api/image'
import useAxios from '@services/api/axios'

export const useUploadImage = (): UseMutationResult<string, ApiError, FormData> => {
  const { post } = useAxios()

  return useMutation<string, ApiError, FormData>({
    mutationFn: async (file: FormData) => await post<FormData, Image>('/api/images', file).then((body) => body['@id'])
  }
  )
}
