import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import AudioGallery from '@components/audio/audio-gallery/audio-gallery'
import VoiceRecorder from '@components/audio/voice-recorder/voice-recorder'
import { type CategoryTemplates, type ChatAudioProps } from '@components/chat-message/chat-message-form/chat-message-form-interfaces'
import useCategoriesTemplates from '@services/api/audio/audio-categories-templates/use-audio-categories-templates'
import { Microphone, Volume } from '@svg/icons'

const ChatAudio = ({ audioCategories, createMessage, room }: ChatAudioProps) => {
  const { t } = useTranslation('chat', { keyPrefix: 'room' })
  const [option, setOption] = useState<'default' | 'recording'>('default')
  const [galleryOpen, setGalleryOpen] = useState(false)
  const galleryRef = useRef<HTMLDivElement>(null)

  const handleClick = () => {
    setGalleryOpen(!galleryOpen)
    setOption('default')
  }

  useEffect(() => {
    if (galleryRef?.current) {
      const clickEvent = (event) => {
        if (!galleryRef.current?.contains(event.target) && galleryOpen) {
          setGalleryOpen(false)
          setOption('default')
        }
      }

      document.addEventListener('click', clickEvent)

      return () => {
        document.removeEventListener('click', clickEvent)
      }
    }
  }, [galleryRef?.current, galleryOpen])

  const handleRegisterClick = (e) => {
    e.stopPropagation()
    setOption('recording')
  }

  const onReturn = () => {
    setOption('default')
  }

  const onSelectAudio = () => {
    setGalleryOpen(false)
    setOption('default')
  }

  const categoriesTemplates: CategoryTemplates[] = []
  let allTemplates = []
  const categoriesData = useCategoriesTemplates({ categories: audioCategories })

  categoriesData.forEach((category) => {
    if (category.data?.templates.length) {
      categoriesTemplates.push(category.data)

      allTemplates = allTemplates.concat(category.data.templates)
    }
  })

  return (
    <div className='flex items-center justify-center relative ml-1' ref={galleryRef}>
      <button className={`p-1 hover:bg-primary rounded-md group ${galleryOpen ? 'bg-primary' : 'bg-white'}`} onClick={handleClick}>
        <Volume className={`w-6 h-6 group-hover:fill-white ${galleryOpen ? 'fill-white' : 'fill-gray-900'}`} />
      </button>

      {galleryOpen && (
        <div className='p-3 bg-white full-shadow absolute bottom-full left-full rounded-lg z-10 flex flex-col items-center gap-4 min-w-[200px]'>
          {option === 'default' && (
            <div className='flex flex-col gap-4 items-start'>
              <AudioGallery allTemplates={allTemplates} categoriesTemplates={categoriesTemplates} onSelect={onSelectAudio} />

              <button className='bg-primary px-2 py-1.5 rounded-full text-white hover:bg-gray-800 flex items-center gap-1.5' onClick={handleRegisterClick}>
                <Microphone className='w-4 h-5 fill-white' />

                <div className='text-sm'>{t('register')}</div>
              </button>
            </div>
          )}

          {option === 'recording' && <VoiceRecorder createMessage={createMessage} onReturn={onReturn} room={room} setOpen={setGalleryOpen} />}
        </div>
      )}
    </div>
  )
}

export default ChatAudio
