import React from 'react'

import { type ApiResourceOrderProps } from '@components/api-resource/api-resource-order/index'
import IconButton from '@components/buttons/icon-button'
import useReorderEntry from '@services/api/resources/reorder-entry-query'
import { captureException } from '@services/exceptions/capture-exception'
import { ChevronDown, ChevronUp } from '@svg/icons'

const ApiResourceOrder = ({ id, idList, onReorder, resourcePath }: ApiResourceOrderProps) => {
  const resourceParameters = { id: '', path: resourcePath }
  const { mutateAsync: reorderEntry } = useReorderEntry(resourceParameters)

  const onReorderHandler = (direction: number) => () => {
    const newOrder: string[] = []
    for (let i = 0; i < idList.length; i++) {
      // if the cursor is on the resource to reorder forward
      // append item n+1 THEN the resource we want
      // then skip the items
      const shouldOffsetForward = (direction === 1 && idList[i] === id)
      // if the next item is the resource we want to reorder backward
      // append the resource we want THEN the current item
      // then skip the items
      const shouldOffsetBackward = (direction === -1 && idList[i + 1] === id)
      if (shouldOffsetForward || shouldOffsetBackward) {
        newOrder.push(idList[i + 1])
        newOrder.push(idList[i])
        i += 1
      } else {
        newOrder.push(idList[i])
      }
    }

    reorderEntry(newOrder).then(() => {
      onReorder()
    }).catch((err) => {
      captureException(err as Error)
    })
  }

  const resourceIndex = idList.findIndex(entry => entry === id)
  const canGoUp = resourceIndex > 0
  const canGoDown = resourceIndex <= idList.length - 2

  return (
    <div className='flex gap-1'>
      <IconButton disabled={!canGoDown} icon={ChevronDown} onClick={onReorderHandler(1)} />

      <IconButton disabled={!canGoUp} icon={ChevronUp} onClick={onReorderHandler(-1)} />
    </div>
  )
}

export default ApiResourceOrder
