import { useQuery, type UseQueryResult } from '@tanstack/react-query'

import { type ApiPlatformListResponse, type RequestParameters } from '@interfaces/api'
import { type User, type UsersResponse } from '@interfaces/api/user'
import useAxios from '@services/api/axios'
import { formatApiListResponse } from '@services/tools/api-resources/format-list-response'

const useUsersCoachesListQuery = (): UseQueryResult<UsersResponse> => {
  const { get } = useAxios()

  return useQuery({
    queryFn: async () => {
      return await get<RequestParameters, ApiPlatformListResponse<User>>('api/users/coaches').then(result => formatApiListResponse<User>(result))
    },
    queryKey: ['users-coaches'],
    retry: false
  })
}

export default useUsersCoachesListQuery
