import {
  useMutation
} from '@tanstack/react-query'

import { type ResourceItemParameters } from '@interfaces/api/definition/resource'
import useAxios from '@services/api/axios'

const useReorderEntry = ({ id, path }: ResourceItemParameters) => {
  const { put } = useAxios()
  const url = `api/${path.replace('{uid}', id)}`

  return useMutation({
    mutationFn: async (reorderedIds: string[]) => {
      return await put(url, { order: reorderedIds }).then((body) => body)
    }
  })
}

export default useReorderEntry
