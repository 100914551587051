import { useQuery, type UseQueryResult } from '@tanstack/react-query'

import { type Audio } from '@interfaces/api/audio-templates'
import useAxios from '@services/api/axios'

const useAudioQuery = (uid: string): UseQueryResult<Audio> => {
  const { get } = useAxios()

  return useQuery({
    queryFn: async () => {
      return await get<undefined, Audio>(`api/audio/${uid}`).then(result => result)
    },
    queryKey: ['audio', uid]
  })
}

export default useAudioQuery
