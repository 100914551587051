import { XMarkIcon } from '@heroicons/react/24/outline'
import React, { useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import AudioGalleryTemplates from '@components/audio/audio-gallery/audio-gallery-templates'
import AudioTemplateButton from '@components/audio/audio-gallery/audio-template'
import { type AudioGalleryProps } from '@components/chat-message/chat-message-form/chat-message-form-interfaces'
import { Search } from '@svg/icons'

const AudioGallery = ({ allTemplates, categoriesTemplates, onSelect }: AudioGalleryProps) => {
  const [titleFilter, setTitleFilter] = useState<string>('')
  const filterRef = useRef<HTMLInputElement>(null)
  const { t: translateResource } = useTranslation('apiResources')

  const handleChange = (e) => {
    setTitleFilter(e.target.value)
  }

  const handleResetFilter = () => {
    setTitleFilter('')
    if (filterRef?.current) {
      filterRef.current.value = ''
    }
  }

  const filteredAudioTemplates = useMemo(() =>
    allTemplates?.filter(template => template.title.toLowerCase().includes(titleFilter.toLowerCase()))
  , [allTemplates, titleFilter])

  return (
    <div className='w-full relative min-w-[230px]'>
      <div className='flex items-center self-start border-2 border-gray-100 rounded-lg px-3 relative mb-3'>
        <Search className='w-6 h-6 fill-gray-500' />

        <input
          className='appearance-none block w-full pl-3 py-2 placeholder-gray-300 placeholder:text-md focus:outline-none sm:text-md'
          id='title'
          onChange={handleChange}
          placeholder={translateResource('labels.findAudio')}
          ref={filterRef}
          type='text'
        />

        <button className={`absolute right-4 rounded-full group hover:bg-red-800 ${!titleFilter.length ? 'hidden' : 'flex'}`} onClick={handleResetFilter} >
          <XMarkIcon className='w-6 h-6 text-red-800 group-hover:text-white font-bold' />
        </button>
      </div>

      <div className='flex flex-col items-start gap-3 overflow-y-auto max-h-[180px] scrollbar-hide'>
        {!titleFilter
          ? (
            <>
              {categoriesTemplates?.map((category, index) => (
                <AudioGalleryTemplates category={category.category} key={index} onSelect={onSelect} templates={category.templates} />
              ))}
            </>
          )
          : (
            <>
              {filteredAudioTemplates?.map((template) => (
                <AudioTemplateButton key={template.uid} onSelect={onSelect} template={template} />
              ))}
            </>
          )
        }
      </div>
    </div>
  )
}

export default AudioGallery
