import { type InfiniteData, useQueryClient } from '@tanstack/react-query'
import moment from 'moment'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'

import ButtonLoader from '@components/buttons/button-loader/button-loader'
import ChatMessage from '@components/chat-message/chat-message'
import ChatMessageForm from '@components/chat-message/chat-message-form/chat-message-form'
import { type FormattedApiListResponse } from '@interfaces/api'
import { type ChatMessage as ChatMessageType, type FormattedDateMessages } from '@interfaces/api/chat'
import { type ChatRoomEntryProps } from '@pages/chat-room-entry/chat-room-entry-interfaces'
import {
  useChatEventListQuery,
  useChatMessageListQuery,
  useCreateChatMessageMutation,
  useMarkAsReadChatMessagesMutation
} from '@services/api/chat'
import useApiResource from '@services/api/definition'
import { captureException } from '@services/exceptions/capture-exception'
import { ChevronDown } from '@svg/icons'

const ChatRoomEntry = ({ chatRoom }: ChatRoomEntryProps) => {
  const SHORTCUT_TRIGGER_OFFSET = -100
  const { t } = useTranslation('chat', { keyPrefix: 'room' })

  const [showAnchor, setShowAnchor] = useState<boolean>(false)
  const [showNewMessageBadge, setShowNewMessageBadge] = useState<boolean>(false)
  const scrollRef = useRef<HTMLDivElement>(null)
  const location = useLocation()

  useEffect(() => {
    updateMessageReadStatus()
  }, [location])

  const definition = useApiResource('chat-messages')

  const { mutate: markAsRead } = useMarkAsReadChatMessagesMutation()
  const { dataUpdatedAt: newEventUpdatedAt, fetchNextPage: fetchNextChatEvent, isFetchingNextPage: isFetchingNextEvents } = useChatEventListQuery({ room: chatRoom.uid })
  const { isPending: isMutatingMsg, mutateAsync: createMessage } = useCreateChatMessageMutation()
  const { data: chatMessages, dataUpdatedAt, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } = useChatMessageListQuery({ room: chatRoom.uid })

  const room = chatRoom?.['@id'] ?? ''
  const interval = useRef<NodeJS.Timeout | null>(null)
  const queryClient = useQueryClient()

  // FETCHING DATA

  const formattedChatMessages = useMemo(() =>
    chatMessages?.pages?.map((page) => page?.data)?.flat() ?? [],
  [isLoading, isFetchingNextPage, dataUpdatedAt, isMutatingMsg])

  const fetchNewEventsInBg = () => {
    interval.current = setInterval(() => {
      !isFetchingNextEvents && fetchNextChatEvent().catch(captureException)
    }, 5000)
  }

  useEffect(() => {
    fetchNewEventsInBg()

    return () => {
      queryClient.resetQueries({ queryKey: ['chat-events', chatRoom.uid] }).catch(captureException)
      clearInterval(interval.current as NodeJS.Timeout)
    }
  }, [])

  const updateMessageReadStatus = () => {
    if (chatMessages?.pages?.length && chatRoom) {
      const unreadMessagesUidList = chatMessages.pages.map(p => p.data.filter(cm => !cm.isFromAdmin && !cm.isRead)).flat().map(cm => cm.uid)
      if (unreadMessagesUidList?.length && scrollRef?.current) {
        if (scrollRef.current.scrollTop === 0) {
          markAsRead({ messageUids: unreadMessagesUidList, room: chatRoom['@id'] })

          queryClient.setQueryData<InfiniteData<FormattedApiListResponse<ChatMessageType>>>(
            ['chat-messages', chatRoom.uid], (data) => {
              if (data) {
                const updatedData = data?.pages?.map(p => ({
                  ...p,
                  data: p.data.map(cm => {
                    if (unreadMessagesUidList.includes(cm.uid)) {
                      return {
                        ...cm,
                        isRead: true
                      }
                    }

                    return cm
                  })
                }))

                return {
                  pageParams: data?.pageParams ?? [],
                  pages: updatedData
                }
              }

              return data
            })
        }

        if ((chatMessages?.pages?.length ?? 0 > 1) && scrollRef.current.scrollTop < SHORTCUT_TRIGGER_OFFSET) {
          setShowNewMessageBadge(true)
        }
      }
    }
  }

  useEffect(() => {
    updateMessageReadStatus()
  }, [chatMessages?.pages?.length, newEventUpdatedAt])

  // SCROLL LOGICS

  useEffect(() => {
    const scrollEvent = () => {
      if (scrollRef?.current) {
        const scroll = scrollRef.current.scrollHeight + scrollRef.current.scrollTop
        if ((scroll < scrollRef.current.offsetHeight + 100) && !isFetchingNextPage && hasNextPage) {
          fetchNextPage().catch(captureException)
        }

        if (scrollRef.current.scrollTop === 0) {
          updateMessageReadStatus()
        }

        if (scrollRef.current.scrollTop < SHORTCUT_TRIGGER_OFFSET && !showAnchor) {
          setShowAnchor(true)
        } else if (scrollRef.current.scrollTop > SHORTCUT_TRIGGER_OFFSET && showAnchor) {
          setShowAnchor(false)
        } else if (scrollRef.current.scrollTop > SHORTCUT_TRIGGER_OFFSET && showNewMessageBadge) {
          setShowNewMessageBadge(false)
        }
      }
    }

    scrollRef.current?.addEventListener('scroll', scrollEvent)

    return () => {
      scrollRef.current?.removeEventListener('scroll', scrollEvent)
    }
  }, [scrollRef?.current, isFetchingNextPage, hasNextPage, showAnchor])

  const scrollToEnd = () => {
    const scrollHeight = scrollRef?.current?.scrollHeight
    if (scrollHeight) {
      scrollRef.current.scrollTo({ behavior: 'smooth', left: 0, top: 0 })
    }
  }

  const scrollToEndAtSending = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({ left: 0, top: 0 })
    }
  }

  useEffect(() => {
    scrollToEnd()
  }, [])

  // FORMATTED DATE MESSAGE

  const formattedDate = (d: string) => moment(d).format('YYYY-MM-DD')

  const getFormattedDate = (givenDate) => {
    const yesterday = moment().subtract(1, 'days')
    const currentDate = givenDate ? moment(givenDate) : moment()
    const past7DaysStartDate = moment().subtract(7, 'days')
    const isInLast7days = currentDate.isAfter(past7DaysStartDate) && currentDate.isBefore(yesterday)
    if (!moment(currentDate).isBefore(moment(), 'day')) {
      return t('today')
    } else if (currentDate.isSame(yesterday, 'd')) {
      return t('yesterday')
    } else if (isInLast7days) {
      return currentDate.format('dddd')
    } else {
      return currentDate.format('Do MMM YYYY')
    }
  }

  const dataWithSections = useMemo(() => {
    const nextData: FormattedDateMessages[] = []

    formattedChatMessages.forEach(cm => {
      const date = formattedDate(cm.createdAt)
      if (date && !nextData.some(nD => nD.title === date)) {
        nextData.push({ data: [cm], title: date })
      } else {
        const sectionIndex = nextData.findIndex(nD => nD.title === date)
        nextData[sectionIndex].data = [...(nextData[sectionIndex]?.data ?? []), cm]
      }
    })

    return nextData
  }, [formattedChatMessages])

  return (
    <div className='shadow border-1 border-gray-50 shadow-gray-200 bg-white rounded-lg flex flex-col items-center h-full min-h-[85vh] divide-y'>
      {showNewMessageBadge && (<button className='animate-bounce z-10 px-4 py-1 rounded-lg border-2 border-gray-100 bg-whit absolute bottom-28 self-center' onClick={scrollToEnd}>{t('newMessage')}</button>)}

      {!showNewMessageBadge && (<button className={`transition z-10 px-4 py-1 rounded-lg border-2 border-gray-100 bg-white absolute bottom-28 self-center ${showAnchor ? 'opacity-100 translate-y-0' : 'opacity-0 pointer-events-none translate-y-8'}`} onClick={scrollToEnd}><ChevronDown className='w-8 h-8' /></button>)}

      <div className='flex flex-col-reverse gap-4 p-3 overflow-y-auto w-full h-full scrollbar-hide' ref={scrollRef}>

        {dataWithSections?.map((section) => {
          return (
            <div className='flex flex-col-reverse gap-4' key={section.title}>
              {section.data.map((message, index) => {
                return (
                  <ChatMessage key={index} messageEntry={message} room={chatRoom.uid} />
                )
              })}

              <div className='text-center border border-gray-100 text-gray-500 self-center px-3 rounded-lg'>{getFormattedDate(section.title)}</div>
            </div>
          )
        })}

        {isFetchingNextPage && <ButtonLoader dark wrapperClassName='flex justify-center' />}
      </div>

      {chatRoom?.isDisabled
        ? (
          <div className='w-full border-gray-100'>
            <div className='w-full bg-gray-100 text-center italic p-3 font-medium'>{t('chatDisabled')}</div>
          </div>
        )
        : (
          <div className='w-full rounded-b-lg bg-white py-2 px-2 md:px-4'>
            {definition && <ChatMessageForm createMessage={createMessage} definition={definition} room={room} scrollToEnd={scrollToEndAtSending} />}
          </div>
        )
      }
    </div>
  )
}

export default ChatRoomEntry
