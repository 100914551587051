import { ErrorMessage, Field, useField } from 'formik'
import { useEffect } from 'react'

import { type FormNumberFieldProps } from '@components/form-fields/form-number-field/index'
import { captureException } from '@services/exceptions/capture-exception'
import { Add, Remove } from '@svg/icons'

const FormNumberField = ({ label, name, required = false, unit, zeroAllowed = false }: FormNumberFieldProps) => {
  const [input, , helpers] = useField({ name })
  const { setValue } = helpers
  const { value } = input

  useEffect(() => {
    if (value === 0 && !zeroAllowed) {
      setValue(1).catch(captureException)
    }
  }, [value])

  const onPlusClick = (e) => {
    e.preventDefault()
    setValue((value as number) + 1).catch(captureException)
  }
  const onMinusClick = (e) => {
    e.preventDefault()
    const newValue = (value as number) - 1
    if (newValue >= 0) {
      setValue(newValue).catch(captureException)
    }
  }

  return (
    <div className='flex flex-col text-gray-700 gap-2'>
      <label className='block font-medium' htmlFor={name}>{label}</label>

      <div className='flex relative'>
        <Field
          className={`noArrow text-gray-700 text-start border-2 border-gray-100 max-w-[80px] rounded-lg appearance-none w-full px-2 pb-0 placeholder-gray-400 focus:outline-none ${unit ? 'pr-10' : ''}`}
          min='0'
          name={name}
          required={required}
          type='number'
        />

        {unit
          ? (
            <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3'>
              <span className='text-gray-500 sm:text-sm' id='price-currency'>
                {unit}
              </span>
            </div>
          )
          : (
            <div className='grid grid-cols-2 2xl:flex absolute right-0 bottom-0 z-10 h-full gap-px'>
              <button className='bg-gray-200 fill-gray-700 px-0.5 py-1 2xl:py-0 rounded-l-lg hover:bg-slate-300' onClick={onPlusClick}>
                <Add className='w-4 h-4 mx-auto' />
              </button>

              <button className='bg-gray-200 fill-gray-700 px-0.5 rounded-r-lg hover:bg-slate-300' onClick={onMinusClick}>
                <Remove className='w-4 h-4 mx-auto' />
              </button>
            </div>
          )
        }
      </div>

      <ErrorMessage className='mt-2 text-xs text-red-600 font-medium' component='div' name={name} />
    </div>
  )
}

export default FormNumberField
