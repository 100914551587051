import { ErrorMessage, Field, useField } from 'formik'
import { Fragment, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { type FormSelectFieldProps } from '@components/form-fields/form-select-field/index'
import { captureException } from '@services/exceptions/capture-exception'
import { ChevronDown } from '@svg/icons'

const FormSelectField = ({ defaultValue, label, name, options, placeholder = '', required = false, size = 'normal' }: FormSelectFieldProps) => {
  const { t } = useTranslation()

  const [, , helpers] = useField({ name })
  const { setValue } = helpers

  useEffect(() => {
    if (!placeholder) {
      setValue(options[0].key).catch(captureException)
    }

    if (defaultValue) {
      setValue(defaultValue).catch(captureException)
    }
  }, [defaultValue])

  return (
    <div className='flex flex-col'>
      <label className='block font-medium text-gray-700' htmlFor={name}>{t(label)}</label>

      <div className='flex items-center mt-2 relative border-2 border-gray-100 rounded-lg'>
        <Field
          as='select'
          className={`appearance-none block py-2 px-4 placeholder-gray-400 focus:outline-none cursor-pointer rounded-lg ${size === 'small' ? 'w-40' : 'w-full'}`}
          name={name}
          required={required}
        >
          {options.map(option => (
            <Fragment key={option.key}>
              {placeholder && <option value=''>{placeholder}</option>}

              <option value={option.key}>{option.name}</option>
            </Fragment>
          ))}

        </Field>

        <ChevronDown className='w-6 h-6 flex-none absolute right-4 pointer-events-none' />
      </div>

      <ErrorMessage className='mt-2 text-xs text-red-600 font-medium' component='div' name={name} />
    </div>
  )
}

export default FormSelectField
