import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import ApiResourceEdit from '@components/api-resource/api-resource-edit'
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import useApiResource from '@services/api/definition'
import { captureException } from '@services/exceptions/capture-exception'
import { calendarRoles } from '@services/roles/roles'

const CalendarAvailabilityEdit = () => {
  const { t: translateCalendar } = useTranslation('calendar')
  const { t: bc } = useTranslation('breadcrumbs')
  const { id = '' } = useParams()
  const navigate = useNavigate()

  const breadcrumbs = [
    { href: '/calendar', name: bc('calendar') },
    { href: '/calendar-availabilities', name: bc('availabilities') },
    { href: '', name: bc('edit') }
  ]

  const queryClient = useQueryClient()

  const reloadCalendar = () => {
    queryClient.refetchQueries({ queryKey: ['calendar-days'] }).catch((err) => {
      captureException(err as Error)
    })
  }

  const definition = useApiResource('calendar-availabilities/{id}')

  const onEdit = () => {
    reloadCalendar()
    navigate('/calendar-availabilities')
  }

  return (
    <Layout description={translateCalendar('description')} title={translateCalendar('title')}>
      <Guard acceptedRoles={calendarRoles}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <div className='flex flex-col items-center p-8'>
          {definition && <ApiResourceEdit definition={definition} id={id} onEdit={onEdit} />}
        </div>
      </Guard>
    </Layout>
  )
}

export default CalendarAvailabilityEdit
