import { ErrorMessage, Field, useField } from 'formik'
import { useEffect } from 'react'

import ApiResourceAutocomplete from '@components/api-resource/api-resource-autocomplete'
import { type FormAutocompleteFieldProps } from '@components/form-fields/form-autocomplete-field/form-autocomplete-field-interfaces'
import { captureException } from '@services/exceptions/capture-exception'

const FormAutocompleteField = ({ label, name, required = false, value }: FormAutocompleteFieldProps) => {
  const [, , helpers] = useField({ name, value })
  const { setValue } = helpers

  const onChange = (entry) => {
    setValue(entry['@id']).catch(captureException)
  }

  useEffect(() => {
    if (value) {
      setValue(value['@id']).catch(captureException)
    }
  }, [])

  return (
    <div className='flex flex-col'>
      <label className='block font-medium text-gray-700' htmlFor={name}>{label}</label>

      <Field
        className='appearance-none block w-full mt-2 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:border-slate-500 sm:text-sm'
        name={name}
        required={required}
        type='hidden'
      />

      <ApiResourceAutocomplete field={name} onChange={onChange} value={value} />

      <ErrorMessage className='mt-2 text-xs text-red-600 font-medium' component='div' name={name} />
    </div>
  )
}

export default FormAutocompleteField
