import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import UserProfileChat from '@components/user/user-profile-chat/user-profile-chat'
import UserProfile from '@pages/user-profile/user-profile'
import useChatRoomUserQuery from '@services/api/chat/room/use-chat-room-user-query'
import useUserProfilQuery from '@services/api/user/use-user-profile-query'
import { userProfileRoles } from '@services/roles/roles'

const UserEdit = () => {
  const { t: translateUser } = useTranslation('user', { keyPrefix: 'userProfile' })
  const { t: bc } = useTranslation('breadcrumbs')
  const { id } = useParams()
  const userId = Number(id)

  const { data } = useUserProfilQuery(userId)

  const fullName = data?.firstName && data?.lastName ? `${data.firstName} ${data.lastName}` : ''

  const breadcrumbs = [
    { href: '/users', name: bc('users') },
    { href: '', name: fullName }
  ]

  const {
    data: {
      data: userChatRooms
    } = {}
  } = useChatRoomUserQuery({ user: userId })

  return (
    <Layout description={translateUser('description')} title={`${translateUser('title')} - ${fullName}`}>
      <Guard acceptedRoles={userProfileRoles}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <div className='flex flex-col lg:grid lg:grid-cols-4 gap-4 px-4 sm:px-6 md:px-8'>
          <div className='col-span-2'>
            {data && <UserProfile user={data} />}
          </div>

          {!!userChatRooms?.length && (
            <div className='col-span-2 my-8 lg:m-0'>
              <UserProfileChat userChatRooms={userChatRooms} userId={userId} />
            </div>
          )}
        </div>
      </Guard>
    </Layout>
  )
}

export default UserEdit
