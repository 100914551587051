import {
  useMutation,
  type UseMutationResult
} from '@tanstack/react-query'

import useAxios from '@services/api/axios'

const usePutEntry = (path: string): UseMutationResult => {
  const { put } = useAxios()

  return useMutation({ mutationFn: async (data) => await put<never, never>(path, data as never).then((body) => body) })
}

export default usePutEntry
