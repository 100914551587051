import { type OpenAPIV3 } from 'openapi-types'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { type ApiResourceListProps } from '@components/api-resource/api-resource-list/index'
import ApiResourceListEntry from '@components/api-resource/api-resource-list-entry'
import ApiResourceListFilters from '@components/api-resource/api-resource-list-filters'
import Table from '@components/api-resource/api-resource-table'
import ContextualButton from '@components/buttons/contextual-button'
import { type Field } from '@interfaces/api/definition/field'
import { Roles } from '@root/interfaces/api/roles'
import useApiResourceListQuery from '@services/api/resources/list-query'
import useUserMeQuery from '@services/api/user/use-user-me-query'
import { captureException } from '@services/exceptions/capture-exception'
import { CircleAdd } from '@svg/icons'

const ApiResourceList = ({ createdAt, customFieldsToDisplay, definition, entryComponent: EntryComponent, onEntryClick, registerAt, withCloneOption = false, withCreateOption, withDeleteOption = false, withEditOption }: ApiResourceListProps) => {
  const { t: translateResource } = useTranslation('apiResources')
  const { data } = useUserMeQuery()
  // Filtering
  const hiddenFilters = ['page', 'prev_cursor', 'next_cursor']
  const filterDefinitions = definition.methods.list.definition.parameters.filter(param => !hiddenFilters.includes(param.name) && !param.name.includes('order') && !param.name.includes('pagination') && !param.name.includes('tag') && !param.name.includes('[]'))
  const filtersString = filterDefinitions.filter(item => {
    const schema = item.schema as OpenAPIV3.SchemaObject
    if (!schema.enum) {
      return item
    }

    return null
  })
  const filters = filtersString.map(filter => ({
    [filter.name]: ''
  }))

  // Ordering
  const orderingDefinitions = definition.methods.list.definition.parameters.filter(param => param.name.includes('order'))
  const orders = orderingDefinitions.map(order => {
    if (createdAt && order.name === 'order[createdAt]') {
      return {
        [order.name]: createdAt
      }
    } else if (registerAt && order.name === 'order[registerAt]') {
      return {
        [order.name]: registerAt
      }
    } else {
      return {
        [order.name]: ''
      }
    }
  })

  // Search Params
  const [searchParams, setSearchParams] = useSearchParams({
    page: '1',
    ...Object.assign({}, ...filters, ...orders)
  })

  // Pagination
  const setPageIndex = (index: number) => {
    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      page: `${index}`
    })
  }

  const {
    data: {
      'hydra:member': resourceEntries = [],
      'hydra:totalItems': totalItems = 0,
      'hydra:view': pagination = undefined
    } = {},
    isLoading,
    refetch
  } = useApiResourceListQuery({
    definition,
    parameters: searchParams
  })

  useEffect(() => {
    refetch().catch((error) => {
      console.error(error)
    })
  }, [searchParams])

  const reload = () => {
    refetch().catch((err) => {
      captureException(err as Error)
    })
  }

  const onReload = () => {
    reload()
  }

  const pageIndex = Number(searchParams.get('page') ?? '1')
  let fieldsToDisplay: Field[] = definition.methods.list.getFieldsToDisplay()
  if (customFieldsToDisplay?.length) {
    fieldsToDisplay = fieldsToDisplay.filter(item => customFieldsToDisplay.includes(item.name))

    // Reorder by props order
    fieldsToDisplay.sort((a, b) => {
      const indexA = customFieldsToDisplay.indexOf(a.name)
      const indexB = customFieldsToDisplay.indexOf(b.name)

      return indexA - indexB
    })
  }

  if (withCloneOption || withDeleteOption || withEditOption) {
    fieldsToDisplay.push({
      name: 'actions',
      properties: {
        type: 'string'
      }
    })
  }

  // Check if user is Super Admin to show roles in table

  const userIsSuperAdmin = !!data?.roles.find(role => role === Roles.ROLE_SUPER_ADMIN)
  if (!userIsSuperAdmin) {
    const rolesIndex = fieldsToDisplay.findIndex(field => field.name === 'roles')
    if (rolesIndex !== -1) {
      fieldsToDisplay.splice(rolesIndex, 1)
    }
  }

  const headers = fieldsToDisplay.map(field => field.name)

  const onCreateClick = () => {
    withCreateOption?.()
  }

  return (
    <>
      <div className='flex items-center mb-4 mx-4 sm:mx-6 md:mx-8 justify-between'>
        <ApiResourceListFilters filters={filterDefinitions} searchParams={searchParams} setSearchParams={setSearchParams} />

        {withCreateOption && (
          <ContextualButton icon={CircleAdd} onClick={onCreateClick}>
            {translateResource(`create.${definition.name}`)}
          </ContextualButton>
        )}
      </div>
      <Table
        headers={headers}
        isLoading={isLoading}
        ordering={orderingDefinitions}
        pageIndex={pageIndex}
        pagination={pagination}
        searchParams={searchParams}
        setPageIndex={setPageIndex}
        setSearchParams={setSearchParams}
        totalItems={totalItems}
      >
        {
          resourceEntries.map((entry, index) => {
            if (EntryComponent) {
              return (
                <EntryComponent
                  definition={definition}
                  entry={entry}
                  fieldsToDisplay={fieldsToDisplay}
                  key={index}
                  onClick={onEntryClick}
                />
              )
            } else {
              return (
                <ApiResourceListEntry
                  definition={definition}
                  entry={entry}
                  fieldsToDisplay={fieldsToDisplay}
                  key={index}
                  onClick={onEntryClick}
                  onReload={onReload}
                  withCloneOption={withCloneOption}
                  withDeleteOption={withDeleteOption}
                  withEditOption={withEditOption}
                />
              )
            }
          })
        }

        {!resourceEntries.length && (
          <tr className='text-center bg-gray-50'>
            <td className='p-6' colSpan={headers.length}>
              <div className='text-gray-900 text-sm'>{translateResource(`empty.${definition.name}`)}</div>

              <div className='text-gray-500 text-xs'>{translateResource(`empty.create${definition.name}`)}</div>
            </td>
          </tr>
        )}
      </Table>
    </>
  )
}

export default ApiResourceList
