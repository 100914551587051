import { useQuery, type UseQueryResult } from '@tanstack/react-query'

import { type FormattedApiListResponse } from '@interfaces/api/api'
import { type AudioTemplateCategoriesResponse, type AudioTemplateCategory } from '@interfaces/api/audio-templates'
import { type UserRequestParameters } from '@interfaces/api/request'
import useAxios from '@services/api/axios'
import { formatApiListResponse } from '@services/tools/api-resources/format-list-response'

const useAudioTemplateCategoriesList = (): UseQueryResult<FormattedApiListResponse<AudioTemplateCategory>> => {
  const { get } = useAxios()

  return useQuery({
    queryFn: async () => {
      return await get<UserRequestParameters, AudioTemplateCategoriesResponse>('api/audio-template-categories').then(result => formatApiListResponse<AudioTemplateCategory>(result))
    },
    queryKey: ['audio-template-categories'],
    retry: false
  })
}

export default useAudioTemplateCategoriesList
