import { MealplanCreate } from '@pages'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import ApiResource from '@components/api-resource'
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import Modal from '@components/modals/modal'
import SectionTitle from '@components/section-title/section-title'
import { type ApiPlatformEntity } from '@interfaces/api'
import { mealplansRoles } from '@services/roles/roles'
import { Calendar } from '@svg/icons'

const MealplansList = () => {
  const [open, setOpen] = useState(false)
  const { t: translateResource } = useTranslation('apiResources', { keyPrefix: 'create' })
  const { t: translateMealplan } = useTranslation('mealplan')
  const { t: bc } = useTranslation('breadcrumbs')

  const navigate = useNavigate()

  const onEntryClick = (entry: ApiPlatformEntity) => {
    navigate(`/mealplans/${entry.uid}`)
  }

  const onCreateClick = () => {
    setOpen(true)
  }

  const breadcrumbs = [
    { href: '', name: bc('mealplans') }
  ]

  return (
    <Layout description={translateMealplan('description')} title={translateMealplan('mealplans')}>
      <Guard acceptedRoles={mealplansRoles}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <SectionTitle description={translateMealplan('description')} icon={Calendar} title={translateMealplan('mealplans')} />

        <ApiResource createdAt={'desc'} name={'mealplans'} onClick={onEntryClick} withCloneOption withCreateOption={onCreateClick} withDeleteOption />

        <Modal center open={open} setOpen={setOpen} title={translateResource('mealplans')} >
          <MealplanCreate />
        </Modal>
      </Guard>
    </Layout>
  )
}

export default MealplansList
