import { useTranslation } from 'react-i18next'

import ApiResourceList from '@components/api-resource/api-resource-list'
import { type DataStoreListProps } from '@pages/configuration/components/data-store-list/data-store-list.interfaces'
import useApiResource from '@services/api/definition'
import { Settings } from '@svg/icons'

const DataStoreList = ({ onCreateClick, onEditClick }: DataStoreListProps) => {
  const definition = useApiResource('data-store')
  const { t: translateConfig } = useTranslation('configuration')

  return (
    <div className='mb-6 mx-auto px-4 sm:px-6 md:px-8 w-full'>

      <header className='flex flex-col lg:flex-row flex-none lg:items-center justify-between gap-4 lg:gap-8 pb-8'>
        <div className='flex gap-4 items-start'>
          <div className='p-2.5 bg-primary rounded-lg'>
            <Settings className='w-8 h-8 fill-white' />
          </div>

          <div className='flex flex-col'>
            <div className='font-bold text-gray-900 text-4xl flex gap-4 items-center'>
              {translateConfig('title')}
            </div>

            <div className='text-gray-500 text-xl font-light'>{translateConfig('description')}</div>
          </div>
        </div>
      </header>

      <div className='flex h-full flex-col max-h-[85vh]'>

        {definition && (
          <ApiResourceList
            definition={definition}
            withCreateOption={onCreateClick}
            withEditOption={onEditClick}
          />
        )}

      </div>

    </div>
  )
}

export default DataStoreList
