import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { type OpenAPIV3 } from 'openapi-types'
import { type ChangeEvent, type ReactElement, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { type ApiResourceListFiltersProps } from '@components/api-resource/api-resource-list-filters/index'
import { ChevronDown } from '@svg/icons'

const ApiResourceListFilters = ({ filters, searchParams, setSearchParams }: ApiResourceListFiltersProps) => {
  const selectRef = useRef<HTMLSelectElement>(null)
  const { t } = useTranslation('apiResources')
  const onChangeHandler = (filter: OpenAPIV3.ParameterObject) => (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    if (event.target.value === '') {
      searchParams.delete(filter.name)
      setSearchParams(searchParams)
    } else {
      setSearchParams({
        [filter.name]: event.target.value
      })
    }
  }

  const components: ReactElement[] = []
  filters.forEach(filter => {
    const schema = filter.schema as OpenAPIV3.SchemaObject
    if (schema.enum) {
      components.push(
        <div className='flex flex-col' key={filter.name}>
          <div className='flex items-center shadow shadow shadow-gray-200 rounded-lg relative bg-white'>
            <select
              className={`appearance-none block w-full py-2.5 min-w-[200px] pl-4 pr-10 rounded-lg focus:outline-none cursor-pointer ${selectRef?.current?.value ? 'text-gray-900' : 'text-gray-300'}`}
              id={filter.name}
              onChange={onChangeHandler(filter)}
              ref={selectRef}
            >
              <option value={''}>{`${t('filters.filterBy')} ${t(`filters.${filter.name}`)}`}</option>

              {schema.enum.map((entry: string) => (
                <option key={entry} value={entry}>{t(`filters.${entry}`)}</option>
              ))}
            </select>

            <ChevronDown className='w-6 h-6 flex-none absolute right-4 pointer-events-none' />
          </div>
        </div>
      )
    } else {
      components.push(
        <div className='flex items-center shadow shadow shadow-gray-200 rounded-lg px-4 bg-white' key={filter.name}>
          <MagnifyingGlassIcon className='w-6 h-6 text-gray-500' />

          <input
            className='appearance-none block w-full pl-3 py-2 placeholder-gray-300 placeholder:text-md focus:outline-none sm:text-md'
            id={filter.name}
            onChange={onChangeHandler(filter)}
            placeholder={`${t('labels.search')} ${t(`labels.${filter.name}`)}`}
            type='text'
          />
        </div>
      )
    }
  })

  return (
    <div className='flex items-center gap-4 w-auto'>
      {components}
    </div>
  )
}

export default ApiResourceListFilters
