import { useTranslation } from 'react-i18next'

import { type AnalyseDayAfterProps } from '@components/analyse-day/analyse-day-after/analyse-day-after-interfaces'
import PhotoContainer from '@components/photo-container/photo-container'
import useAnalyseDayQuery from '@services/api/analyse-day/use-analyse-day-query'

const AnalyseDayAfter = ({ dayAfter }: AnalyseDayAfterProps) => {
  const { t: translateAnalyseDay } = useTranslation('analysePeriods', { keyPrefix: 'day' })

  const { data } = useAnalyseDayQuery(dayAfter.uid)

  if (!data) {
    return null
  }

  const bodyPicturesAfter = [
    {
      imageSrc: data?.dayEvolution.bodyPhoto1?.url,
      name: translateAnalyseDay('frontPicture')
    },
    {
      imageSrc: data?.dayEvolution.bodyPhoto2?.url,
      name: translateAnalyseDay('profilePicture')
    },
    {
      imageSrc: data?.dayEvolution.bodyPhoto3?.url,
      name: translateAnalyseDay('backPicture')
    }
  ]

  return (
    <div className='bg-gray-100 rounded-lg px-4 pt-2 pb-4'>
      <div className='flex items-center gap-2'>
        <div className='font-medium text-gray-700'>{translateAnalyseDay('afterPicture')}</div>
      </div>

      <PhotoContainer pictureList={bodyPicturesAfter} title={translateAnalyseDay('bodyPicture')} />
    </div>
  )
}

export default AnalyseDayAfter
