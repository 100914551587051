import { useMutation, type UseMutationOptions, type UseMutationResult } from '@tanstack/react-query'

import { type MarkAsReadBody } from '@interfaces/api/chat'
import useApi from '@services/api/axios'

const useMarkAsReadChatMessages = (
  options: UseMutationOptions<string, unknown, MarkAsReadBody> = {}
): UseMutationResult<string, unknown, MarkAsReadBody> => {
  const { post } = useApi()

  return useMutation<string, unknown, MarkAsReadBody>({
    mutationFn: async (body: MarkAsReadBody) => await post<MarkAsReadBody, string>('api/chat-messages/mark-as-read', body),
    ...options
  })
}

export default useMarkAsReadChatMessages
