import { type Resource } from '@api-platform/api-doc-parser'
import { useQuery, type UseQueryResult } from '@tanstack/react-query'

import { type ApiPlatformEntity, type ApiPlatformListResponse } from '@interfaces/api/api'
import useAxios from '@services/api/axios'
import { formatApiListResponse } from '@services/tools/api-resources/format-list-response'

interface ResourceListParameters {
  definition: Resource
  parameters?: URLSearchParams
}

const useApiResourceListFormatQuery = <T extends ApiPlatformEntity>({ definition, parameters }: ResourceListParameters) => {
  const { get } = useAxios()

  const queryKey = [definition.name, parameters]
  const queryFn = async () => {
    return await get(`api/${definition.name}`, parameters).then(result => result)
  }
  const staleTime = 5 * 60 * 1000

  const { data, isFetching, refetch, ...useQueryVars }: UseQueryResult<ApiPlatformListResponse<T>> = useQuery({
    queryFn,
    queryKey,
    staleTime
  })

  return {
    data: data ? formatApiListResponse(data) : undefined,
    isFetching,
    refetch,
    ...useQueryVars
  }
}

export default useApiResourceListFormatQuery
