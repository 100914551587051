import {
  useMutation,
  type UseMutationResult
} from '@tanstack/react-query'

import { type CreateResourceItemParameters } from '@interfaces/api/definition/resource'
import useAxios from '@services/api/axios'

const useCreateEntry = ({ id, path }: CreateResourceItemParameters): UseMutationResult => {
  const { post } = useAxios()
  let url = `api/${path}`

  if (id) {
    url = url.replace('{uid}', id)
  }

  return useMutation({
    mutationFn: async (data) => await post<never, never>(url, data as never)
  })
}

export default useCreateEntry
