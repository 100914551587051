interface SearchMapField {
  path: string
  searchField: string
}
const SearchMap: Record<string, SearchMapField> = {
  video: {
    path: 'videos',
    searchField: 'originalName'
  }
}

export default SearchMap
