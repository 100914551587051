import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { type ToggleableEntityProps } from '@components/toggleable-entity/toggleable-entity-interfaces'
import { ChevronDown, ChevronUp } from '@svg/icons'

const ToggleableEntity = ({ children, defaultOpen = false }: ToggleableEntityProps) => {
  const { t: translateGeneral } = useTranslation('general')
  const [open, setOpen] = useState<boolean>(defaultOpen)

  const handleOpenClick = () => {
    setOpen(!open)
  }

  return (
    <div className={`overflow-hidden rounded-lg relative shadow border-1 border-gray-50 shadow-gray-200 mb-2 ${open ? 'max-h-full' : 'max-h-56'}`}>

      {children}

      {!open && (
        <button
          className='uppercase w-full pb-2 pt-6 text-center absolute bottom-0 bg-gray-100 flex items-center justify-center gap-2 z-10'
          onClick={handleOpenClick}
        >
          <ChevronDown className='w-5 h-5' />

          <span className='text-sm font-normal tracking-wider'>{translateGeneral('open')}</span>

          <ChevronDown className='w-5 h-5' />
        </button>
      )}

      {open && (
        <button
          className='uppercase w-full py-2 text-center flex items-center justify-center gap-2'
          onClick={handleOpenClick}
        >
          <ChevronUp className='w-5 h-5' />

          <span className='text-sm font-normal tracking-wider'>{translateGeneral('close')}</span>

          <ChevronUp className='w-5 h-5' />
        </button>
      )}
    </div>
  )
}

export default ToggleableEntity
