import { ErrorMessage, Field } from 'formik'

import { type FormTextFieldProps } from '@components/form-fields/form-text-field/index'

const FormTextField = ({ label, name, placeholder, required = false, type = 'text' }: FormTextFieldProps) => {
  return (
    <div className='flex flex-col w-full'>
      <label className='block font-medium text-gray-700' htmlFor={name}>{label}</label>

      <Field
        className='appearance-none block w-full mt-2 px-3 py-2 border-2 border-gray-100 rounded-lg placeholder-gray-300 focus:outline-none'
        name={name}
        placeholder={placeholder}
        required={required}
        type={type}
      />

      <ErrorMessage className='mt-2 text-xs text-red-600 font-medium' component='div' name={name} />
    </div>
  )
}

export default FormTextField
