import { useTranslation } from 'react-i18next'

import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import SectionTitle from '@components/section-title/section-title'
import UserPrograms from '@components/user/user-programs/user-programs'
import { programsRoles } from '@services/roles/roles'
import { Programs } from '@svg/icons'

const UserProgramsList = () => {
  const { t: translateProgram } = useTranslation('program', { keyPrefix: 'userPrograms' })
  const { t: bc } = useTranslation('breadcrumbs')

  const breadcrumbs = [
    { href: '', name: bc('user-programs') }
  ]

  return (
    <Layout description={translateProgram('description')} title={translateProgram('title')}>
      <Guard acceptedRoles={programsRoles}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <SectionTitle description={translateProgram('description')} icon={Programs} title={translateProgram('title')} />

        <div className='pl-4 pr-2 sm:pr:4 sm:pl-6 md:pr-6 md:pl-8 w-full sm:w-auto md:w-full mb-8'>
          <UserPrograms />
        </div>
      </Guard>
    </Layout>
  )
}

export default UserProgramsList
