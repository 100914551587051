import { type OpenAPIV3 } from 'openapi-types'
import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { type TableProps } from '@components/api-resource/api-resource-table/api-resource-table.interfaces'
import InputCyclingSelect from '@components/input-cycling-select'
import Pagination from '@components/pagination/pagination'
import SkeletonLoaderTable from '@components/skeleton-loader/skeleton-loader-table'

const Table = ({ children, headers, isLoading, ordering, pageIndex, pagination, searchParams, setPageIndex, setSearchParams, totalItems }: TableProps) => {
  const { t } = useTranslation('apiResources')
  const onOrderingChange = (orderingDefinition: OpenAPIV3.ParameterObject) => (newValue: string) => {
    setSearchParams({
      [orderingDefinition.name]: newValue
    })
  }

  const headersComponents: ReactElement[] = []
  headers.forEach(header => {
    // If column can be ordered, add control
    const orderingDefinition = ordering?.find(parameter => parameter.name.includes(header))
    let orderingControl = <></>
    if (orderingDefinition) {
      const schema = (orderingDefinition?.schema as OpenAPIV3.SchemaObject)
      if (schema.enum) {
        const values = schema.enum.map((entry: string) => ({
          key: entry,
          label: t(`filters.${entry}`)
        }))
        const currentKey = searchParams.get(orderingDefinition.name) ?? ''
        const key = currentKey !== '' ? currentKey : values[0].key
        const currentValue = { key, label: t(`filters.${key}`) }
        orderingControl = (
          <InputCyclingSelect
            currentValue={currentValue}
            onChange={onOrderingChange(orderingDefinition)}
            values={values}
          />
        )
      }
    }

    // Display column header
    headersComponents.push(
      <th className={`text-left ${orderingDefinition && orderingControl ? 'p-0 px-1' : 'p-4'}`} key={header}>
        <div className='flex items-start'>
          <div className={`flex items-center justify-between gap-2 font-medium ${orderingDefinition && orderingControl ? 'p-3' : 'p-0'}`}>
            {t(`labels.${header}`)}

            {orderingDefinition && orderingControl}
          </div>
        </div>
      </th>
    )
  })

  return (
    <div className='overflow-x-auto pl-4 pr-2 sm:pr:4 sm:pl-6 md:pr-6 md:pl-8 w-full sm:w-auto md:w-full mb-8 scrollbar-hide'>
      <div className='overflow-hidden shadow shadow-gray-200 border-1 border-gray-50 sm:rounded-lg my-4'>
        <table className='min-w-full divide-y'>
          <thead className='bg-gray-50'>
            <tr className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
              {headersComponents}
            </tr>
          </thead>

          <tbody>
            {isLoading
              ? (
                <SkeletonLoaderTable headers={headers} />
              )
              : children}
          </tbody>
        </table>
      </div>

      {!!pagination && pagination['hydra:first'] && (
        <div className='py-4'>
          <Pagination
            pageIndex={pageIndex}
            pagination={pagination}
            setPageIndex={setPageIndex}
            totalItems={totalItems}
          />
        </div>
      )}
    </div>
  )
}

export default Table
