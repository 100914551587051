import { useQuery, type UseQueryResult } from '@tanstack/react-query'

import { type ChatMessage } from '@interfaces/api/chat'
import useAxios from '@services/api/axios'

const useChatMessageQuery = (uid: string): UseQueryResult<ChatMessage> => {
  const { get } = useAxios()

  return useQuery({
    queryFn: async () => {
      return await get<undefined, ChatMessage>(`api/chat-messages/${uid}`).then(result => result)
    },
    queryKey: ['chat-message', uid]
  })
}

export default useChatMessageQuery
