import { XMarkIcon } from '@heroicons/react/24/outline'
import { Form, Formik } from 'formik'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import ApiResourceCreate from '@components/api-resource/api-resource-create'
import ButtonLoader from '@components/buttons/button-loader/button-loader'
import ContextualButton from '@components/buttons/contextual-button'
import Modal from '@components/modals/modal'
import ModalEditPlaylist from '@components/modals/modal-edit-playlist/modal-edit-playlist'
import {
  type AddModulePlaylistProps
} from '@components/program/module-entry/add-module-playlist/add-module-playlist-interfaces'
import { type Playlist } from '@interfaces/api/video'
import useApiResource from '@services/api/definition'
import useApiResourceListFormatQuery from '@services/api/resources/list-format-query'
import usePatchEntry from '@services/api/resources/patch-entry-query'
import { captureException } from '@services/exceptions/capture-exception'
import { CircleAdd, Delete, Search } from '@svg/icons'

const AddModulePlaylist = ({ module, onModuleChange, open, setOpen }: AddModulePlaylistProps) => {
  const { t: translateProgram } = useTranslation('program')
  const { t: translateVideo } = useTranslation('videos', { keyPrefix: 'playlist' })
  const { t: translateResource } = useTranslation('apiResources')

  const [titleFilter, setTitleFilter] = useState<string>('')
  const [playlistIri, setPlaylistIri] = useState<string>(module.playlist?.['@id'] ?? '')
  const [playlistCreateModal, setPlaylistCreateModal] = useState(false)
  const [playlistEditModal, setPlaylistEditModal] = useState(false)
  const filterRef = useRef<HTMLInputElement>(null)

  const { mutateAsync: editResourceEntry } = usePatchEntry({ id: module.uid, path: 'program-modules/{uid}' })
  const playlistDefinition = useApiResource('playlists')

  const path = {
    name: 'playlists?pagination=false',
    url: ''
  }

  const {
    data: {
      data: items
    } = {},
    refetch
  } = useApiResourceListFormatQuery<Playlist>({ definition: path })

  const handleClick = (entry: Playlist) => () => {
    setPlaylistIri(entry['@id'])
  }

  // Filters

  const filteredItems = items?.filter(item => item.label.toLowerCase().includes(titleFilter.toLowerCase()))

  const handleChange = (e) => {
    setTitleFilter(e.target.value)
  }

  const handleResetFilter = () => {
    setTitleFilter('')
    if (filterRef?.current) {
      filterRef.current.value = ''
    }
  }

  const renderPlaylistsList = () => {
    if (filteredItems?.length) {
      return (
        <>
          {filteredItems.map((item) => (
            <button className={`px-6 py-4 flex items-center gap-3 w-full rounded-lg ${playlistIri === item['@id'] ? 'bg-gray-100' : 'bg-white hover:bg-gray-50'}`} key={item.uid} onClick={handleClick(item)}>
              <div className='text-gray-700 text-sm'>
                {item.label}
              </div>
            </button>
          ))}
        </>
      )
    }

    return (
      <div className='text-center flex items-center justify-center h-full'>
        <div className='p-6'>
          <div className='text-gray-900 text-sm'>{translateResource('empty.playlists')}</div>

          <div className='text-gray-500 text-xs'>{translateResource('empty.createplaylists')}</div>
        </div>
      </div>
    )
  }

  // Forms

  const initialValues = {
    playlist: module.playlist?.['@id'] ?? ''
  }

  const onSubmit = async (values, { setSubmitting }) => {
    if (playlistIri) {
      const payload = {
        playlist: playlistIri
      }

      setSubmitting(true)

      try {
        await editResourceEntry(payload)
        setSubmitting(false)
        setOpen(false)
      } catch (e) {
        captureException(e as Error)
      } finally {
        onModuleChange()
      }
    }
  }

  const onDelete = async () => {
    const payload = {
      playlist: null
    }

    try {
      await editResourceEntry(payload)
      setOpen(false)
    } catch (e) {
      captureException(e as Error)
    } finally {
      onModuleChange()
    }
  }

  // Create/Edit playlists modal

  const handleCreate = () => {
    setPlaylistCreateModal(true)
  }

  const onCreate = () => {
    setPlaylistCreateModal(false)
    refetch().catch(captureException)
  }

  const handleEdit = () => {
    setPlaylistEditModal(true)
  }

  return (
    <Modal center open={open} setOpen={setOpen} size='medium' title={translateProgram('addPlaylist')}>
      <div className='px-4 py-3 flex gap-4 items-start'>
        <div className='flex flex-col gap-3'>
          <button className='border-2 border-gray-100 px-4 py-6 rounded-lg flex flex-col justify-center items-center text-center min-w-[224px]' onClick={handleCreate}>
            <CircleAdd />

            <div className='font-medium mt-1.5'>{translateResource('create.playlists')}</div>

            <div className='text-gray-500 text-xs'>{translateResource('create.playlistsDescription')}</div>

            <Modal center open={playlistCreateModal} setOpen={setPlaylistCreateModal} title={translateVideo('createPlaylist')}>
              {playlistDefinition && <ApiResourceCreate definition={playlistDefinition} onEdit={onCreate} />}
            </Modal>
          </button>

          {module.playlist && (
            <>
              <button
                className='border-2 border-gray-100 px-4 py-6 rounded-lg flex flex-col justify-center items-center text-center min-w-[224px]'
                onClick={handleEdit}
              >
                <CircleAdd />

                <div className='font-medium mt-1.5'>{translateResource('edit.playlists')}</div>

                <div className='text-gray-500 text-xs'>{translateResource('edit.playlistsDescription')}</div>

                <ModalEditPlaylist open={playlistEditModal} playlist={module.playlist} setOpen={setPlaylistEditModal} />
              </button>

              <div className='flex justify-center'>
                <ContextualButton icon={Delete} onClick={onDelete}>{translateVideo('removePlaylist')}</ContextualButton>
              </div>
            </>
          )}
        </div>

        <div className='flex flex-col gap-3 w-full'>
          <div className='font-medium -mb-2 text-gray-700'>{translateResource('labels.findPlaylist')}</div>

          <div className='flex items-center self-start shadow shadow shadow-gray-200 rounded-lg px-4 relative bg-white w-full'>
            <Search className='w-6 h-6 fill-gray-500' />

            <input
              className='appearance-none block w-full pl-3 py-2 placeholder-gray-300 placeholder:text-md focus:outline-none sm:text-md'
              id='title'
              onChange={handleChange}
              placeholder={`${translateResource('labels.search')} ${translateResource('labels.label')}`}
              ref={filterRef}
              type='text'
            />

            <button className={`absolute right-4 rounded-full group hover:bg-red-800 ${!titleFilter.length ? 'hidden' : 'flex'}`} onClick={handleResetFilter} >
              <XMarkIcon className='w-6 h-6 text-red-800 group-hover:text-white font-bold' />
            </button>
          </div>

          <div className='p-0.5 border-2 border-gray-100 rounded-lg h-60 overflow-scroll scrollbar-hide'>
            {renderPlaylistsList()}
          </div>

          <div>
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
              {({ isSubmitting }) => {
                return (
                  <div className='flex flex-col gap-2'>
                    <Form className='flex flex-col gap-4'>
                      <button
                        className={`flex self-end gap-2 items-center rounded-lg px-4 py-2 text-lg font-semibold tracking-wide text-white fill-white hover:bg-gray-600 hover:text-white ${!playlistIri ? 'cursor-not-allowed bg-gray-600' : 'cursor-pointer  bg-primary'}`}
                        disabled={isSubmitting || !playlistIri}
                        type='submit'
                      >
                        {isSubmitting
                          ? <ButtonLoader />
                          : (
                            <div className='flex items-center gap-2'>
                              {translateResource('actions.validate')}
                            </div>
                          )
                        }
                      </button>
                    </Form>
                  </div>
                )
              }}
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default AddModulePlaylist
