import lamejs from 'lamejstmp'

export const encodedAudio = async (blob) => {
  const buffer: Int8Array[] = []
  const array = await new Response(blob).arrayBuffer()
  const wavHdr = lamejs.WavHeader.readHeader(new DataView(array))

  const channels = 1
  const sampleRate = wavHdr.sampleRate ?? 48000
  const kbps = 128
  const mp3encoder = new lamejs.Mp3Encoder(channels, sampleRate, kbps)

  const samples = new Int16Array(array, wavHdr.dataOffset, wavHdr.dataLen / 2)
  const maxSamples = 1152
  for (let i = 0; i < samples.length; i += maxSamples) {
    const sampleChunk = samples.subarray(i, i + maxSamples)
    const mp3buf = mp3encoder.encodeBuffer(sampleChunk)
    if (mp3buf.length > 0) {
      buffer.push(mp3buf)
    }
  }

  const mp3flush = mp3encoder.flush()

  if (mp3flush.length > 0) {
    buffer.push(new Int8Array(mp3flush))
  }

  return new Blob(buffer, { type: 'audio/mp3' })
}
