import { PlusIcon, XCircleIcon } from '@heroicons/react/24/outline'
import { type UseMutationResult, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import ModalAddEntry from '@components/modals/modal-add-entry'
import ExerciseEntry from '@components/program/exercise-entry'
import {
  type UserProgramCourseExercise,
  type UserProgramEntry,
  type UserProgramExercisesProps
} from '@components/user-program-entry/user-program-entry-interfaces'
import { type ApiReponseError } from '@interfaces/api'
import { type ExercisesListEntry } from '@interfaces/api/exercise/exercise-entry'
import { Status } from '@interfaces/api/status'
import useApiResource from '@services/api/definition'
import {
  appendExercise,
  deleteUserProgramExercise, editUserProgramExercices
} from '@services/api/exercise/list-tools'
import useCreateEntry from '@services/api/resources/create-entry-query'
import { captureException } from '@services/exceptions/capture-exception'
import { useDraggableStore } from '@services/stores/draggable/draggable'

const UserProgramExercises = ({ courseExercises, courseUid }: UserProgramExercisesProps) => {
  const { t: translateProgram } = useTranslation('program')
  const { t: translateError } = useTranslation('errors')

  const { uid = '' } = useParams()
  const exerciseDefinition = useApiResource('exercises')
  const queryClient = useQueryClient()
  const { isDraggable } = useDraggableStore()
  const userProgram = queryClient.getQueryData<UserProgramEntry>([`api/user-programs/${uid}`])

  const [open, setOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>()

  const openExerciseAddModal = () => {
    setOpen(true)
  }

  const reload = () => {
    queryClient.refetchQueries({ queryKey: [`api/user-programs/${uid}`] }).catch((err) => {
      captureException(err as Error)
    })
  }

  const userProgramIri = `/api/user-programs/${uid}`
  const courseIri = `/api/program-courses/${courseUid}`

  const { mutateAsync: alterExercises } = useCreateEntry({ path: 'user-program-events/alter-exercises' })

  const onExerciseAdd = (exercisePayload: ExercisesListEntry) => {
    const formattedCourseExercises = courseExercises.map(ce => ({ ...ce, isWarmup: false }))
    const newExerciseList = appendExercise(exercisePayload, formattedCourseExercises)
    alterExercises({
      course: courseIri,
      courseExercises: newExerciseList,
      userProgram: userProgramIri
    }).then(() => {
      reload()
      setOpen(false)
    }).catch((e) => {
      const err = e as UseMutationResult<ApiReponseError>
      const errorCode = err.data?.errorCode ?? 'generic'
      setErrorMessage(translateError(errorCode))
    })
  }

  const onExerciseDelete = (position: number) => () => {
    const newExerciseList = deleteUserProgramExercise(position, courseExercises)
    alterExercises({
      course: courseIri,
      courseExercises: newExerciseList,
      userProgram: userProgramIri
    }).then(() => {
      reload()
    }).catch((err) => {
      captureException(err as Error)
    })
  }

  const onExerciseSubmit = (setSubmitting, values, exercise, setOpen) => {
    const newExerciseList = editUserProgramExercices(exercise.exercise.uid, exercise.position, values, courseExercises)
    alterExercises({
      course: courseIri,
      courseExercises: newExerciseList,
      userProgram: userProgramIri
    }).then(() => {
      reload()
      setOpen(false)
    }).catch((err) => {
      captureException(err as Error)
    }).finally(() => {
      setSubmitting(false)
    })
  }

  const exercisesCheck: UserProgramCourseExercise[] = []

  courseExercises?.forEach((exercise) => {
    if (exercise.setCount === 0 || (exercise.effortDuration === 0 && exercise.repetitionCount === 0)) {
      exercisesCheck.push(exercise)
    }
  })

  return (
    <>
      {!!exercisesCheck?.length && (
        <div className='absolute top-2 left-0 right-0 w-5 h-5 rounded-md mx-auto flex items-center justify-center group'>
          <XCircleIcon className='text-red-500' />

          <div className='group-hover:flex hidden w-[15vw] absolute text-sm -bottom-14 md:ml-auto bg-red-500 rounded-md px-2 py-1 text-white font-bold z-10'>{translateProgram('checkExercise')}</div>
        </div>
      )}

      <Droppable droppableId={courseUid}>
        {(provided) => (
          <div
            {...provided.droppableProps}
            className={`flex flex-col ${!courseExercises?.length ? 'pb-0' : 'pb-4'}`}
            ref={provided.innerRef}
          >
            {courseExercises?.length
              ? (
                <>
                  {courseExercises.map((exercise, index) => (
                    <Draggable draggableId={`${exercise.exercise.uid}-${courseUid}-${index}`} index={index} isDragDisabled={!isDraggable || userProgram?.status !== Status.STATUS_DRAFT} key={`${exercise.exercise.uid}-${courseUid}-${index}`}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={`rounded-lg p-1.5 bg-white ${snapshot.isDragging ? 'drop-shadow-md' : 'shadow-none'}`}
                        >
                          <ExerciseEntry
                            courseExercises={courseExercises}
                            errorMessage={errorMessage}
                            exercise={exercise}
                            onDelete={onExerciseDelete(exercise.position)}
                            onSubmitCallback={onExerciseSubmit}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                </>
              )
              : (
                <div className={`flex flex-col px-5 text-center ${userProgram?.status === Status.STATUS_DRAFT ? 'py-[14px]' : 'py-[30px]'}`}>
                  <div className='text-gray-900 text-sm'>{translateProgram('noExercise')}</div>

                  {userProgram?.status === Status.STATUS_DRAFT &&
                    <div className='text-gray-500 text-xs'>{translateProgram('addExercise')}</div>
                  }
                </div>
              )}

            {provided.placeholder}
          </div>
        )}
      </Droppable>

      {userProgram?.status === Status.STATUS_DRAFT && (
        <button className='w-full flex items-center justify-center bg-gray-100 rounded-lg p-1' onClick={openExerciseAddModal}>
          <PlusIcon className='w-4 h-4' />
        </button>
      )}

      {exerciseDefinition && (
        <ModalAddEntry
          definition={exerciseDefinition}
          errorMessage={errorMessage}
          onAdd={onExerciseAdd}
          open={open}
          setOpen={setOpen}
        />
      )}
    </>
  )
}

export default UserProgramExercises
