import { useQueryClient } from '@tanstack/react-query'
import { DragDropContext } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'

import UserProgramCourseEntry from '@components/user-program-entry/user-program-course-entry'
import { type UserProgramCoursesProps, type UserProgramEntry } from '@components/user-program-entry/user-program-entry-interfaces'
import { type DroppableType } from '@interfaces/api/droppable'
import { convertExercisesToPayload } from '@services/api/exercise/list-tools'
import useCreateEntry from '@services/api/resources/create-entry-query'
import { captureException } from '@services/exceptions/capture-exception'
import { useDraggableStore } from '@services/stores/draggable/draggable'
import { getReorderList } from '@services/tools/drag-reorder'

const UserProgramCourses = ({ courses, moduleUid, programUid }: UserProgramCoursesProps) => {
  const { t: translateProgram } = useTranslation('program')
  const { mutateAsync: alterExercises } = useCreateEntry({ path: 'user-program-events/alter-exercises' })
  const queryClient = useQueryClient()
  const { setIsDraggable } = useDraggableStore()
  const updateQueryCache = (updatedData, courseUid) => {
    queryClient.setQueryData<UserProgramEntry>([`api/user-programs/${programUid}`], (data) => {
      if (data) {
        const newData = { ...data }
        const moduleIndex = newData.modules.findIndex(module => module.uid === moduleUid)
        const module = newData.modules[moduleIndex]
        const courseIndex = module.courses.findIndex(course => course.uid === courseUid)
        const course = module.courses[courseIndex]
        course.courseExercises = updatedData

        return newData
      }
    })
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    const destination: DroppableType = result.destination
    const source: DroppableType = result.source

    // Prevent move on the same place
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return
    }

    setIsDraggable(false)

    const sourceData = courses.find(course => course.uid === source.droppableId)
    const destinationData = courses.find(course => course.uid === destination.droppableId)
    const userProgramIri = `/api/user-programs/${programUid}`
    const destinationCourseIri = `/api/program-courses/${destination.droppableId}`
    const sourceCourseIri = `/api/program-courses/${source.droppableId}`

    if (destination.droppableId === source.droppableId) {
      // Reorder exercises in the current course
      const newExercisesReorder = getReorderList(result.source.index, result.destination.index, sourceData?.courseExercises ?? [])
      const newExercisesList = newExercisesReorder.map(exercise => convertExercisesToPayload(exercise))
      updateQueryCache(newExercisesReorder, destination.droppableId)

      alterExercises({
        course: sourceCourseIri,
        courseExercises: newExercisesList,
        userProgram: userProgramIri
      }).catch((err) => {
        captureException(err as Error)
        // Reset cache on error
        updateQueryCache(sourceData?.courseExercises ?? [], source.droppableId)
      }).finally(() => {
        setIsDraggable(true)
      })
    } else {
      // Extract exerciseUid from custom exercise draggableId
      const exerciseUid = result.draggableId.split('-')[0]
      const reorderedExercise = sourceData?.courseExercises.find(item => item.exercise.uid === exerciseUid)

      if (reorderedExercise) {
        // Update source exercises list
        const sourceExercises = sourceData?.courseExercises ?? []
        const sourceList = [...sourceExercises]
        sourceList.splice(result.source.index, 1)
        const newSourceList = sourceList.map(exercise => convertExercisesToPayload(exercise))
        updateQueryCache(sourceList, source.droppableId)

        alterExercises({
          course: sourceCourseIri,
          courseExercises: newSourceList,
          userProgram: userProgramIri
        }).catch((err) => {
          captureException(err as Error)
          // Reset cache on error
          updateQueryCache(sourceExercises, source.droppableId)
        }).finally(() => {
          setIsDraggable(true)
        })

        // Update destination exercises list
        const destinationExercises = destinationData?.courseExercises ?? []
        const destinationList = [...destinationExercises]
        destinationList.splice(result.destination.index, 0, reorderedExercise)
        const newDestinationList = destinationList.map(exercise => convertExercisesToPayload(exercise))
        updateQueryCache(destinationList, destination.droppableId)

        alterExercises({
          course: destinationCourseIri,
          courseExercises: newDestinationList,
          userProgram: userProgramIri
        }).catch((err) => {
          captureException(err as Error)
          // Reset cache on error
          updateQueryCache(destinationExercises, source.droppableId)
        }).finally(() => {
          setIsDraggable(true)
        })
      }
    }
  }

  return (
    <>
      {courses?.length
        ? (
          <DragDropContext onDragEnd={onDragEnd}>
            <div className='flex overflow-x-scroll scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 scrollbar-rounded scrollbar-height gap-1 pb-2 items-start'>
              {courses.map((course, index) => (
                <UserProgramCourseEntry {...course} index={index + 1} key={course.uid} />
              ))}
            </div>
          </DragDropContext>
        )
        : (
          <div className='flex gap-1 items-start'>
            <div className='flex flex-col justify-start border border-gray-200 rounded-lg p-1 w-[250px]'>
              <div className='text-gray-900 text-sm px-5 py-[45px] text-center'>{translateProgram('noCourse')}</div>
            </div>
          </div>
        )}
    </>
  )
}

export default UserProgramCourses
