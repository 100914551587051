import { useQueryClient } from '@tanstack/react-query'
import { type ReactElement, useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'

import ApiResourceEdit from '@components/api-resource/api-resource-edit'
import Date from '@components/api-resource/resource-entry-fields/date/date'
import Duration from '@components/api-resource/resource-entry-fields/duration'
import ContextualButton from '@components/buttons/contextual-button'
import Modal from '@components/modals/modal'
import StatusTag from '@components/status-tag/status-tag'
import { type PlaylistVideoEntryProps } from '@components/video/playlist-video/playlist-video-interfaces'
import useApiResource from '@services/api/definition'
import useDeleteEntry from '@services/api/resources/delete-entry-query'
import { captureException } from '@services/exceptions/capture-exception'
import { useDraggableStore } from '@services/stores/draggable/draggable'
import { Delete, Edit } from '@svg/icons'

const PlaylistVideoEntry = ({ entry, fieldsToDisplay, index, onClick, playlistUid }: PlaylistVideoEntryProps) => {
  const { t: translateActions } = useTranslation('apiResources', { keyPrefix: 'actions' })
  const { isDraggable } = useDraggableStore()

  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [openEditModal, setOpenEditModal] = useState<boolean>(false)

  const definition = useApiResource('playlist-videos/{uid}')
  const resourceParameters = { id: entry.uid, path: 'playlist-videos/{uid}' }
  const { mutateAsync: deleteResourceEntry } = useDeleteEntry(resourceParameters)

  const queryClient = useQueryClient()
  const reloadPlaylist = () => {
    queryClient.refetchQueries({ queryKey: [`playlists/${playlistUid}/videos`] }).catch(captureException)
  }

  const onClickHandler = () => {
    if (onClick) {
      onClick(entry)
    }
  }

  const handleDeleteClick = (e) => {
    e.stopPropagation()
    setOpenDeleteModal(true)
  }

  const handleEditClick = (e) => {
    e.stopPropagation()
    setOpenEditModal(true)
  }

  const handleCancelClick = () => {
    setOpenDeleteModal(false)
  }

  const onEdit = () => {
    reloadPlaylist()
    setOpenEditModal(false)
  }

  const onDelete = () => {
    deleteResourceEntry(undefined).then(() => {
      reloadPlaylist()
      setOpenDeleteModal(false)
    }).catch(captureException)
  }

  const fields: ReactElement[] = []

  fieldsToDisplay.forEach((field, index) => {
    if (entry.video[field.name] !== undefined) {
      if (field.name === 'status') {
        fields.push(<StatusTag status={entry.video[field.name]} />)
      } else if (field.name === 'createdAt') {
        fields.push(<Date value={entry.video[field.name]} />)
      } else if (field.name === 'duration') {
        fields.push(<Duration value={entry.video[field.name]} />)
      } else if (field.name === 'tag') {
        fields.push(<div key={index}>{entry[field.name] ?? '-'}</div>)
      } else if (field.name === 'category') {
        fields.push(<div key={index}>{entry.video[field.name].name ?? '-'}</div>)
      } else {
        fields.push(<div key={index}>{entry.video[field.name] ?? '-'}</div>)
      }
    }
  })

  return (
    <Draggable draggableId={entry.uid} index={index} isDragDisabled={!isDraggable} key={entry.uid}>
      {(provided, snapshot) => (
        <tr
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={`bg-white hover:bg-gray-100 cursor-pointer group !top-auto !left-auto ${snapshot.isDragging ? 'drop-shadow-md flex justify-between' : 'shadow-none'}`}
          onClick={onClickHandler}
        >
          {fields.map((field, index) => (
            <td
              className='p-3 text-base'
              key={index}
            >
              {field}
            </td>
          ))}

          <td className='p-3 text-base'>
            <div className='flex items-center gap-4'>
              {/* Delete button */}

              <>
                <button className='flex items-center text-gray-700 fill-gray-700 group-hover:hover:fill-white gap-2 py-1 px-2 group-hover:hover:bg-red-500 group-hover:hover:border-red-500 group-hover:hover:text-white text-sm rounded-md hover:text-red-700 bg-gray-100 cursor-pointer hover:bg-white hover:border-red-700 border border-gray-100 group-hover:border-gray-900 group-hover:bg-white' onClick={handleDeleteClick}>
                  <Delete className='w-5 h-5 mx-auto' />

                  <span>{translateActions('delete')}</span>
                </button>

                <Modal center open={openDeleteModal} setOpen={setOpenDeleteModal} title={translateActions('deleteResource')}>
                  <div className='flex flex-col items-center p-8'>
                    <div className='text-center text-xl'>{translateActions('deleteConfirmation')}</div>

                    <div className='flex mt-6 gap-8'>
                      <ContextualButton onClick={onDelete} style='warning'>{translateActions('delete')}</ContextualButton>

                      <ContextualButton onClick={handleCancelClick}>{translateActions('cancel')}</ContextualButton>
                    </div>
                  </div>
                </Modal>
              </>

              {/* Edit button */}

              <>
                <button className='flex items-center gap-2 text-gray-700 fill-gray-700 group-hover:hover:fill-white py-1 px-2 group-hover:hover:bg-gray-800 group-hover:hover:text-white text-sm rounded-md cursor-pointer bg-gray-100 hover:bg-white hover:border-gray-900 border border-gray-100 group-hover:border-gray-900 group-hover:bg-white' onClick={handleEditClick}>
                  <Edit className='w-5 h-5 mx-auto' />

                  <span>{translateActions('edit')}</span>
                </button>

                <Modal center open={openEditModal} setOpen={setOpenEditModal} title={translateActions('editResource')}>
                  <div className='flex flex-col items-center px-4 py-3'>
                    {definition && <ApiResourceEdit definition={definition} id={entry.uid} onEdit={onEdit} />}
                  </div>
                </Modal>
              </>
            </div>
          </td>
        </tr>
      )}
    </Draggable>
  )
}

export default PlaylistVideoEntry
