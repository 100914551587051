import { type ReactElement } from 'react'

import Pagination from '@components/pagination/pagination'
import SkeletonLoaderTable from '@components/skeleton-loader/skeleton-loader-table'
import { type TableProps as Props } from '@components/table/table.interfaces'

const Table = ({ children, headers, isLoading, noScroll = false, pageIndex, pagination, setPageIndex, totalItems }: Props) => {
  const headersComponents: ReactElement[] = []
  headers.forEach(header => {
    // Display column header
    headersComponents.push(
      <th className='p-4 text-left' key={header}>
        <div className='flex items-center gap-2 text-base font-medium'>
          {header}
        </div>
      </th>
    )
  })

  return (
    <div className='shadow shadow-gray-200 border-1 border-gray-50 rounded-lg overflow-hidden'>
      <div className='w-screen sm:w-auto'>
        <div className={`min-w-min w-full table-wrp overflow-y-auto block ${!noScroll ? 'max-h-[60vh] scrollbar-hide' : ''}`}>
          <table className='w-full divide-y'>
            <thead className='bg-gray-50 text-gray-900 sticky top-0'>
              <tr>
                {headersComponents}
              </tr>
            </thead>

            <tbody className='overflow-auto scrollbar-hide bg-white'>
              {isLoading
                ? (
                  <SkeletonLoaderTable headers={headers} />
                )
                : children}
            </tbody>
          </table>
        </div>
      </div>

      {!!pagination && pagination['hydra:first'] && (
        <div className='p-4'>
          <Pagination
            pageIndex={pageIndex}
            pagination={pagination}
            setPageIndex={setPageIndex}
            totalItems={totalItems}
          />
        </div>
      )}
    </div>
  )
}

export default Table
