import ApiResourceCreate from '@components/api-resource/api-resource-create'
import useApiResource from '@services/api/definition'

interface IngredientCreateProps {
  onCreate: () => void
}

const IngredientCreate = ({ onCreate }: IngredientCreateProps) => {
  const definition = useApiResource('ingredients')

  const onCreateResult = () => {
    onCreate()
  }

  return (
    <div>
      {definition && <ApiResourceCreate definition={definition} onEdit={onCreateResult} />}
    </div>
  )
}

export default IngredientCreate
