import { type TabsProps } from '@components/tabs/tabs-interfaces'

const Tabs = ({ currentTab, setCurrentTab, tabs }: TabsProps) => {
  const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ')
  }

  const handleTabClick = (id: number) => () => {
    setCurrentTab(id)
  }

  return (
    <div className='bg-gray-100 p-1 rounded-lg'>
      <nav className='flex flex-wrap gap-1'>
        {tabs.map((tab) => (
          <button
            className={classNames(
              tab.id === currentTab
                ? 'bg-white'
                : 'hover:bg-white',
              'whitespace-nowrap py-2.5 px-4 font-medium rounded-lg'
            )}
            key={tab.name}
            onClick={handleTabClick(tab.id)}
          >
            <div className='flex gap-2 items-center'>
              {tab.name}
            </div>
          </button>
        ))}
      </nav>
    </div>
  )
}

export default Tabs
