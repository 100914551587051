import React from 'react'
import { useTranslation } from 'react-i18next'

import AddCoach from '@components/user/coach-profile/add-coach'
import { type CoachProfileProps } from '@components/user/coach-profile/coach-profile-interfaces'

const CoachProfile = ({ coach, userId }: CoachProfileProps) => {
  const { t: translateProfile } = useTranslation('user', { keyPrefix: 'profile' })

  const fullName = coach?.firstName && coach?.lastName ? `${coach.firstName} ${coach.lastName}` : ''

  return (
    <div className='group flex-1'>
      <div className='border-gray-100 border-l-2 flex px-10 relative'>

        <div className='absolute group-hover:visible invisible right-2 top-2'>
          <AddCoach userId={userId} />
        </div>

        <div className='flex flex-col '>
          <div className='text-2xl font-semibold flex items-center gap-4'>
            {translateProfile('coach')}
          </div>

          {coach
            ? (
              <div className='flex space-x-2 items-center mt-2'>
                {coach.photo
                  ? <img alt='preview' className='h-16 w-16 object-cover rounded-full object-center' src={coach.photo.url} />
                  : <div className='text-white text-2xl h-16 w-16 flex items-center justify-center bg-secondary rounded-full'>{fullName.charAt(0) ?? '-'}</div>
                }

                <div className='flex flex-col'>
                  <div className='text-xl font-medium'>{fullName}</div>

                  <div className='text-sm text-gray-500'>{coach?.email}</div>
                </div>
              </div>
            )
            : <div className='text-xl font-medium'>{translateProfile('noCoach')}</div>
          }
        </div>
      </div>
    </div>
  )
}

export default CoachProfile
