import { useTranslation } from 'react-i18next'

import ContextualButton from '@components/buttons/contextual-button'
import EmptyState from '@components/empty-state'
import MealplanWeekEntry from '@components/mealplan/mealplan-week-entry/mealplan-week-entry'
import { type MealplanWeeksProps } from '@components/mealplan/mealplan-weeks/mealplan-weeks-interfaces'
import { type MealplanWeek } from '@interfaces/api/mealplan'
import useCreateEntry from '@services/api/resources/create-entry-query'
import useItemChildrenQuery from '@services/api/resources/item-children'
import { captureException } from '@services/exceptions/capture-exception'
import { getIdList } from '@services/tools/api-resources/get-id-list'
import { CircleAdd } from '@svg/icons'

const MealplanWeeks = ({ mealplanId }: MealplanWeeksProps) => {
  const { t: translateMealplan } = useTranslation('mealplan')

  const {
    data: {
      data: mealplanWeeks
    } = {},
    isFetching,
    refetch
  } = useItemChildrenQuery<MealplanWeek>({
    itemId: mealplanId,
    path: 'mealplans/{uid}/weeks'
  })

  const weekIds = mealplanWeeks ? getIdList(mealplanWeeks) : []

  const reload = () => {
    refetch().catch((err) => {
      captureException(err as Error)
    })
  }

  const { mutateAsync: createResourceEntry } = useCreateEntry({ path: 'mealplan-weeks' })

  const onWeekAdd = () => {
    createResourceEntry({
      disable: false,
      mealplan: `/api/mealplans/${mealplanId}`
    }).then(() => {
      reload()
    }).catch(captureException)
  }

  const onWeekChange = () => {
    reload()
  }

  return (
    <div>
      {mealplanWeeks?.length && !isFetching
        ? (
          <div className='flex flex-col gap-2'>
            {mealplanWeeks.map((mealplanWeek, index) => (
              <MealplanWeekEntry
                idList={weekIds}
                key={mealplanWeek.uid}
                mealplanId={mealplanId}
                mealplanWeekId={mealplanWeek.uid}
                onClone={onWeekChange}
                onDelete={onWeekChange}
                onReorder={onWeekChange}
                position={index + 1}
              />
            ))}
          </div>
        )
        : (
          <EmptyState>
            <div className='flex flex-col'>
              <div className='text-gray-900 text-sm'>{translateMealplan('noWeek')}</div>

              <div className='text-gray-500 text-xs'>{translateMealplan('selectWeek')}</div>
            </div>
          </EmptyState>
        )}

      <div className='flex justify-end mt-2'>
        <ContextualButton icon={CircleAdd} onClick={onWeekAdd}>
          {translateMealplan('addWeek')}
        </ContextualButton>
      </div>
    </div>
  )
}

export default MealplanWeeks
