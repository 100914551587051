import MealplanHead from '@components/mealplan/mealplan-head/mealplan-head'
import { type MealplanProps } from '@components/mealplan/mealplan-interfaces'
import MealplanWeeks from '@components/mealplan/mealplan-weeks/mealplan-weeks'

const Mealplan = ({ data }: MealplanProps) => {
  const { uid } = data

  return (
    <div>
      <MealplanHead mealplan={data} />

      {/* Module list */}

      <div className='mt-12 mb-8'>
        <MealplanWeeks mealplanId={uid} />
      </div>
    </div>
  )
}

export default Mealplan
