import { type DurationProps } from '@components/api-resource/resource-entry-fields/duration/index'

const Duration = ({ value }: DurationProps) => {
  const string = new Date(value * 1000).toISOString().substring(11, 19)

  return (
    <div>
      {string}
    </div>
  )
}

export default Duration
