import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import FinishedTag from '@components/finished-tag/finished-tag'
import SetGoals from '@components/set-goals/set-goals'
import UserProgramCourses from '@components/user-program-entry/user-program-courses'
import { type UserProgramModuleEntryProps } from '@components/user-program-entry/user-program-module-entry.interfaces'
import { Roles } from '@interfaces/api/roles'
import useUserMeQuery from '@services/api/user/use-user-me-query'

const UserProgramModuleEntry = ({ module, position, programId, status }: UserProgramModuleEntryProps) => {
  const { t: translateProgram } = useTranslation('program')
  const moduleName = `${translateProgram('module')} ${position}`
  const [visible, setVisible] = useState<boolean>(position === 1)
  const { data: currentUser } = useUserMeQuery()
  const userIsSuperAdmin = !!currentUser?.roles.find(role => role === Roles.ROLE_SUPER_ADMIN)

  const onToggleClick = () => {
    setVisible(!visible)
  }

  return (
    <div className='p-1 bg-slate-200 rounded-lg'>
      <button className='flex items-center w-full group py-2 px-4  gap-2 text-gray-900 hover:cursor-pointer' onClick={onToggleClick}>

        <span className='font-medium'>{moduleName}</span>

        {module.finishedAt && <FinishedTag date={module.finishedAt} />}

        <SetGoals module={module} status={status} userProgram={programId} />

        {(process.env.NODE_ENV === 'development' || userIsSuperAdmin) && (
          <Link state={{
            moduleId: module.uid,
            moduleNumber: position,
            programId
          }} to='/notifications/new'
          >
            Envoyer notification
          </Link>
        )}
      </button>

      {visible && (
        <UserProgramCourses courses={module.courses} moduleUid={module.uid} programUid={programId} />
      )}
    </div>
  )
}

export default UserProgramModuleEntry
