import {
  BookOpenIcon,
  CheckIcon,
  DocumentDuplicateIcon,
  PencilSquareIcon,
  TrashIcon,
  UserIcon, XMarkIcon
} from '@heroicons/react/24/outline'
import React, { type ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { type ApiResourceListEntryProps } from '@components/api-resource/api-resource-list-entry/index'
import Date from '@components/api-resource/resource-entry-fields/date'
import Duration from '@components/api-resource/resource-entry-fields/duration'
import ContextualButton from '@components/buttons/contextual-button'
import ImageEmptyState from '@components/image-empty-state/image-empty-state'
import Modal from '@components/modals/modal'
import StatusTag from '@components/status-tag/status-tag'
import { type ApiPlatformEntity } from '@interfaces/api'
import { type Coach } from '@interfaces/api/coach'
import { type Image } from '@interfaces/api/image'
import { type Mealplan } from '@interfaces/api/mealplan'
import useCloneEntry from '@services/api/resources/clone-entry-query'
import useDeleteEntrySimple from '@services/api/resources/delete-entry-query-simple'
import { captureException } from '@services/exceptions/capture-exception'

const ApiResourceListEntry = <T extends ApiPlatformEntity>({ definition, entry, fieldsToDisplay, onClick, onReload, withCloneOption = false, withDeleteOption = false, withEditOption }: ApiResourceListEntryProps<T>) => {
  const { t } = useTranslation('apiResources')
  const { t: translateActions } = useTranslation('apiResources', { keyPrefix: 'actions' })
  const { t: translateCategory } = useTranslation('ingredient', { keyPrefix: 'category' })
  const { t: translateRoles } = useTranslation('user', { keyPrefix: 'roles' })
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [openCloneModal, setOpenCloneModal] = useState<boolean>(false)
  const { mutateAsync: deleteResourceEntry } = useDeleteEntrySimple()
  const { mutateAsync: cloneResourceEntry } = useCloneEntry(`api/${definition.url}/clone`)

  const fields: ReactElement[] = []
  const onClickHandler = () => {
    if (onClick) {
      onClick(entry)
    }
  }

  const handleDeleteClick = (e) => {
    e.stopPropagation()
    setOpenDeleteModal(true)
  }

  const handleCloneClick = (e) => {
    e.stopPropagation()
    setOpenCloneModal(true)
  }

  const handleEditClick = (e) => {
    e.stopPropagation()
    withEditOption?.(entry)
  }

  const handleCancelClick = () => {
    setOpenDeleteModal(false)
    setOpenCloneModal(false)
  }

  const onDeleteHandler = () => {
    let path = `${definition.url}`
    if (definition.name === 'experiences' && 'type' in entry) {
      path = `${entry.type as string}s`
    }
    deleteResourceEntry({ id: entry.uid, path: `${path}/{uid}` }).then(() => {
      onReload()
      setOpenDeleteModal(false)
    }).catch((err) => {
      captureException(err as Error)
    })
  }

  const onCloneHandler = () => {
    cloneResourceEntry({ original: entry['@id'] }).then(() => {
      onReload()
      setOpenCloneModal(false)
    }).catch((err) => {
      captureException(err as Error)
    })
  }

  fieldsToDisplay.forEach((field, index) => {
    if (entry[field.name] !== undefined) {
      if ('type' in field.properties) {
        if (field.properties.type === 'string') {
          if (field.properties.format === 'date-time' && entry[field.name]) {
            fields.push(<Date value={entry[field.name]} />)
          } else if (field.name === 'status') {
            fields.push(
              <StatusTag status={entry[field.name]} />
            )
          } else if (field.name === 'category' && definition.name === 'ingredients') {
            fields.push(<div key={index}>{translateCategory(entry[field.name]) ?? '-'}</div>)
          } else if (field.name === 'type' && definition.name === 'recipes') {
            fields.push(<div key={index}>{t(`labels.recipe.types.${entry[field.name]}`) ?? '-'}</div>)
          } else if (field.name === 'title') {
            fields.push(
              <div className='flex gap-4' key={index}>
                {'image' in entry && entry?.image
                  ? (
                    <div className='w-24 h-16 rounded-lg overflow-hidden bg-gray-500'>
                      <img alt='preview' className='w-full h-full object-cover' src={(entry.image as Image).url} />
                    </div>
                  )
                  : (
                    <ImageEmptyState />
                  )}

                <div className='flex flex-col justify-center'>
                  <div className='text-lg font-normal text-gray-700'>{entry[field.name]}</div>
                </div>
              </div>
            )
          } else if (field.name === 'name') {
            fields.push(<div className='font-normal' key={index}>{entry[field.name] ?? '-'}</div>)
          } else {
            fields.push(<div key={index}>{entry[field.name] ?? '-'}</div>)
          }
        }
        if (field.properties.type === 'integer' || field.properties.type === 'number') {
          if (field.name === 'duration') {
            fields.push(<Duration value={entry[field.name]} />)
          } else {
            fields.push(<div key={index}>{entry[field.name]}</div>)
          }
        }
        if (field.properties.type === 'boolean') {
          fields.push(
            <div key={index}>
              {entry[field.name]
                ? (
                  <div className='inline-block w-6 h-6 bg-green-100 rounded p-1.5'>
                    <CheckIcon className='w-3 h-3 text-green-700' />
                  </div>
                )
                : (
                  <div className='inline-block w-6 h-6 bg-gray-100 rounded p-1.5'>
                    <XMarkIcon className='w-3 h-3 text-gray-700' />
                  </div>
                )
              }
            </div>
          )
        }
        if (field.properties.type === 'array') {
          if (field.name === 'roles') {
            fields.push(<div key={index}>
              {entry[field.name].map(entry => {
                return <div key={entry}>{translateRoles(entry)}</div>
              })}
            </div>)
          } else if (field.name === 'data') {
            return fields.push(
              <pre>
                <div>{JSON.stringify(JSON.parse(entry[field.name]), null, 2)}</div>
              </pre>
            )
          } else {
            fields.push(<div key={index}>{(entry[field.name] as never[]).toString()}</div>)
          }
        }
      }

      if ('anyOf' in field.properties || '$ref' in field.properties) {
        if (entry[field.name]) {
          if (entry[field.name]['@type'] === 'Image') {
            if (entry[field.name]) {
              fields.push(
                <div key={index}>
                  <img alt='' className='w-12 h-12 object-cover rounded-full' src={(entry[field.name] as Image).url} />
                </div>
              )
            }
          } else if (entry[field.name]['@type'] === 'User') {
            const user: Coach = entry[field.name]
            const name = `${user.firstName} ${user.lastName}`
            fields.push(
              <div className='flex flex-nowrap items-center gap-2' key={index}>
                <div className='inline-block w-6 h-6 bg-gray-100 rounded p-1.5'>
                  <UserIcon className='w-3 h-3 text-gray-700' />
                </div>

                <div className='whitespace-nowrap'>{name}</div>
              </div>
            )
          } else if (entry[field.name]['@type'] === 'Mealplan') {
            fields.push(
              <div className='flex flex-nowrap items-center gap-2' key={index}>
                <div className='inline-block w-6 h-6 bg-gray-100 rounded p-1.5'>
                  <BookOpenIcon className='w-3 h-3 text-gray-700' />
                </div>

                <div className='whitespace-nowrap'>
                  {(entry[field.name] as Mealplan).title}
                </div>
              </div>
            )
          } else {
            fields.push(<div key={index}>{entry[field.name].name ?? entry[field.name]['@id']}</div>)
          }
        } else {
          fields.push(<div key={index}>-</div>)
        }
      }
    }

    if (field.name === 'actions') {
      fields.push(
        <div className='flex items-center gap-4' key={index}>
          {withCloneOption && (
            <>
              <button className='flex items-center gap-2 text-gray-700 fill-gray-700 group-hover:hover:fill-white py-1 px-2 group-hover:hover:bg-gray-800 group-hover:hover:text-white text-sm rounded-md cursor-pointer bg-gray-100 hover:bg-white hover:border-gray-900 border border-gray-100 group-hover:border-gray-900 group-hover:bg-white' onClick={handleCloneClick}>
                <DocumentDuplicateIcon className='w-5 h-5 mx-auto' />
              </button>

              <Modal center open={openCloneModal} setOpen={setOpenCloneModal} title={translateActions('duplicateResource')}>
                <div className='flex flex-col items-center p-8'>
                  <div className='text-center text-xl'>{translateActions('duplicateConfirmation')}</div>

                  <div className='flex mt-6 gap-8'>
                    <ContextualButton onClick={onCloneHandler}>
                      {translateActions('clone')}
                    </ContextualButton>

                    <ContextualButton onClick={handleCancelClick}>{translateActions('cancel')}</ContextualButton>
                  </div>
                </div>
              </Modal>
            </>
          )}

          {withEditOption && (
            <>
              <button className='flex items-center gap-2 text-gray-700 fill-gray-700 group-hover:hover:fill-white py-1 px-2 group-hover:hover:bg-gray-800 group-hover:hover:text-white text-sm rounded-md cursor-pointer bg-gray-100 hover:bg-white hover:border-gray-900 border border-gray-100 group-hover:border-gray-900 group-hover:bg-white' onClick={handleEditClick}>
                <PencilSquareIcon className='w-5 h-5 mx-auto' />
              </button>
            </>
          )}

          {withDeleteOption && (
            <>
              <button className='flex items-center text-gray-700 fill-gray-700 group-hover:hover:fill-white gap-2 py-1 px-2 group-hover:hover:bg-red-500 group-hover:hover:border-red-500 group-hover:hover:text-white text-sm rounded-md hover:text-red-700 bg-gray-100 cursor-pointer hover:bg-white hover:border-red-700 border border-gray-100 group-hover:border-gray-900 group-hover:bg-white' onClick={handleDeleteClick}>
                <TrashIcon className='w-5 h-5 mx-auto' />
              </button>

              <Modal center open={openDeleteModal} setOpen={setOpenDeleteModal} title={translateActions('deleteResource')}>
                <div className='flex flex-col items-center p-8'>
                  <div className='text-center text-xl'>{translateActions('deleteConfirmation')}</div>

                  <div className='flex mt-6 gap-8'>
                    <ContextualButton onClick={onDeleteHandler} style='warning'>{translateActions('delete')}</ContextualButton>

                    <ContextualButton onClick={handleCancelClick}>{translateActions('cancel')}</ContextualButton>
                  </div>
                </div>
              </Modal>
            </>
          )}
        </div>
      )
    }
  })

  return (
    <tr
      className='bg-white hover:bg-gray-100 cursor-pointer group'
      onClick={onClickHandler}
    >
      {fields.map((field, index) => (
        <td
          className='p-3 text-base'
          key={index}
        >
          {field}
        </td>
      ))}
    </tr>
  )
}

export default ApiResourceListEntry
