import { type TableEntryProps } from '@components/table/table-data/table-entry.interfaces'

const TableEntry = ({ children, onClick }: TableEntryProps) => {
  return (
    <tr className='bg-white hover:bg-gray-100 cursor-pointer' onClick={onClick}>
      {children}
    </tr>
  )
}

export default TableEntry
