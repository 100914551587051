import analysePeriods from '@services/translations/en/analyse-periods.json'
import apiResources from '@services/translations/en/api-resources.json'
import breadcrumbs from '@services/translations/en/breadcrumbs.json'
import configuration from '@services/translations/en/configuration.json'
import errors from '@services/translations/en/errors.json'
import exercise from '@services/translations/en/exercise.json'
import general from '@services/translations/en/general.json'
import ingredient from '@services/translations/en/ingredient.json'
import program from '@services/translations/en/program.json'
import status from '@services/translations/en/status.json'
import user from '@services/translations/en/user.json'
import videos from '@services/translations/en/videos.json'

const translation = {
  analysePeriods,
  apiResources,
  breadcrumbs,
  configuration,
  errors,
  exercise,
  general,
  ingredient,
  program,
  status,
  user,
  videos
}

export default translation
