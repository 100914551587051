import classNames from 'classnames'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import DropdownMenu from '@components/dropdown-menu/dropdown-menu'
import ModalDeleteEntry from '@components/modals/modal-delete-entry/modal-delete-entry'
import { type CourseEntryProps } from '@components/program/course-entry/index'
import ExerciseList from '@components/program/exercise-list'
import { type CourseEntry as Course } from '@interfaces/api/course'
import { Status } from '@interfaces/api/status'
import useAxios from '@services/api/axios'
import useApiResource from '@services/api/definition'
import useCloneEntry from '@services/api/resources/clone-entry-query'
import usePatchEntry from '@services/api/resources/patch-entry-query'
import { captureException } from '@services/exceptions/capture-exception'

const CourseEntry = ({ courseEntry, idList, onClone, onDelete, onSetOptionnal, position }: CourseEntryProps) => {
  const { t: translateDays } = useTranslation('calendar', { keyPrefix: 'days' })
  const { patch } = useAxios()

  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const definition = useApiResource('program-courses/{uid}')
  const { mutateAsync: cloneResourceEntry } = useCloneEntry('api/program-courses/clone')
  const { mutateAsync: updateCourseEntry } = usePatchEntry({ id: courseEntry.uid, path: 'program-courses/{uid}' })

  const onDeleteHandler = () => {
    onDelete()
  }
  const onSetOptionnalHandler = () => {
    // UPDATE ENTRY
    updateCourseEntry({ isOptional: !courseEntry.isOptional }).then(() => {
      onSetOptionnal()
    }).catch(captureException)
  }

  const onSetRestHandler = () => {
    // UPDATE ENTRY
    updateCourseEntry({ isRest: !courseEntry.isRest }).then(() => {
      onSetOptionnal()
    }).catch(captureException)
  }

  const onCloneHandler = async () => {
    try {
      const result = await cloneResourceEntry({ original: courseEntry['@id'] }) as Course

      if (result) {
        const url = `api/program-courses/${result.uid}`
        await patch(url, { status: Status.STATUS_PUBLISHED }).catch(captureException)
      }
      onClone()
    } catch (err) {
      captureException(err as Error)
    }
  }

  const handleDeleteClick = () => {
    setOpenDeleteModal(true)
  }

  const getDayByIndex = (index) => {
    switch (index) {
      case 1 : return translateDays('monday')
      case 2 : return translateDays('tuesday')
      case 3 : return translateDays('wednesday')
      case 4 : return translateDays('thursday')
      case 5 : return translateDays('friday')
      case 6 : return translateDays('saturday')
      case 7 : return translateDays('sunday')
      default: return null
    }
  }

  return (
    <div className={classNames({
      'bg-gray-400': courseEntry.isOptional || courseEntry.isRest,
      'bg-white': !courseEntry.isOptional && !courseEntry.isRest,
      'rounded-lg p-1 relative w-[250px]': true
    })}
    >
      <div className='group px-3 py-1 flex items-start gap-4'>
        <div className='flex gap-2'>
          <div className={classNames({
            'font-medium': true,
            'text-gray-700': !courseEntry.isOptional && !courseEntry.isRest,
            'text-white': courseEntry.isOptional || courseEntry.isRest
          })}
          >
            {`${getDayByIndex(position)} ${courseEntry.isOptional ? ' - Optionnel' : ''} ${courseEntry.isRest ? ' - Repos' : ''}`}
          </div>
        </div>

        <div className='ml-auto flex flex-col items-end gap-1'>
          <div className='flex gap-1'>

            <DropdownMenu items={[
              { enabled: true, label: courseEntry.isOptional ? 'Désactiver optionnel' : 'Activer optionnel', onClick: onSetOptionnalHandler },
              { enabled: true, label: courseEntry.isRest ? 'Désactiver repos' : 'Activer repos', onClick: onSetRestHandler },
              { enabled: idList.length < 7, label: 'Cloner', onClick: onCloneHandler },
              { enabled: true, label: 'Supprimer', onClick: handleDeleteClick }
            ]}
            />

            {definition && (
              <ModalDeleteEntry
                definition={definition}
                entryId={courseEntry.uid}
                onDelete={onDeleteHandler}
                open={openDeleteModal}
                setOpen={setOpenDeleteModal}
              />
            )}
          </div>
        </div>
      </div>

      <ExerciseList courseId={courseEntry.uid} />
    </div>
  )
}

export default CourseEntry
