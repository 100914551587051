import { useTranslation } from 'react-i18next'

import EmptyState from '@components/empty-state'
import FinishedTag from '@components/finished-tag/finished-tag'
import ToggleableEntity from '@components/toggleable-entity/toggleable-entity'
import UserMealplanDays from '@components/user-mealplan-entry/user-mealplan-days/user-mealplan-days'
import {
  type UserMealplanWeeksProps
} from '@components/user-mealplan-entry/user-mealplan-weeks/user-mealplan-weeks-interfaces'

const UserMealplanWeeks = ({ mealplanWeeks }: UserMealplanWeeksProps) => {
  const { t: translateMealplan } = useTranslation('mealplan')

  return (
    <div>
      {mealplanWeeks?.length
        ? (
          <div className='flex flex-col gap-2'>
            {mealplanWeeks.map((mealplanWeek, index) => {
              const weekName = `${translateMealplan('week')} ${index + 1}`

              return (
                <ToggleableEntity defaultOpen={index === 0} key={index}>
                  <div className='p-1'>
                    <div className='py-2 px-4 flex items-center gap-2 text-gray-700'>
                      <span className='font-medium'>{weekName}</span>

                      {mealplanWeek.finishedAt && <FinishedTag date={mealplanWeek.finishedAt} />}
                    </div>

                    <UserMealplanDays mealplanDays={mealplanWeek.days} />
                  </div>
                </ToggleableEntity>
              )
            })}
          </div>
        )
        : (
          <EmptyState>
            <div className='flex flex-col'>
              <div className='text-gray-900 text-sm'>{translateMealplan('noWeek')}</div>

              <div className='text-gray-500 text-xs'>{translateMealplan('selectWeek')}</div>
            </div>
          </EmptyState>
        )}

    </div>
  )
}

export default UserMealplanWeeks
