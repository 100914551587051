import { ChevronRightIcon, FireIcon, PlusCircleIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import ApiResourceOrder from '@components/api-resource/api-resource-order'
import ContextualButton from '@components/buttons/contextual-button'
import DeleteButton from '@components/buttons/delete-button'
import DropdownMenu from '@components/dropdown-menu/dropdown-menu'
import MealplanDays from '@components/mealplan/mealplan-days/mealplan-days'
import { type MealplanWeekEntryProps } from '@components/mealplan/mealplan-week-entry/mealplan-week-entry-interfaces'
import Modal from '@components/modals/modal'
import ModalDeleteEntry from '@components/modals/modal-delete-entry/modal-delete-entry'
import ModalSearch from '@components/modals/modal-search/modal-search'
import { type MealplanWeek } from '@interfaces/api/mealplan'
import useApiResource from '@services/api/definition'
import useCloneEntry from '@services/api/resources/clone-entry-query'
import useApiResourceItemQuery from '@services/api/resources/item-query'
import usePutEntry from '@services/api/resources/put-entry-query'
import { captureException } from '@services/exceptions/capture-exception'

const MealplanWeekEntry = ({ idList, mealplanId, mealplanWeekId, onClone, onDelete, onReorder, position }: MealplanWeekEntryProps) => {
  const { t: translateMealplan } = useTranslation('mealplan')
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [openRecipeModal, setOpenRecipeModal] = useState<boolean>(false)
  const [openSearchModal, setOpenSearchModal] = useState<boolean>(false)
  const { mutateAsync: cloneResourceEntry } = useCloneEntry('api/mealplan-weeks/clone')
  const { mutateAsync: patchRecipe } = usePutEntry(`api/mealplan-weeks/${mealplanWeekId}`)
  const definition = useApiResource('mealplan-weeks/{uid}')
  const weekName = `${translateMealplan('week')} ${position}`
  const [visible, setVisible] = useState<boolean>(position === 1)
  const recipesDefinition = useApiResource('recipes')

  const {
    data: mealplanWeek,
    refetch
  } = useApiResourceItemQuery<MealplanWeek>({
    id: mealplanWeekId,
    path: 'mealplan-weeks/{uid}'
  })

  const onDeleteHandler = () => {
    onDelete()
  }

  const onReorderHandler = () => {
    onReorder()
  }

  const onToggleClick = () => {
    setVisible(!visible)
  }

  const onCloneHandler = async () => {
    try {
      if (mealplanWeek) {
        await cloneResourceEntry({ original: mealplanWeek['@id'] })
        onClone()
      }
    } catch (err) {
      captureException(err as Error)
    }
  }

  const handleDeleteClick = () => {
    setOpenDeleteModal(true)
  }

  const onRecipeClick = () => {
    setOpenRecipeModal(true)
  }

  const onRecipeAddClick = () => {
    setOpenRecipeModal(false)
    setOpenSearchModal(true)
  }

  const renderRow = (active, entry) => (
    <>
      <FireIcon
        aria-hidden='true'
        className={classNames('h-6 w-6 flex-none', active ? 'text-white' : 'text-gray-500')}
      />
      <span className='ml-3 flex-auto truncate'>{entry.title}</span>
      {active && <ChevronRightIcon className='ml-3 flex-none text-gray-400 h-6 w-6' />}
    </>
  )

  const onRecipeSelected = async (recipe) => {
    if (mealplanWeek) {
      const isRecipeAlreadyAdded = mealplanWeek.recipes.some(r => r['@id'] === recipe['@id'])

      if (!isRecipeAlreadyAdded) {
        await patchRecipe({
          recipes: [
            ...Object.values(mealplanWeek.recipes).map(recipe => recipe['@id']),
            recipe['@id']
          ]
        }).then(async () => await refetch())
      }

      setOpenSearchModal(false)
      setOpenRecipeModal(true)
    }
  }

  const onDeleteRecipe = async (recipeId) => {
    try {
      const updatedRecipes = mealplanWeek?.recipes.filter(recipe => recipe['@id'] !== recipeId)
      await patchRecipe({ recipes: updatedRecipes?.map(recipe => recipe['@id']) })
        .then(async () => await refetch())
    } catch (err) {
      captureException(err as Error)
    }
  }

  const handleDeleteRecipe = async (recipe) => {
    await onDeleteRecipe(recipe['@id'])
  }

  const weekOrderPath = `mealplans/${mealplanId}/weeks/change-order`

  if (!mealplanWeek) {
    return null
  }

  return (
    <div
      className='p-1 bg-slate-200 rounded-lg'
    >
      <button className='flex items-center w-full group py-2 px-4  gap-2 text-gray-900 hover:cursor-pointer' onClick={onToggleClick}>
        <span className='font-medium'>{weekName}</span>

        <div className='ml-2'>
          {definition && (
            <ApiResourceOrder
              id={mealplanWeek['@id']}
              idList={idList}
              onReorder={onReorderHandler}
              resourcePath={weekOrderPath}
            />
          )}
        </div>

        <div className='ml-auto flex gap-1'>

          <DropdownMenu items={[
            { label: 'Associer des recettes', onClick: onRecipeClick },
            { label: 'Cloner', onClick: onCloneHandler },
            { label: 'Supprimer', onClick: handleDeleteClick }
          ]}
          />

          {definition && (
            <ModalDeleteEntry
              definition={definition}
              entryId={mealplanWeek.uid}
              onDelete={onDeleteHandler}
              open={openDeleteModal}
              setOpen={setOpenDeleteModal}
            />
          )}
        </div>

      </button>

      {visible && (
        <MealplanDays mealplanWeekId={mealplanWeek.uid} />
      )}

      {openRecipeModal && (
        <Modal center open={openRecipeModal} setOpen={setOpenRecipeModal} size={'large'} title='Recettes associées'>
          <div className='max-w-full p-4 overflow-hidden bg-white rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300 ease-in-out'>
            <div className='flex mb-10 space-x-4'>
              {mealplanWeek.recipes?.map((recipe, index) => (
                <div className='group flex flex-col relative'>

                  <div className='absolute top-2 right-2 hidden group-hover:block'>
                    <DeleteButton classNames='border-red-500 !bg-red-500 text-white' onClick={() => {
                      handleDeleteRecipe(recipe).catch(captureException)
                    }}
                    />
                  </div>

                  {recipe.image
                    ? (
                      <img
                        alt={recipe.title}
                        className='w-40 object-cover object-center rounded-lg'
                        src={recipe.image.url}
                      />
                    )
                    : (
                      <img
                        alt='Placeholder'
                        className='w-40 object-cover object-center rounded-lg'
                        src={`https://via.placeholder.com/500x300?text=${encodeURIComponent(recipe.title)}`}
                      />
                    )}

                  <div className='py-4'>
                    <h3 className='text-lg font-semibold text-gray-800 truncate'>{recipe.title}</h3>
                  </div>
                </div>
              ))}
            </div>

            <ContextualButton icon={PlusCircleIcon} onClick={onRecipeAddClick}>
              Ajouter une recette
            </ContextualButton>
          </div>
        </Modal>
      )}

      {recipesDefinition && (
        <ModalSearch definition={recipesDefinition} onSelect={onRecipeSelected} open={openSearchModal} renderRow={renderRow} searchTerm={'title'} setOpen={setOpenSearchModal} />
      )}
    </div>
  )
}

export default MealplanWeekEntry
