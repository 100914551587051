import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import ApiResource from '@components/api-resource'
import ApiResourceCreate from '@components/api-resource/api-resource-create'
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import Modal from '@components/modals/modal'
import ModalEditPlaylist from '@components/modals/modal-edit-playlist/modal-edit-playlist'
import SectionTitle from '@components/section-title/section-title'
import { type Playlist } from '@interfaces/api/video'
import useApiResource from '@services/api/definition'
import { captureException } from '@services/exceptions/capture-exception'
import { playlistsRoles } from '@services/roles/roles'
import { Camera } from '@svg/icons'

const Playlists = () => {
  const { t: translateVideo } = useTranslation('videos', { keyPrefix: 'playlist' })
  const { t: bc } = useTranslation('breadcrumbs')

  const [playlistModal, setPlaylistModal] = useState(false)
  const [playlistEditModal, setPlaylistEditModal] = useState(false)
  const [currentPlaylist, setCurrentPlaylist] = useState<Playlist>()

  const queryClient = useQueryClient()
  const reloadPlaylists = () => {
    queryClient.refetchQueries({ queryKey: ['playlists'] }).catch(captureException)
  }

  const breadcrumbs = [
    { href: '', name: bc('playlists') }
  ]

  const handleCreatePlaylist = () => {
    setPlaylistModal(true)
  }

  const playlistDefinition = useApiResource('playlists')

  const onCreate = () => {
    reloadPlaylists()
    setPlaylistModal(false)
  }

  const onEntryClick = (entry) => {
    setCurrentPlaylist(entry)
    setPlaylistEditModal(true)
  }

  return (
    <Layout description={translateVideo('description')} title={translateVideo('title')}>
      <Guard acceptedRoles={playlistsRoles}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <SectionTitle description={translateVideo('description')} icon={Camera} title={translateVideo('title')} />

        <Modal center open={playlistModal} setOpen={setPlaylistModal} title={translateVideo('createPlaylist')}>
          {playlistDefinition && <ApiResourceCreate definition={playlistDefinition} onEdit={onCreate} />}
        </Modal>

        <ApiResource createdAt={'desc'} name={'playlists'} onClick={onEntryClick} withCreateOption={handleCreatePlaylist} withDeleteOption />

        <>
          {currentPlaylist &&
            <ModalEditPlaylist open={playlistEditModal} playlist={currentPlaylist} setOpen={setPlaylistEditModal} />
          }
        </>
      </Guard>
    </Layout>
  )
}

export default Playlists
