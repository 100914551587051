import { Popover, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { SketchPicker } from 'react-color'
import { useTranslation } from 'react-i18next'

import { type ColorPickerButtonProps } from '@components/color-picker-button/color-picker-button-interfaces'

const ColorPickerButton = ({ color, onChange, setColor }: ColorPickerButtonProps) => {
  const { t: translateTheme } = useTranslation('theme')

  const handleChange = (newColor) => {
    setColor(newColor.hex)
  }

  const onChangeClick = () => {
    onChange()
  }

  return (
    <Popover className='relative flex items-center'>
      <Popover.Button className='inline-flex items-center gap-x-1 text-sm font-semibold p-2 border-2 rounded-md leading-6 bg-white text-gray-900' >
        <div className='w-12 h-6' style={{ backgroundColor: color }} />
      </Popover.Button>

      <Transition
        as={Fragment}
        enter='transition ease-out duration-200'
        enterFrom='opacity-0 translate-x-0'
        enterTo='opacity-100 translate-x-1'
        leave='transition ease-in duration-150'
        leaveFrom='opacity-100 translate-x-1'
        leaveTo='opacity-0 translate-x-0'
      >
        <Popover.Panel className='absolute left-full z-10 mt-6 ml-4 flex flex-col w-screen max-w-min translate-y-1 px-4'>
          <div className='shrink rounded-md border-2 bg-gray-100 p-4 text-sm font-semibold leading-6 text-gray-900 shadow-lg'>
            <SketchPicker color={color} disableAlpha onChange={handleChange} />

            <div className='mt-2 flex justify-center'>
              <Popover.Button className='flex gap-2 items-center rounded-lg px-4 py-2 text-lg font-semibold tracking-wide bg-primary text-white fill-white hover:bg-gray-600 hover:text-white' onClick={onChangeClick}>
                {translateTheme('confirm')}
              </Popover.Button>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}

export default ColorPickerButton
