import { type UseMutationResult, useQueryClient } from '@tanstack/react-query'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import ResourceHead from '@components/api-resource/resource-head/resource-head'
import ButtonLoader from '@components/buttons/button-loader/button-loader'
import ContextualButton from '@components/buttons/contextual-button'
import Modal from '@components/modals/modal'
import { type UserMealplanHeadProps } from '@components/user-mealplan-entry/user-mealplan-entry-interfaces'
import { type ApiReponseError } from '@interfaces/api'
import { Status } from '@interfaces/api/status'
import useCreateEntry from '@services/api/resources/create-entry-query'
import usePutEntry from '@services/api/resources/put-entry-query'
import { captureException } from '@services/exceptions/capture-exception'
import { Edit, Lock } from '@svg/icons'

const UserMealplanHead = ({ createdAt, mealplan, status, uid, userMealplan }: UserMealplanHeadProps) => {
  const { description, image, title } = mealplan
  const { t: translateActions } = useTranslation('apiResources', { keyPrefix: 'actions' })
  const { t: translateLabel } = useTranslation('apiResources', { keyPrefix: 'labels' })
  const { t: translateError } = useTranslation('errors')

  const [publishModal, setPublishModal] = useState(false)
  const [finishModal, setFinishModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>()

  const { mutateAsync: publishEntry } = usePutEntry(`/api/user-mealplans/${uid}/publish`)
  const { mutateAsync: finishEntry } = useCreateEntry({ path: 'user-mealplan-events/force-finish' })
  const queryClient = useQueryClient()

  const reload = () => {
    queryClient.refetchQueries({ queryKey: [`api/user-mealplans/${uid}`] }).catch(captureException)
    queryClient.refetchQueries({ queryKey: ['user-mealplans'] }).catch(captureException)
  }

  const onPublishClick = () => {
    setPublishModal(true)
  }

  const { handleSubmit, isSubmitting, setFieldValue, setSubmitting } = useFormik({
    initialValues: { startAt: '' },
    onSubmit: async (values) => {
      setSubmitting(true)

      if (values.startAt) {
        try {
          await publishEntry({ startAt: values.startAt })
          reload()
          setPublishModal(false)
        } catch (e) {
          const err = e as UseMutationResult<ApiReponseError>
          const errorCode = err.data?.errorCode ?? 'generic'
          setErrorMessage(translateError(errorCode))
        }
      }
    }
  })

  const handleChange = async (e) => {
    await setFieldValue('startAt', e.target.value)
  }

  const onFinishClick = () => {
    setFinishModal(true)
  }

  const onFinishHandler = async () => {
    try {
      await finishEntry({
        userMealplan
      })
      reload()
      setFinishModal(false)
    } catch (e) {
      const err = e as UseMutationResult<ApiReponseError>
      const errorCode = err.data?.errorCode ?? 'generic'
      setErrorMessage(translateError(errorCode))
    }
  }

  const onCancelHandler = () => {
    setPublishModal(false)
    setFinishModal(false)
  }

  return (
    <div className='flex justify-between flex-col lg:flex-row gap-4'>
      <ResourceHead description={description} image={image} status={status} title={title} />

      {/* Buttons */}

      <div className='flex lg:flex-col items-end gap-2'>
        {status === Status.STATUS_DRAFT && (
          <ContextualButton icon={Edit} onClick={onPublishClick}>
            {translateActions('publish')}
          </ContextualButton>
        )}

        {status === Status.STATUS_PUBLISHED && (
          <ContextualButton icon={Lock} onClick={onFinishClick}>
            {translateActions('finish')}
          </ContextualButton>
        )}

        <Modal center open={publishModal} setOpen={setPublishModal} title={translateActions('publishResource')}>
          <div className='flex flex-col items-center p-2 py-4'>
            <div className='text-center text-xl'>{translateActions('publishConfirmation')}</div>

            <form className='flex flex-col items-center mt-4' onSubmit={handleSubmit}>
              <label className='block font-medium text-gray-700 w-full' htmlFor='startAt'>{translateLabel('startAt')}</label>

              <input className='border-2 py-2 px-4 w-48 rounded-md mt-1 self-start' name='startAt' onChange={handleChange} required type='date' />

              <button className='flex gap-2 items-center rounded-lg px-4 py-2 mt-6 text-lg font-semibold tracking-wide bg-primary text-white fill-white hover:bg-gray-600 hover:text-white' type='submit'>
                {isSubmitting ? <ButtonLoader /> : translateActions('publish')}
              </button>
            </form>
          </div>
        </Modal>

        <Modal center open={finishModal} setOpen={setFinishModal} title={translateActions('finishResource')}>
          <div className='flex flex-col items-center p-2'>
            <div className='text-center text-xl'>{translateActions('finishConfirmation')}</div>

            <div className='flex mt-6 gap-8'>
              <ContextualButton onClick={onFinishHandler}>{translateActions('finish')}</ContextualButton>

              <ContextualButton onClick={onCancelHandler}>{translateActions('cancel')}</ContextualButton>
            </div>

            {errorMessage && <div className='text-red-700 flex font-medium my-2 self-center text-lg border border-red-700 px-2 rounded-md'>{errorMessage}</div>}
          </div>
        </Modal>
      </div>
    </div>
  )
}

export default UserMealplanHead
