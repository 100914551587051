import { type ApiError } from '@interfaces/api'

export const handleImageViolations = (violations: ApiError[]) => {
  let error = 'generic'
  if (!violations?.length) {
    return error
  }
  for (const violation of violations) {
    if (violation.message.includes('The image height is too big')) {
      error = 'La hauteur maximum autorisé est de 4096px'
    }
    if (violation.message.includes('The image width is too big')) {
      error = 'La largeur maximum autorisé est de 4096px'
    }
    if (violation.message.includes('The file is too large.')) {
      error = 'Le fichier est trop volumineux. La taille maximale autorisée est de 2 Mo'
    }
  }

  return error
}
