import { ChevronDownIcon, ChevronUpIcon, MinusIcon, PlusIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import TimeField from 'react-simple-timefield'

import { type Range, type TimePickerRangeProps } from '@components/form-fields/form-workschedule-field/form-workschedule-field-interfaces'
import { useWorkScheduleInputStore } from '@services/stores/work-schedule-input/work-schedule-input'
import { updateDuration } from '@services/tools/update-duration'

const TimePickerRange = ({ daySchedule, defaultRange, isLastRange = false, onAddRange, onDeletteRange, range, rangeIndex, setDaySchedule }: TimePickerRangeProps) => {
  const [rangeHours, setRangeHours] = useState<Range>({ end: range[1] ?? defaultRange[1], start: range[0] ?? defaultRange[0] })
  const [startIsHigher, setStartIsHigher] = useState<boolean>(true)
  const [endIsHigher, setEndIsHigher] = useState<boolean>(true)
  const { setInputIsInvalid } = useWorkScheduleInputStore()
  const HOURS_MAX_END = '23:00'

  const onStartChange = (e) => {
    const value = e.target.value
    setRangeHours({ end: rangeHours.end, start: value })
  }

  const onEndChange = (e) => {
    const value = e.target.value
    setRangeHours({ end: value, start: rangeHours.start })
  }

  useEffect(() => {
    const previousRange = daySchedule[rangeIndex - 1]

    if (previousRange && previousRange[1] >= rangeHours.start) {
      setStartIsHigher(false)
    } else {
      setStartIsHigher(true)
    }

    if (rangeHours.start >= rangeHours.end) {
      setEndIsHigher(false)
    } else {
      setEndIsHigher(true)
    }
  }, [daySchedule, rangeHours])

  useEffect(() => {
    if (!startIsHigher || !endIsHigher) {
      setInputIsInvalid(true)
    } else {
      setInputIsInvalid(false)
    }
  }, [startIsHigher, endIsHigher])

  useEffect(() => {
    const newDaySchedule = [...daySchedule]
    newDaySchedule[rangeIndex] = [rangeHours.start, rangeHours.end]
    setDaySchedule(newDaySchedule)
  }, [rangeHours])

  const handleDeletteRange = () => {
    onDeletteRange(rangeIndex)
  }

  const handleAddRange = () => {
    onAddRange(rangeIndex)
  }

  const onUpdateStartValue = (direction) => () => {
    const newValue = updateDuration(rangeHours.start, direction)
    setRangeHours({ end: rangeHours.end, start: newValue })
  }

  const onUpdateEndValue = (direction) => () => {
    const newValue = updateDuration(rangeHours.end, direction)
    setRangeHours({ end: newValue, start: rangeHours.start })
  }

  const StartTimeInput = <input className={`w-16 border text-center py-1 px-2 rounded-md rounded-r-none ${startIsHigher ? 'border-gray-200' : 'border-red-700 outline-red-700'} `} readOnly type='text' />
  const EndTimeInput = <input className={`w-16 border text-center py-1 px-2 rounded-md rounded-r-none ${endIsHigher ? 'border-gray-200' : 'border-red-700 outline-red-700'} `} readOnly type='text' />

  return (
    <div className='flex gap-2 items-center group'>
      <div className='flex'>
        <TimeField
          colon=':'
          input={StartTimeInput}
          onChange={onStartChange}
          value={rangeHours.start}
        />

        <div className='flex flex-col'>
          <ChevronUpIcon className='w-6 h-5 bg-gray-100 border border-l-0 rounded-tr-md cursor-pointer hover:bg-gray-300' onClick={onUpdateStartValue(1)} />

          <ChevronDownIcon className='w-6 h-5 bg-gray-100 border border-l-0 rounded-br-md cursor-pointer hover:bg-gray-300' onClick={onUpdateStartValue(-1)} />
        </div>
      </div>

      <div className='flex'>
        <TimeField
          colon=':'
          input={EndTimeInput}
          onChange={onEndChange}
          value={rangeHours.end}
        />

        <div className='flex flex-col'>
          <ChevronUpIcon className='w-6 h-5 bg-gray-100 border border-l-0 rounded-tr-md cursor-pointer hover:bg-gray-300' onClick={onUpdateEndValue(1)} />

          <ChevronDownIcon className='w-6 h-5 bg-gray-100 border border-l-0 rounded-br-md cursor-pointer hover:bg-gray-300' onClick={onUpdateEndValue(-1)} />
        </div>
      </div>

      {isLastRange && rangeHours.end < HOURS_MAX_END && (
        <button className='hidden group-hover:flex' onClick={handleAddRange} type='button'>
          <PlusIcon className='w-6 h-6 rounded-md hover:bg-gray-800 hover:text-white border' />
        </button>
      )}

      {rangeIndex !== 0 && (
        <button className='hidden group-hover:flex' onClick={handleDeletteRange} type='button'>
          <MinusIcon className='w-6 h-6 rounded-md hover:bg-gray-800 hover:text-white border' />
        </button>
      )}
    </div>
  )
}

export default TimePickerRange
