import { VideoCameraSlashIcon } from '@heroicons/react/24/outline'
import { Form, Formik } from 'formik'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import ButtonLoader from '@components/buttons/button-loader/button-loader'
import DeleteButton from '@components/buttons/delete-button'
import EditButton from '@components/buttons/edit-button/edit-button'
import FinishedTag from '@components/finished-tag/finished-tag'
import FormNumberField from '@components/form-fields/form-number-field'
import FormToggleField from '@components/form-fields/form-toggle-field'
import Modal from '@components/modals/modal'
import { type ExerciseEntryProps } from '@components/program/exercise-entry'
import { CourseExerciseType } from '@interfaces/api/exercise'

const ExerciseEntry = ({ courseExercises, errorMessage, exercise, onDelete, onSubmitCallback }: ExerciseEntryProps) => {
  const formRef = useRef<HTMLFormElement>(null)
  const [open, setOpen] = useState(false)
  const [type, setType] = useState<CourseExerciseType>(exercise.type)
  const { t: translateExercise } = useTranslation('exercise', { keyPrefix: 'entry' })
  const { t: translateResource } = useTranslation('apiResources')

  const onDeleteHandler = () => {
    onDelete()
  }

  const onEditHandler = () => {
    setOpen(true)
  }

  const initialValues = {
    effortDuration: exercise.effortDuration,
    isWarmup: exercise.isWarmup,
    repetitionCount: exercise.repetitionCount,
    restDuration: exercise.restDuration,
    setCount: exercise.setCount,
    type: exercise.type
  }

  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(true)
    onSubmitCallback(setSubmitting, { ...values, type }, exercise, setOpen)
  }

  const onClose = () => {
    if (formRef?.current) {
      formRef.current.reset()
      setType(exercise.type)
    }
  }

  return (
    <div className='group flex flex-col text-left bg-white relative'>
      <div className='flex gap-3 items-center'>
        <div className='flex items-center justify-center bg-gray-200 w-[52px] h-[52px] rounded-lg flex-shrink-0 overflow-hidden'>
          {exercise.exercise.video ? <img alt='' className='w-full h-full object-cover' src={exercise.exercise.video?.image?.url} /> : <VideoCameraSlashIcon className='h-4 w-4 text-gray-500' />}
        </div>

        <div className='flex items-center gap-1 w-full justify-between'>
          <div className='flex flex-col gap-1'>
            <div className='text-gray-700 flex items-center gap-2 text-sm w-full'>
              {exercise.exercise?.title}

              {exercise?.finishedAt && (<FinishedTag date={exercise.finishedAt} />)}
            </div>

            <div className='flex flex-col gap-1 flex-wrap'>
              <div className='flex gap-1'>
                <div className='flex gap-0.5'>
                  <div className='text-gray-500 uppercase text-[10px]'>{`${translateExercise('effort')} :`}</div>

                  <div className='text-gray-700 text-[10px] font-medium'>{`${exercise.setCount} x`}</div>
                </div>

                {[CourseExerciseType.TYPE_REPS, CourseExerciseType.TYPE_DISTANCE, CourseExerciseType.TYPE_STEPS].includes(exercise.type) && (
                  <div className='flex gap-0.5'>
                    <div className='text-gray-700 text-[10px] font-medium'>
                      {exercise.repetitionCount}

                      {[CourseExerciseType.TYPE_DISTANCE, CourseExerciseType.TYPE_STEPS].includes(exercise.type) && (
                        <span className='ml-0.5 text-gray-500 text-[8px] font-normal'>{translateExercise(`typesUnit.${exercise.type}`)}</span>
                      )}

                      {CourseExerciseType.TYPE_REPS === exercise.type && (
                        <span className='ml-0.5 text-gray-500 text-[8px] font-normal'>{translateExercise(`typesUnit.${exercise.type}`)}</span>
                      )}
                    </div>
                  </div>
                )}

                {exercise.type === CourseExerciseType.TYPE_DURATION && (
                  <div className='flex gap-0.5'>
                    <div className='text-gray-700 text-[10px] font-medium'>
                      {exercise.effortDuration}

                      <span className='ml-0.5 text-gray-500 text-[8px] font-normal'>{translateExercise(`typesUnit.${exercise.type}`)}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className='flex gap-0.5'>
                <div className='text-gray-500 uppercase text-[10px]'>{`${translateExercise('restDuration')} :`}</div>

                <div className='text-gray-700 text-[10px] font-medium'>
                  {exercise.restDuration}

                  <span className='ml-0.5 text-gray-500 text-[8px] font-normal'>secs</span>
                </div>
              </div>
            </div>
          </div>

          <div className='flex gap-1 group-hover:visible invisible absolute top-0 right-0'>
            <EditButton onClick={onEditHandler} />

            <DeleteButton onClick={onDeleteHandler} />
          </div>
        </div>
      </div>

      {/* Edit modal */}

      <Modal center onClose={onClose} open={open} setOpen={setOpen} title={translateResource('edit.exercises')}>
        <div className='px-4 py-3'>
          <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {({ isSubmitting, setFieldValue }) => {
              const onTypeClick = (type) => {
                setType(type)
              }

              return (
                <div className='flex flex-col gap-4'>
                  <div className='flex gap-3 items-center w-full'>
                    <div className='flex items-center justify-center bg-gray-200 w-24 h-16 rounded-lg flex-shrink-0 overflow-hidden'>
                      {exercise.exercise.image ? <img alt='' className='w-full h-full object-cover bg-gray-500' src={exercise.exercise.image?.url} /> : <VideoCameraSlashIcon className='h-4 w-4 text-gray-500' />}
                    </div>

                    <div className='flex flex-col gap-1'>
                      <div className='text-lg text-gray-700'>{exercise.exercise.title}</div>

                      <div className='text-xs text-gray-500'>{exercise.exercise.description}</div>
                    </div>
                  </div>

                  <Form className='flex flex-col gap-4' ref={formRef}>

                    <div className='mb-2'>
                      <FormToggleField label={translateExercise('warmup')} name='isWarmup' />
                    </div>

                    <div className='flex gap-2'>
                      {Object.values(CourseExerciseType).map((metric) => (
                        <button
                          className={`rounded-full py-1 px-4 ${type === metric ? 'bg-gray-300' : 'bg-gray-100'}`}
                          onClick={() => {
                            onTypeClick(metric)
                          }}
                          type='button'
                        >
                          {translateExercise(`type.${metric}`)}
                        </button>
                      ))}
                    </div>

                    <div className='relative gap-6 flex'>
                      <FormNumberField label={translateExercise('setCount')} name='setCount' />

                      {[CourseExerciseType.TYPE_REPS, CourseExerciseType.TYPE_DISTANCE, CourseExerciseType.TYPE_STEPS].includes(type) && <FormNumberField label={translateExercise(`type.${type}`)} name='repetitionCount' unit={translateExercise(`typesUnit.${type}`)} />}

                      {type === CourseExerciseType.TYPE_DURATION && <FormNumberField label={translateExercise('effortDuration')} name='effortDuration' />}

                      <FormNumberField label={translateExercise('restDuration')} name='restDuration' zeroAllowed />
                    </div>

                    <button
                      className='flex gap-2 items-center rounded-lg px-4 py-2 text-lg font-semibold tracking-wide text-white justify-center fill-white hover:bg-gray-600 hover:text-white bg-gray-900'
                      disabled={isSubmitting}
                      type='submit'
                    >
                      {isSubmitting
                        ? <ButtonLoader />
                        : (
                          <div className='flex items-center gap-2'>
                            {translateResource('actions.validate')}
                          </div>
                        )
                      }
                    </button>
                  </Form>

                  {errorMessage && <div className='text-red-700 flex font-medium justify-center text-lg border border-red-700 px-2 rounded-md'>{errorMessage}</div>}
                </div>
              )
            }}
          </Formik>
        </div>
      </Modal>
    </div>
  )
}

export default ExerciseEntry
