import {
  useMutation,
  type UseMutationResult
} from '@tanstack/react-query'

import { type ApiError } from '@interfaces/api/api'
import { type Audio } from '@interfaces/api/audio-templates'
import useAxios from '@services/api/axios'

export const useUploadAudio = (): UseMutationResult<string, ApiError, FormData> => {
  const { post } = useAxios()

  return useMutation<string, ApiError, FormData>({
    mutationFn: async (file: FormData) => await post<FormData, Audio>('/api/audio', file).then((body) => body['@id'])
  })
}
