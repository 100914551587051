import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'

import StatusTag from '@components/status-tag/status-tag'
import TableEntry from '@components/table/table-data/table-entry'
import { type UserMealplan } from '@interfaces/api/user-mealplan'

const UserMealplanTableEntry = (userMealplan: UserMealplan) => {
  const { id = '' } = useParams()
  const navigate = useNavigate()
  const {
    createdAt,
    finishedAt,
    mealplan,
    status,
    uid
  } = userMealplan

  const onClick = () => {
    navigate(`/users/${id}/user-mealplan/${uid}`)
  }

  return (
    <TableEntry onClick={onClick}>
      <td className='p-4'>
        {mealplan.title}
      </td>

      <td className='p-4 tabular-nums'>
        {(new Date(createdAt)).toLocaleDateString()}
      </td>

      <td className='p-4 tabular-nums'>
        {finishedAt && (new Date(finishedAt)).toLocaleDateString()}
      </td>

      <td className='p-4'>
        <StatusTag status={status} />
      </td>
    </TableEntry>
  )
}

export default UserMealplanTableEntry
