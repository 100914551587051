import moment from 'moment'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import ButtonLoader from '@components/buttons/button-loader/button-loader'
import ContextualButton from '@components/buttons/contextual-button'
import { type BusySlotProps } from '@components/calendar/calendar-interfaces'
import Modal from '@components/modals/modal'
import useDeleteEntry from '@services/api/resources/delete-entry-query'
import { captureException } from '@services/exceptions/capture-exception'

const BusySlot = ({ busySlot, onReload }: BusySlotProps) => {
  const { t: translateActions } = useTranslation('apiResources', { keyPrefix: 'actions' })
  const { t: translateLabels } = useTranslation('apiResources', { keyPrefix: 'labels' })
  const { t: translateCalendar } = useTranslation('calendar')

  const [openModal, setOpenModal] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const busySlotDate = moment(busySlot.startAt).format('LLL')

  const resourceParameters = { id: busySlot.id.toString(), path: 'calendar-busy-slots/{uid}' }
  const { mutateAsync: removeBusySlot } = useDeleteEntry(resourceParameters)

  const handleClick = () => {
    setOpenModal(true)
  }

  const onCancelHandler = () => {
    setOpenModal(false)
  }

  const onCreateHandler = async () => {
    setIsSubmitting(true)
    try {
      await removeBusySlot({})
    } catch (err) {
      captureException(err as Error)
    } finally {
      onReload()
      setOpenModal(false)
      setIsSubmitting(false)
    }
  }

  return (
    <>
      <div className='p-[1px]'>
        <button className='border border-gray-500 hover:border-gray-900 hover:text-gray-900 text-sm text-gray-500 flex items-center justify-center h-full w-full rounded-md' onClick={handleClick}>{translateLabels('unavailable')}</button>
      </div>

      <Modal center open={openModal} setOpen={setOpenModal} title={translateCalendar('updateAvailability')}>
        <div className='flex flex-col items-center p-8'>
          <div className='text-center text-xl'>{`${translateActions('reactivateSlot')} ${busySlotDate}`}</div>

          <div className='flex mt-6 gap-8'>
            <ContextualButton onClick={onCreateHandler}>{isSubmitting ? <ButtonLoader /> : translateActions('confirm')}</ContextualButton>

            <ContextualButton onClick={onCancelHandler}>{translateActions('cancel')}</ContextualButton>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default BusySlot
