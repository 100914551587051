import { type ApiPlatformEntity } from '@interfaces/api'
import { type Field } from '@interfaces/api/definition/field'

function getDefaultValue (field: Field) {
  let defaultValue: (string | number | boolean) = ''
  if ('type' in field.properties) {
    if (field.properties.type === 'string') {
      defaultValue = ''
    }
    if (field.properties.type === 'string' && field.name === 'status') {
      defaultValue = 'PUBLISHED'
    }
    if (field.properties.type === 'integer' || field.properties.type === 'number') {
      defaultValue = 1
    }
    if (field.properties.type === 'boolean') {
      defaultValue = false
    }
    if (field.properties.type === 'array') {
      defaultValue = ''
    }
    if (field.properties.type === 'object') {
      defaultValue = ''
    }
  }

  return defaultValue
}

export const getInitialValues = <T extends ApiPlatformEntity> (fields: Field[], existingValues?: T | never[]) => {
  const initialValues = fields.map(field => {
    let value = getDefaultValue(field)
    if (existingValues && field.name in existingValues) {
      value = existingValues[field.name]
    }

    return [
      field.name,
      value
    ]
  })

  return Object.fromEntries(initialValues)
}
