import { type UserMealplanEntryProps } from '@components/user-mealplan-entry/user-mealplan-entry-interfaces'
import UserMealplanHead from '@components/user-mealplan-entry/user-mealplan-head/user-mealplan-head'
import UserMealplanWeeks from '@components/user-mealplan-entry/user-mealplan-weeks/user-mealplan-weeks'

const UserMealplanEntry = ({ data }: UserMealplanEntryProps) => {
  const { createdAt, mealplan, status, uid, weeks } = data

  return (
    <>
      <UserMealplanHead createdAt={createdAt} mealplan={mealplan} status={status} uid={uid} userMealplan={data['@id']} />

      <div className='mt-12 mb-8'>
        <UserMealplanWeeks mealplanWeeks={weeks} />
      </div>
    </>
  )
}

export default UserMealplanEntry
