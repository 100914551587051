import { EllipsisHorizontalIcon } from '@heroicons/react/24/outline'
import { type InfiniteData } from '@tanstack/react-query'
import classNames from 'classnames'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { queryClient } from '@app'
import ContextualButton from '@components/buttons/contextual-button'
import ChatMessageAttachment from '@components/chat-message/chat-message-attachment'
import ChatMessageAudio from '@components/chat-message/chat-message-audio'
import { type ChatMessageProps } from '@components/chat-message/chat-message-interfaces'
import ChatMessageUpdateForm from '@components/chat-message/chat-message-update/chat-message-update'
import Modal from '@components/modals/modal'
import { type FormattedApiListResponse } from '@interfaces/api'
import { type ChatMessage as ChatMessageType } from '@interfaces/api/chat'
import { useDeleteChatMessageMutation, useUpdateChatMessageMutation } from '@services/api/chat'
import { CHAT_MESSAGE_TYPE } from '@services/api/chat/message/use-create-chat-message-mutation'
import { captureException } from '@services/exceptions/capture-exception'
import { Delete, Edit } from '@svg/icons'

const ChatMessage = ({ messageEntry, room }: ChatMessageProps) => {
  const { t } = useTranslation('chat', { keyPrefix: 'room' })

  const [openMessageEdit, setOpenMessageEdit] = useState(false)
  const [settingsOpen, setSettingsOpen] = useState(false)
  const [openModal, setOpenModal] = useState(false)

  const messageRef = useRef<HTMLDivElement>(null)
  const settingsRef = useRef<HTMLDivElement>(null)

  const { mutateAsync: deleteMessage } = useDeleteChatMessageMutation()
  const { mutateAsync: updateMessage } = useUpdateChatMessageMutation()

  useEffect(() => {
    if (messageRef?.current) {
      const clickEvent = (event) => {
        if (!messageRef.current?.contains(event.target) && openMessageEdit) {
          closeEdit()
        }

        if (!settingsRef.current?.contains(event.target) && settingsOpen) {
          closeEdit()
        }
      }

      document.addEventListener('click', clickEvent)

      return () => {
        document.removeEventListener('click', clickEvent)
      }
    }
  }, [messageRef?.current, settingsRef?.current, openMessageEdit, settingsOpen])

  const handleClick = () => {
    setSettingsOpen(!settingsOpen)
  }

  const handleUpdateClick = () => {
    setSettingsOpen(false)
    setOpenMessageEdit(true)
  }

  const closeEdit = () => {
    setOpenMessageEdit(false)
    setSettingsOpen(false)

    if (openModal) {
      setOpenModal(false)
    }
  }

  const onDeleteMessage = (messageUid) => async () => {
    try {
      await deleteMessage(messageUid)

      queryClient.setQueryData<InfiniteData<FormattedApiListResponse<ChatMessageType>>>(
        ['chat-messages', room], (data) => {
          data?.pages.forEach((page) => {
            const pageIndex = page.data.findIndex(message => message.uid === messageUid)
            if (pageIndex !== -1) {
              page.data[pageIndex].isDeleted = true
            }
          })

          const nextPages = [...(data?.pages ?? [])]

          return {
            pageParams: data?.pageParams ?? [],
            pages: nextPages
          }
        }
      )
    } catch (err) {
      captureException(err as Error)
    } finally {
      setOpenModal(false)
    }
  }

  const handleDeleteModalClick = () => {
    setOpenModal(true)
  }

  const onClickStopPropagation = (e) => {
    e.stopPropagation()
  }

  return (
    <div className='chat-message' ref={messageRef}>
      {openMessageEdit
        ? <ChatMessageUpdateForm message={messageEntry} reset={closeEdit} room={room} updateMessage={updateMessage} />
        : (
          <div className={`flex items-center ${messageEntry.isFromAdmin ? 'justify-end' : ''}`}>
            <div className={`flex flex-col max-w-xs mx-2 ${messageEntry.isFromAdmin ? 'order-1 items-end' : 'order-2 items-start'}`}>
              <div>
                <div
                  className={classNames(
                    'break-words group max-w-xs relative rounded-lg inline-block',
                    messageEntry.attachment && !messageEntry.isDeleted ? 'p-0' : 'py-4 px-6',
                    messageEntry.isDeleted && 'text-gray-400 italic bg-gray-200',
                    messageEntry.isFromAdmin ? 'bg-secondary text-secondaryText' : 'bg-gray-100'
                  )}
                >
                  {messageEntry.attachment && !messageEntry.isDeleted && (<ChatMessageAttachment messageUid={messageEntry.uid} />)}

                  {messageEntry.audio && !messageEntry.isDeleted && (<ChatMessageAudio audioUid={messageEntry.audio.uid} />)}

                  {messageEntry.isDeleted ? t('messageDeleted') : messageEntry.text}

                  {messageEntry.isFromAdmin && !messageEntry.isDeleted && (
                    <div className='absolute top-1 right-2 flex items-center' ref={settingsRef}>

                      {settingsOpen && (
                        <div className='absolute right-1/2 top-full overflow-hidden bg-gray-100 flex flex-col items-center rounded-lg full-shadow text-gray-900 w-40' onClick={onClickStopPropagation} onKeyDown={onClickStopPropagation} role='button' tabIndex={0}>

                          {messageEntry.type === CHAT_MESSAGE_TYPE.TYPE_TEXT && (
                            <button className='py-1 px-2 hover:bg-white w-full flex items-center justify-center gap-4 z-10' onClick={handleUpdateClick}>
                              <Edit className='w-5 h-5' />

                              {t('update')}
                            </button>
                          )}

                          <button className='py-1 px-2 hover:bg-white w-full flex items-center justify-center gap-4 z-10' onClick={handleDeleteModalClick}>
                            <Delete className='w-5 h-5' />

                            {t('delete')}
                          </button>
                        </div>
                      )

                      }

                      <div className={`hover:bg-primary rounded-full z-10 ${settingsOpen ? 'bg-primary' : 'bg-transparent'}`}>
                        <EllipsisHorizontalIcon className={`${settingsOpen ? 'flex text-white' : 'hidden text-gray-900'} ${messageEntry.attachment ? 'group-hover:text-white' : ''} group-hover:flex hover:text-white cursor-pointer my-auto w-6 h-6`} onClick={handleClick} />
                      </div>
                    </div>
                  )}
                </div>

                {!messageEntry.isDeleted &&
                <div className={`${messageEntry.isFromAdmin ? 'text-end' : 'text-start'} text-sm font-light ${messageEntry.attachment ? 'text-white absolute bottom-2 right-3' : ' text-gray-500 mt-2'}`}>{moment(messageEntry.createdAt).format('HH:mm')}</div>
                }
              </div>
            </div>
          </div>
        )
      }

      <Modal center open={openModal} setOpen={setOpenModal}>
        <div className='flex flex-col items-center p-8'>
          <div className='text-center text-xl'>{t('deleteConfirmation')}</div>

          <div className='flex mt-6 gap-8'>
            <ContextualButton onClick={onDeleteMessage(messageEntry.uid)} style='warning'>{t('delete')}</ContextualButton>

            <ContextualButton onClick={closeEdit}>{t('cancel')}</ContextualButton>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default ChatMessage
