import { create } from 'zustand'

interface WorkScheduleInputStore {
  inputIsInvalid: boolean
  setInputIsInvalid: (isInvalid: boolean) => void
}

export const useWorkScheduleInputStore = create<WorkScheduleInputStore>((set) => ({
  inputIsInvalid: false,
  setInputIsInvalid: (inputIsInvalid) => {
    set({ inputIsInvalid })
  }
}))
