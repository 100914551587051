import { useQuery, type UseQueryResult } from '@tanstack/react-query'

import { type UserProfile } from '@interfaces/api/user-profile'
import useAxios from '@services/api/axios'

const useUserMeQuery = (): UseQueryResult<UserProfile> => {
  const { get } = useAxios()

  return useQuery({
    queryFn: async () => {
      return await get<UserProfile, UserProfile>('api/me').then(result => result)
    },
    queryKey: ['user-me']
  })
}

export default useUserMeQuery
