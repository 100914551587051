import { type ApiResourceProps } from '@components/api-resource'
import ApiResourceList from '@components/api-resource/api-resource-list'
import useApiResource from '@root/services/api/definition'

const ApiResource = ({ createdAt, customFieldsToDisplay, entryComponent, name, onClick, withCloneOption = false, withCreateOption, withDeleteOption = false, withEditOption }: ApiResourceProps) => {
  const resource = useApiResource(name)
  const onClickHandler = (entry) => {
    if (onClick) {
      onClick(entry)
    }
  }

  return (
    <>
      {resource && (
        <ApiResourceList
          createdAt={createdAt}
          customFieldsToDisplay={customFieldsToDisplay}
          definition={resource}
          entryComponent={entryComponent}
          onEntryClick={onClickHandler}
          withCloneOption={withCloneOption}
          withCreateOption={withCreateOption}
          withDeleteOption={withDeleteOption}
          withEditOption={withEditOption}
        />
      )}
    </>
  )
}

export default ApiResource
