import {
  type UserMealplanDayEntryProps
} from '@components/user-mealplan-entry/user-mealplan-day-entry/user-mealplan-day-entry-interfaces'
import UserMealplanMealEntry from '@components/user-mealplan-entry/user-mealplan-meal-entry/user-mealplan-meal-entry'

const UserMealplanDayEntry = ({ day }: UserMealplanDayEntryProps) => {
  const { breakfast, dinner, lunch, snack } = day

  return (
    <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-1 items-start'>
      <UserMealplanMealEntry dayInfo={day} label={'breakfast'} meal={breakfast} />

      <UserMealplanMealEntry dayInfo={day} label={'lunch'} meal={lunch} />

      <UserMealplanMealEntry dayInfo={day} label={'snack'} meal={snack} />

      <UserMealplanMealEntry dayInfo={day} label={'dinner'} meal={dinner} />
    </div>
  )
}

export default UserMealplanDayEntry
