import { CheckCircleIcon } from '@heroicons/react/24/outline'

import { type FinishedTagProps } from '@components/finished-tag/finished-tag-interfaces'

const FinishedTag = ({ date }: FinishedTagProps) => {
  return (
    <div className='group rounded-full relative flex justify-center p-1'>
      <CheckCircleIcon className='w-4 h-4 text-green-500' />

      <div className='group-hover:flex hidden absolute w-max -bottom-10 md:ml-auto bg-green-500 rounded-md px-2 py-1 text-white uppercase font-medium z-10'>{new Date(date).toLocaleString()}</div>
    </div>
  )
}

export default FinishedTag
