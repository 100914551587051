import {
  type DefaultError,
  type InfiniteData,
  type QueryKey,
  useInfiniteQuery
} from '@tanstack/react-query'

import { type FormattedApiListResponse, type RoomRequestParameters } from '@interfaces/api'
import { type ChatMessage, type ChatMessagesResponse } from '@interfaces/api/chat'
import useAxios from '@services/api/axios'
import { formatApiListResponse } from '@services/tools/api-resources/format-list-response'

const useChatMessageListQuery = ({ room }: RoomRequestParameters) => {
  const { get } = useAxios()

  return useInfiniteQuery<FormattedApiListResponse<ChatMessage>, DefaultError, InfiniteData<FormattedApiListResponse<ChatMessage>, string>, QueryKey, string>({
    getNextPageParam: (lastPageObj) => {
      return lastPageObj?.pagination?.['hydra:next']
    },
    initialPageParam: '/api/chat-messages',
    queryFn: async ({ pageParam = '/api/chat-messages' }) => {
      return await get<RoomRequestParameters, ChatMessagesResponse>(pageParam, { room }).then(result => formatApiListResponse<ChatMessage>(result))
    },
    queryKey: ['chat-messages', room],
    retry: false
  })
}

export default useChatMessageListQuery
