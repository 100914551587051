import { useMutation, type UseMutationOptions, type UseMutationResult } from '@tanstack/react-query'

import useApi from '@services/api/axios'

const useDeleteChatMessageMutation = (
  options: UseMutationOptions<undefined, unknown, string> = {}
): UseMutationResult<undefined, unknown, string> => {
  const { del } = useApi()

  return useMutation<undefined, unknown, string>({
    mutationFn: async (uid: string) => {
      await del<undefined>(`api/chat-messages/${uid}`)

      return undefined
    },
    ...options
  })
}

export default useDeleteChatMessageMutation
