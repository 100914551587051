import { type InfiniteData } from '@tanstack/react-query'
import { Field, Form, Formik } from 'formik'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { queryClient } from '@app'
import ButtonLoader from '@components/buttons/button-loader/button-loader'
import { type ChatMessageUpdateFormProps } from '@components/chat-message/chat-message-update/chat-message-update-interfaces'
import { type FormattedApiListResponse } from '@interfaces/api'
import { type ChatMessage as ChatMessageType } from '@interfaces/api/chat'
import { captureException } from '@services/exceptions/capture-exception'
import { Send } from '@svg/icons'

const ChatMessageUpdateForm = ({ message, reset, room, updateMessage }: ChatMessageUpdateFormProps) => {
  const { t } = useTranslation('chat', { keyPrefix: 'room' })
  const inputRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    inputRef?.current?.focus()
    inputRef?.current?.setSelectionRange(inputRef?.current?.value.length, inputRef?.current?.value.length)
  }, [inputRef?.current])

  const initialValues = {
    text: message.text,
    type: message.type
  }

  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true)

    const params = {
      body: values,
      uid: message.uid
    }

    try {
      const result = await updateMessage(params)

      queryClient.setQueryData<InfiniteData<FormattedApiListResponse<ChatMessageType>>>(
        ['chat-messages', room], (data) => {
          data?.pages.forEach((page) => {
            const pageIndex = page.data.findIndex(message => message.uid === result.uid)
            if (pageIndex !== -1) {
              page.data[pageIndex] = result
            }
          })

          const nextPages = [...(data?.pages ?? [])]

          return {
            pageParams: data?.pageParams ?? [],
            pages: nextPages
          }
        }
      )
    } catch (err) {
      captureException(err as Error)
    } finally {
      setSubmitting(false)
      reset()
    }
  }

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} >
      {({ isSubmitting, values }) => {
        const emptyText = !values.text.length

        return (
          <div className='flex items-center justify-end'>
            <div className='max-w-xs mx-2 w-full'>
              <Form className='relative flex flex-col'>
                <Field as='textarea' className='w-full border-x rounded-lg rounded-b-none border-t border-gray-500 h-32 focus:outline-none focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 px-4 py-2 bg-gray-100' innerRef={inputRef} name='text' placeholder={t('writeMessage')} required />

                <div className='items-center inset-y-0 flex'>
                  <button
                    className={`w-full inline-flex border-x rounded-lg rounded-t-none border-b border-gray-900 items-center justify-center px-4 py-3 transition duration-500 ease-in-out text-white focus:outline-none ${emptyText ? 'bg-gray-500 cursor-not-allowed' : 'bg-primary hover:bg-gray-800'}`}
                    disabled={isSubmitting || emptyText}
                    type='submit'
                  >
                    {isSubmitting ? <ButtonLoader /> : <Send className='w-6 h-6 rotate-90 fill-white' />}
                  </button>
                </div>
              </Form>
            </div>
          </div>
        )
      }}
    </Formik>
  )
}

export default ChatMessageUpdateForm
