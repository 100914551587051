import { type UseMutationResult, useQueryClient } from '@tanstack/react-query'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'

import ButtonLoader from '@components/buttons/button-loader/button-loader'
import FormFileField from '@components/form-fields/form-file-field'
import FormSelectField from '@components/form-fields/form-select-field'
import FormTextField from '@components/form-fields/form-text-field'
import { type EditVideoProps } from '@components/video/edit-video/edit-video-interfaces'
import { type ApiReponseError } from '@interfaces/api/api'
import usePatchEntry from '@services/api/resources/patch-entry-query'
import { useUploadImage } from '@services/api/upload-image/upload-image'
import { captureException } from '@services/exceptions/capture-exception'
import { handleImageViolations } from '@services/tools/api-resources/violations'
import { Edit } from '@svg/icons'

const EditVideo = ({ categories, onEdit, video }: EditVideoProps) => {
  const { t: translateResource } = useTranslation('apiResources')
  const { mutateAsync: editResourceEntry } = usePatchEntry({ id: video.uid, path: 'videos/{uid}' })
  const { mutateAsync: uploadImageAsync } = useUploadImage()

  const queryClient = useQueryClient()
  const onReload = () => {
    queryClient.refetchQueries({ queryKey: ['videos'] }).catch(captureException)
  }

  const onSubmit = async (values, { setErrors, setSubmitting }) => {
    let errorMessage = ''
    setSubmitting(true)

    if (values.image) {
      if ('@id' in values.image) {
        values.image = values.image['@id']
      } else {
        const formData = new FormData()
        formData.append('file', values.image)
        try {
          const imageId = await uploadImageAsync(formData)
          values.image = imageId
        } catch (e) {
          const err = e as UseMutationResult<ApiReponseError>
          errorMessage = handleImageViolations(err?.data?.violations ?? [])
          setErrors({ image: errorMessage })
        }
      }
    }

    if (!values.category) {
      values.category = null
    }

    try {
      await editResourceEntry(values)
      setSubmitting(false)
      onEdit()
    } catch (e) {
      captureException(e as Error)
    } finally {
      onReload()
    }
  }

  interface Option {
    key: string
    name: string
  }

  const options: Option[] = []
  if (categories.length > 0) {
    categories.forEach(category => {
      options.push({ key: category['@id'], name: category.name })
    })
  }

  const initialValues = {
    category: video.category?.['@id'] ?? '',
    image: video.image ?? null,
    tag: video.tag ?? ''
  }

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, setFieldValue }) => {
          return (
            <Form className='space-y-6 px-4 py-3'>

              {!!options.length && <FormSelectField label={translateResource('labels.category')} name='category' options={options} placeholder={'Aucune catégorie'} required={false} />}

              <FormTextField label={translateResource('labels.tag')} name='tag' />

              <FormFileField label={translateResource('labels.image')} name='image' setFieldValue={setFieldValue} value={initialValues.image} />

              <button
                className='flex gap-2 ml-auto w-auto items-center justify-center rounded-lg px-4 py-2 text-lg font-semibold tracking-wide text-white fill-white hover:text-white bg-gray-900 hover:bg-gray-600 '
                disabled={isSubmitting}
                type='submit'
              >
                {isSubmitting
                  ? <ButtonLoader />
                  : (
                    <>
                      <Edit className='w-6 h-6' />
                      {translateResource('actions.editVideo')}
                    </>
                  )}
              </button>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default EditVideo
