import { type EditButtonProps } from '@components/buttons/edit-button/index'
import IconButton from '@components/buttons/icon-button'
import { Edit } from '@svg/icons'

const EditButton = ({ onClick }: EditButtonProps) => {
  return (
    <IconButton icon={Edit} onClick={onClick} />
  )
}

export default EditButton
