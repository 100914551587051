import { XMarkIcon } from '@heroicons/react/24/outline'
import { type InfiniteData, type UseMutationResult, useQueryClient } from '@tanstack/react-query'
import { useFormik } from 'formik'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import ButtonLoader from '@components/buttons/button-loader/button-loader'
import ChatAudio from '@components/chat-message/chat-audio/chat-audio'
import { type ChatMessageFormProps } from '@components/chat-message/chat-message-form/chat-message-form-interfaces'
import { type ApiReponseError, type FormattedApiListResponse } from '@interfaces/api'
import { type ChatMessageCreateBody, type ChatMessage as ChatMessageType } from '@interfaces/api/chat'
import useAudioTemplateCategoriesList
  from '@services/api/audio/audio-template-categories/use-audio-template-categories-list'
import { useCreateChatAttachmentMutation } from '@services/api/chat'
import { CHAT_MESSAGE_TYPE } from '@services/api/chat/message/use-create-chat-message-mutation'
import { captureException } from '@services/exceptions/capture-exception'
import { useAudioTemplateStore } from '@services/stores/audio-template/audio-template'
import { getInitialValues } from '@services/tools/api-resources/formik'
import { Delete, Image, Send } from '@svg/icons'

const ChatMessageForm = ({ createMessage, definition, room, scrollToEnd }: ChatMessageFormProps) => {
  const { t } = useTranslation('chat', { keyPrefix: 'room' })
  const { t: tError } = useTranslation('errors')

  const [imagePreview, setImagePreview] = useState('')
  const [audioSubmitting, setAudioSubmitting] = useState<boolean>(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const roomId = room.replace('/api/chat-rooms/', '')
  const { mutateAsync: createChatAttachment } = useCreateChatAttachmentMutation()
  const { audioTemplate, setAudioTemplate } = useAudioTemplateStore()

  const requestFields = definition?.methods.post.getRequestFields()
  const initialValues = requestFields && getInitialValues(requestFields)

  const queryClient = useQueryClient()

  const reloadChatList = () => {
    queryClient.refetchQueries({ queryKey: ['priority-chat-rooms'] }).catch((err) => {
      captureException(err as Error)
    })
  }

  const {
    errors,
    handleChange,
    handleSubmit,
    isSubmitting,
    resetForm,
    setErrors,
    setFieldValue,
    setSubmitting,
    values: { attachment, text }
  } = useFormik<ChatMessageCreateBody>({
    initialValues,
    onSubmit: async (values) => {
      let errorMessage = ''

      values.room = room
      values.audio = null

      if (values.attachment) {
        values.type = CHAT_MESSAGE_TYPE.TYPE_ATTACHMENT
        const formData = new FormData()
        formData.append('file', values.attachment)

        try {
          const chatAttachment = await createChatAttachment(formData)
          if (chatAttachment?.['@id']) {
            values.attachment = chatAttachment['@id']
          }
        } catch (e) {
          const err = e as UseMutationResult<ApiReponseError>
          const errorCode = err.data?.errorCode ?? 'generic'
          errorMessage = tError(errorCode)
          setErrors({ attachment: errorMessage })
        }
      } else {
        values.type = CHAT_MESSAGE_TYPE.TYPE_TEXT
        values.attachment = null
      }

      if (!errorMessage) {
        scrollToEnd()
        setSubmitting(true)

        // Sends the POST request and trigger the onCreate callback on success
        try {
          const result = await createMessage(values)

          const msgvalues: Partial<ChatMessageType> = {
            attachment: result.attachment,
            createdAt: result.createdAt,
            isDeleted: result.isDeleted,
            isEdited: result.isEdited,
            isFromAdmin: result.isFromAdmin,
            isRead: result.isRead,
            text: result.text,
            type: result.type,
            uid: result.uid
          }

          queryClient.setQueryData<InfiniteData<FormattedApiListResponse<ChatMessageType>>>(
            ['chat-messages', roomId], (data) => {
              const lastPage = data?.pages?.[0]
              if (lastPage?.data) {
                lastPage?.data?.unshift(msgvalues as ChatMessageType)
              }

              const nextPages = [...(data?.pages ?? [])]

              if (lastPage) {
                nextPages.splice(0, 1, lastPage)
              }

              return {
                pageParams: data?.pageParams ?? [],
                pages: nextPages
              }
            }
          )
        } catch (err) {
          captureException(err as Error)
        } finally {
          setSubmitting(false)
          removeFile().catch(captureException)
          resetForm()
          reloadChatList()
        }
      }
    }
  })

  const emptyText = !text.length

  const removeFile = async () => {
    setImagePreview('')
    await setFieldValue('attachment', '')
    if (inputRef.current?.value) {
      inputRef.current.value = ''
    }
  }

  const handleChangeInput = async (event) => {
    const file = event?.currentTarget?.files[0]
    const fileUrl = URL.createObjectURL(file)
    await setFieldValue('attachment', file)
    setImagePreview(fileUrl)
  }

  // Sending audio templates
  const handleDeleteAudio = () => {
    setAudioTemplate(null)
  }

  const onSubmitAudio = async () => {
    if (audioTemplate) {
      const msgPayload = {
        attachment: null,
        audio: audioTemplate.audio['@id'],
        room,
        text: '',
        type: CHAT_MESSAGE_TYPE.TYPE_AUDIO
      }

      setAudioSubmitting(true)

      try {
        const result = await createMessage(msgPayload)

        const msgvalues: Partial<ChatMessageType> = {
          attachment: result.attachment,
          audio: result.audio,
          createdAt: result.createdAt,
          isDeleted: result.isDeleted,
          isEdited: result.isEdited,
          isFromAdmin: result.isFromAdmin,
          isRead: result.isRead,
          text: result.text,
          type: result.type,
          uid: result.uid
        }

        queryClient.setQueryData<InfiniteData<FormattedApiListResponse<ChatMessageType>>>(
          ['chat-messages', roomId], (data) => {
            const lastPage = data?.pages?.[0]
            if (lastPage?.data) {
              lastPage?.data?.unshift(msgvalues as ChatMessageType)
            }

            const nextPages = [...(data?.pages ?? [])]

            if (lastPage) {
              nextPages.splice(0, 1, lastPage)
            }

            return {
              pageParams: data?.pageParams ?? [],
              pages: nextPages
            }
          }
        )
      } catch (e) {
        captureException(e as Error)
      } finally {
        setAudioSubmitting(false)
        setAudioTemplate(null)
      }
    }
  }

  const { data: { data: audioCategories } = {} } = useAudioTemplateCategoriesList()

  return (
    <>
      {imagePreview &&
        (
          <div className='relative mb-4 self-start bg-white border-2 p-1 rounded-md w-1/4'>
            <img alt='preview' className='w-full object-cover rounded-md object-center' src={imagePreview} />

            <button className='absolute group bg-white rounded-md right-2 top-2 p-0.5 text-sm text-center shadow-lg border border-gray-300 cursor-pointer hover:border-red-900 hover:bg-red-700' onClick={removeFile} >
              <XMarkIcon className='w-4 h-4 text-red-700 font-bold group-hover:text-white' />
            </button>

            {errors?.attachment && <div className='mt-2 text-xs text-red-600 font-medium'>{errors.attachment}</div>}
          </div>
        )}

      <div className='flex w-full'>
        <label className='flex items-center justify-center' htmlFor='attachment'>
          <div className={`p-1 group rounded-md ${!emptyText ? 'cursor-not-allowed' : 'cursor-pointer hover:bg-primary'}`}>
            <Image className={`h-6 w-6 ${!emptyText ? 'fill-gray-500' : ' group-hover:fill-white'}`} />
          </div>
        </label>

        <input disabled={!emptyText} hidden id='attachment' name='attachment' onChange={handleChangeInput} ref={inputRef} type='file' value={undefined} />

        <ChatAudio audioCategories={audioCategories ?? []} createMessage={createMessage} room={room} />

        {!audioTemplate && (
          <form className='relative flex w-full' onSubmit={handleSubmit}>
            <input
              autoComplete='off'
              className={`w-full focus:outline-none disabled:bg-white focus:placeholder-gray-300 text-gray-900 placeholder-gray-500 px-2 ${attachment ? 'cursor-not-allowed' : 'cursor-pointer'}`}
              disabled={!!attachment}
              name='text'
              onChange={handleChange}
              placeholder={t('writeMessage')}
              required={!attachment}
              type='text'
              value={text}
            />

            <div className='items-center inset-y-0 flex'>
              <button
                className={`inline-flex items-center justify-center rounded-full p-2.5 transition duration-500 ease-in-out text-white focus:outline-none ${emptyText && !attachment ? 'bg-gray-500 cursor-not-allowed' : 'bg-primary hover:bg-gray-800'}`}
                disabled={isSubmitting || (emptyText && !attachment)}
                type='submit'
              >
                {isSubmitting ? <ButtonLoader /> : <Send className='w-5 h-5 rotate-90 fill-white' />}

              </button>
            </div>
          </form>
        )}

        {audioTemplate && (
          <div className='w-full flex ml-3'>

            <div className='flex items-center pr-3 w-full'>
              <div className='bg-gray-100 rounded-l-full border-2 border-gray-100 py-1 pl-[5px] pr-[3px]'>
                <svg className='w-4 h-4' fill='none' stroke='currentColor' strokeWidth='2' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
                  <path d='M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z' strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              </div>

              <div className='flex items-center gap-2 justify-between w-full border-2 border-gray-100 max-w-[765px] rounded-r-full pl-1.5 pr-3 py-1 text-xs'>
                <div className='truncate'>
                  {audioTemplate.title}
                </div>

                <button onClick={handleDeleteAudio}>
                  <Delete className='w-3 h-3 fill-gray-500' />
                </button>
              </div>
            </div>

            <button
              className='inline-flex items-center justify-center rounded-full p-2.5 transition duration-500 ease-in-out text-white focus:outline-none bg-primary hover:bg-gray-600'
              disabled={audioSubmitting}
              onClick={onSubmitAudio}
            >
              {audioSubmitting ? <ButtonLoader /> : <Send className='w-5 h-5 rotate-90 fill-white' />}

            </button>
          </div>
        )}
      </div>
    </>
  )
}

export default ChatMessageForm
