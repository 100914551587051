import * as React from 'react'

import { type LayoutProps } from '@components/layout-login/layout-login.interfaces'

export default function LayoutLogin ({ children, title }: LayoutProps) {
  return (
    <div className='flex items-center flex-col p-10 gap-12 h-screen bg-login bg-cover'>

      <div className='flex flex-col items-center'>
        <a href='/'>
          <img
            alt='Logo'
            className='h-7 w-auto'
            src='/assets/theme/logo-white.svg'
          />
        </a>
      </div>

      <div className='flex-1 flex flex-col space-y-10 -mt-10 items-center justify-center w-full'>
        <div className='text-white font-bold text-[36px]'>{title}</div>

        <div className='w-11/12 sm:max-w-[480px] bg-white p-4 rounded-lg min-w-[300px]'>
          {children}
        </div>
      </div>

    </div>
  )
}
