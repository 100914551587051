import React from 'react'

import { type ContextualButtonProps } from '@components/buttons/contextual-button/index'

const ContextualButton = ({ children, disabled = false, icon: Icon, onClick, style }: ContextualButtonProps) => {
  let buttonStyle = 'bg-primary text-white fill-white hover:bg-gray-600 hover:text-white'
  if (style === 'warning') {
    buttonStyle = 'border border-red-500 bg-white text-red-500 fill-red-500 hover:border-red-500 hover:bg-red-500 hover:text-white hover:fill-white'
  }

  return (
    <button
      className={`flex gap-2 items-center rounded-lg px-4 py-2 text-lg font-semibold tracking-wide ${buttonStyle} ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
      disabled={disabled}
      onClick={onClick}
    >
      {Icon && <Icon className='flex-none w-6 h-6' />}

      {children}
    </button>
  )
}

export default ContextualButton
