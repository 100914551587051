import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'

import StatusTag from '@components/status-tag/status-tag'
import TableEntry from '@components/table/table-data/table-entry'
import { type UserProgram } from '@interfaces/api/user-program'

const UserProgramTableEntry = (userProgram: UserProgram) => {
  const { id = '' } = useParams()
  const navigate = useNavigate()
  const {
    finishedAt,
    program,
    startAt,
    status,
    uid,
    user
  } = userProgram

  const onClick = () => {
    navigate(`/users/${id || (user?.id ?? '')}/user-program/${uid}`)
  }

  return (
    <TableEntry onClick={onClick}>
      <td className='p-4'>
        {program.title}
      </td>

      <td className='p-4 tabular-nums'>
        {(new Date(startAt)).toLocaleDateString()}
      </td>

      <td className='p-4 tabular-nums'>
        {finishedAt && (new Date(finishedAt)).toLocaleDateString()}
      </td>

      <td className='p-4'>
        <StatusTag status={status} />
      </td>
    </TableEntry>
  )
}

export default UserProgramTableEntry
