import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import ApiResourceSingleEntry from '@components/api-resource/api-resource-single-entry'
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import { Recipe } from '@pages/recipe'
import { recipesRoles } from '@services/roles/roles'

const RecipeEntry = () => {
  const [apiResponse, setApiResponse] = useState('')
  const { t: translateProgram } = useTranslation('recipe', { keyPrefix: 'entry' })
  const { t: bc } = useTranslation('breadcrumbs')
  const { id = '' } = useParams()

  const breadcrumbs = [
    { href: '/recipes', name: bc('recipes') },
    { href: '', name: apiResponse ?? id }
  ]

  const onSuccess = (res) => {
    setApiResponse(res.title)
  }

  return (
    <Layout description={translateProgram('description')} title={`${translateProgram('title')} - ${apiResponse}`}>
      <Guard acceptedRoles={recipesRoles}>

        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <>
          {id && (
            <ApiResourceSingleEntry
              component={Recipe}
              id={id}
              onSuccess={onSuccess}
              path={'recipes/{uid}'}
            />
          )}
        </>
      </Guard>
    </Layout>
  )
}

export default RecipeEntry
