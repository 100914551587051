import { type ExercicesValues } from '@components/program/exercise-entry'
import { type UserProgramCourseExercise } from '@components/user-program-entry/user-program-entry-interfaces'
import { type ExerciseEntry, type ExercisesListEntry } from '@interfaces/api/exercise/exercise-entry'
import { reorderEntries } from '@services/tools/api-resources/reorder-list'

const convertExercisesToPayload = (exercise: ExerciseEntry | UserProgramCourseExercise) => {
  return {
    effortDuration: exercise.effortDuration,
    exercise: `/api/exercises/${exercise.exercise.uid}`,
    isWarmup: exercise.isWarmup,
    repetitionCount: exercise.repetitionCount,
    restDuration: exercise.restDuration,
    setCount: exercise.setCount,
    type: exercise.type
  }
}

const appendExercise = (exercise: ExercisesListEntry, list: ExerciseEntry[] | UserProgramCourseExercise[] = []) => {
  const newExerciseList = list.map(exercise => convertExercisesToPayload(exercise))
  newExerciseList.push(exercise)

  return newExerciseList
}

const deleteExercise = (exerciseId: string, list: ExerciseEntry[] = []) => {
  const newExerciseList = list.filter(entry => entry.uid !== exerciseId)

  return newExerciseList.map(exercise => convertExercisesToPayload(exercise))
}

const deleteUserProgramExercise = (position: number, list: UserProgramCourseExercise[] = []) => {
  const newExerciseList = list.filter(entry => entry.position !== position)

  return newExerciseList.map(exercise => convertExercisesToPayload(exercise))
}

const reorderExercises = (direction: number, exerciseId: string, list: ExerciseEntry[] = []) => {
  const newExerciseList = reorderEntries<ExerciseEntry>(list, exerciseId, direction)

  return newExerciseList.map(exercise => convertExercisesToPayload(exercise))
}

const reorderUserProgramExercises = (list: UserProgramCourseExercise[] = []) => {
  return list.map(exercise => convertExercisesToPayload(exercise))
}

const editExercices = (uid: string, values: ExercicesValues, list: ExerciseEntry[] = []) => {
  const exerciseIndex = list.findIndex(entry => entry.uid === uid)

  if (exerciseIndex !== -1) {
    const exercise = list[exerciseIndex]

    list.splice(exerciseIndex, 1)
    const newExercise = { ...exercise, ...values }

    list.splice(exerciseIndex, 0, newExercise)
  }

  return list.map(exercise => convertExercisesToPayload(exercise))
}

const editUserProgramExercices = (uid: string, position: number, values: ExercicesValues, list: UserProgramCourseExercise[] = []) => {
  const exerciseIndex = list.findIndex(entry => entry.exercise.uid === uid && entry.position === position)

  if (exerciseIndex !== -1) {
    const exercise = list[exerciseIndex]

    list.splice(exerciseIndex, 1)
    const newExercise = { ...exercise, ...values }

    list.splice(exerciseIndex, 0, newExercise)
  }

  return list.map(exercise => convertExercisesToPayload(exercise))
}

export { appendExercise, deleteExercise, deleteUserProgramExercise, reorderExercises, reorderUserProgramExercises, editExercices, editUserProgramExercices, convertExercisesToPayload }
