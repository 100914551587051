import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import ApiResourceEdit from '@components/api-resource/api-resource-edit'
import ResourceHead from '@components/api-resource/resource-head/resource-head'
import ContextualButton from '@components/buttons/contextual-button'
import { type MealplanHeadProps } from '@components/mealplan/mealplan-head/mealplan-head-interfaces'
import Modal from '@components/modals/modal'
import { type Program } from '@interfaces/api/program'
import useApiResource from '@services/api/definition'
import useCloneEntry from '@services/api/resources/clone-entry-query'
import { captureException } from '@services/exceptions/capture-exception'
import { Copy, Edit } from '@svg/icons'

const MealplanHead = ({ mealplan }: MealplanHeadProps) => {
  const { description, image, introVideo, status, title, uid } = mealplan
  const { t: translateActions } = useTranslation('apiResources', { keyPrefix: 'actions' })
  const { t: translateResource } = useTranslation('apiResources', { keyPrefix: 'edit' })
  const navigate = useNavigate()

  const definition = useApiResource('mealplans/{uid}')
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [openEditModal, setOpenEditModal] = useState<boolean>(false)
  const { mutateAsync: cloneResourceEntry } = useCloneEntry('api/mealplans/clone')

  const onEditHandler = () => {
    setOpenEditModal(true)
  }

  const onEdit = () => {
    setOpenEditModal(false)
  }

  const onCloneHandler = async () => {
    try {
      const result = await cloneResourceEntry({ original: mealplan['@id'] }) as Program

      navigate(`/mealplans/${result.uid}`)
    } catch (err) {
      captureException(err as Error)
    }
  }

  const handleCancelClick = () => {
    setOpenModal(false)
  }

  const handleDuplicateClick = () => {
    setOpenModal(true)
  }

  return (
    <div className='flex justify-between flex-col lg:flex-row gap-4'>
      <ResourceHead description={description} image={image} status={status} title={title} video={introVideo} />

      {/* Buttons */}

      <div className='flex lg:flex-col items-end gap-2'>
        <ContextualButton icon={Copy} onClick={handleDuplicateClick}>
          {translateActions('clone')}
        </ContextualButton>

        <ContextualButton icon={Edit} onClick={onEditHandler}>
          {translateActions('edit')}
        </ContextualButton>

        <Modal center open={openModal} setOpen={setOpenModal} title={translateActions('duplicateResource')}>
          <div className='flex flex-col items-center p-4'>
            <div className='text-center text-xl'>{translateActions('duplicateConfirmation')}</div>

            <div className='flex mt-6 gap-8'>
              <ContextualButton onClick={onCloneHandler}>{translateActions('clone')}</ContextualButton>

              <ContextualButton onClick={handleCancelClick}>{translateActions('cancel')}</ContextualButton>
            </div>
          </div>
        </Modal>
      </div>

      {/* Edit Modal */}

      <Modal center open={openEditModal} setOpen={setOpenEditModal} size='large' title={translateResource('mealplans')}>
        {uid && definition && (
          <ApiResourceEdit definition={definition} id={uid} onEdit={onEdit} />
        )}
      </Modal>
    </div>
  )
}

export default MealplanHead
