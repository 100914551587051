import { TrashIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { queryClient } from '@app'
import Date from '@components/api-resource/resource-entry-fields/date'
import ContextualButton from '@components/buttons/contextual-button'
import Modal from '@components/modals/modal'
import { type AudioEntryProps } from '@components/table/table-data/audio-table-entry-interfaces'
import { type FormattedApiListResponse } from '@interfaces/api'
import { type AudioTemplate } from '@interfaces/api/audio-templates'
import useDeleteAudioTemplatesMutation from '@services/api/audio/audio-templates/use-delete-audio-templates-mutations'
import { captureException } from '@services/exceptions/capture-exception'

const AudioTableEntry = ({ audio, categoryUid, createdAt, title, uid }: AudioEntryProps) => {
  const { t } = useTranslation('audio')
  const { t: translateActions } = useTranslation('apiResources', { keyPrefix: 'actions' })

  const [openModal, setOpenModal] = useState<boolean>(false)
  const { mutateAsync: deleteAudio } = useDeleteAudioTemplatesMutation()

  const handleClick = () => {
    setOpenModal(true)
  }

  const onDeleteAudio = (templateUid) => async () => {
    try {
      await deleteAudio(templateUid)

      queryClient.setQueryData<FormattedApiListResponse<AudioTemplate>>(
        [`audio-template-categories/${categoryUid}/templates`], (data) => {
          if (data) {
            const templates = data['hydra:member']
            const templateIndex = templates.findIndex(template => template.uid === templateUid)
            templates.splice(templateIndex, 1)

            return data
          }
        }
      )
    } catch (err) {
      captureException(err as Error)
    } finally {
      setOpenModal(false)
    }
  }

  const handleCancelClick = () => {
    setOpenModal(false)
  }

  return (
    <tr className='bg-white text-gray-700 w-full'>
      <td className='p-4 text-lg font-normal w-1/3'>
        {title}
      </td>

      <td className='p-3 text-sm w-1/3'>
        <audio controls src={audio.url} />
      </td>

      <td className='p-4'>
        <Date value={createdAt} />
      </td>

      <td className='p-4'>
        <button className='flex items-center text-gray-700 gap-2 py-1 px-2 hover:bg-red-500 hover:border-red-500 hover:text-white text-base rounded-md bg-gray-100 cursor-pointer border border-gray-100' onClick={handleClick}>
          <TrashIcon className='w-5 h-5 mx-auto' />

          <span>{translateActions('delete')}</span>
        </button>

        <Modal center open={openModal} setOpen={setOpenModal} title={translateActions('deleteResource')}>
          <div className='flex flex-col items-center p-2'>
            <div className='text-center text-xl'>{t('deleteConfirmation')}</div>

            <div className='flex mt-6 gap-8'>
              <ContextualButton onClick={onDeleteAudio(uid)} style='warning'>{t('delete')}</ContextualButton>

              <ContextualButton onClick={handleCancelClick}>{t('cancel')}</ContextualButton>
            </div>
          </div>
        </Modal>
      </td>
    </tr>
  )
}

export default AudioTableEntry
