import { useTranslation } from 'react-i18next'

import ContextualButton from '@components/buttons/contextual-button'
import EmptyState from '@components/empty-state'
import ModuleEntry from '@components/program/module-entry'
import { type ModuleListProps } from '@components/program/module-list/index'
import { type Module } from '@interfaces/api/module'
import useCreateEntry from '@services/api/resources/create-entry-query'
import useItemChildrenQuery from '@services/api/resources/item-children'
import { captureException } from '@services/exceptions/capture-exception'
import { getIdList } from '@services/tools/api-resources/get-id-list'
import { CircleAdd } from '@svg/icons'

const ModuleList = ({ programId }: ModuleListProps) => {
  const { t: translateProgram } = useTranslation('program')

  const {
    data: {
      data: modules
    } = {},
    isFetching,
    refetch
  } = useItemChildrenQuery<Module>({
    itemId: programId,
    path: 'programs/{uid}/modules'
  })

  const moduleIds = modules ? getIdList(modules) : []

  const reload = () => {
    refetch().catch((err) => {
      captureException(err as Error)
    })
  }

  const { mutateAsync: createResourceEntry } = useCreateEntry({ path: 'program-modules' })

  const onModuleAdd = () => {
    createResourceEntry({
      disable: false,
      program: `/api/programs/${programId}`
    }).then(() => {
      reload()
    }).catch(() => {
      console.error('Failed to add module to program', programId)
    })
  }

  const onModuleChange = () => {
    reload()
  }

  return (
    <div>
      {modules && modules.length > 0 && !isFetching
        ? (
          <div className='mb-2 flex flex-col gap-2'>
            {modules.map((module, index) => (
              <ModuleEntry
                idList={moduleIds}
                key={module.uid}
                module={module}
                onModuleChange={onModuleChange}
                position={index + 1}
                programId={programId}
              />
            ))}
          </div>
        )
        : (
          <EmptyState>
            <div className='flex flex-col'>
              <div className='text-gray-900 text-sm'>{translateProgram('noModule')}</div>

              <div className='text-gray-500 text-xs'>{translateProgram('selectModule')}</div>
            </div>
          </EmptyState>
        )}

      <div className='flex justify-end'>
        <ContextualButton icon={CircleAdd} onClick={onModuleAdd}>
          {translateProgram('addModule')}
        </ContextualButton>
      </div>
    </div>
  )
}

export default ModuleList
