import { CogIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

import Modal from '@components/modals/modal'
import { type ModalVideoPlayerProps } from '@components/modals/modal-video-player/index'
import { VideoStatus } from '@interfaces/api/video'

const ModalVideoPlayer = ({ open, setOpen, video }: ModalVideoPlayerProps) => {
  const { t } = useTranslation('videos')
  const url = video.formats?.[0].url
  const isTranscoding = video.status === VideoStatus.TRANSCODING

  return (
    <Modal center fullscreen={!isTranscoding} open={open} setOpen={setOpen} title={isTranscoding ? t('title') : ''}>
      {isTranscoding && (
        <div className='flex flex-col items-center px-6 py-12 text-center'>
          <CogIcon className='w-8 h-8 mb-4 text-gray-700 animate-spin' />

          <strong className='text-gray-700'>{t('transcoding')}</strong>

          <p className='text-gray-900 text-sm'>{t('transcodingDescription')}</p>
        </div>
      )}

      {url && (
        <video
          autoPlay
          className='max-h-[75vh] max-w-[75vw] bg-gray-900 rounded-lg overflow-hidden'
          controls
          src={url}
        />
      )}
    </Modal>
  )
}

export default ModalVideoPlayer
