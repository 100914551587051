import { useTranslation } from 'react-i18next'

import ApiResourceDelete from '@components/api-resource/api-resource-delete'
import ContextualButton from '@components/buttons/contextual-button'
import DeleteButtonWithLabel from '@components/buttons/delete-button-with-label'
import Modal from '@components/modals/modal'
import { type ModalDeleteEntryProps } from '@components/modals/modal-delete-entry/modal-delete-entry-interfaces'

const ModalDeleteEntry = ({ definition, entryId, onDelete, open, setOpen }: ModalDeleteEntryProps) => {
  const { t: translateActions } = useTranslation('apiResources', { keyPrefix: 'actions' })

  const handleCancelClick = () => {
    setOpen(false)
  }

  return (
    <Modal center open={open} setOpen={setOpen} title={translateActions('deleteResource')}>
      <div className='flex flex-col items-center p-8'>
        <div className='text-center text-xl'>{translateActions('deleteConfirmation')}</div>

        <div className='flex mt-6 gap-8'>
          {definition && (
            <ApiResourceDelete
              component={DeleteButtonWithLabel}
              definition={definition}
              id={entryId}
              onDelete={onDelete}
            />
          )}

          <ContextualButton onClick={handleCancelClick}>{translateActions('cancel')}</ContextualButton>
        </div>
      </div>
    </Modal>
  )
}

export default ModalDeleteEntry
