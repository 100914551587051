import { type UseMutationResult } from '@tanstack/react-query'
import { Form, Formik } from 'formik'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import ButtonLoader from '@components/buttons/button-loader/button-loader'
import ColorPickerButton from '@components/color-picker-button/color-picker-button'
import FormFileField from '@components/form-fields/form-file-field'
import Layout from '@components/layout/layout'
import SectionTitle from '@components/section-title/section-title'
import { type ApiReponseError } from '@interfaces/api'
import Guard from '@root/components/guard/guard'
import usePutEntry from '@services/api/resources/put-entry-query'
import useImage from '@services/api/upload-image/get-image'
import { useUploadImage } from '@services/api/upload-image/upload-image'
import { captureException } from '@services/exceptions/capture-exception'
import { themeRoles } from '@services/roles/roles'
import { useThemeStore } from '@services/stores/theme/theme'
import { Settings } from '@svg/icons'

const Theme = () => {
  const { t: translateTheme } = useTranslation('theme')
  const { t: bc } = useTranslation('breadcrumbs')
  const { t: tError } = useTranslation('errors')

  const { logo, primary, secondary, setLogo, setPrimary, setSecondary } = useThemeStore()

  const { mutateAsync: uploadImageAsync } = useUploadImage()

  const { mutateAsync: editValue } = usePutEntry('/api/key-value-store/TEST')

  const [primaryColor, setPrimaryColor] = useState(primary)
  const [secondaryColor, setSecondaryColor] = useState(secondary)

  const breadcrumbs = [
    { href: '', name: bc('theme') }
  ]

  const onSaveValues = () => {
    const value = {
      primary: primaryColor,
      secondary: secondaryColor
    }

    const valueStringify = JSON.stringify(value)

    editValue({ value: valueStringify }).catch(captureException)
  }

  const onChangePrimary = () => {
    setPrimary(primaryColor)
    onSaveValues()
  }

  const onChangeSecondary = () => {
    setSecondary(secondaryColor)
    onSaveValues()
  }

  const onSubmit = async (values, { setErrors, setSubmitting }) => {
    const formData = new FormData()
    formData.append('file', values.imageLogo)
    try {
      const imageId = await uploadImageAsync(formData)
      values.imageLogo = imageId
      setLogo(values.imageLogo)
      const value = {
        logo: imageId,
        primary: primaryColor,
        secondary: secondaryColor
      }

      const valueStringify = JSON.stringify(value)

      editValue({ value: valueStringify }).catch(captureException)
    } catch (e) {
      const err = e as UseMutationResult<ApiReponseError>
      const errorCode = err.data?.errorCode ?? 'generic'
      setErrors({ imageLogo: tError(errorCode) })
    }
  }

  const { data: imageData } = useImage(logo)

  const initialValues = { logo: imageData }

  return (
    <Layout description={translateTheme('description')} title={translateTheme('title')}>
      <Guard acceptedRoles={themeRoles}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <SectionTitle description={translateTheme('description')} icon={Settings} title={translateTheme('title')} />

        <div className='px-4 sm:px-6 md:px-8 flex flex-col items-start gap-4'>
          <div className='flex bg-gray-100 items-center border-2 rounded-md py-2 px-6 gap-4'>
            <div className='font-medium text-lg'>{translateTheme('primaryColor')}</div>

            <ColorPickerButton color={primaryColor} onChange={onChangePrimary} setColor={setPrimaryColor} />
          </div>

          <div className='flex bg-gray-100 items-center border-2 rounded-md py-2 px-6 gap-4'>
            <div className='font-medium text-lg'>{translateTheme('secondaryColor')}</div>

            <ColorPickerButton color={secondaryColor} onChange={onChangeSecondary} setColor={setSecondaryColor} />
          </div>
        </div>

        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, setFieldValue, values }) => {
            return (
              <Form className='space-y-6 w-1/6'>
                <div className='flex flex-col p-8 gap-4'>
                  <div className='flex flex-col gap-3 flex-none'>
                    <FormFileField
                      label={'Logo'}
                      name={'imageLogo'}
                      required
                      setFieldValue={setFieldValue}
                      value={values.logo}
                    />
                  </div>

                  <button
                    className={'flex gap-2 items-center justify-center rounded-lg px-4 py-2 text-lg font-semibold tracking-wide text-white fill-white hover:bg-gray-600 hover:text-white bg-gray-900 hover:bg-gray-600 w-auto'}
                    disabled={isSubmitting}
                    type='submit'
                  >
                    {isSubmitting
                      ? <ButtonLoader />
                      : (
                        <>
                          Valider
                        </>
                      )}
                  </button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </Guard>
    </Layout>
  )
}

export default Theme
