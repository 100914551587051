import { type UseMutationResult } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import ApiResourceList from '@components/api-resource/api-resource-list'
import ContextualButton from '@components/buttons/contextual-button'
import Modal from '@components/modals/modal'
import { type AddUserMealplansProps } from '@components/user/user-mealplans/user-mealplans-interfaces'
import { type ApiReponseError } from '@interfaces/api'
import { type UserMealplan } from '@interfaces/api/user-mealplan'
import useCreateEntry from '@services/api/resources/create-entry-query'
import { CircleAdd } from '@svg/icons'

const AddUserMealplans = ({ definition, onSuccess, userId }: AddUserMealplansProps) => {
  const { t: translateUserMealplans } = useTranslation('user', { keyPrefix: 'mealplans' })
  const { t: translateError } = useTranslation('errors')
  const { mutateAsync: createResourceEntry } = useCreateEntry({ path: 'user-mealplans' })

  const [openModal, setOpenModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>()

  const handleClick = () => {
    setOpenModal(true)
  }

  const onEntryClick = async (entry) => {
    const values = {
      mealplan: entry['@id'],
      user: `/api/users/${userId}`
    }

    try {
      await createResourceEntry(values) as UserMealplan
      onSuccess()
      setOpenModal(false)
    } catch (e) {
      const err = e as UseMutationResult<ApiReponseError>
      const errorCode = err.data?.errorCode ?? 'generic'
      setErrorMessage(translateError(errorCode))
    }
  }

  return (
    <div className='flex justify-end mt-2'>
      <ContextualButton icon={CircleAdd} onClick={handleClick}>
        {translateUserMealplans('addMealplans')}
      </ContextualButton>

      <Modal center open={openModal} setOpen={setOpenModal} size='large' title={translateUserMealplans('addMealplans')}>
        <div className='flex relative flex-col pb-4 pt-8 max-h-[80vh]'>
          <ApiResourceList definition={definition} onEntryClick={onEntryClick} />

          {errorMessage && <div className='text-red-700 flex font-medium mb-2 self-center text-lg absolute bottom-0 border border-red-700 px-2 rounded-md'>{errorMessage}</div>}
        </div>
      </Modal>
    </div>
  )
}

export default AddUserMealplans
