import { useQuery, type UseQueryResult } from '@tanstack/react-query'

import { type AnalyseDay } from '@interfaces/api/analyse-day'
import { type AnalysePeriodDaysResponse } from '@interfaces/api/analyse-period-days'
import { type ApiError, type ApiPlatformListResponse } from '@interfaces/api/api'
import { type RessourceRequestParameters } from '@interfaces/api/request'
import useAxios from '@services/api/axios'
import { formatApiListResponse } from '@services/tools/api-resources/format-list-response'

const useAnalysePeriodDays = (uid: string): UseQueryResult<AnalysePeriodDaysResponse> => {
  const { get } = useAxios()

  return useQuery<AnalysePeriodDaysResponse, ApiError>({
    queryFn: async () => {
      return await get<RessourceRequestParameters, ApiPlatformListResponse<AnalyseDay>>(`api/analyse-periods/${uid}/days`)
        .then(result => formatApiListResponse<AnalyseDay>(result))
    },
    queryKey: ['analyse-period-days', uid],
    retry: false
  })
}

export default useAnalysePeriodDays
