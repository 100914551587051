import { useTranslation } from 'react-i18next'

import Duration from '@components/api-resource/resource-entry-fields/duration'
import RecipeHead from '@components/recipe/recipe-head'
import { type RecipeProps as Props } from '@pages/recipe/recipe.interfaces'

const Recipe = ({ data: recipe }: Props) => {
  const { t } = useTranslation('recipe', { keyPrefix: 'entry' })

  return (
    <>
      <RecipeHead recipe={recipe} />

      <div className='mt-12 mb-8'>
        <div className='flex flex-col'>
          <div className='block text-sm font-medium text-gray-700'>{t('duration')}</div>

          <Duration value={recipe.duration} />

          {!!recipe.video && (
            <video
              className='max-h-[75vh] md:max-w-[75vw] bg-gray-900 rounded-lg overflow-hidden'
              controls
              playsInline
              poster={recipe.video.image?.url}
            >
              {recipe.video.formats?.map(video => (
                <source
                  key={video?.['@id']}
                  sizes={`${video?.size}`}
                  src={video?.url}
                />
              ))}
            </video>
          )}
        </div>
      </div>
    </>
  )
}

export default Recipe
