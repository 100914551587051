import { useNavigate } from 'react-router-dom'

import ApiResourceCreate from '@components/api-resource/api-resource-create'
import useApiResource from '@services/api/definition'

const MealplanCreate = () => {
  const definition = useApiResource('mealplans')
  const navigate = useNavigate()

  const onCreate = (iriReference: string) => {
    const splitRef = iriReference.split('/')
    const uid = splitRef[splitRef.length - 1]
    navigate(`/mealplans/${uid}`)
  }

  return (
    <div>
      {definition && <ApiResourceCreate definition={definition} onEdit={onCreate} />}
    </div>
  )
}

export default MealplanCreate
