import { useTranslation } from 'react-i18next'

import { type UserProgramModulesProps } from '@components/user-program-entry/user-program-entry-interfaces'
import UserProgramModuleEntry from '@components/user-program-entry/user-program-module-entry'
import EmptyState from '@root/components/empty-state'

const UserProgramModules = ({ modules, programUid, status }: UserProgramModulesProps) => {
  const { t: translateProgram } = useTranslation('program')

  return (
    <div>
      {modules && modules.length > 0
        ? (
          <div className='mb-4 flex flex-col gap-2'>
            {modules.map((module, index) => {
              return (
                <UserProgramModuleEntry
                  key={module.uid}
                  module={module}
                  position={index + 1}
                  programId={programUid}
                  status={status}
                />
              )
            })}
          </div>
        )
        : (
          <EmptyState>
            <div className='flex flex-col'>
              <div className='text-gray-900 text-sm'>{translateProgram('noModule')}</div>

              <div className='text-gray-500 text-xs'>{translateProgram('selectModule')}</div>
            </div>
          </EmptyState>
        )}
    </div>
  )
}

export default UserProgramModules
