import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import { type BreadcrumbsProps } from '@components/breadcrumbs/breadcrumbs-interfaces'
import { ChevronDown, Home } from '@svg/icons'

const Breadcrumbs = ({ breadcrumbs }: BreadcrumbsProps) => {
  const { t: translateGeneral } = useTranslation('general', { keyPrefix: 'sidebar' })

  return (
    <div className='mb-4 mx-auto px-4 sm:px-6 md:px-8 w-full'>
      <nav aria-label='Breadcrumb' className='flex'>
        <ol className='flex space-x-4'>
          <li className='flex uppercase font-medium py-2 tracking-wider'>
            <div className='flex items-center gap-2 flex-wrap'>
              <a className='flex items-center gap-2 text-gray-500 text-xs hover:text-secondary' href='/'>
                <Home className='h-4 w-4 flex-shrink-0 fill-gray-500' />

                <span>{translateGeneral('home')}</span>
              </a>

              {breadcrumbs?.map((breadcrumb, index) => {
                return (
                  <Fragment key={index}>
                    <ChevronDown className='h-4 w-4 fill-gray-500 -rotate-90' />

                    <a
                      className='text-xs text-gray-500 hover:text-secondary'
                      href={breadcrumb.href}
                    >
                      {breadcrumb.name}
                    </a>
                  </Fragment>
                )
              })
              }
            </div>
          </li>
        </ol>
      </nav>
    </div>
  )
}

export default Breadcrumbs
