import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { type AnalyseDayMoreData, type AnalyseDayProps } from '@components/analyse-day/analyse-day-interfaces'
import AnalyseMoreData from '@components/analyse-day/analyse-more-data/analyse-more-data'
import PhotoContainer from '@components/photo-container/photo-container'
import UserMoreData from '@components/user/user-more-data/user-more-data'
import useAnalyseDayQuery from '@services/api/analyse-day/use-analyse-day-query'

const AnalyseDay = ({ analyseDay, index }: AnalyseDayProps) => {
  const { t: translateAnalyseDay } = useTranslation('analysePeriods', { keyPrefix: 'day' })
  const { t: translateDays } = useTranslation('calendar', { keyPrefix: 'days' })
  const { id = '' } = useParams()
  const { data } = useAnalyseDayQuery(analyseDay.uid)

  if (!data) {
    return null
  }

  const getDayByIndex = (index) => {
    switch (index) {
      case 1 : return translateDays('monday')
      case 2 : return translateDays('tuesday')
      case 3 : return translateDays('wednesday')
      case 4 : return translateDays('thursday')
      case 5 : return translateDays('friday')
      default: return null
    }
  }

  const dayIndex = new Date().getDay()

  const moreData: AnalyseDayMoreData = JSON.parse(data.dayEvolution.moreData?.[0] ?? '{}')

  const bodyPictures = [
    {
      imageSrc: data.dayEvolution.bodyPhoto1?.url,
      name: translateAnalyseDay('frontPicture')
    },
    {
      imageSrc: data.dayEvolution.bodyPhoto2?.url,
      name: translateAnalyseDay('profilePicture')
    },
    {
      imageSrc: data.dayEvolution.bodyPhoto3?.url,
      name: translateAnalyseDay('backPicture')
    }
  ]

  const mealsPictures = [
    {
      checkbox: moreData.checkBoxes?.morning,
      imageSrc: data.dayEvolution.mealPhoto1?.url,
      name: translateAnalyseDay('morning')
    },
    {
      checkbox: moreData.checkBoxes?.noon,
      imageSrc: data.dayEvolution.mealPhoto2?.url,
      name: translateAnalyseDay('noon')
    },
    {
      checkbox: false,
      imageSrc: data.dayEvolution.mealPhoto3?.url,
      name: translateAnalyseDay('snack')
    },
    {
      checkbox: moreData.checkBoxes?.evening,
      imageSrc: data.dayEvolution.mealPhoto4?.url,
      name: translateAnalyseDay('evening')
    }
  ]

  return (
    <div>
      {index === 1 && (
        <div className='px-4 flex flex-col sm:flex-row gap-6 mb-4'>
          <UserMoreData userId={Number(id)} />

          <AnalyseMoreData moreData={moreData} />
        </div>
      )}

      <div className='bg-gray-100 rounded-lg px-4 pt-2 pb-4'>
        <div className='flex items-center gap-2'>
          <div className='font-medium text-gray-700'>{getDayByIndex(index)}</div>

          {dayIndex === index && <span className='text-[10px] text-white font-bold bg-gray-900 rounded-full px-1.5 py-1 leading-3'>{translateAnalyseDay('today')}</span>}
        </div>

        {/* Body pictures */}

        {index === 1 && <PhotoContainer pictureList={bodyPictures} title={translateAnalyseDay('bodyPicture')} />}

        {/* Meal pictures */}

        <PhotoContainer pictureList={mealsPictures} title={translateAnalyseDay('mealsPicture')} withCheckbox />

      </div>
    </div>
  )
}

export default AnalyseDay
