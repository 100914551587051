import { useQuery, type UseQueryResult } from '@tanstack/react-query'

import { type ApiPlatformListResponse } from '@interfaces/api/api'
import { type CalendarDay, type CalendarDaysResponse } from '@interfaces/api/calendar'
import useAxios from '@services/api/axios'
import { formatApiListResponse } from '@services/tools/api-resources/format-list-response'

interface CalendarListParameters {
  parameters: CalendarParameters
}

interface CalendarParameters {
  end_date: string
  start_date: string
  timezone: string
}

const useCalendarDaysListQuery = ({ parameters }: CalendarListParameters): UseQueryResult<CalendarDaysResponse> => {
  const { get } = useAxios()

  const queryKey = ['calendar-days', parameters]
  const queryFn = async () => {
    return await get<CalendarParameters, ApiPlatformListResponse<CalendarDay>>('api/calendar-days', parameters)
      .then(result => formatApiListResponse<CalendarDay>(result))
  }

  return useQuery({
    queryFn,
    queryKey
  })
}

export default useCalendarDaysListQuery
