import { type ApiResourceSingleEntryProps } from '@components/api-resource/api-resource-single-entry/index'
import useApiResourceItemQuery from '@services/api/resources/item-query'

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const ApiResourceSingleEntry = <T extends unknown>({
  component: Component,
  id,
  path,
  ...props
}: ApiResourceSingleEntryProps<T>) => {
  const { data } = useApiResourceItemQuery<T>({
    id,
    path,
    refetchOnMount: 'always',
    ...props
  })

  return (
    <div className='px-6'>
      {data && Component && (<Component data={data} />)}
    </div>
  )
}

export default ApiResourceSingleEntry
