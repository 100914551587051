import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  type DayTimePickerProps,
  type WorkScheduleDayList
} from '@components/form-fields/form-workschedule-field/form-workschedule-field-interfaces'
import TimePickerRange from '@components/form-fields/form-workschedule-field/time-picker-range'
import { updateDuration } from '@services/tools/update-duration'

const DayTimePicker = ({ dayIndex, initialValues, setTimesValues, timesValues }: DayTimePickerProps) => {
  const { t: translateActions } = useTranslation('apiResources', { keyPrefix: 'actions' })
  const [daySchedule, setDaySchedule] = useState<WorkScheduleDayList>([])
  const [isActive, setIsActive] = useState<boolean>(false)
  const defaultRange = ['08:00', '16:00']

  useEffect(() => {
    if (initialValues.length) {
      setDaySchedule(initialValues[dayIndex])

      if (initialValues[dayIndex].length) {
        setIsActive(true)
      } else {
        setIsActive(false)
      }
    }
  }, [initialValues])

  const handleAddRange = (rangeIndex) => {
    const previousRange = daySchedule[rangeIndex]
    const newStarDuration = updateDuration(previousRange[1])
    const newEndDuration = updateDuration(newStarDuration)

    const newDaySchedule = [...daySchedule]
    newDaySchedule.push([newStarDuration, newEndDuration])
    setDaySchedule(newDaySchedule)
  }

  const handleDeleteRange = (rangeIndex) => {
    const newDaySchedule = [...daySchedule]
    newDaySchedule.splice(rangeIndex, 1)
    setDaySchedule(newDaySchedule)
  }

  useEffect(() => {
    const newTimes = [...timesValues]
    newTimes[dayIndex] = daySchedule
    setTimesValues(newTimes)
  }, [daySchedule])

  const handleClick = () => {
    setIsActive(!isActive)

    if (isActive) {
      setDaySchedule([])
    } else {
      setDaySchedule([defaultRange])
    }
  }

  return (
    <div className='flex flex-col gap-2'>
      {!isActive
        ? <button className='absolute top-2 right-2 self-start flex gap-2 items-center rounded-lg border-2 border-gray-200 px-2 py-1 uppercase text-xs font-medium bg-white text-gray-700 hover:border-gray-700 hover:bg-gray-800 hover:text-white' onClick={handleClick} type='button'>{translateActions('add')}</button>
        : (
          <>
            {daySchedule.map((range, index) => {
              return (
                <TimePickerRange
                  daySchedule={daySchedule}
                  defaultRange={defaultRange}
                  isLastRange={index === daySchedule.length - 1}
                  key={index}
                  onAddRange={handleAddRange}
                  onDeletteRange={handleDeleteRange}
                  range={range}
                  rangeIndex={index}
                  setDaySchedule={setDaySchedule}
                />
              )
            })}

            <button className='absolute top-2 right-2 self-start flex gap-2 items-center rounded-lg border px-2 py-1 shadow uppercase text-xs font-medium border-red-500 bg-white text-red-500 hover:bg-red-500 hover:text-white' onClick={handleClick} type='button'>{translateActions('delete')}</button>
          </>
        )
      }
    </div>
  )
}

export default DayTimePicker
