import { BellIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { Form, Formik, type FormikConfig } from 'formik'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import ButtonLoader from '@components/buttons/button-loader/button-loader'
import FormTextField from '@components/form-fields/form-text-field'
import FormTextAreaField from '@components/form-fields/form-textarea-field'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import MobileMockupNotification from '@components/mobile-mockup-notification/mobile-mockup-notification'
import SectionTitle from '@components/section-title/section-title'
import Steps from '@pages/notifications/steps'
import { programsRoles } from '@services/roles/roles'

interface FormValues {
  content: string
  link: string
  planned_at: Date | null
  title: string
}

const NewNotification = () => {
  const { t: translateNotifications } = useTranslation('notification', { keyPrefix: 'new' })
  const { t: bc } = useTranslation('breadcrumbs')
  const [currentStep, setCurrentStep] = useState(1)
  const [success, setSuccess] = useState(false)

  const breadcrumbs = [
    { href: '', name: bc('programs') }
  ]

  const FormSchema = Yup.object({
    content: Yup.string().required('Requis'),
    title: Yup.string().required('Requis')
  })

  const onSubmit: FormikConfig<FormValues>['onSubmit'] = async (values, { setFieldTouched, setFieldValue, setSubmitting }) => {
    if (currentStep < 2) {
      setCurrentStep(currentStep + 1)

      return
    }

    setSuccess(true)
  }

  const steps = [
    { id: '01', name: 'Création notification' },
    { id: '02', name: 'Ciblage et programmation' }
  ]

  const initialValues: FormValues = {
    content: 'N\'attendez plus et découvrez le nouveau programme !',
    link: '',
    planned_at: null,
    title: 'Nouveau programme'
  }

  return (
    <Layout title={translateNotifications('title')}>
      <Guard acceptedRoles={programsRoles}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <SectionTitle icon={BellIcon} title={translateNotifications('title')} />

        <Formik
          autoComplete='off'
          enableReinitialize
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={FormSchema}
        >
          {({ isSubmitting, values }) => {
            return (
              <div>
                <Steps currentStep={currentStep} setCurrentStep={setCurrentStep} steps={steps} />

                <div className='flex md:flex-row flex-col pl-4 pr-2 sm:pr:4 sm:pl-6 md:pr-6 md:pl-8'>
                  <aside className='flex-1 py-6 lg:col-span-3'>
                    <Form className='lg:col-span-9 bg-white shadow shadow-gray-200 border-1 border-gray-50 rounded-lg'>

                      <div className='px-4 sm:p-6 lg:pb-8'>

                        <div className='mt-6 flex-grow space-y-6'>

                          {currentStep === 1 && (
                            <>
                              <FormTextField label='Titre' name='title' required />

                              <FormTextAreaField label='Contenu' name='content' required />
                            </>
                          )}

                          {currentStep === 2 && (
                            <>
                              <FormTextField label="Planifier l'envoi" name='planned_at' type='datetime-local' />
                            </>
                          )}
                        </div>
                      </div>

                      <div className='flex justify-end gap-x-3 py-4 px-4 sm:px-6'>

                        <button
                          className={'flex gap-2 items-center justify-center rounded-lg px-4 py-2 text-lg font-semibold tracking-wide text-white fill-white hover:bg-gray-600 hover:text-white bg-gray-900 hover:bg-gray-600'}
                          disabled={isSubmitting}
                          type='submit'
                        >
                          {isSubmitting
                            ? <ButtonLoader />
                            : (
                              <>
                                {success ? 'Envoyé' : currentStep === steps.length ? (values.planned_at ? 'Programmer l\'envoi (bientôt dispo)' : 'Envoyer maintenant (bientôt dispo)') : 'Suivant'}

                                <ChevronRightIcon className='w-6 h-6' />
                              </>
                            )}
                        </button>

                      </div>

                    </Form>
                  </aside>

                  <div className='flex-1 py-6 md:px-4 sm:p-6 lg:pb-8'>
                    <MobileMockupNotification content={values.content} title={values.title} />
                  </div>

                </div>
              </div>
            )
          }}
        </Formik>
      </Guard>
    </Layout>
  )
}

export default NewNotification
