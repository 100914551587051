import { useState } from 'react'

import { type ChatMessageAttachmentProps } from '@components/chat-message/chat-message-interfaces'
import Modal from '@components/modals/modal'
import { useChatMessageQuery } from '@services/api/chat'

const ChatMessageAttachment = ({ messageUid }: ChatMessageAttachmentProps) => {
  const [openModalPhoto, setOpenModalPhoto] = useState(false)

  const { data: chatAttachment } = useChatMessageQuery(messageUid)

  const handleClickPhoto = () => {
    setOpenModalPhoto(true)
  }

  return (
    <>
      <button className='flex' onClick={handleClickPhoto}>
        <img alt='' className='rounded-lg bg-gray-100 h-full object-cover' src={chatAttachment?.attachment?.url} />
      </button>

      <Modal center fullscreen open={openModalPhoto} setOpen={setOpenModalPhoto}>
        <div className='flex items-center justify-center'>
          <img alt='' className='rounded-lg bg-gray-100 max-h-[85vh] max-w-[85vw]' src={chatAttachment?.attachment?.url} />
        </div>
      </Modal>
    </>
  )
}

export default ChatMessageAttachment
