import { TrashIcon } from '@heroicons/react/24/outline'

import { type DeleteButtonProps } from '@components/buttons/delete-button/index'
import IconButton from '@components/buttons/icon-button'

const DeleteButton = ({ classNames, onClick }: DeleteButtonProps) => {
  return (
    <IconButton classNames={classNames} icon={TrashIcon} onClick={onClick} style='warning' />
  )
}

export default DeleteButton
