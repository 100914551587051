import { useMutation, type UseMutationOptions, type UseMutationResult } from '@tanstack/react-query'

import { type AudioTemplate, type AudioTemplateBody } from '@interfaces/api/audio-templates'
import useApi from '@services/api/axios'

const useCreateAudioTemplateMutation = (
  options: UseMutationOptions<AudioTemplate, unknown, AudioTemplateBody> = {}
): UseMutationResult<AudioTemplate, unknown, AudioTemplateBody> => {
  const { post } = useApi()

  return useMutation<AudioTemplate, unknown, AudioTemplateBody>({
    mutationFn: async (body: AudioTemplateBody) => await post<AudioTemplateBody, AudioTemplate>('api/audio-templates', body),
    ...options
  })
}

export default useCreateAudioTemplateMutation
