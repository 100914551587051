import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import ApiResourceEdit from '@components/api-resource/api-resource-edit'
import ApiResourceList from '@components/api-resource/api-resource-list'
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import Modal from '@components/modals/modal'
import SectionTitle from '@components/section-title/section-title'
import { type User } from '@interfaces/api/user'
import useApiResource from '@services/api/definition'
import { captureException } from '@services/exceptions/capture-exception'
import { userListRoles } from '@services/roles/roles'
import { Users } from '@svg/icons'

const UsersList = () => {
  const { t } = useTranslation('user', { keyPrefix: 'list' })
  const { t: translateEdit } = useTranslation('apiResources', { keyPrefix: 'edit' })
  const { t: bc } = useTranslation('breadcrumbs')
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const resource = useApiResource('users')
  const userDefinition = useApiResource('users/{id}')

  const [openEdit, setOpenEdit] = useState(false)
  const [user, setUser] = useState<User>()

  const onEditClick = (entry) => {
    const userEntry = entry as User
    setUser(userEntry)
    setOpenEdit(true)
  }

  const onReload = () => {
    queryClient.refetchQueries({ queryKey: ['users'] }).catch((err) => {
      captureException(err as Error)
    })
  }

  const onEdit = () => {
    onReload()
    setOpenEdit(false)
  }

  const onClickHandler = (entry) => {
    const user = entry as User
    navigate(`/users/${user.id}`)
  }

  const breadcrumbs = [
    { href: '', name: bc('users') }
  ]

  return (
    <Layout description={t('description')} title={t('title')}>
      <Guard acceptedRoles={userListRoles}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <SectionTitle description={t('description')} icon={Users} title={t('title')} />

        <>
          {resource &&
            <ApiResourceList definition={resource} onEntryClick={onClickHandler} registerAt={'desc'} withEditOption={onEditClick} />
          }
        </>

        <>
          {user && (
            <Modal center open={openEdit} setOpen={setOpenEdit} size='large' title={translateEdit('users')}>
              {userDefinition && <ApiResourceEdit definition={userDefinition} id={user.id.toString()} onEdit={onEdit} />}
            </Modal>
          )}
        </>
      </Guard>
    </Layout>
  )
}

export default UsersList
