import { useFormik } from 'formik'
import React, { useState } from 'react'

import ButtonLoader from '@components/buttons/button-loader/button-loader'
import {
  type ConfigurationFormProps,
  type DataStoreFormValues,
  FormMode
} from '@pages/configuration/components/configuration-form/data-store-form.interfaces'
import { Add, Edit } from '@svg/icons'

const DataStoreForm = ({ initialValues, isLoading = false, mode, onSubmit }: ConfigurationFormProps) => {
  const [formData, setFormData] = useState<DataStoreFormValues>({
    data: mode === FormMode.creation
      ? JSON.stringify(initialValues?.data || [{}], null, 2)
      : JSON.stringify(JSON.parse(initialValues.data), null, 2),
    key: initialValues.key || ''
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!validateJSON(formData.data)) {
      setErrors({ data: 'Le json est mal formattée' })
      setSubmitting(false)
    } else {
      const dataToSend = formData.data?.replace('[', '')?.replace(']', '')
      setSubmitting(true)
      onSubmit({ data: [JSON.stringify(JSON.parse(dataToSend))], key: values.key })
    }
  }

  const { errors, handleBlur, handleChange, isSubmitting, setErrors, setSubmitting, values } = useFormik({
    initialValues,
    onSubmit: handleSubmit
  })

  const validateJSON = (jsonString) => {
    try {
      JSON.parse(jsonString)

      return true
    } catch (error) {
      return false
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value
    })
  }

  const Icon = mode === FormMode.edition ? Edit : Add

  return (
    <form className='p-4' onSubmit={handleSubmit}>
      <div className='form-group my-2'>
        <div className='flex flex-col w-full'>
          <label className='block font-medium text-gray-700' htmlFor={'key'}>{'Clé'}</label>

          <input
            className='appearance-none block w-full mt-2 px-3 py-2 border-2 border-gray-100 rounded-lg placeholder-gray-300 focus:outline-none'
            disabled={mode === FormMode.edition}
            name={'key'}
            onBlur={handleBlur}
            onChange={handleChange}
            required
            type={'text'}
            value={values.key}
          />

        </div>
      </div>

      <div className='form-group my-2'>
        <label htmlFor='data'>Data</label>

        <textarea
          className={`appearance-none block w-full mt-2 px-3 py-2 border-2 border-gray-100 rounded-lg placeholder-gray-300 focus:outline-none ${errors.data ? 'border-red-500' : 'border-gray-100'}`}
          id='data'
          name='data'
          onChange={handleInputChange}
          style={{
            height: `calc(${formData.data.split('\n').length} * 1.2rem + 50px)`,
            resize: 'none'
          }}
          value={formData.data}
        />

        {errors?.data && (
          <div className='mt-2 text-red-600 font-medium'>{(errors.data)}</div>
        )}
      </div>

      <div className='flex justify-end'>
        <button
          className={`flex gap-2 items-center justify-center rounded-lg px-4 py-2 text-lg font-semibold tracking-wide bg-primary text-white fill-white hover:bg-gray-600 hover:text-white ${'bg-slate-800 hover:bg-slate-700'}`}
          disabled={isSubmitting || isLoading}
          type='submit'
        >
          {isSubmitting || isLoading
            ? <ButtonLoader />
            : (
              <>
                <Icon className='w-6 h-6' />

                {mode === FormMode.edition ? 'Modifier' : 'Créer'}
              </>
            )}
        </button>
      </div>
    </form>
  )
}

export default DataStoreForm
