import { CheckCircleIcon } from '@heroicons/react/24/outline'
import { type UseMutationResult } from '@tanstack/react-query'
import { useFormik } from 'formik'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { queryClient } from '@app'
import { type AudioUploaderProps } from '@components/audio/audio-uploader/audio-uploader-interfaces'
import ButtonLoader from '@components/buttons/button-loader/button-loader'
import { type ApiReponseError, type FormattedApiListResponse } from '@interfaces/api'
import { type AudioTemplate, type AudioTemplateBody } from '@interfaces/api/audio-templates'
import { useUploadAudio } from '@services/api/audio/audio/use-create-audio-mutation'
import useAudioTemplateCategoriesList
  from '@services/api/audio/audio-template-categories/use-audio-template-categories-list'
import useCreateAudioTemplateMutation from '@services/api/audio/audio-templates/use-create-audio-templates-mutation'
import useApiResource from '@services/api/definition'
import { captureException } from '@services/exceptions/capture-exception'
import { getInitialValues } from '@services/tools/api-resources/formik'
import { Export, Volume } from '@svg/icons'

const AudioUploader = ({ onSuccess }: AudioUploaderProps) => {
  const { t: translateAudio } = useTranslation('audio')
  const ref = useRef<HTMLInputElement>(null)
  const [file, setFile] = useState<File>()
  const [uploadComplete, setUploadComplete] = useState<boolean>(false)

  const { data: { data: audioCategories } = {} } = useAudioTemplateCategoriesList()
  const { mutateAsync: uploadAudio } = useUploadAudio()
  const { mutateAsync: createAudioTemplate } = useCreateAudioTemplateMutation()

  const completedStyles = (completed: boolean) => {
    const common = 'absolute -top-[4.5rem] right-0 h-12 flex gap-2 items-center justify-center bg-green-500 border border-green-700 rounded-md shadow text-white text-sm font-bold px-4 transition'

    return `${common} ${completed ? 'opacity-100 scale-100' : 'opacity-0 scale-0'}`
  }

  const onFileChange = async (event) => {
    const file = event?.currentTarget?.files[0]
    await setFieldValue('audio', file)
    setFile(file)
  }

  const definition = useApiResource('audio-templates')
  const requestFields = definition?.methods.post.getRequestFields()
  const initialValues = requestFields && getInitialValues(requestFields, [])

  // Reset style after 5sec
  useEffect(() => {
    if (uploadComplete) {
      setTimeout(() => {
        setUploadComplete(false)
      }, 5000)
    }
  }, [uploadComplete])

  const {
    errors,
    handleChange,
    handleSubmit,
    isSubmitting,
    resetForm,
    setErrors,
    setFieldValue,
    setSubmitting,
    values: { category, title }
  } = useFormik<AudioTemplateBody>({
    initialValues,
    onSubmit: async (values) => {
      let errorMessage = ''

      const formData = new FormData()
      formData.append('file', values.audio)

      try {
        const audio = await uploadAudio(formData)

        if (audio) {
          values.audio = audio
        }
      } catch (e) {
        const err = e as UseMutationResult<ApiReponseError>
        errorMessage = err.data?.['hydra:description'] ?? translateAudio('fileIssue')
        setErrors({ audio: errorMessage })
      }

      if (!errorMessage) {
        setSubmitting(true)
        try {
          const result = await createAudioTemplate(values)
          const categoryUid = result.category.uid

          const template: Partial<AudioTemplate> = {
            '@id': result['@id'],
            '@type': result['@type'],
            audio: result.audio,
            createdAt: result.createdAt,
            position: result.position,
            title: result.title,
            uid: result.uid
          }

          queryClient.setQueryData<FormattedApiListResponse<AudioTemplate>>(
            [`audio-template-categories/${categoryUid}/templates`], (data) => {
              if (data) {
                const templates = data['hydra:member']
                templates.push(template)

                return data
              }
            }
          )

          onSuccess(categoryUid)
        } catch (e) {
          captureException(e as Error)
        } finally {
          setSubmitting(false)
          setUploadComplete(true)
          setFile(undefined)
          resetForm()
          if (ref?.current) {
            ref.current.value = ''
          }
        }
      }
    }
  })

  return (
    <div className='relative m-8 mt-0'>
      <div className={completedStyles(uploadComplete)}>
        <CheckCircleIcon className='w-5 h-5' />

        <span className='text'>{translateAudio('uploadComplete')}</span>
      </div>

      <form onSubmit={handleSubmit}>
        <div className='grid grid-cols-2 gap-4'>
          <div className='flex flex-col'>
            <label className='block text-sm font-medium text-gray-700' htmlFor='title'>{translateAudio('audioTitle')}</label>

            <input
              className='appearance-none block w-full mt-1 px-3 py-2 border rounded-md border-1 border-gray-50 shadow shadow-gray-200 placeholder-gray-400 focus:outline-none focus:border-slate-500 sm:text-sm'
              name='title'
              onChange={handleChange}
              required
              type='text'
              value={title}
            />

            <label className='block text-sm font-medium text-gray-700 mt-4' htmlFor='category'>{translateAudio('selectLabel')}</label>

            <select className='border  text-gray-700 sm:text-sm rounded-lg shadow shadow-gray-200 border-1 border-gray-50 focus:outline-none block w-full p-2 appearance-none cursor-pointer mt-1' id='category' onChange={handleChange} required value={category}>
              <option value=''>{translateAudio('selectCategory')}</option>

              {audioCategories?.map((category) => (
                <option key={category.uid} value={category['@id']}>{category.name}</option>
              ))}
            </select>
          </div>

          <div className='relative w-full flex flex-col gap-1 items-center justify-center bg-white border rounded-md border-1 border-gray-50 shadow shadow-gray-200 placeholder-gray-400 focus:outline-none focus:border-slate-500 sm:text-sm px-3 py-6 group'>
            <input
              className='opacity-0 absolute inset-0 cursor-pointer'
              id='audio'
              name='audio'
              onChange={onFileChange}
              ref={ref}
              required
              type='file'
              value={undefined}
            />

            {!file && (
              <>
                <Export className='w-6 h-6 group-hover:text-gray-700' />

                <span className='leading-6 font-medium text-base'>{translateAudio('upload')}</span>

                <span className='text-gray-500 text-xs'>{translateAudio('uploadDescription')}</span>
              </>
            )}

            {file && (
              <div className='flex items-center px-3 py-2  first:border-0'>
                <Volume className='w-6 h-6 mr-4 text-gray-900' />

                <div className='text-gray-700 text-lg'>
                  {file.name}
                </div>
              </div>
            )}

            {errors?.audio && <div className='mt-2 text-xs text-center text-red-600 font-medium'>{errors.audio}</div>}
          </div>
        </div>

        <button className='mt-4 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-slate-700 focus:outline-none' disabled={isSubmitting} type='submit'>
          {isSubmitting ? <ButtonLoader /> : <span className='font-medium text-base hidden md:flex'>{translateAudio('add')}</span>}
        </button>
      </form>
    </div>
  )
}

export default AudioUploader
