import { useTranslation } from 'react-i18next'

import { type ProfileMoreData, type UserIdProps } from '@pages/user-profile/user-profile-interfaces'
import useUserProfilQuery from '@services/api/user/use-user-profile-query'

const UserMoreData = ({ userId }: UserIdProps) => {
  const { t } = useTranslation('user', { keyPrefix: 'profile' })
  const { t: translateGeneral } = useTranslation('general')

  const { data } = useUserProfilQuery(userId)

  if (!data) {
    return null
  }

  const moreData: ProfileMoreData = JSON.parse(data.profile.moreData?.[0] ?? '{}')
  const weight = data.profile.weight ? `${data.profile.weight}${translateGeneral('weightUnit')}` : '-'
  const waist = moreData?.waist ? `${moreData.waist}${translateGeneral('sizeUnit')}` : '-'
  const arm = moreData?.arm ? `${moreData.arm}${translateGeneral('sizeUnit')}` : '-'
  const thigh = moreData?.thigh ? `${moreData.thigh}${translateGeneral('sizeUnit')}` : '-'

  return (
    <div className='flex flex-col items-start gap-1'>
      <div className='text-lg font-normal text-gray-700'>{t('moreData')}</div>

      <div className='grid gap-1'>
        <div className='bg-gray-100 p-1 rounded-full flex justify-between items-center text-sm gap-2'>
          <div className='text-gray-500 pl-2'>{t('weight')}</div>

          <div className='rounded-full bg-white px-2 py-1'>{weight}</div>
        </div>

        <div className='bg-gray-100 p-1 rounded-full flex justify-between items-center text-sm gap-2'>
          <div className='text-gray-500 p-1 pl-2'>{t('waist')}</div>

          <div className='rounded-full bg-white px-2 py-1'>{waist}</div>
        </div>

        <div className='bg-gray-100 p-1 rounded-full flex justify-between items-center text-sm gap-2'>
          <div className='text-gray-500 p-1 pl-2'>{t('arm')}</div>

          <div className='rounded-full bg-white px-2 py-1'>{arm}</div>
        </div>

        <div className='bg-gray-100 p-1 rounded-full flex justify-between items-center text-sm gap-2'>
          <div className='text-gray-500 p-1 pl-2'>{t('thigh')}</div>

          <div className='rounded-full bg-white px-2 py-1'>{thigh}</div>
        </div>
      </div>
    </div>
  )
}

export default UserMoreData
