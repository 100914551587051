import { useQueryClient } from '@tanstack/react-query'
import { Form, Formik } from 'formik'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import ButtonLoader from '@components/buttons/button-loader/button-loader'
import FormTextField from '@components/form-fields/form-text-field'
import Modal from '@components/modals/modal'
import { type SetGoalsProps } from '@components/set-goals/set-goals-interfaces'
import useCreateEntry from '@services/api/resources/create-entry-query'
import { captureException } from '@services/exceptions/capture-exception'

const SetGoals = ({ module, userProgram }: SetGoalsProps) => {
  const { t: translateProgram } = useTranslation('program')
  const { t: translateAction } = useTranslation('apiResources')

  const { mutateAsync: setGoals } = useCreateEntry({ path: 'user-program-events/set-goals' })

  const [open, setOpen] = useState<boolean>(false)
  const [type, setType] = useState<'size' | 'weight' | null>(null)

  const onEditHandler = () => {
    setOpen(true)
  }
  const handleWeightClick = () => {
    setType('weight')
  }
  const handleSizeClick = () => {
    setType('size')
  }
  const handleResetType = () => {
    setType(null)
  }

  const queryClient = useQueryClient()

  const reload = () => {
    queryClient.refetchQueries({ queryKey: [`api/user-programs/${userProgram}`] }).catch(captureException)
  }

  const initialValues = {
    size: '',
    weight: ''
  }

  const onSubmit = (values, { setSubmitting }) => {
    const payload = {
      goals: values,
      module: `/api/program-modules/${module.uid}`,
      userProgram: `/api/user-programs/${userProgram}`
    }

    setSubmitting(true)

    setGoals(payload).then(() => {
      reload()
      setSubmitting(false)
      setOpen(false)
      handleResetType()
    }).catch(captureException)
  }

  const onDeleteGoals = () => {
    const payload = {
      goals: initialValues,
      module: `/api/program-modules/${module.uid}`,
      userProgram: `/api/user-programs/${userProgram}`
    }

    setGoals(payload).then(() => {
      reload()
      setOpen(false)
    }).catch(captureException)
  }

  const renderGoals = () => {
    if (module.goals?.weight) {
      return (
        <>
          <div className='group-hover:text-white text-gray-700'>{translateAction('labels.weight')}</div>

          <div className='text-gray-500'>{module.goals.weight}</div>
        </>
      )
    }

    if (module.goals?.size) {
      return (
        <>
          <div className='group-hover:text-white text-gray-700'>{translateAction('labels.size')}</div>

          <div className='text-gray-500'>{module.goals.size}</div>
        </>
      )
    }

    return translateProgram('noGoals')
  }

  return (
    <div className='ml-auto'>
      <button className='flex bg-white rounded-md px-2 gap-2 items-center group hover:bg-gray-900 hover:text-white' onClick={onEditHandler}>
        {renderGoals()}
      </button>

      <Modal center onReturn={type !== null ? handleResetType : undefined} open={open} setOpen={setOpen} title={translateProgram('setGoals')}>
        <div className='p-4'>
          {type === null && (
            <div className='flex flex-col gap-4'>
              <div className='grid grid-cols-2 gap-4'>
                <button className='border-2 border-gray-100 px-4 py-6 rounded-lg font-medium' onClick={handleWeightClick}>{translateAction('labels.weight')}</button>

                <button className='border-2 border-gray-100 px-4 py-6 rounded-lg font-medium' onClick={handleSizeClick}>{translateAction('labels.size')}</button>
              </div>

              {(module.goals?.size || module.goals?.weight) && <button className='self-center text-lg font-semibold tracking-wide border border-red-500 rounded-lg px-4 py-2 bg-white text-red-500 fill-red-500 hover:border-red-500 hover:bg-red-500 hover:text-white hover:fill-white' onClick={onDeleteGoals}>{translateAction('labels.delete')}</button>}
            </div>
          )}

          {type !== null && (
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
              {({ isSubmitting }) => {
                return (
                  <Form className='space-y-6'>
                    {type === 'weight' && <FormTextField label={translateAction('labels.weight')} name='weight' required />}

                    {type === 'size' && <FormTextField label={translateAction('labels.size')} name='size' required />}

                    <button
                      className={'w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm font-medium text-white bg-gray-900 focus:outline-none'}
                      disabled={isSubmitting}
                      type='submit'
                    >
                      {isSubmitting ? <ButtonLoader /> : translateAction('actions.submit')}
                    </button>
                  </Form>
                )
              }}
            </Formik>
          )}
        </div>
      </Modal>
    </div>
  )
}

export default SetGoals
