import { PlusIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import DeleteButton from '@components/buttons/delete-button'
import { type MealEntryProps } from '@components/mealplan/meal/meal-interfaces'
import MealIngredient from '@components/mealplan/meal-ingredient/meal-ingredient'
import ModalAddIngredientEntry from '@components/modals/modal-add-ingredient-entry'
import { type IngredientListEntry } from '@interfaces/api/ingredient'
import useApiResource from '@services/api/definition'
import usePatchEntry from '@services/api/resources/patch-entry-query'
import { captureException } from '@services/exceptions/capture-exception'
import { convertMealsToPayload, type MealPayload } from '@services/tools/convert-meals-to-payload'

const MealEntry = ({ dayInfo, label, meal, onReload }: MealEntryProps) => {
  const [open, setOpen] = useState(false)

  const { t: translateMealplan } = useTranslation('mealplan', { keyPrefix: 'dayInfo' })
  const { mutateAsync: editResourceEntry } = usePatchEntry({ id: dayInfo.uid, path: 'mealplan-days/{uid}' })
  const ingredientsDefinition = useApiResource('ingredients')

  const onEditHandler = () => {
    setOpen(true)
  }

  const onIngredientAdd = async (ingredientPayload: IngredientListEntry, mealName: string) => {
    let mealIngredients: MealPayload[]

    if (meal) {
      const meals = meal.mealIngredients
      mealIngredients = convertMealsToPayload(meals)

      mealIngredients.push({ ingredient: ingredientPayload.ingredient, quantity: ingredientPayload.quantity })
    } else {
      mealIngredients = [ingredientPayload]
    }

    const payload = {
      [label]: {
        mealIngredients,
        name: mealName
      }
    }

    try {
      await editResourceEntry(payload)
      onReload()
      setOpen(false)
    } catch (err) {
      captureException(err as Error)
    }
  }

  const onDeleteAll = async () => {
    const payload = {
      [label]: {
        mealIngredients: []
      }
    }

    try {
      await editResourceEntry(payload)
      onReload()
    } catch (err) {
      captureException(err as Error)
    }
  }

  const onDeleteEntry = async (uid) => {
    const meals = meal.mealIngredients
    const filteredMeals = meals.filter(meal => meal.uid !== uid)
    const mealIngredients = convertMealsToPayload(filteredMeals)

    const payload = {
      [label]: {
        mealIngredients,
        name: meal.name ?? null
      }
    }

    try {
      await editResourceEntry(payload)
      onReload()
    } catch (err) {
      captureException(err as Error)
    }
  }

  return (
    <div className='bg-white rounded-lg p-1'>
      <div className='group flex justify-between px-2 py-1 font-medium'>
        {translateMealplan(label)}

        <div className='flex gap-2'>
          <div className='group-hover:visible invisible flex gap-1'>
            <DeleteButton onClick={onDeleteAll} />
          </div>
        </div>
      </div>

      <div className=''>
        {/* <MealName editResourceEntry={editResourceEntry} label={label} meal={meal} onReload={onReload} /> */}

        {meal
          ? (
            <div className='flex flex-col mb-1 border-2 pl-2 py-0.5 gap-0.5 border-gray-100 rounded-[10px]'>
              {meal.mealIngredients.map((ingredient) => {
                return (
                  <MealIngredient
                    editResourceEntry={editResourceEntry}
                    ingredient={ingredient}
                    key={ingredient.uid}
                    meal={meal}
                    onDeleteEntry={onDeleteEntry}
                    onReload={onReload}
                    type={label}
                  />
                )
              })}
            </div>
          )
          : (
            <div className='flex flex-col p-6 text-center'>
              <div className='text-gray-900 text-sm'>{translateMealplan('noMeal')}</div>

              <div className='text-gray-500 text-xs'>{translateMealplan('addMeal')}</div>
            </div>
          )
        }

        <button className='w-full flex items-center justify-center bg-gray-100 rounded-lg p-1' onClick={onEditHandler}>
          <PlusIcon className='w-4 h-4' />
        </button>

        {ingredientsDefinition &&
          <ModalAddIngredientEntry definition={ingredientsDefinition} mealName={meal?.name} onAdd={onIngredientAdd} open={open} setOpen={setOpen} />
        }
      </div>
    </div>
  )
}

export default MealEntry
