import { useCombobox } from 'downshift'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { type ApiResourceAutocompleteProps } from '@components/api-resource/api-resource-autocomplete/api-resource-autocomplete.interfaces'
import { type ApiPlatformEntity, type ApiPlatformListResponse } from '@interfaces/api/api'
import SearchMap from '@services/api/definition/search-map'
import useApiResourceListQuery from '@services/api/resources/list-query'
import { formatApiListResponse } from '@services/tools/api-resources/format-list-response'

const ApiResourceAutocomplete = <T extends ApiPlatformEntity>({ field, onChange, value }: ApiResourceAutocompleteProps<T>) => {
  const { t } = useTranslation('apiResources')
  const [searchTerm, setSearchTerm] = useState('')

  const { path = '', searchField = '' } = SearchMap[field] ?? {}

  const definition = {
    name: path,
    url: ''
  }

  const {
    data = [] as unknown as ApiPlatformListResponse<T>
  } = useApiResourceListQuery<T>({
    definition,
    parameters: {
      [searchField]: searchTerm
    }
  })

  const items = useMemo(() => {
    return formatApiListResponse(data)?.data
  }, [data])

  const {
    getInputProps,
    getItemProps,
    getMenuProps,
    isOpen
  } = useCombobox({
    initialInputValue: value?.[searchField],
    items,
    itemToString: (item): string => {
      return item?.[searchField]
    },
    onInputValueChange ({ inputValue }) {
      setSearchTerm(inputValue ?? '')
    },
    onStateChange: ({ selectedItem }) => {
      if (selectedItem) {
        onChange(selectedItem)
      }
    }
  })

  if (data && field in SearchMap) {
    return (
      <div>
        <div className='relative z-10'>
          <div>
            <input
              {...getInputProps()}
              className='appearance-none block w-full mt-2 px-3 py-2.5 border-2 border-gray-100 rounded-lg placeholder-gray-400 focus:outline-none sm:text-sm'
              placeholder={t('actions.findInExisting')}
            />
          </div>

          <ul {...getMenuProps()} className='absolute bg-white rounded overflow-auto max-h-72 shadow text-gray-700'>
            {isOpen
              ? items.map((item, index) => (
                <li
                  {...getItemProps({
                    className: 'px-3 py-2 hover:bg-blue-500 hover:text-white hover:text-white hover:cursor-pointer',
                    index,
                    item,
                    key: item.uid
                  })}
                >
                  {item?.[searchField]}
                </li>
              ))
              : null}
          </ul>
        </div>
      </div>
    )
  }

  return <></>
}

export default ApiResourceAutocomplete
