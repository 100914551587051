import { ShoppingBagIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import ApiResourceList from '@components/api-resource/api-resource-list'
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import PurchaseListEntry from '@components/purchase-list-entry/purchase-list-entry'
import SectionTitle from '@components/section-title/section-title'
import { type Purchase } from '@interfaces/api/purchase'
import useApiResource from '@services/api/definition'
import { userListRoles } from '@services/roles/roles'

const PurchasesList = () => {
  const { t } = useTranslation('purchase', { keyPrefix: 'list' })
  const { t: bc } = useTranslation('breadcrumbs')
  const navigate = useNavigate()
  const resource = useApiResource('purchases')

  const onClickHandler = (entry) => {
    const purchase = entry as Purchase
    navigate(`/users/${purchase.user.id}`)
  }

  const breadcrumbs = [
    { href: '', name: bc('purchases') }
  ]

  return (
    <Layout description={t('description')} title={t('title')}>
      <Guard acceptedRoles={userListRoles}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <SectionTitle description={t('description')} icon={ShoppingBagIcon} title={t('title')} />

        <>
          {resource &&
            <ApiResourceList customFieldsToDisplay={['orderId', 'user', 'createdAt']} definition={resource} entryComponent={PurchaseListEntry} onEntryClick={onClickHandler} />
          }
        </>
      </Guard>
    </Layout>
  )
}

export default PurchasesList
