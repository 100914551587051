import { type UseMutationResult } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { queryClient } from '@app'
import EditButton from '@components/buttons/edit-button'
import Modal from '@components/modals/modal'
import { type AddCoachProps } from '@components/user/coach-profile/coach-profile-interfaces'
import { type ApiReponseError } from '@interfaces/api'
import { type UserProfile } from '@interfaces/api/user-profile'
import usePatchEntry from '@services/api/resources/patch-entry-query'
import useUsersCoachesListQuery from '@services/api/user/use-users-coaches-list-query'
import { captureException } from '@services/exceptions/capture-exception'

const AddCoach = ({ userId }: AddCoachProps) => {
  const { t } = useTranslation('user', { keyPrefix: 'profile' })
  const { mutateAsync: editResourceEntry } = usePatchEntry({ id: userId.toString(), path: 'users/{uid}/coach' })

  const {
    data: {
      data: coaches
    } = {}
  } = useUsersCoachesListQuery()

  const [openModal, setOpenModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const handleClick = () => {
    setOpenModal(true)
  }

  const onCoachClick = (coachId) => async () => {
    const values = {
      coach: coachId
    }

    try {
      const result = await editResourceEntry(values) as UserProfile

      queryClient.setQueryData<UserProfile>(
        ['users', userId], (data) => {
          if (data) {
            const newProfile = { ...data }
            newProfile.coach = result.coach

            return newProfile
          }
        }
      )

      setOpenModal(false)
    } catch (err) {
      captureException(err as Error)
      const error = err as UseMutationResult<ApiReponseError>
      const errorCode = error.data?.['hydra:description'] ?? ''
      setErrorMessage(errorCode)
    }
  }

  return (
    <div className='flex gap-2'>
      <EditButton onClick={handleClick} />

      <Modal center open={openModal} setOpen={setOpenModal} title={t('addCoach')}>
        <div className='flex items-center justify-center flex-col py-8 gap-4'>
          {coaches?.map((coach) => {
            const fullName = coach?.firstName && coach?.lastName ? `${coach.firstName} ${coach.lastName}` : ''

            return (
              <button className='px-4 py-2 border-2 rounded-lg font-bold cursor-pointer text-xl border-gray-100 bg-white text-gray-700 hover:border-gray-700 hover:bg-gray-800 hover:text-white ' key={coach.id} onClick={onCoachClick(coach['@id'])}>{fullName}</button>
            )
          })}

          {errorMessage && <div className='text-red-700'>{errorMessage}</div>}
        </div>
      </Modal>
    </div>
  )
}

export default AddCoach
